import { atom, useRecoilState } from 'recoil';

const collapsibleControlLayoutRecoil = atom<boolean>({
  key: 'collapsibleControlLayoutRecoil',
  default: true,
});

export function useShowCollapsibleControlLayout(): any {
  const [showCollapsibleControl, setShowCollapsibleControl] = useRecoilState(collapsibleControlLayoutRecoil);
  return [showCollapsibleControl, setShowCollapsibleControl];
}
