import React from 'react';
import { Table } from 'antd';
interface Props {
  value?: any;
}

export default function TableDetail(props: Props) {
  const { value } = props;
  const columns = [
    {
      title: 'Category',
      dataIndex: 'category_name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'Note',
      dataIndex: 'note',
    },
  ];

  return (
    <Table
      columns={columns}
      className="inline-form-table table-master-style"
      pagination={false}
      dataSource={value ?? []}
      expandIcon={() => <span />}
      expandedRowKeys={value?.map((data) => data.key)}
    />
  );
}
