import React, { useEffect } from 'react';
import { Button, Drawer, DrawerProps, FormInstance } from 'antd';
import { FormBuilder, FormBuilderProps } from '../../form-components';
import { omit } from 'lodash';
import { Form, Spin } from 'antd';
import { useMediaQuery } from '@base-hooks';

export interface DrawerFilterProps extends DrawerProps {
  formBuilderProps?: FormBuilderProps;
  defaultValueFilter?: any;
  onSubmitFilter?(payload: any): void;
  onValuesChange?(item: any, values: any, form: FormInstance): void;
}

export function DrawerFilter(props: DrawerFilterProps) {
  const { formBuilderProps, defaultValueFilter, onSubmitFilter, onClose, onValuesChange } = props;
  const [form] = Form.useForm();

  const isMobile = useMediaQuery({ media: 'mobile' });

  const InitDrawerProps: DrawerProps = {
    ...omit(props ?? {}, ['formBuilderProps', 'defaultValueFilter', 'onSubmitFilter']),
    width: isMobile ? '100%' : props.width ?? '500',
    title: props.title ?? 'Filters',
  };

  async function onFinish(payload: any) {
    if (onSubmitFilter) await onSubmitFilter(payload);
    if (onClose) onClose(undefined);
  }

  async function onReset() {
    await form.resetFields();
    const fields = form.getFieldsValue();
    const payload = {};
    for (const key of Object.keys(fields)) {
      Object.assign(payload, { [key]: undefined });
    }
    if (onSubmitFilter) await onSubmitFilter(payload);
  }

  useEffect(() => {
    if (defaultValueFilter) form.setFieldsValue(defaultValueFilter);
  }, [defaultValueFilter]);

  function FooterDrawer() {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Button
          style={{ marginRight: '5px' }}
          onClick={() => {
            onReset();
          }}
        >
          Reset
        </Button>
        <Button type="primary" onClick={() => form.submit()}>
          Filter
        </Button>
      </div>
    );
  }
  return (
    <Drawer {...InitDrawerProps} footer={FooterDrawer()} footerStyle={{ height: '50px' }}>
      <Form
        form={form}
        layout="vertical"
        name="form-filter"
        scrollToFirstError
        onFinish={onFinish}
        onValuesChange={(item, values) => {
          if (onValuesChange) onValuesChange(item, values, form);
        }}
      >
        <FormBuilder {...formBuilderProps} />
      </Form>
    </Drawer>
  );
}
