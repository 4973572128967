import { atom, useRecoilState } from 'recoil';

const collapsibleLayoutRecoil = atom<boolean>({
  key: 'collapsibleLayoutRecoil',
  default: false,
});

export function useCollapsibleLayout(): any {
  const [collapsed, setCollapsed] = useRecoilState(collapsibleLayoutRecoil);
  return [collapsed, setCollapsed];
}
