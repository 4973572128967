import React from 'react';
import { Layout } from 'antd';
const { Sider: SidebarContent } = Layout;
import { MenuComponent } from '../menu';
import { useCollapsibleLayout, useSidebarThemeLayout } from '@base-hooks';
import SmallImage from '@base-images/logo-extra-large.png';
import LargeImage from '@base-images/logo-extra-large.png';
import { ArrowLineRight } from 'phosphor-react';

import '../styles/sidebar.less';

import { DataMenu } from '../menu/data-menu';
import { BaseMenuEntity } from '@base-entities';
import { WebUrlData } from '@base-configs';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { omit } from 'lodash';

export default function Sidebar() {
  const { authState } = useRecoilValue(loginAccountState);
  const [collapsed] = useCollapsibleLayout();
  const [sidebarTheme] = useSidebarThemeLayout();
  const account = authState.getAccount();

  const RegionMenu = [
    WebUrlData.map,
    WebUrlData.dashboard,
    WebUrlData.activity,
    WebUrlData.crm_board,
    WebUrlData.report,
  ];

  const pastoralHubMenu: BaseMenuEntity = {
    label: 'Pastoral HUB',
    key: 'redirect-pastoral-hub',
    icon: <ArrowLineRight size={16} />,
    onClick() {
      window.open(
        process.env.REACT_APP_BASE_URL + 'v1/users/pastoral-hub' + `?token=${account['access_token']}`,
        '_blank',
      );
    },
  };

  function filterMenu(menu: BaseMenuEntity[]): BaseMenuEntity[] {
    const scope = account?.scope;
    let newMenu = menu;

    if (scope === 'global') newMenu = menu;
    else newMenu = menu.filter((item) => RegionMenu.includes(item.key));

    newMenu = [...newMenu, pastoralHubMenu];
    return newMenu.map((item) => omit(item, ['children']));
  }

  return (
    <div className="custom-sidebar-wrapper">
      <SidebarContent
        theme={sidebarTheme}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ minHeight: '100vh' }}
        collapsedWidth={65}
      >
        <div
          style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}
          className={`logo-wrapper ${collapsed ? 'collapsed' : 'no-collapsed'}`}
        >
          <img src={collapsed ? SmallImage : LargeImage} height={collapsed ? '28' : '43'} />
        </div>

        <MenuComponent
          mode="inline"
          theme={sidebarTheme}
          dataMenu={filterMenu(DataMenu)}
          defaultSelectedKeys={['1']}
          style={{
            paddingRight: !collapsed ? '10px' : '0',
            paddingLeft: !collapsed ? '10px' : '0',
          }}
        />
      </SidebarContent>
    </div>
  );
}
