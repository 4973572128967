import { ApiUrlData } from '@base-configs';
export const ReadStatusOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const formBuilderProps: any = {
  columns: [
    {
      fields: [
        // {
        //   name: 'title',
        //   label: 'Title',
        //   fieldType: 'inputText',
        //   gridColumn: { span: 12 },
        // },
        {
          name: 'content',
          label: 'Content',
          fieldType: 'inputText',
          gridColumn: { span: 12 },
        },
        {
          name: 'read_status',
          label: 'Read Status',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            options: ReadStatusOptions,
            customLabel(value: any): string {
              return ReadStatusOptions.find((item) => value === item.value).label;
            },
          },
        },
        // {
        //   name: 'is_read_by_id',
        //   label: 'Read By',
        //   fieldType: 'inputText',
        //   gridColumn: { span: 12 },
        // },
        {
          name: 'created_at',
          label: 'Created At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'created_ids',
          label: 'Created By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.users,
            customLabel(row) {
              return row?.first_name;
            },
          },
        },
        {
          name: 'updated_at',
          label: 'Read At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'updated_ids',
          label: 'Updated By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.users,
            customLabel(row) {
              return row?.first_name;
            },
          },
        },
      ],
    },
  ],
};
