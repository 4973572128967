import React from 'react';
import { useRecoilValue } from 'recoil';
import { Form } from 'antd';

import { FormBuilder } from '@base-components';
import { ApiUrlData } from '@base-configs';
import { loginAccountState } from '@base-states';

import { useBoardContextValue } from '../../context';
import {
  AddressOptions,
  ScopeOptions,
  SendMethod,
  SendMethodOptions,
  SizeOptions,
  TaskCategoryOptions,
  VenueTypeOptions,
} from '../../../../helpers/options';

import './index.less';
import { ScopeValue } from '@pages/dashboard-pages/presentation/helpers';

export default function DrawerFormBody(): JSX.Element {
  const { actionForm } = useBoardContextValue();
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();

  const scope_ls = authUser?.scope;
  const region_ids = authUser?.scope_regions ?? [];
  const sub_region_ids = authUser?.scope_sub_regions ?? [];
  const sub_region_tpi_ids = authUser?.scope_sub_regions_tpi ?? [];

  const sub_region = authUser?.data_sub_regions ?? [];
  const sub_region_tpi = authUser?.data_sub_regions_tpi ?? [];

  const region_ids_ls = authUser?.data_regions ?? [];
  const sub_region_ids_ls = [...sub_region, ...sub_region_tpi];

  function makeDefaultScope(scope) {
    if (scope === ScopeValue.global) return scope;
    else if (scope === ScopeValue.region) {
      if (region_ids_ls.length > 0) return scope;
      else if (sub_region_ids_ls?.length > 0) return ScopeValue.sub_region;
    }
    return scope;
  }

  function filterScopeOptions(options, scope) {
    const newScope = makeDefaultScope(scope);
    if (newScope === ScopeValue.global) return options;
    else if (newScope === ScopeValue.region) return options.filter((item) => item.value !== ScopeValue.global);
    else if (newScope === ScopeValue.sub_region) return options.filter((item) => item.value === ScopeValue.sub_region);
  }

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const values = getFieldsValue();
          const scope = values?.scope;

          return (
            <FormBuilder
              className="drawer-form-general"
              columns={[
                {
                  fields: [
                    {
                      name: 'task_category',
                      label: 'Task Category',
                      fieldType: 'select',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      selectProps: {
                        options: TaskCategoryOptions,
                        customLabel(value) {
                          return TaskCategoryOptions.find((item) => value === item.value)?.label;
                        },
                      },
                    },
                    {
                      name: 'workflow',
                      label: 'Status',
                      fieldType: 'selectPaginate',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      selectPaginateProps: {
                        dataSourceUrl: ApiUrlData.workflow,
                        isDisabled: actionForm === 'create',
                        customLabel: (row) => row?.name,
                      },
                    },
                    {
                      name: 'summary',
                      label: 'Subject',
                      fieldType: 'inputText',
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      inputTextProps: {
                        placeholder: 'Enter your subject',
                      },
                    },
                    {
                      name: 'scope',
                      label: 'Scope',
                      fieldType: 'select',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      selectProps: {
                        // options:
                        //   scope_ls === 'global' ? ScopeOptions : ScopeOptions.filter((item) => item.value !== 'global'),
                        options: filterScopeOptions(ScopeOptions, scope_ls),
                        customLabel(value) {
                          return ScopeOptions.find((item) => value === item.value)?.label;
                        },
                      },
                    },
                    {
                      name: 'region',
                      label: 'Region',
                      fieldType: 'selectPaginate',
                      gridColumn: scope === 'region' ? { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } : { span: 0 },
                      hidden: scope !== 'region',
                      selectPaginateProps: {
                        dataSourceUrl: ApiUrlData.region,
                        customFilterRequest: () => {
                          return {
                            // region_ids,
                            status: 'Active',
                          };
                        },
                        customLabel: (value) => {
                          return value?.rc_dpw_name;
                        },
                        transformOptions(options) {
                          if (scope_ls === 'global') return options;
                          return options
                            .map((item) => {
                              if (region_ids.includes(item?.id)) return item;
                              return undefined;
                            })
                            .filter(Boolean);
                        },
                      },
                    },
                    {
                      name: 'sub_region',
                      label: 'Church',
                      fieldType: 'selectPaginate',
                      gridColumn: scope === 'sub_region' ? { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } : { span: 0 },
                      hidden: scope !== 'sub_region',
                      selectPaginateProps: {
                        dataSourceUrl: ApiUrlData.sub_region,
                        customLabel: (value) => {
                          return value?.church_name;
                        },
                        customFilterRequest: () => {
                          return {
                            status: 'Active',
                            // region_ids,
                            // sub_region_ids: [...sub_region_ids, ...sub_region_tpi_ids],
                          };
                        },
                      },
                    },
                    {
                      name: 'description',
                      label: 'Message Body',
                      fieldType: 'textArea',
                      gridColumn: { span: 24 },
                      textAreaProps: {
                        placeholder: 'Enter your message body here',
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const value = getFieldsValue();
          const address = value?.address;
          const taskCategory = value?.task_category;
          const venueType = value?.venue_type;

          return (
            <>
              <FormBuilder
                className="drawer-form-general"
                columns={[
                  {
                    fields: [
                      {
                        name: 'send_method',
                        label: 'Send Method',
                        fieldType: 'select',
                        // customHidden() {
                        //   return scope_ls !== 'global';
                        // },
                        rules: [{ required: taskCategory === 'send_email' && scope_ls === 'global' }],
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectProps: {
                          placeholder: 'Choose One',
                          defaultValue: 'automatic',
                          // options:
                          //   scope_ls === 'global'
                          //     ? SendMethodOptions
                          //     : SendMethodOptions.filter((item) => item.value !== SendMethod.automatic),

                          options: SendMethodOptions,
                          customLabel(value) {
                            return SendMethodOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                      {
                        name: 'size',
                        label: 'Size',
                        fieldType: 'select',
                        style: { display: taskCategory !== 'meeting' && 'none' },
                        gridColumn: { span: taskCategory === 'meeting' ? 8 : 0 },
                        selectProps: {
                          placeholder: 'Choose One',
                          options: SizeOptions,
                          customLabel(value) {
                            return SizeOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                      {
                        name: 'assignee',
                        label: 'Assignee',
                        fieldType: 'selectPaginate',
                        rules: [{ required: true }],
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customKey: (form) => {
                            const data = form.getFieldsValue();
                            return JSON.stringify(data ?? {});
                          },
                          customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                          customFilterRequest: (payload) => {
                            const regionID = payload?.region ? payload?.region?.id : null;
                            const subRegionID = payload?.sub_region ? payload?.sub_region?.id : null;
                            return {
                              region_ids: regionID,
                              sub_region_ids: subRegionID,
                            };
                          },
                        },
                      },
                      {
                        name: 'recipient',
                        label: 'Recipient',
                        fieldType: 'selectPaginate',
                        rules: [{ required: taskCategory === 'send_email' }],
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: taskCategory === 'send_email' ? 8 : 0 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                        },
                      },
                      {
                        name: 'participant',
                        label: 'Participant',
                        fieldType: 'selectPaginate',
                        // rules: [{ required: taskCategory !== 'send_email' }],
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 8 : 0 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                        },
                      },
                      {
                        name: 'email_template',
                        label: 'Email Template',
                        customHidden(form) {
                          const sendMethod = form.getFieldValue('send_method');
                          return sendMethod === 'manual' ? true : false;
                        },
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one',
                          dataSourceUrl: ApiUrlData.email_template,
                          customLabel: (row) => row?.template_name,
                          customFilterRequest() {
                            return {
                              status: 'Active',
                              is_droplist: true,
                            };
                          },
                        },
                      },
                      {
                        name: 'date_plan',
                        label: 'Date Plan',
                        fieldType: 'datePicker',
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: 8 },
                        datePickerProps: {
                          showTime: true,
                        },
                      },
                      {
                        name: 'cc',
                        label: 'Cc',
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                        },
                      },
                      {
                        name: 'bcc',
                        label: 'Bcc',
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                        },
                      },
                      {
                        name: 'venue_type',
                        label: 'Venue Type',
                        fieldType: 'select',
                        // rules: [{ required: taskCategory === 'meeting' || taskCategory === 'training' }],
                        style: {
                          display: taskCategory === 'meeting' || taskCategory === 'training' ? 'block' : 'none',
                        },
                        gridColumn: { span: taskCategory === 'meeting' || taskCategory === 'training' ? 8 : 0 },
                        selectProps: {
                          placeholder: 'Choose one',
                          options: VenueTypeOptions,
                          customLabel(value) {
                            return VenueTypeOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                    ],
                  },
                ]}
              />

              {taskCategory && (
                <FormBuilder
                  title={venueType === 'online' || venueType === 'on_site' ? 'Venue Type' : ''}
                  columns={[
                    {
                      fields: [
                        {
                          name: 'link',
                          label: 'Link',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Link',
                          },
                        },
                        {
                          name: 'meeting_id',
                          label: 'Meeting ID',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Meeting ID',
                          },
                        },
                        {
                          name: 'password',
                          label: 'Password',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Password',
                          },
                        },
                        {
                          name: 'address',
                          label: 'Address',
                          fieldType: 'select',
                          style: { display: venueType === 'on_site' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'on_site' ? 8 : 0 },
                          selectProps: {
                            placeholder: 'Choose One',
                            options: AddressOptions,
                            customLabel(value) {
                              return AddressOptions.find((item) => value === item.value)?.label;
                            },
                          },
                        },
                        {
                          name: 'sub_region_location',
                          label: 'Church Location',
                          fieldType: 'selectPaginate',
                          gridColumn: { span: venueType === 'on_site' && address === 'sub_region' ? 8 : 0 },
                          selectPaginateProps: {
                            placeholder: 'Choose One',
                            dataSourceUrl: ApiUrlData.sub_region,
                            customLabel: (row) => row?.church_address || '-',
                            transformOptions(options) {
                              if (!options) return [];
                              return options.map((item) => {
                                return {
                                  ...item,
                                  id: String(item.id),
                                };
                              });
                            },
                          },
                        },
                        {
                          name: 'location',
                          label: 'Other Location',
                          fieldType: 'inputText',
                          gridColumn: { span: venueType === 'on_site' && address === 'other' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Location',
                          },
                        },
                        {
                          name: 'google_map_link',
                          label: 'Google Map Link',
                          fieldType: 'inputText',
                          gridColumn: { span: venueType === 'on_site' && address === 'other' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Link',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
}
