import { RouteEntity } from '@base-entities';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

const currentRouteRecoil = atom<RouteEntity>({
  key: 'currentRouteRecoil',
  default: undefined,
});

interface DefaultRouteProps extends RouteEntity {
  [key: string]: any;
}

export function useCurrentRoute(defaultRoute?: DefaultRouteProps): any[] {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useRecoilState(currentRouteRecoil);

  useEffect(() => {
    if (defaultRoute) {
      const isAction = defaultRoute.path.split('/').includes('action');
      setCurrentRoute({ ...defaultRoute, ...location, path: isAction ? location.pathname : defaultRoute.path });
    }
  }, [defaultRoute, location]);

  return [currentRoute, setCurrentRoute];
}
