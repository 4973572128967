import React, { useEffect, useState } from 'react';
import { makeScreenSize, useMediaQuery } from '@base-hooks';
import Draggable, { DraggableCore } from 'react-draggable'; // Both at the same time
import PreviewIndicator from './preview-indicator';
import './react-draggable.less';
import { getDraggableIndicatorLayoutFromLS, saveDraggableIndicatorLayoutToLS } from './react-draggable.helper';
import { omit } from 'lodash';

interface Props {
  data: any[];
  loading: boolean;
}

export default function ReactDraggable(props: Props) {
  const { data, loading } = props;
  const [activeDrags, setActiveDrags] = useState(0);

  const isXs = useMediaQuery({ customQuery: makeScreenSize({ maxSize: 576 }) });
  const isS = useMediaQuery({ customQuery: makeScreenSize({ minSize: 577, maxSize: 767 }) });
  const isM = useMediaQuery({ customQuery: makeScreenSize({ minSize: 768, maxSize: 991 }) });
  const isL = useMediaQuery({ customQuery: makeScreenSize({ minSize: 992, maxSize: 1199 }) });
  const isXl = useMediaQuery({ customQuery: makeScreenSize({ minSize: 1200, maxSize: 1399 }) });
  const isXxl = useMediaQuery({ customQuery: makeScreenSize({ minSize: 14000 }) });

  const [showContent, setShowContent] = useState(true);

  const [olsMediaLs, setOldMediaLs] = useState('l');
  let mediaLs = 'l';

  if (isXs) mediaLs = 'xs';
  else if (isS) mediaLs = 's';
  else if (isM) mediaLs = 'm';
  else if (isL) mediaLs = 'l';
  else if (isXl) mediaLs = 'xl';
  else if (isXxl) mediaLs = 'xxl';

  useEffect(() => {
    if (mediaLs !== olsMediaLs) {
      setShowContent(false);
      setTimeout(() => {
        setShowContent(true);
      }, 0);
    }
  }, [mediaLs]);

  function handleDrag(e, ui, id) {
    const newUi = { id, ...omit(ui, 'node') };
    const oldUiFromLs = getDraggableIndicatorLayoutFromLS('layout') ?? {};
    let newNode = [];

    if (!oldUiFromLs[mediaLs]) {
      newNode = [newUi];
    } else {
      const findNode = oldUiFromLs[mediaLs].find((item) => item.id === id);
      if (findNode) {
        newNode = oldUiFromLs[mediaLs].map((item) => {
          if (item.id === id) return newUi;
          return item;
        });
      } else {
        newNode = [...oldUiFromLs[mediaLs], newUi];
      }
    }

    Object.assign(oldUiFromLs, {
      [mediaLs]: newNode,
    });
    saveDraggableIndicatorLayoutToLS('layout', oldUiFromLs);
  }

  function onStart() {
    setActiveDrags((prev) => ++prev);
  }

  function onStop() {
    setActiveDrags((prev) => --prev);
  }

  const dragHandlers = { onStart: onStart, onStop: onStop };
  const isMobile = useMediaQuery({ media: 'mobile' });

  if (!showContent) return <div></div>;
  return (
    <div style={{ height: 'fit-content', position: 'relative', overflow: 'auto', padding: '0' }}>
      <div className="react-draggable-indicator">
        {data.map((item) => {
          const originalLayouts = getDraggableIndicatorLayoutFromLS('layout') ?? {};
          const ui = originalLayouts[mediaLs]?.find((itemG) => itemG.id === item.id);
          const x = ui?.x;
          const y = ui?.y;

          return (
            <div key={item.id ?? item._id}>
              <Draggable
                onDrag={(e, ui) => handleDrag(e, ui, item.id ?? item._id)}
                {...dragHandlers}
                position={x && y ? { x, y } : undefined}
              >
                <div className="box">
                  <PreviewIndicator
                    key={item.id ?? item._id}
                    dataIndicator={item}
                    style={{ width: isMobile ? '100vw' : 'fit-content', minWidth: 300 }}
                  />
                </div>
              </Draggable>
            </div>
          );
        })}
      </div>
    </div>
  );
}
