import React, { useEffect, useState } from 'react';
import { authHelper } from '@base-helpers';
import cubejs from '@cubejs-client/core';

import moment from 'moment';
import { round } from 'mathjs';

import { WidthProvider, Responsive } from 'react-grid-layout';
import { getChartLayoutFromLS, resetChartLayout, saveChartLayoutToLS } from './helper';
import { makeScreenSize } from '@base-hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

import PreviewChart from './preview-chart';
import { CubeProvider } from '@cubejs-client/react';
import { Button, Card } from 'antd';
import { chartLayoutState } from '../../state';

const API_URL = `${process.env.REACT_APP_BASE_CUBE_API_URL}/v1`;
const CUBEJS_TOKEN = authHelper.getAuthToken();
const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });

interface Props {
  data: any[];
  showDelete: boolean;
  showUpdate: boolean;
  handleDelete(payload: any): Promise<void>;
  loading: boolean;
}

export default function LayoutChart(props: Props) {
  const { data, showDelete, showUpdate, handleDelete, loading } = props;

  const originalLayouts = getChartLayoutFromLS('layouts') || {};
  const [layouts, setLayouts] = useRecoilState(chartLayoutState);

  const mediaXS = window.matchMedia(makeScreenSize({ minSize: 593, maxSize: 880 }))?.matches;
  const mediaSM = window.matchMedia(makeScreenSize({ minSize: 881, maxSize: 1108 }))?.matches;
  const mediaMD = window.matchMedia(makeScreenSize({ minSize: 1109, maxSize: 1312 }))?.matches;
  const mediaLG = window.matchMedia(makeScreenSize({ minSize: 1312 }))?.matches;

  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const scope = authUser.scope;

  const xs = 1;
  const sm = 2;
  const lg = 2;
  const md = 2;

  let totalContent = 1;
  if (mediaXS) totalContent = xs;
  else if (mediaSM) totalContent = sm;
  else if (mediaMD) totalContent = md;
  else if (mediaLG) totalContent = lg;

  function onLayoutChange(layout, layouts) {
    saveChartLayoutToLS('layouts', layouts);
    setLayouts(layouts);
  }

  useEffect(() => {
    setLayouts(JSON.parse(JSON.stringify(originalLayouts)));
  }, []);

  return (
    <div className="grip-layout-wrapper">
      {/* {(!data || data?.length === 0) && (
        <Card className="card-content-wrapper" style={{ marginBottom: 20 }}>
          <div style={{ textAlign: 'center', paddingTop: 30, paddingBottom: 30 }}>
            {loading ? 'Load content' : 'No chart content.'}
          </div>
        </Card>
      )} */}
      {data.length > 0 && (
        <CubeProvider cubejsApi={cubejsApi}>
          <ResponsiveReactGridLayout
            isDraggable
            isResizable
            rowHeight={30}
            margin={[15, 15]}
            containerPadding={[0, 0]}
            layouts={layouts ? layouts : undefined}
            onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
            cols={{ lg: 12, md: 12, sm: 10, xs: 6, xxs: 2 }}
            draggableCancel=".dashboard-item-no-drag"
            draggableHandle=".dashboard-item-drag-control"
          >
            {data.map((itemDta, index) => {
              const w = 6;
              const h = 11;
              const minW = 6;
              const minH = 11;

              const newIndex = index % totalContent;
              const x = newIndex * w;
              let y = round(index / totalContent - 0.5);
              if (y <= 0) y = 0;

              return (
                <div key={itemDta.id ?? itemDta._id} data-grid={{ w, h, x, y, minW, minH }}>
                  <PreviewChart
                    dataChart={itemDta}
                    showDelete={showDelete}
                    showUpdate={showUpdate}
                    handleDelete={handleDelete}
                  />
                </div>
              );
            })}
          </ResponsiveReactGridLayout>
        </CubeProvider>
      )}
    </div>
  );
}
