import React from 'react';
import { RouteEntity } from '@base-entities';
import { WebUrlData } from '@base-configs';
import { LoginPage } from '@pages/authentication-pages/login/presentation/pages/auth';

export const Login = () => {
  return <div>This is a login page</div>;
};

export const NoAuthRoutes: RouteEntity[] = [
  {
    label: 'Login',
    path: WebUrlData.login,
    element: LoginPage,
  },
  {
    label: 'Login Token',
    path: WebUrlData.login_token,
    element: LoginPage,
  },
];
