import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import * as _ from 'lodash';

export class UserTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, {
      title_id: 1,
      profile_image: payload?.profile_image ?? undefined,
      phone: payload?.phone ?? undefined,
    });
    return cleanVal;
  }

  transformerGetIndex(payload: E[]): E[] {
    console.log(payload);
    return payload;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    // Object.assign(cleanVal, {
    //   title_id: 1,
    // });
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }
}
