import React, { useState } from 'react';
import { RenderAddress, SelectPaginate, ServerSideExpandableFormTable } from '@base-components';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';
import * as _ from 'lodash';
import { makeSubRegionTransformer } from '@pages/data-pages/region/domain/factories';
import { SubRegionEntity } from '@pages/data-pages/region/domain/entities';
import { useFilterDataTable, useMetaDataTable } from '@base-hooks';
import { Button, Modal, Tag, Tooltip } from 'antd';
import { ArrowsClockwise } from 'phosphor-react';
import { stringFormatter } from '@base-helpers';
import { EnumColorStatusData, EnumStatusData } from '@base-entities';

function makeColorStatus(status) {
  if (status === EnumStatusData.active) return EnumColorStatusData.active;
  else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
  return '';
}

interface Props {
  region: SubRegionEntity;
}
export function SubRegionTable(props: Props) {
  const { region } = props;
  const moduleKey = 'expandable-sub-region-fom-table';
  const [recordData, setRecordData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [meta, setMeta] = useMetaDataTable(moduleKey);
  const [filter, setFilter] = useFilterDataTable(moduleKey);
  const [loading, setLoading] = useState(false);
  const [showContentSubRegion, setShowContentSubRegion] = useState(true);

  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.sub_region });
  const synchronizeSubRegion = makeCommonDataSource({ apiUrl: 'v1/synchron/internal' });
  const transformer = makeSubRegionTransformer<SubRegionEntity>(region);
  const columns: any = [
    {
      title: 'Church Name',
      sorter: true,
      key: 'church_name',
      dataIndex: 'church_name',
      ellipsis: true,
      editable: true,
      width: 200,
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      sorter: true,
      dataIndex: 'status',
      render(item) {
        if (!item) return <div></div>;
        return <Tag color={makeColorStatus(item)}>{stringFormatter.capitalizeEachWord(item)}</Tag>;
      },
    },
    {
      title: 'Address',
      sorter: true,
      key: 'address',
      dataIndex: ['address', 'address'],
      ellipsis: true,
      editable: true,
      width: 250,
      render(item) {
        return <RenderAddress value={item} />;
      },
    },
    {
      title: 'Latitude',
      sorter: true,
      key: 'latitude',
      dataIndex: ['address', 'latitude'],
      ellipsis: true,
      editable: true,
      width: 150,
    },
    {
      title: 'Longitude',
      sorter: true,
      key: 'longitude',
      dataIndex: ['address', 'longitude'],
      ellipsis: true,
      editable: true,
      width: 150,
    },

    // {
    //   title: 'Notes',
    //   sorter: true,
    //   key: 'notes',
    //   dataIndex: ['notes'],
    //   ellipsis: true,
    //   editable: true,
    //   width: 200,
    // },
  ];

  const refreshSubRegionData = () => {
    setShowContentSubRegion(false);
    setTimeout(() => {
      setShowContentSubRegion(true);
    }, 0);
  };

  function transformerFilterGetIndex(filter) {
    const realFilter = filter;
    if (region) {
      Object.assign(realFilter, { region_ids: [region.id] });
    }
    return realFilter;
  }

  function onClickSynchronizeProcess(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Synchronize Church Confirmation',
      content: 'Are you sure you want to synchronize ?',
      onOk: handleSyncSubRegion,
    });
  }

  const handleSyncSubRegion = async (): Promise<void> => {
    if (selectedData) {
      const selectedSubRegionID = selectedData?.map((data) => data.id);
      const payload = {
        ids: selectedSubRegionID,
        action: 'update',
        module: 'sub_region',
      };

      await synchronizeSubRegion.handleCreate(payload, {
        onSuccess: ({ response }: any): any => {
          console.log(response);
          refreshSubRegionData();
        },
        onFailed: ({ message }: any): any => {
          console.log(message);
        },
      });
    }
  };

  function SyncSubRegionToolbar(): JSX.Element {
    return (
      <>
        {selectedData.length > 0 && (
          <Tooltip title="Sync Church" placement="bottom">
            <Button
              style={{ marginBottom: '10px' }}
              type="text"
              icon={
                <span role="img" aria-label="arrow-left" className="anticon ">
                  <ArrowsClockwise size={17} />
                </span>
              }
              onClick={() => onClickSynchronizeProcess()}
            >
              Sync Church
            </Button>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      {showContentSubRegion && (
        <ServerSideExpandableFormTable
          showBatchDelete={false}
          showBatchActivate={false}
          showBatchDeactivate={false}
          loading={loading}
          setLoading={setLoading}
          meta={meta}
          setMeta={setMeta}
          filter={filter}
          setFilter={setFilter}
          transformer={transformer}
          dataSource={dataSource}
          moduleKey={moduleKey}
          recordData={recordData}
          setRecordData={setRecordData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          transformerFilterGetIndex={transformerFilterGetIndex}
          columns={columns}
          ignoreDefaultColumn={['status']}
          tooltipTitleAddButton="Add sub region"
          additionalToolbarContent={<SyncSubRegionToolbar />}
          rowAction={{
            showActivate: () => false,
            showDeactivate: () => false,
            showDuplicate: () => false,
            showDelete: () => false,
            prefixExtensionAction: undefined,
          }}
          inlineFormFields={[
            {
              columnKey: 'church_name',
              name: 'church_name',
              fieldType: 'inputText',
              noStyle: true,
              rules: [{ required: true, message: 'Sub region name is required!' }],
              inputTextProps: {
                disabled: true,
              },
            },
            {
              columnKey: 'parent',
              name: 'parent',
              fieldType: 'selectPaginate',
              noStyle: true,
              selectPaginateProps: {
                isDisabled: true,
                keyLabel: 'church_name',
                dataSourceUrl: ApiUrlData.sub_region,
                menuPortalTarget: document.body,
                customFilterRequest() {
                  return {
                    region_ids: region ? [region.id] : undefined,
                  };
                },
              },
            },
            {
              columnKey: 'task_color',
              name: 'task_color',
              fieldType: 'colorPicker',
              noStyle: true,
              rules: [{ required: true, message: 'Task color is required!' }],
            },
            {
              columnKey: 'address',
              name: ['address'],
              rules: [{ required: true, message: 'Address is required!' }],
              fieldType: 'inputAddress',
              inputAddressProps: {
                placeholder: 'Input address',
              },
            },
            {
              columnKey: 'latitude',
              name: ['address', 'latitude'],
              rules: [
                {
                  pattern: /^((\-?|\+?)?\d+(\.\d+)?)$/,
                  message: 'The input is not valid latitude!',
                },
                { required: true, message: 'Latitude is required!' },
              ],

              fieldType: 'inputText',
            },
            {
              rules: [
                {
                  pattern: /^((\-?|\+?)?\d+(\.\d+)?)$/,
                  message: 'The input is not valid longitude!',
                },
                { required: true, message: 'Longitude is required!' },
              ],
              columnKey: 'longitude',
              name: ['address', 'longitude'],

              fieldType: 'inputText',
            },
            {
              columnKey: 'notes',
              name: ['notes'],
              fieldType: 'inputText',
            },
          ]}
        />
      )}
    </div>
  );
}
