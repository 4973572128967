import { atom, useRecoilState } from 'recoil';

const mobileMenuRecoil = atom<boolean>({
  key: 'mobileMenuRecoil',
  default: false,
});

export function useShowMobileMenu(): any {
  const [showMobileMenu, setShowMobileMenu] = useRecoilState(mobileMenuRecoil);
  return [showMobileMenu, setShowMobileMenu];
}
