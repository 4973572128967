import React, { useState } from 'react';
import { Card, Col, Row, Avatar, Tag } from 'antd';
import { Menu, Dropdown } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  CalendarOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

import { CardEntity, DataSourceEntity } from '../../entities';
import { useBoardContextValue } from '../../context';
import { dateFormatter } from '@base-helpers';
import moment from 'moment';
import { EnvelopeSimple, Palette, Phone, Student, UsersThree, WarningCircle } from 'phosphor-react';

interface Props {
  cardItem: CardEntity;
  groupItem: DataSourceEntity;
}

export default function index(props: Props): JSX.Element {
  const { cardItem, groupItem } = props;

  const {
    keyTitleCard = 'task_id',
    keyBodyCard = 'description',
    renderTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    makeColorBadgeCard,
    keyTitleBadgeCard = 'summary',
    dataOnDrawer,
    onCloseShowCard,
    onShowCard,
    handleDeleteCard,
    onUpdateCard,
  } = useBoardContextValue();

  function TitleCard(row: any): React.ReactNode {
    if (renderTitleCard) return renderTitleCard(row);
    return row[keyTitleCard];
  }

  function BodyCard(row: any): React.ReactNode {
    if (renderBodyCard) return renderBodyCard(row);
    return row[keyBodyCard];
  }

  function TitleBadgeCard(row: any): React.ReactNode {
    if (renderTitleBadgeCard) return renderTitleBadgeCard(row);
    return row[keyTitleBadgeCard];
  }

  function ColorBadge(row: any): string {
    if (makeColorBadgeCard) return makeColorBadgeCard(row);
    return;
    return '#108ee9';
  }

  function checkButtonShow(content): boolean {
    if (!dataOnDrawer) return true;
    else if (dataOnDrawer.id !== content.id) return true;
    return false;
  }

  function checkButtonCloseShow(content): boolean {
    if (dataOnDrawer && content && dataOnDrawer?.id === content.id) return true;
    return false;
  }

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  function toggleDropdown() {
    setVisibleDropdown(!visibleDropdown);
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item
          icon={<EditOutlined />}
          key="0"
          onClick={() => {
            setVisibleDropdown(false);
            onUpdateCard(cardItem, cardItem.id);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          icon={<DeleteOutlined />}
          key="1"
          onClick={() => {
            setVisibleDropdown(false);
            handleDeleteCard(cardItem);
          }}
        >
          Delete
        </Menu.Item>
        <Menu.Divider />
        {checkButtonCloseShow(content) && (
          <Menu.Item
            icon={<EyeInvisibleOutlined />}
            key="4"
            onClick={() => {
              setVisibleDropdown(false);
              onCloseShowCard(content, groupItem);
            }}
          >
            Close details
          </Menu.Item>
        )}
        {checkButtonShow(content) && (
          <Menu.Item
            icon={<EyeOutlined />}
            key="4"
            onClick={() => {
              setVisibleDropdown(false);
              onShowCard(content, groupItem);
            }}
          >
            Show details
          </Menu.Item>
        )}
      </Menu>
    );
  }

  const TaskCategoryOptions = [
    {
      icon: <EnvelopeSimple weight="bold" />,
      color: 'blue',
      label: 'Send Email',
      value: 'send_email',
    },
    {
      icon: <UsersThree weight="bold" />,
      color: 'blue',
      label: 'Meeting',
      value: 'meeting',
    },
    {
      icon: <Student weight="bold" />,
      color: 'blue',
      label: 'Training',
      value: 'training',
    },
    {
      icon: <Phone weight="bold" />,
      color: 'blue',
      label: 'Call',
      value: 'call',
    },
    {
      icon: <WarningCircle weight="bold" />,
      color: 'blue',
      label: 'Other',
      value: 'other',
    },
  ];

  const CardCategory = TaskCategoryOptions.find((item) => item.value === cardItem?.task_category);
  const assignee = cardItem?.assignee ?? [];
  const scope = cardItem?.scope;
  const taskColor = cardItem?.task_color;

  function makeImage(url) {
    const previewUrl = `${process.env.REACT_APP_BASE_ASSET_URL}${url}`;
    return previewUrl;
  }

  function generateTitleCard() {
    let str = '';
    if (scope === 'global') {
      str = 'Global';
    }

    if (scope === 'region') {
      str = cardItem?.region?.rc_dpw_name || '-';
    }

    if (scope === 'sub_region') {
      str = cardItem?.sub_region?.church_name || '-';
    }

    return `${cardItem?.task_id} | ${str}`;
  }

  return (
    <div className="card-item">
      <Card size="small">
        <div className="title-content">
          <span className="title-ticket">
            {/* {cardItem?.task_id} | {scope === 'global' && 'Global'} */}
            {generateTitleCard()}
          </span>
        </div>
        {cardItem?.summary && (
          <div className="body-content">
            <div>{cardItem?.summary}</div>
          </div>
        )}
        <div className="footer-content">
          <div>
            {cardItem?.date_plan && (
              <div style={{ marginBottom: '10px' }} className="due-date-content">
                <CalendarOutlined style={{ marginRight: '5px' }} />
                {/* {dateFormatter(cardItem?.date_plan ?? moment()).toLocal('ll')} */}
                {cardItem?.date_plan ? moment(cardItem?.date_plan).format('MMM D, YYYY') : '-'}
              </div>
            )}
            <div style={{ marginBottom: '10px' }}>
              <Tag color={CardCategory?.color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '5px', marginTop: '3px' }}>{CardCategory.icon}</span>
                  <span>{CardCategory?.label}</span>
                </div>
              </Tag>
              {/* {scope === 'sub_region' && (
                <Tag color={taskColor || '#cb3244'}>
                  <div style={{ display: 'flex', alignItems: 'center', paddingTop: 5, paddingBottom: 6 }}>
                    <Palette />
                  </div>
                </Tag>
              )} */}
            </div>
          </div>
          <Row>
            <Col span={4}>
              <div style={{ display: 'flex' }}>
                <Avatar.Group>
                  {assignee.slice(0, 6).map((item, index) => {
                    const username = item?.first_name.split('')?.splice(0, 2)?.join('')?.toUpperCase();
                    return (
                      <div key={index} style={{ marginRight: '5px' }}>
                        {item.profile_image ? (
                          <Avatar size="small" src={makeImage(item.profile_image)} />
                        ) : (
                          <Avatar size="small">{username}</Avatar>
                        )}
                      </div>
                    );
                  })}{' '}
                  {assignee.length > 6 ? '...' : ''}
                </Avatar.Group>
              </div>
            </Col>
            <Col span={17}></Col>
            <Col span={3}>
              <div className="action-content" onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  overlay={() => menuAction(cardItem)}
                  trigger={['click']}
                  visible={visibleDropdown}
                  onVisibleChange={() => toggleDropdown()}
                >
                  <div
                    style={{
                      width: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      height: '25px',
                    }}
                  >
                    <EllipsisOutlined />
                  </div>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}
