import React from 'react';
import { Tag } from 'antd';
import { round } from 'mathjs';
import moment from 'moment';
import { uniq } from 'lodash';
import { parsePeriod } from '../../helper';

export function TrendingCard({ score, grow, color, scoreValue, periods, label, Icon }) {
  return (
    <div className="trending-metric">
      <div className="percentage-indicator">
        <div className="percentage" style={{ color }}>
          {score ? round(score) + '%' : '-'}
        </div>
        <Icon grow={grow} />
      </div>
      <div className="score-value">{scoreValue}</div>
      <div className="trending-period">{periods}</div>
      <Tag style={{ borderRadius: 10, padding: '0px 10x 0px 15px' }} color={color}>
        {label}
      </Tag>
    </div>
  );
}

export function CompareCard({ periods, label, color, variables, isLastYear, isCompare, isLastIndex }) {
  return (
    <div className={`compare-metric-indicator ${isLastYear ? 'transparent-content' : ''}`}>
      <div className="indicator-period" style={{ display: 'flex', alignItems: 'center' }}>
        {periods}
        {isLastYear && (
          <Tag
            color="rgb(0,0,0)"
            style={{ fontSize: 8, borderRadius: 10, marginLeft: 10, padding: '0px 15px 0px 15px' }}
          >
            LAST YEAR
          </Tag>
        )}
      </div>
      <div className="indicator-label" style={{ color }}>
        {label}
      </div>
      <div className="indicator-variable" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {variables.map((itemVar, indexVar) => {
          const name = itemVar?.category?.name;
          const categoryName = itemVar?.category?.category_name;
          const names = [categoryName, name];
          const itemName = names.join(' - ');

          const itemVarValue = itemVar.value;
          const percent = isCompare ? itemVar.percentCompare : itemVar.percent;
          return (
            <div
              className={`variable-item ${indexVar < variables.length - 1 ? 'divider' : ''}`}
              key={indexVar}
              style={{ marginBottom: !isLastIndex ? 20 : 10 }}
            >
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="indicator-label" style={{ color }}>
                    {itemVarValue}
                  </div>
                  <div className="percent-item">{percent || percent == 0 ? round(percent, 2) + '%' : '-'}</div>
                </div>
                <div className="item-name">{itemName}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function SingleCard({ color, scoreValue, periods, label, score }) {
  return (
    <div className="score-metric">
      <div className="score-metric-label text-center" style={{ color }}>
        {scoreValue}
      </div>
      <div className="horizontal-divider"></div>
      <table className="indicator-card">
        <tr>
          <td className="indicator">
            <div className="indicator-value">{periods}</div>
            <div className="indicator-label">Period</div>
          </td>
          <td className="vertical-divider"> </td>
          <td className="indicator" style={{ maxWidth: 150 }}>
            <div className="indicator-value " style={{ color, fontSize: 14 }}>
              {label}
            </div>
            <div className="indicator-label">label</div>
          </td>
          <td className="vertical-divider"> </td>
          <td className="indicator">
            <div className="indicator-value percent-value">{score ? round(score) + '%' : '-'}</div>
            <div className="indicator-label">Growth Rate</div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export function SingleCardSingleData({ compare, card }) {
  const cardItem = card[0];
  const compareItem = compare[0];

  const color = cardItem?.color;
  const label = cardItem?.label;
  const month = cardItem?.month?.split(' - ');
  const score = cardItem?.percent_result_yoy;
  const scoreValue = cardItem?.scoreValue;
  const periods = parsePeriod(month);

  const compareColor = compareItem?.color;
  const compareLabel = compareItem?.label;
  const compareMonth = compareItem?.month?.split(' - ');
  const compareScore = compareItem?.percent_result_yoy;
  const compareScoreValue = compareItem?.scoreValue;
  const comparePeriods = parsePeriod(compareMonth);

  return (
    <div className="card-metric-wrapper ">
      <div className="score-metric">
        <div style={{ display: 'flex', gap: 30 }}>
          <div>
            <div className="indicator-label" style={{ fontWeight: 600 }}>
              {periods}
            </div>
            <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
              <div className="score-metric-label " style={{ color }}>
                {scoreValue}
              </div>
              <div className="indicator-value" style={{ fontSize: 14 }}>
                {score ? round(score) + '%' : '-'}
              </div>
            </div>
            <Tag style={{ borderRadius: 10, padding: '0px 10x 0px 15px' }} color={color}>
              {label}
            </Tag>
          </div>
          <div className="transparent-content">
            <div className="indicator-label" style={{ fontWeight: 600 }}>
              {comparePeriods}
            </div>
            <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
              <div className="score-metric-label " style={{ color }}>
                {compareScoreValue}
              </div>
              <div className="indicator-value" style={{ fontSize: 14 }}>
                {compareScore ? round(compareScore) + '%' : '-'}
              </div>
            </div>
            <Tag style={{ borderRadius: 10, padding: '0px 10x 0px 15px' }} color={compareColor}>
              {compareLabel}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SingleCardMultiData({ compare, card }) {
  return (
    <React.Fragment>
      <div className="card-metric-wrapper ">
        {card.map((itemCard, index) => {
          const color = itemCard?.color;
          const label = itemCard?.label;
          const month = itemCard?.month?.split(' - ');
          const score = itemCard?.percent_result_yoy;
          const scoreValue = itemCard?.scoreValue;
          const periods = parsePeriod(month);

          return (
            <div className="score-metric" key={index}>
              <div>
                <div className="indicator-label" style={{ fontWeight: 600 }}>
                  {periods}
                </div>
                <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
                  <div className="score-metric-label " style={{ color }}>
                    {scoreValue}
                  </div>
                  <div className="indicator-value" style={{ fontSize: 14 }}>
                    {round(score) + '%'}
                  </div>
                </div>
                <Tag style={{ borderRadius: 10, padding: '0px 10x 0px 15px' }} color={color}>
                  {label}
                </Tag>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ marginBottom: 20 }}></div>
      <div className="card-metric-wrapper transparent-content">
        {compare.map((itemCard, index) => {
          const color = itemCard?.color;
          const label = itemCard?.label;
          const month = itemCard?.month?.split(' - ');
          const score = itemCard?.percent_result_yoy;
          const scoreValue = itemCard?.scoreValue;
          const periods = parsePeriod(month);

          return (
            <div className="score-metric" key={index}>
              <div>
                <div className="indicator-label" style={{ fontWeight: 600 }}>
                  {periods}
                </div>
                <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
                  <div className="score-metric-label " style={{ color }}>
                    {scoreValue}
                  </div>
                  <div className="indicator-value" style={{ fontSize: 14 }}>
                    {round(score) + '%'}
                  </div>
                </div>
                <Tag style={{ borderRadius: 10, padding: '0px 10x 0px 15px' }} color={color}>
                  {label}
                </Tag>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
