import React from 'react';

interface FormGeneratorContextEntity {
  onDelete(record: any): void;
  onUpdate(record: any): void;
  onDuplicate(record: any): void;

  setCurrentRecordEditing(record: any): void;
  currentRecordEditing: any;
}

export const FormGeneratorContext = React.createContext<FormGeneratorContextEntity>(null);
export function useFormGeneratorContent(): FormGeneratorContextEntity {
  return React.useContext<FormGeneratorContextEntity>(FormGeneratorContext);
}
