import { useCurrentRoute, usePageProviderAction } from '@base-hooks';
import React from 'react';
import './page-wrapper.less';

export type PaddingType = 'left' | 'right' | 'top' | 'bottom';
interface PageWrapperProps {
  children?: JSX.Element | React.ReactNode;
  padding?: PaddingType[];
  showHeader?: boolean;
}

export function PageWrapper(props: PageWrapperProps) {
  const { children, padding = ['left', 'right', 'top', 'bottom'], showHeader } = props;
  const hasPaddingTop = padding.includes('top');

  function makePadding(paddingType: PaddingType[]) {
    return paddingType.map((item) => `padding-${item}`).join(' ');
  }

  function makeClassPadding(): string {
    if (!showHeader) return '';
    if (hasPaddingTop) return 'with-padding-top';
    return 'no-padding-top';
  }

  return <div className={`content-page-wrapper ${makeClassPadding()} ${makePadding(padding)}`}>{children}</div>;
}
