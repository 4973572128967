import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

import './map.style.less';
import { typeOf } from 'mathjs';
import { InputAddress } from '../input-address';
import { Row, Col, InputNumber, Form } from 'antd';
interface MarkerType {
  latitude: number;
  longitude: number;
  address: string;
  address_components: Array<any>;
}
interface Props {
  onChange?: (location: any) => void;
  mapsClassName?: string;
  withDefaultLocation?: boolean;
  addressMarker?: MarkerType;
  hideMap?: boolean;
  triggerResetAddress?: string;
}

function errorMaps({ className, isError }) {
  return (
    <div className={`${className ? className : ''} error-display-maps-default`}>
      <div className="tex-center">
        {isError && (
          <React.Fragment>
            <div className="d-flex justify-content-center">
              <i className="mdi mdi-block-helper fs-20"></i>
            </div>
            <div>
              <p>Failed to display maps, please check your connection!</p>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export function MapPicker(props: Props) {
  const [form] = Form.useForm();
  const { withDefaultLocation = true, mapsClassName, addressMarker, onChange, hideMap, triggerResetAddress } = props;
  // setup ref map
  const mapRef: any = useRef();

  // setup selected for show information on marker clicked
  const [selected, setSelected] = useState(null);

  // get callback on load map
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  //setup google maps api key
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ['places'],
  });

  // set map container style
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };

  // setup position center on map
  const [center, setCenter] = useState({
    lat: -6.917464,
    lng: 107.619125,
  });

  // setup additional options for maps
  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  // setup maker location
  const [marker, setMarker] = useState({
    lat: center.lat,
    lng: center.lng,
    address: '',
    address_components: [],
    time: new Date(),
  });

  // get full address by latitude and Longitude
  async function getAddressLocation({ lat, lng }) {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
      );

      const result = await response.json();
      return result ? result.results[0] : '';
    } catch (error) {
      return '';
    }
  }

  // action when marker clicked and will show info location
  function handleClickMarker(marker) {
    setSelected(marker);
  }

  // action when map clicked
  const handleClickMaps = async (event) => {
    const addr = await getAddressLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    const maker = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      address: addr.formatted_address || '',
      address_components: addr.address_components,
      time: new Date(),
    };
    handleChangeMarker(maker);
    const newAddress = makeAddress(marker);
    form.setFieldsValue({ ...newAddress, address: newAddress });
    setSelected(null);
  };

  // get current location by position user
  async function getLocation() {
    async function showPosition(position) {
      const addr = await getAddressLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
      setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
      const maker = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        address: addr.formatted_address || '',
        address_components: addr.address_components || [],
        time: new Date(position.timestamp),
      };
      form.setFieldsValue({
        address: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          address: addr.formatted_address || '',
        },
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });

      handleChangeMarker(maker);
    }

    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  function makeAddress(marker) {
    return {
      address: marker.address,
      country: marker.address_components.find((item) => item.types[0] === 'country'),
      province: marker.address_components.find((item) => item.types[0] === 'administrative_area_level_1'),
      city: marker.address_components.find((item) => item.types[0] === 'administrative_area_level_2'),
      postalCode: marker.address_components.find((item) => item.types[0] === 'postal_code'),
      districts: marker.address_components.find((item) => item.types[0] === 'administrative_area_level_3'),
      route: marker.address_components.find((item) => item.types[0] === 'route'),
      street_number: marker.address_components.find((item) => item.types[0] === 'street_number'),
      latitude: marker.lat,
      longitude: marker.lng,
    };
  }

  function handleChangeMarker(marker) {
    setMarker(marker);
    if (marker.address_components?.length > 0) {
      const address = makeAddress(marker);
      if (onChange) onChange(address);
    }
  }

  async function onMountMap() {
    if (addressMarker) {
      let latitudeMarker = addressMarker.latitude ?? -6.917464;
      let longitudeMarker = addressMarker.longitude ?? 107.619125;
      let address_components = addressMarker.address_components;
      if (!addressMarker.latitude || !addressMarker.latitude) {
        if (addressMarker.address) {
          const resultAddress = await getGeocode({ address: addressMarker.address });
          const { lat, lng } = await getLatLng(resultAddress[0]);
          latitudeMarker = lat;
          longitudeMarker = lng;
          address_components = resultAddress[0]?.address_components;
        }
      }

      const lat = typeOf(latitudeMarker) === 'string' ? Number(latitudeMarker) : latitudeMarker;
      const lng = typeOf(longitudeMarker) === 'string' ? Number(longitudeMarker) : longitudeMarker;

      mapRef?.current?.setZoom(13);
      mapRef?.current?.panTo({ lat, lng });
      setCenter({ lat, lng });
      const marker = {
        lat,
        lng,
        address: addressMarker.address,
        address_components,
        time: new Date(),
      };
      setMarker(marker);
      handleChangeMarker(marker);
      form.setFieldsValue({ address: addressMarker, latitude: lat, longitude: lng });
      setSelected(null);
    } else {
      form.resetFields();
    }
  }

  useEffect(() => {
    onMountMap();
  }, [addressMarker, mapRef, triggerResetAddress]);

  // useEffect(() => {
  //   if (withDefaultLocation && !addressMarker) {
  //     getLocation();
  //   }
  // }, [addressMarker]);

  async function makeAddressMarker(latitude, longitude) {
    const lat = typeOf(latitude) === 'string' ? Number(latitude) : latitude;
    const lng = typeOf(longitude) === 'string' ? Number(longitude) : longitude;
    const addr = await getAddressLocation({ lat, lng });
    mapRef.current.setZoom(13);
    mapRef.current.panTo({ lat, lng });
    const maker = {
      lat,
      lng,
      address: addr.formatted_address || '',
      address_components: addr.address_components || [],
      time: new Date(),
    };
    return maker;
  }

  async function onChangeForm(item, values) {
    const keyChange = Object.keys(item)[0];
    const valueLat = values.latitude ?? 0;
    const valueLng = values.longitude ?? 0;

    if (keyChange === 'address') {
      const address = item[keyChange];
      if (address.latitude && address.longitude) {
        const lat = typeOf(address.latitude) === 'string' ? Number(address.latitude) : address.latitude;
        const lng = typeOf(address.longitude) === 'string' ? Number(address.longitude) : address.longitude;
        mapRef.current.setZoom(13);
        mapRef.current.panTo({ lat, lng });
        const maker = await makeAddressMarker(lat, lng);
        handleChangeMarker(maker);
        form.setFieldsValue({ address, latitude: lat, longitude: lng });
        setSelected(null);
      }
    } else {
      const lat = typeOf(valueLat) === 'string' ? Number(valueLat) : valueLat;
      const lng = typeOf(valueLng) === 'string' ? Number(valueLng) : valueLng;
      mapRef.current.setZoom(13);
      mapRef.current.panTo({ lat, lng });
      const maker = await makeAddressMarker(lat, lng);
      handleChangeMarker(maker);
      setSelected(null);
    }
  }

  if (loadError) return errorMaps({ className: mapsClassName, isError: true });
  if (!isLoaded) return errorMaps({ className: mapsClassName, isError: true });

  return (
    <div id="google-maps-wrapper">
      <Form layout="vertical" form={form} onValuesChange={onChangeForm}>
        <Row gutter={[8, 2]}>
          <Col xs={22} sm={22} md={22} lg={12} xl={12}>
            <Form.Item name="address" label="Search" tooltip="Press enter or click item for select address">
              <InputAddress showMapIcon={false} placeholder="Search location" />
            </Form.Item>
          </Col>
          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="latitude" label="Latitude">
              <InputNumber placeholder="Input latitude" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={22} sm={11} md={11} lg={5} xl={4}>
            <Form.Item name="longitude" label="Longitude">
              <InputNumber placeholder="Input longitude" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ marginBottom: '10px' }}></div>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        {!hideMap && (
          <div className={`${mapsClassName ? mapsClassName : 'default-maps-wrapper'}`}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={9}
              center={center}
              options={options}
              onClick={(event) => handleClickMaps(event)}
              onLoad={onMapLoad}
              mapContainerClassName="container-google-maps"
            >
              <Marker
                key={marker.time.toISOString()}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => handleClickMarker(marker)}
              />
              {selected && (
                <InfoWindow position={{ lat: selected.lat, lng: selected.lng }} onCloseClick={() => setSelected(null)}>
                  <div>{marker.address}</div>
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
}
