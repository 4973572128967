import React from 'react';
import { components } from 'react-select';
import { DownOutlined, CloseOutlined, CloseCircleFilled } from '@ant-design/icons';

export const DropdownIndicator = (props: any) => {
  const style = {
    cursor: 'pointer',
  };
  return (
    <components.DropdownIndicator {...props}>
      <DownOutlined className="fs-12" style={style} />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (props: any) => {
  const style = {
    cursor: 'pointer',
  };
  return (
    <components.ClearIndicator {...props}>
      <CloseCircleFilled style={style} />
    </components.ClearIndicator>
  );
};

export const MultiValueRemove = (props: any) => {
  const style = {
    cursor: 'pointer',
    fontSize: '9px',
  };
  return (
    <components.MultiValueRemove {...props}>
      <CloseOutlined style={style} />
    </components.MultiValueRemove>
  );
};

export const IndicatorSeparator = (props: any) => {
  return <span></span>;
};
