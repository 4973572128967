import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Input, Popconfirm, Row, Tooltip } from 'antd';
import { Plus, Check, X } from 'phosphor-react';

interface Props {
  item: any;
  loading: boolean;
  onFinishCreate(item: any): void;
}

export default function WorkflowCreateCard({ item, loading, onFinishCreate }: Props): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleVisibleChange = (newVisible: boolean): void => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (!show) form.resetFields();
  }, [show]);

  return (
    <Col xs={24} sm={12} md={8} lg={4} xl={4}>
      <Card style={{ height: '100%' }} className="Workflow__card">
        <Form name="dynamic_workflow_card" form={form} onFinish={onFinishCreate}>
          <Form.Item className={show ? 'is-show' : 'is-hidden'}>
            <Row className="Workflow__form">
              <Col span={16}>
                <Form.Item name="name" rules={[{ required: true, message: 'Please input workflow title !' }]}>
                  <Input placeholder="Add Title" className="Workflow__form-input" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Popconfirm
                  placement="top"
                  title="Create Card ?"
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  okButtonProps={{ loading: loading }}
                  onConfirm={() => {
                    form.submit();
                    setShow(show);
                  }}
                >
                  <Form.Item style={{ margin: 0 }}>
                    <Tooltip placement="top" title="Save">
                      <Button
                        type="text"
                        shape="circle"
                        htmlType="submit"
                        loading={loading}
                        icon={<Check size={14} />}
                      />
                    </Tooltip>
                  </Form.Item>
                </Popconfirm>
              </Col>
              <Col span={3}>
                <Form.Item style={{ margin: 0 }}>
                  <Tooltip placement="top" title="Cancel">
                    <Button type="text" shape="circle" icon={<X size={14} />} onClick={() => setShow(!show)} />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item className={!show ? 'is-show' : 'is-hidden'} style={{ margin: 0 }}>
            <Button
              className="Workflow__add-card"
              size="large"
              icon={<Plus size={12} />}
              onClick={() => {
                setShow(!show);
              }}
            >
              {!item.length ? 'Add Card' : 'Add Another Card'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
}
