import React from 'react';
import { FromPageWrapper, FormBuilder } from '@base-components';
import { usePageProviderAction } from '@base-hooks';
import { Card } from 'antd';

export default function PageForm() {
  const { navigation } = usePageProviderAction();

  return (
    <FromPageWrapper handleAfterUpdate={() => navigation.goToIndex()} handleAfterCreate={() => navigation.goToIndex()}>
      <Card className="card-content-wrapper">
        <FormBuilder
          title="GENERAL INFORMATION"
          columns={[
            {
              fields: [
                {
                  label: 'Name',
                  rules: [{ required: true }],
                  name: 'name',
                  fieldType: 'inputText',
                },
                {
                  label: 'Address',
                  tooltip: 'Click map icon to find address with google maps!',
                  rules: [{ required: true }],
                  name: ['address'],
                  fieldType: 'inputAddress',
                  gridColumn: { xs: 24, sm: 16, md: 12, lg: 8, xl: 6 },
                },
                {
                  label: 'Latitude',
                  rules: [
                    { required: true },
                    {
                      pattern: /^((\-?|\+?)?\d+(\.\d+)?)$/,
                      message: 'The input is not valid latitude!',
                    },
                  ],
                  name: ['address', 'latitude'],
                  fieldType: 'inputText',
                },

                {
                  label: 'Longitude',
                  rules: [
                    { required: true },
                    {
                      pattern: /^((\-?|\+?)?\d+(\.\d+)?)$/,
                      message: 'The input is not valid longitude!',
                    },
                  ],
                  name: ['address', 'longitude'],
                  fieldType: 'inputText',
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Notes',
                  name: 'notes',
                  fieldType: 'textArea',
                  gridColumn: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                },
              ],
            },
          ]}
        />
      </Card>
    </FromPageWrapper>
  );
}
