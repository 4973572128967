/* eslint-disable react/react-in-jsx-scope */
import { ApiUrlData } from '@base-configs';
import { makeCommonDataSource } from '@base-factories';
import { authHelper, notificationFailed } from '@base-helpers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, Form, Input, Col, Row, Card } from 'antd';
import LargeImage from '@base-images/logo-extra-large.png';
import GoogleLogo from '@base-images/responsive-small.png';
import WhatsAppLogo from '@base-images/whatsapp.png';
import '../style/auth.less';

const whatsappPhone = '6281224512112';

export function LoginPage(): JSX.Element {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.user_login });
  const dataSourceGeneralConfig = makeCommonDataSource({ apiUrl: ApiUrlData.general_config });
  const dataSourceWorkflow = makeCommonDataSource({ apiUrl: ApiUrlData.workflow });
  const regionDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.region });
  const subRegionDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.sub_region });

  const dataSourceLoginToken = makeCommonDataSource({ apiUrl: ApiUrlData.user_login_token });
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  async function handleGetGeneralConfig(token): Promise<void> {
    await dataSourceGeneralConfig.handleGetIndex({
      token,
      params: { limit: 50 },
      onSuccess: ({ response }: any) => {
        const items = response?.items ?? [];
        authHelper.setGeneralConfig(items);
      },
      onFailed: ({ message }: any) => {
        console.log({ message });
      },
    });
  }

  async function handleGetWorkflow(token): Promise<void> {
    await dataSourceWorkflow.handleGetIndex({
      token,
      params: { limit: 50 },
      onSuccess: ({ response }: any) => {
        const items = response?.items ?? [];
        authHelper.setWorkflow(items);
      },
      onFailed: ({ message }: any) => {
        console.log({ message });
      },
    });
  }

  function checkId(data) {
    if (!data || data?.length === 0) return ['id'];
    return data;
  }

  async function handleGetRegionRules(token, authUser): Promise<void> {
    if (authUser.scope !== 'global') {
      let data_regions = [];
      let data_sub_regions = [];
      let data_sub_regions_tpi = [];
      await regionDataSource.handleGetIndex({
        token,
        params: { limit: 50, region_ids: checkId(authUser.scope_regions) },
        onSuccess: ({ response }: any) => {
          data_regions = response?.items ?? [];
        },
        onFailed: ({ message }: any) => {
          console.log({ message });
        },
      });

      await subRegionDataSource.handleGetIndex({
        token,
        params: {
          limit: 50,
          sub_region_ids: checkId([...authUser.scope_sub_regions, ...authUser.scope_sub_regions_tpi]),
        },
        onSuccess: ({ response }: any) => {
          const items = response?.items ?? [];
          data_sub_regions = items.filter((item) => authUser.scope_sub_regions.includes(item.id));
          data_sub_regions_tpi = items.filter((item) => authUser.scope_sub_regions_tpi.includes(item.id));
        },
        onFailed: ({ message }: any) => {
          console.log({ message });
        },
      });

      await authHelper.setAccount({
        ...authUser,
        data_regions,
        data_sub_regions,
        data_sub_regions_tpi,
      });
    }
  }

  async function handleAfterLogin(response: any): Promise<void> {
    await authHelper.setAccount(response);
    const token = response?.access_token ?? '';
    await handleGetRegionRules(token, response);
    await handleGetGeneralConfig(token);
    await handleGetWorkflow(token);
  }

  async function onFinish(payload) {
    setLoading(true);
    await dataSource.handleCreate(payload, {
      async onSuccess({ response }: any) {
        if (process.env.REACT_APP_LOGIN_MODE === 'NORMAL') {
          await handleAfterLogin(response);
          setLoading(false);
          navigate('/');
        } else {
          const account = btoa(btoa(JSON.stringify(response)));
          navigate(`/main-app/${account}`);
          setLoading(false);
        }
      },
      async onFailed({ message }) {
        await authHelper.clearLogout();
        notificationFailed([message as string]);
        setLoading(false);
      },
    });
  }

  async function loginToken(token) {
    setLoading(true);
    await dataSourceLoginToken.handleCreate(
      { token },
      {
        async onSuccess({ response }) {
          if (process.env.REACT_APP_LOGIN_MODE === 'NORMAL') {
            await handleAfterLogin(response);
            setLoading(false);
            navigate('/');
          } else {
            const account = btoa(btoa(JSON.stringify(response)));
            navigate(`/main-app/${account}`);
            setLoading(false);
          }
        },
        async onFailed({ message }) {
          await authHelper.clearLogout();
          notificationFailed([message as string]);
          setLoading(false);
        },
      },
    );
  }

  useEffect(() => {
    if (token) {
      const decodeToken = decodeURIComponent(token);
      loginToken(decodeToken);
    }
  }, [token]);

  return (
    <div className="auth-login-page">
      <div className="container">
        <div className="content">
          <Row wrap={true}>
            <Col xs={24} sm={24} md={5} lg={1} xl={1} xxl={1}></Col>
            <Col xs={24} sm={24} md={14} lg={7} xl={7} xxl={8}>
              <Card className="form-content">
                <div className="form-content-wrapper">
                  <div className="login-title">
                    <img src={LargeImage} height={'50'} />
                  </div>
                  <Form form={form} onFinish={onFinish} name="basic" layout="vertical">
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Invalid email!' },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item>
                      <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                        LOGIN
                      </Button>
                    </Form.Item>
                  </Form>
                  <Divider>or</Divider>
                  <Button
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => window.open(`https://api.whatsapp.com/send?phone=${whatsappPhone}`, '_blank')}
                  >
                    <div>
                      <img src={WhatsAppLogo} height={'20'} style={{ marginRight: '10px' }} />
                      <span style={{ paddingTop: '10px' }}>LOGIN INFORMATION</span>
                    </div>
                  </Button>
                  <div style={{ marginBottom: 10 }}></div>
                  <Button
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => window.location.replace(`${process.env.REACT_APP_BASE_URL}v1/users/auth/google`)}
                  >
                    <div>
                      <img src={GoogleLogo} height={'15'} style={{ marginRight: '10px' }} />
                      <span style={{ paddingTop: '10px' }}>LOGIN WITH GOOGLE</span>
                    </div>
                  </Button>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={5} lg={17} xl={17} xxl={16}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
