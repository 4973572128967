import { BaseEntity } from '@base-entities';
import { CategoryTransformer } from '../transformers';
import { IBaseTransformerRepository } from '@base-repositories';
import { CategoryEntity } from '../entities';

export function makeCategoryTransformer<E extends BaseEntity = BaseEntity>(
  category?: CategoryEntity,
): IBaseTransformerRepository<E> {
  return new CategoryTransformer<E>(category);
}
