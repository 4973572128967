import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { BoardEntity } from '../../domain/entities';

import PageIndex from '../pages';

export function BoardFactory(): JSX.Element {
  const InitialValue: InitialValuePageProviderEntity<BoardEntity> = {
    moduleKey: 'crm-board-pages', // make sure this key unique each module
    webUrl: WebUrlData.crm_board,
    apiUrl: ApiUrlData.crm,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
