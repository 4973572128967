import React, { useState } from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { Card, Form } from 'antd';

import { DetailPageWrapper, DetailBuilder, RenderText } from '@base-components';
import { useMediaQuery } from '@base-hooks';
import { loginAccountState } from '@base-states';

import MobileDetail from '../component/detail/mobile-detail';
import TableDetail from '../component/detail/table-detail';
import { typeOf } from 'mathjs';

export default function PageDetail(): JSX.Element {
  const isMobileScreen = useMediaQuery({ media: 'mobile' });
  const isTabletScreen = useMediaQuery({ media: 'tablet' });
  const isDesktopScreen = useMediaQuery({ media: 'desktop' });
  const isLargeScreen = useMediaQuery({ media: 'large' });
  const [currentData, setCurrentData] = useState(undefined);

  const { authState } = useRecoilValue(loginAccountState);
  const scope = authState.getAccount()?.scope;

  function makeUrlImage(image) {
    const imageType = typeOf(image);
    if (!image || imageType !== 'string') return 'error';
    const stringUrlImage = image.split('/');
    const isStorageFirst = stringUrlImage[0] === 'storage';
    let realUrl = '';
    const BASE_IMAGE_URL = process.env.REACT_APP_BASE_ASSET_URL;
    const IFGF_BASE_IMAGE_URL = process.env.REACT_APP_BASE_ASSET_URL_IFGF;
    if (isStorageFirst) realUrl = `${IFGF_BASE_IMAGE_URL}${image}`;
    else realUrl = `${BASE_IMAGE_URL}${image}`;
    return realUrl;
  }

  return (
    <DetailPageWrapper
      getCurrentData={setCurrentData}
      showActivate={false}
      showDeactivate={false}
      showDuplicate={scope !== 'region'}
      showUpdate={false}
      showDelete={scope !== 'region' && currentData?.status !== 'Posted'}
    >
      <Card className="card-content-wrapper">
        <DetailBuilder
          columns={[
            {
              title: 'ACTIVITY INFORMATION',
              fields: [
                {
                  label: 'ID',
                  name: ['detail_data', 'activity_id'],
                  fieldType: 'text',
                  gridColumn: { span: 5, xs: 24, sm: 12, lg: 3, xl: 3 },
                },
                {
                  label: 'Local Church & Satellite',
                  name: ['detail_data', 'sub_region', 'church_name'],
                  fieldType: 'text',
                  gridColumn: { span: 5, xs: 24, sm: 12, lg: 5, xl: 5 },
                },
                {
                  label: 'Region',
                  name: ['detail_data', 'region', 'rc_dpw_name'],
                  fieldType: 'text',
                  gridColumn: { span: 4, xs: 24, sm: 12, lg: 4, xl: 4 },
                },
                {
                  gridColumn: { span: 4, xs: 24, sm: 12, lg: 4, xl: 4 },
                  renderField(form) {
                    const row = form.getFieldValue('detail_data');
                    return (
                      <Form.Item label="Period" name={['detail_data']}>
                        <RenderText
                          renderValue={(date) => {
                            const item = date?.month ?? date?.week;
                            if (!item) return '';
                            else if (row?.submission_type === 'monthly') return moment(item).format('YYYY-MM');
                            return `${moment(item).format('YYYY-MM-DD')} (Week ${moment(item).week()})`;
                          }}
                        />
                      </Form.Item>
                    );
                  },
                },
              ],
            },
          ]}
        />
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const activityImage = getFieldValue(['detail_data', 'activity_image']);
            return !!activityImage ? (
              <DetailBuilder
                columns={[
                  {
                    title: 'Proof of CO Transaction',
                    fields: [
                      {
                        fieldType: 'image',
                        valuePropName: 'imageUrl',
                        name: ['detail_data', 'activity_image'],
                        renderImageProps: {
                          width: 200,
                          makeImageUrl(props) {
                            return makeUrlImage(props.imageUrl);
                          },
                        },
                      },
                    ],
                  },
                ]}
              />
            ) : (
              <div></div>
            );
          }}
        </Form.Item>
      </Card>

      <div className="custom-title-section" style={{ marginTop: '20px' }}></div>
      <Card className="card-content-wrapper">
        {(isDesktopScreen || isLargeScreen) && (
          <Form.Item name={['detail_data', 'activity_detail']}>
            <TableDetail />
          </Form.Item>
        )}
        {(isMobileScreen || isTabletScreen) && (
          <Form.Item name={['detail_data', 'activity_detail']}>
            <MobileDetail />
          </Form.Item>
        )}
      </Card>
    </DetailPageWrapper>
  );
}
