import { BaseEntity } from '@base-entities';
import { stringFormatter } from '@base-helpers';
import { BaseTransformer } from '@base-transformers';
import moment from 'moment';

export class ActivitySummaryTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerGetData(payload: E): E {
    return payload;
  }
  transformerFilterIndexTable(payload: any) {
    const indicator_name = payload?.indicator_name?.name;
    // const indicator_label = payload?.indicator_label ? stringFormatter.capitalize(payload?.indicator_label) : undefined;
    // const period = payload?.period?.map((item) => item.format('YYYY-MM-DD'));
    const period = payload?.period;

    const region_ids = payload?.region_ids?.map((item) => String(item?.id));
    const sub_region_ids = payload?.sub_region_ids?.map((item) => String(item?.id));
    const submission_type = payload.period_type;

    let period_from = undefined;
    let period_to = undefined;

    if (period) {
      if (submission_type === 'monthly') {
        period_from = moment(period[0]).startOf('month').toISOString();
        period_to = moment(period[1]).endOf('month').toISOString();
      } else if (submission_type === 'weekly') {
        period_from = moment(period[0]).startOf('week').toISOString();
        period_to = moment(period[1]).endOf('week').toISOString();
      } else {
        period_from = moment(period[0]).toISOString();
        period_to = moment(period[1]).toISOString();
      }
    }

    const transformedPayload = {
      ...payload,
      indicator_name,
      // indicator_label,
      region_ids,
      sub_region_ids,
      period: [],
      period_from,
      period_to,
    };

    return transformedPayload;
  }
}
