import React, { useEffect, useState } from 'react';
import { usePageProviderAction } from '@base-hooks';
import { authHelper, notificationFailed, notificationSuccess } from '@base-helpers';
import { Button, Card, Popconfirm, Spin, Tabs } from 'antd';
const { TabPane } = Tabs;

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { pick } from 'lodash';
import { PencilSimple, TrashSimple } from 'phosphor-react';
import { ChartRender } from './chart-render';
import { PageHeader } from 'antd';
const API_URL = `${process.env.REACT_APP_BASE_CUBE_API_URL}/v1`;
const CUBEJS_TOKEN = authHelper.getAuthToken();
const cubejsApi = cubejs(CUBEJS_TOKEN, { apiUrl: API_URL });

import '../../styles/grid-layout.less';
import '../../styles/report.less';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
interface Props {
  filter: any;
  showContent: boolean;
}

export function ReportLayout(props: Props) {
  const { filter, showContent } = props;
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const scope = authUser.scope;

  const { dataSource, navigation } = usePageProviderAction();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function handleGetData() {
    setLoading(true);
    await dataSource.handleGetIndex({
      params: { limit: 0 },
      onSuccess({ response }: any) {
        const items = response?.items ?? [];
        const newDashBoardData = items.map((item) => {
          return {
            ...item,
            ...(item?.configuration ?? {}),
          };
        });
        const dataDashboard =
          newDashBoardData.filter((item) => item.show_on_dashboard && item.chartType === 'table') ?? [];
        const onlyTable =
          dataDashboard.filter((item) => item.settingsPivotTable?.rendererName?.toLowerCase() === 'table') ?? [];

        setRowData(onlyTable);
        setLoading(false);
      },
      onFailed(error) {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    handleGetData();
  }, []);

  function makeRowData(data) {
    return data.filter((item) => item.scope === filter?.scope);
  }

  async function handleDelete(payload): Promise<void> {
    await dataSource.handleDelete(payload, {
      onSuccess: ({ response }: any) => {
        notificationSuccess([response]);
        setRowData(rowData.filter((item: any) => item.id !== payload.id));
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  return (
    <div className="grip-layout-wrapper report-page-wrapper">
      <Spin spinning={loading}>
        {rowData?.length === 0 && (
          <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
            No content.
          </Card>
        )}
        <CubeProvider cubejsApi={cubejsApi}>
          {rowData?.length > 0 && (
            <Tabs tabPosition="left">
              {makeRowData(rowData).map((itemDta, index) => {
                return (
                  <TabPane
                    tab={itemDta.name}
                    key={index}
                    style={{
                      minHeight: '80vh',
                      // maxHeight: '80vh',
                      overflow: 'auto',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '10px',
                        border: ' 1px solid rgb(235, 237, 240)',
                        padding: '10px',
                      }}
                    >
                      <h3>{itemDta.name}</h3>
                      {scope === 'global' && (
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            type="text"
                            icon={<PencilSimple />}
                            onClick={() => navigation.goToUpdate({ id: itemDta.id })}
                          ></Button>

                          <Popconfirm
                            title="Are you sure to delete this?"
                            onConfirm={() => handleDelete(itemDta)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="text" icon={<TrashSimple />}></Button>
                          </Popconfirm>
                        </div>
                      )}
                    </div>
                    <div style={{ overflow: 'auto' }}>
                      {showContent && <ChartRender chartItem={itemDta} filter={filter} />}
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
          )}
        </CubeProvider>
      </Spin>
    </div>
  );
}
