import React from 'react';
import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { dateFormatter } from '@base-helpers';
import { ApiUrlData } from '@base-configs';
import { ModuleOptions, OperationOptions, UserCategoryOptions } from '../helpers/options';
import { Card } from 'antd';

const column: any = [
  {
    title: 'Log Time',
    dataIndex: 'created_at',
    // key: 'created_at',
    sorter: true,
    width: 150,
    fixed: true,
    render: (item: any) => dateFormatter(item).toLocal(),
  },
  {
    title: 'User',
    dataIndex: 'created_by',
    key: 'created_by',
    sorter: true,
    width: 150,
    render: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
  },
  {
    title: 'User Category',
    dataIndex: 'user_category',
    key: 'user_category',
    sorter: true,
    width: 100,
  },
  {
    title: 'Module',
    dataIndex: 'module',
    key: 'module',
    sorter: true,
    width: 150,
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    sorter: true,
    width: 150,
  },
  {
    title: 'Data Name/ID',
    dataIndex: 'data_name',
    key: 'data_name',
    sorter: true,
    width: 250,
    render: (row) => row || '-',
  },
];

export default function PageIndex(): JSX.Element {
  const formBuilderProps: any = {
    columns: [
      {
        fields: [
          {
            name: 'created_ids',
            label: 'User',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              isMulti: true,
              placeholder: 'Select user',
              dataSourceUrl: ApiUrlData.users,
              customLabel: (value) => value?.first_name,
            },
          },
          {
            name: 'user_category',
            label: 'User Category',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              placeholder: 'User Category',
              options: UserCategoryOptions,
              customLabel(value) {
                return UserCategoryOptions.find((item) => value === item.value).label;
              },
            },
          },
          {
            name: 'module',
            label: 'Module',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              placeholder: 'Select module',
              options: ModuleOptions,
              customLabel(value) {
                return ModuleOptions.find((item) => value === item.value).label;
              },
            },
          },
          {
            name: 'operation',
            label: 'Operation',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              placeholder: 'Select operation',
              options: OperationOptions,
              customLabel(value) {
                return OperationOptions.find((item) => value === item.value).value;
              },
            },
          },
          {
            name: 'created_at',
            label: 'Log Time',
            fieldType: 'dateRangePicker',
          },
        ],
      },
    ],
  };

  return (
    <IndexPageWrapper
      showButtonAddNew={false}
      showButtonBack={false}
      drawerFilterProp={{
        formBuilderProps,
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable
          columns={column}
          useRowSelection={false}
          userPointerCursor={false}
          useDirectDetailOnRowClick={false}
          scroll={{ x: 'fit-content', y: 500 }}
          ignoreDefaultColumn={['action', 'status', 'created_at', 'updated_at']}
          rowAction={{
            showActivate: () => false,
            showDeactivate: () => false,
            showDuplicate: () => false,
            showUpdate: () => false,
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
