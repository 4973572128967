import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import * as _ from 'lodash';

export class HeadquarterTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['address', 'latitude', 'longitude']);
    Object.assign(cleanVal, {
      address: { address: payload.address, latitude: payload.latitude, longitude: payload.longitude },
    });
    return cleanVal;
  }
  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return [];
    return payload.map((item) => this.transformerGetData(item));
  }
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action', 'address']);
    Object.assign(cleanVal, {
      address: payload?.address?.address ?? undefined,
      latitude: payload?.address?.latitude?.toString() ?? undefined,
      longitude: payload?.address?.longitude?.toString() ?? undefined,
    });
    return cleanVal;
  }
}
