import React from 'react';
import { Button, Form } from 'antd';

import { FormBuilder } from '@base-components';
import { ApiUrlData } from '@base-configs';

type Props = {
  hideJournal(): void;
};

export default function FormJournal({ hideJournal }: Props): JSX.Element {
  return (
    <>
      <FormBuilder
        className="journal-create"
        columns={[
          {
            fields: [
              {
                name: 'description',
                label: 'Message Body',
                gridColumn: { span: 24 },
                fieldType: 'textArea',
                textAreaProps: {
                  placeholder: 'Enter message body',
                },
              },
              {
                name: 'participant',
                label: 'Participant',
                gridColumn: { span: 24 },
                fieldType: 'selectPaginate',
                rules: [{ required: true, message: 'Participant is required!' }],

                selectPaginateProps: {
                  placeholder: 'Choose participant',
                  isMulti: true,
                  dataSourceUrl: ApiUrlData.users,
                  customLabel: (row) => `${row?.first_name} ${row?.last_name ?? ''}`,
                },
              },
            ],
          },
        ]}
      />

      <Form.Item style={{ marginTop: 20 }}>
        <Button type="primary" htmlType="submit" style={{ marginRight: 12 }}>
          Save
        </Button>
        <Button onClick={() => hideJournal()}>Cancel</Button>
      </Form.Item>
    </>
  );
}
