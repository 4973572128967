import React from 'react';
import { MapPin } from 'phosphor-react';
import { Popover } from 'antd';

export interface RenderAddressProps {
  value?: string;
  withEllipsis?: boolean;
  usePopover?: boolean;
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  iconSize?: number;
}

export function RenderAddress(props: RenderAddressProps) {
  const { value, withEllipsis = true, alignItems = 'center', iconSize = 11, usePopover = true } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: alignItems,
      }}
    >
      {value && <MapPin weight="duotone" color="red" size={iconSize} style={{ marginRight: '5px' }} />}
      <div
        {...(withEllipsis
          ? { style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' } }
          : {})}
      >
        {usePopover ? (
          <Popover content={<div style={{ width: '200px' }}>{value}</div>} placement="bottomLeft">
            {value}
          </Popover>
        ) : (
          value
        )}
      </div>
    </div>
  );
}
