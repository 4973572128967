import React from 'react';
import { Button, Form } from 'antd';
import { DetailPageWrapper } from '@base-components';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';

export default function PageDetail() {
  const [currentRoute] = useCurrentRoute();
  const { action } = usePageProviderData();
  const { navigation } = usePageProviderAction();

  return (
    <DetailPageWrapper>
      <div>Simple Template Page {`${action}`}:</div>
      <Button onClick={() => navigation.goBack(currentRoute)}>Back</Button>
      <Button onClick={() => navigation.goToIndex()}>Index</Button>
      <Button onClick={() => navigation.goToDetail({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Detail</Button>
      <Button onClick={() => navigation.goToCreate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Create</Button>
      <Button onClick={() => navigation.goToDuplicate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>
        Duplicate
      </Button>
      <Button onClick={() => navigation.goToUpdate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Update</Button>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          return <div>{JSON.stringify(getFieldValue('detail_data') ?? {})}</div>;
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
