import { useState, useEffect } from 'react';
import {
  minMobileSize,
  maxMobileSize,
  minTabletSize,
  maxTabletSize,
  minDesktopSize,
  maxDesktopSize,
  minLargeScreen,
  makeScreenSize,
} from './media-query.hook.helper';

export interface MediaQueryHookProps {
  media?: 'mobile' | 'tablet' | 'desktop' | 'large';
  customQuery?: string;
}
export function useMediaQuery(props: MediaQueryHookProps) {
  const { media, customQuery } = props;
  const [matches, setMatches] = useState(false);

  let query = customQuery ?? '';
  if (media === 'mobile') query = makeScreenSize({ minSize: minMobileSize, maxSize: maxMobileSize });
  else if (media === 'tablet') query = makeScreenSize({ minSize: minTabletSize, maxSize: maxTabletSize });
  else if (media === 'desktop') query = makeScreenSize({ minSize: minDesktopSize, maxSize: maxDesktopSize });
  else if (media === 'large') query = makeScreenSize({ minSize: minLargeScreen });

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    /* When Component Unmount */
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
}
