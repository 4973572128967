import React, { useEffect, useState } from 'react';
import { BaseChartEntity } from './base-chart.entity';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import ComponentTableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Plot from 'react-plotly.js';
import { omit, pick } from 'lodash';
const ComponentPlotlyRenderers = createPlotlyRenderers(Plot);
const TableRenderers: any = pick(ComponentTableRenderers, ['Table']);
const PlotlyRenderers: any = omit(ComponentPlotlyRenderers, ['Scatter Chart']);

import '@base-styles/pivot-table.less';
import { stringFormatter } from '@base-helpers';

const { capitalizeEachWord } = stringFormatter;
interface Props extends BaseChartEntity {
  [key: string]: any;
  onChangeSetting(payload: any): void;
}

function makeKeyData(key: any) {
  const newKey = key.split('.').join(' ');
  const newKey2 = newKey.split('_').join(' ');
  return capitalizeEachWord(newKey2);
}

export default function ChartRender(props: Props) {
  const { onChangeSetting } = props;
  const [rowData, setRowData] = useState([]);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const resultSet = props?.resultResponseQuery;
    const isReadyToShow = props?.isReadyToShow;

    if (resultSet && isReadyToShow) {
      const newData = resultSet.tablePivot().map((row) =>
        Object.keys(row).reduce((object, key) => {
          return {
            ...object,
            [makeKeyData(key)]: row[key],
          };
        }, {}),
      );
      setRowData(newData);
    }
  }, [props]);

  useEffect(() => {
    if (props?.settingsPivotTable !== settings) {
      setSettings(props?.settingsPivotTable ?? {});
    }
  }, [props?.settingsPivotTable]);

  function onChangeSettingCustom(newSetting) {
    const set = {
      ...newSetting,
      rows: [...new Set(newSetting.rows ?? [])],
      cols: [...new Set(newSetting.cols ?? [])],
    };

    onChangeSetting(omit(set, ['renderers', 'aggregators']));
  }

  return (
    <div className="chart-render-wrapper">
      <PivotTableUI
        vals={['Indicator Count']}
        aggregatorName="Integer Sum"
        onChange={(s) => onChangeSettingCustom(s)}
        renderers={Object.assign({}, TableRenderers)}
        // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...settings}
        data={rowData}
        hiddenFromAggregators={['id']}
      />
    </div>
  );
}
