import React from 'react';
import { BoardEntity } from '../../entities';
interface Props {
  boardColumns: BoardEntity[];
}

export default function index(props: Props) {
  const { boardColumns } = props;
  return (
    <div style={{ display: 'flex' }}>
      {boardColumns.map((item, index) => {
        return <div key={index} style={{ minWidth: '280px', height: '1px' }}></div>;
      })}
    </div>
  );
}
