import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import * as _ from 'lodash';
import { omit } from 'lodash';

const defaultSettingPivotTable = {
  vals: ['Activities Count'],
  aggregatorName: 'Integer Sum',
  hiddenFromAggregators: ['id'],
  cols: [],
  rows: [],
  sorters: {},
  valueFilter: {},
  rowOrder: 'key_a_to_z',
  colOrder: 'key_a_to_z',
  derivedAttributes: {},
  tableOptions: {},
  rendererName: 'Table',
  hiddenAttributes: [],
  hiddenFromDragDrop: [],
  unusedOrientationCutoff: 85,
  menuLimit: 500,
};

export class DashboardTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerCreate(payload: E): E {
    const prevSettingsPivotTable = payload?.settingsPivotTable ?? defaultSettingPivotTable;
    const settingsPivotTable = omit(prevSettingsPivotTable, ['data']);
    const configuration = {
      ...payload,
      show_on_dashboard: true,
      settingsPivotTable,
      query: {
        ...payload.query,
        timeDimensions: payload.timeDimensions?.length > 0 ? payload.query.timeDimensions : [],
        filters: payload.filters?.length > 0 ? payload.query.filters : [],
      },
    };

    const newPayload: any = {
      configuration: JSON.stringify(configuration),
    };
    return newPayload;
  }
  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }
  transformerGetData(payload: E): E {
    return { id: payload.id, ...payload.configuration };
  }
}
