import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { arrayMove } from 'react-sortable-hoc';

import { usePageProviderAction } from '@base-hooks';
import { IndexPageWrapper } from '@base-components';
import { notificationFailed, notificationSuccess } from '@base-helpers';

import SortableCardContainer from '../components/card/sortable-card/sortable-container';
import SortableCardItem from '../components/card/sortable-card/sortable-item';
import WorkflowCreateCard from '../components/card/create-card';
import * as _ from 'lodash';

import '../styles/index.less';
import { makeCommonDataSource } from '@base-factories';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

export default function PageIndex(): JSX.Element {
  const { dataSource } = usePageProviderAction();
  const [dataCard, setDataCard] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dataSourceUpdate = makeCommonDataSource({ apiUrl: 'v1/workflow/batch-update' });
  const { authState } = useRecoilValue(loginAccountState);

  /* Handle Get Data */
  async function getData(): Promise<void> {
    const params = {
      page: 1,
      limit: 10,
    };

    await dataSource.handleGetIndex({
      params,
      onSuccess: ({ response }: any) => {
        const newData = response.items.map((item) => {
          return { ...item, key: item.id ?? uuidv4() };
        });
        setDataCard(newData);
      },
      onFailed: ({ message, statusCode }) => {
        console.log({ message, statusCode });
      },
    });
  }

  /* Handle Delete Data */
  async function deleteData(id): Promise<void> {
    setLoading(true);

    await dataSource.handleDelete(
      { id },
      {
        onSuccess: ({ response }: any) => {
          const newData = dataCard.filter((item) => item.id !== id);
          setLoading(false);
          setDataCard(newData);
          notificationSuccess(['Successfully deleted data.']);
        },
        onFailed: ({ message, statusCode }: any) => {
          setLoading(false);
          notificationFailed(message);
        },
      },
    );
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dataCard) authState.setWorkflow(dataCard);
  }, [dataCard]);

  /* handle Create Data */
  async function onFinishCreate(item): Promise<void> {
    const newCardItem = {
      key: uuidv4(),
      name: item.name,
    };

    setLoading(true);

    await dataSource.handleCreate(newCardItem, {
      onSuccess: ({ response }: any) => {
        const newData = {
          ...response,
          key: item.id ?? uuidv4(),
        };

        setLoading(false);
        notificationSuccess(['Successfully created data.']);
        setDataCard([...dataCard, newData]);
      },
      onFailed: ({ message, statusCode }: any) => {
        setLoading(false);
        notificationFailed(message);
      },
    });
  }

  /* Handle Update after save */
  function handleAfterSave(payload) {
    const newData = dataCard.map((item) => {
      if (item.id !== payload.id) return item;

      return {
        ...payload,
        key: payload.id ?? uuidv4(),
      };
    });

    setDataCard(newData);
  }

  /* Sorting Card Workflow */
  async function onSortEnd({ oldIndex, newIndex }): Promise<void> {
    const newData = arrayMove(dataCard, oldIndex, newIndex);
    const payloadWorkflow = newData.map((item, index) => {
      return {
        ..._.pick(item, ['id', 'name']),
        position: index + 1,
      };
    });
    setDataCard(payloadWorkflow);
    const payload = {
      workflows: payloadWorkflow,
    };
    await dataSourceUpdate.handleCreate(payload, {
      onSuccess: ({ response }: any) => {
        setDataCard(response?.success ?? []);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
        setDataCard(dataCard);
      },
    });
  }

  return (
    <IndexPageWrapper showButtonAddNew={false} showButtonBack={false} showFilter={false} showSearch={false}>
      <div className="Workflow__container">
        <SortableCardContainer axis="xy" onSortEnd={onSortEnd} useDragHandle>
          {dataCard.map((item, index) => (
            <SortableCardItem
              item={item}
              key={item.key}
              index={index}
              handleDeleteCard={deleteData}
              handleAfterSave={handleAfterSave}
            />
          ))}
          <WorkflowCreateCard onFinishCreate={onFinishCreate} loading={loading} item={dataCard} />
        </SortableCardContainer>
      </div>
    </IndexPageWrapper>
  );
}
