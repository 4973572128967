import React, { useState } from 'react';
import { Button, Space, Upload, Popconfirm, Card, Form } from 'antd';
import { Menu, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  Input,
  Col,
  Row,
  Select,
  InputNumber,
  DatePicker,
  AutoComplete,
  Cascader,
  Tooltip,
  Switch,
  Checkbox,
} from 'antd';
import { Radio } from 'antd';
import { Table } from 'antd';

import { CopyOutlined } from '@ant-design/icons';

const { Option } = Select;
const options = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
];
export default function PreviewComponent() {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/about">
          1st menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="app">
      <Button type="primary">Button</Button>
      <Button type="ghost">Button</Button>
      <Button type="dashed">Button</Button>
      <Button type="link">Button</Button>
      <Button type="text">Button</Button>
      <Button type="default">Button</Button>

      <Space>
        Space
        <Button type="primary">Button</Button>
        <Upload>
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
        <Popconfirm title="Are you sure delete this task?" okText="Yes" cancelText="No">
          <Button>Confirm</Button>
        </Popconfirm>
      </Space>

      <Space direction="vertical">
        <Card title="Card" style={{ width: 300 }}>
          <p>Card content</p>
          <p>Card content</p>
        </Card>
        <Card title="Card" style={{ width: 300 }}>
          <p>Card content</p>
          <p>Card content</p>
        </Card>
      </Space>
      <Space direction="vertical">
        <Space wrap>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button>bottomLeft</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="bottomCenter">
            <Button>bottomCenter</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="bottomRight">
            <Button>bottomRight</Button>
          </Dropdown>
        </Space>
        <Space wrap>
          <Dropdown overlay={menu} placement="topLeft">
            <Button>topLeft</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="topCenter">
            <Button>topCenter</Button>
          </Dropdown>
          <Dropdown overlay={menu} placement="topRight">
            <Button>topRight</Button>
          </Dropdown>
        </Space>
      </Space>
      <div className="site-input-group-wrapper">
        <Input.Group>
          <Row gutter={8}>
            <Col span={5}>
              <Input defaultValue="0571" />
            </Col>
            <Col span={8}>
              <Form layout="vertical">
                <Form.Item label="ssssssssss">
                  <Input defaultValue="26888888" />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input style={{ width: '20%' }} defaultValue="0571" />
          <Input style={{ width: '30%' }} defaultValue="26888888" />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="https://ant.design" />
          <Button type="primary">Submit</Button>
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="git@github.com:ant-design/ant-design.git" />
          <Tooltip title="copy git url">
            <Button icon={<CopyOutlined />} />
          </Tooltip>
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select defaultValue="Zhejiang">
            <Option value="Zhejiang">Zhejiang</Option>
            <Option value="Jiangsu">Jiangsu</Option>
          </Select>
          <Input style={{ width: '50%' }} defaultValue="Xihu District, Hangzhou" />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input.Search allowClear style={{ width: '40%' }} defaultValue="0571" />
          <Input.Search allowClear style={{ width: '40%' }} defaultValue="26888888" />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select defaultValue="Option1">
            <Option value="Option1">Option1</Option>
            <Option value="Option2">Option2</Option>
          </Select>
          <Input style={{ width: '50%' }} defaultValue="input content" />
          <InputNumber />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input style={{ width: '50%' }} defaultValue="input content" />
          <DatePicker style={{ width: '50%' }} />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Input style={{ width: '30%' }} defaultValue="input content" />
          <DatePicker.RangePicker style={{ width: '70%' }} />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select defaultValue="Option1-1">
            <Option value="Option1-1">Option1-1</Option>
            <Option value="Option1-2">Option1-2</Option>
          </Select>
          <Select defaultValue="Option2-2">
            <Option value="Option2-1">Option2-1</Option>
            <Option value="Option2-2">Option2-2</Option>
          </Select>
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select defaultValue="1">
            <Option value="1">Between</Option>
            <Option value="2">Except</Option>
          </Select>
          <Input style={{ width: 100, textAlign: 'center' }} placeholder="Minimum" />
          <Input
            className="site-input-split"
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder="~"
            disabled
          />
          <Input
            className="site-input-right"
            style={{
              width: 100,
              textAlign: 'center',
            }}
            placeholder="Maximum"
          />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select defaultValue="Sign Up" style={{ width: '30%' }}>
            <Option value="Sign Up">Sign Up</Option>
            <Option value="Sign In">Sign In</Option>
          </Select>
          <AutoComplete
            style={{ width: '70%' }}
            placeholder="Email"
            options={[{ value: 'text 1' }, { value: 'text 2' }]}
          />
        </Input.Group>
        <br />
        <Input.Group compact>
          <Select style={{ width: '30%' }} defaultValue="Home">
            <Option value="Home">Home</Option>
            <Option value="Company">Company</Option>
          </Select>
          <Cascader style={{ width: '70%' }} options={options} placeholder="Select Address" />
        </Input.Group>
      </div>

      <Radio.Group>
        <Space direction="vertical">
          <Radio value={1}>Option A</Radio>
          <Radio value={2}>Option B</Radio>
          <Radio value={3}>Option C</Radio>
        </Space>
      </Radio.Group>
      <Checkbox>Checkbox</Checkbox>
      <Switch defaultChecked />
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
    </div>
  );
}
