import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { GeneralConfigurationEntity } from '../../domain/entities';

import PageIndex from '../pages';
import { makeUserTransformer } from '../../domain/factories';

export function GeneralConfigurationFactory() {
  const transformer = makeUserTransformer<GeneralConfigurationEntity>();
  const InitialValue: InitialValuePageProviderEntity<GeneralConfigurationEntity> = {
    transformer,
    moduleKey: 'configuration-configuration-general-pages', // make sure this key unique each module
    webUrl: WebUrlData.configuration_general_configuration,
    apiUrl: ApiUrlData.general_config,
    // baseApiUrl: 'http://api.testing.erp.eigen.co.id/api/',
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
