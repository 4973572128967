import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { DashboardConfigurationEntity } from '../../domain/entities';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

export function DashboardConfigurationFactory() {
  const InitialValue: InitialValuePageProviderEntity<DashboardConfigurationEntity> = {
    moduleKey: 'configuration-dashboard-pages', // make sure this key unique each module
    webUrl: WebUrlData.configuration_dashboard,
    apiUrl: ApiUrlData.sales_basic_price,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
