import React, { useEffect, useState } from 'react';
import {
  useCollapsibleLayout,
  userNavbarPosition,
  useShowDrawerMobileMenu,
  useShowMenuOnNavbar,
  useShowMobileMenu,
  useShowSidebarLayout,
  useSidebarThemeLayout,
} from '@base-hooks';

import { Badge, Layout, Avatar, Menu, Dropdown, Button, Popover } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, BellOutlined, WifiOutlined } from '@ant-design/icons';
const { Header } = Layout;

import '../styles/navbar.less';
import './notification/index.less';
import { useShowCollapsibleControlLayout } from 'base/presentation/hooks/hook-auth-layout/collapsible-control-layout.hook';
import { MenuComponent } from '../menu';

import {
  MenuActivities,
  MenuData,
  MenuConfiguration,
  MenuMaintenance,
  MenuTemplate,
} from '@main/layouts/auth-layout/menu/data-menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebUrlData } from '@base-configs';
import { BaseMenuEntity } from '@base-entities';
import { authHelper } from '@base-helpers';
import { List, SignOut } from 'phosphor-react';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { DrawerMobileMenu } from './drawer-mobile-menu';
import Notification from './notification';
import PopNotification from './notification';
import { NotificationComponent } from './new-notification';
export default function Navbar() {
  const { authState } = useRecoilValue(loginAccountState);
  const account = authState.getAccount();

  const [showSidebar] = useShowSidebarLayout();
  const [showMobileMenu] = useShowMobileMenu();
  const [showMenuNavbar] = useShowMenuOnNavbar();
  const [navbarPosition] = userNavbarPosition();
  const [showCollapsibleControl] = useShowCollapsibleControlLayout();
  const [collapsed, setCollapsed] = useCollapsibleLayout();
  const [sidebarTheme] = useSidebarThemeLayout();
  const [showDrawerMobileMenu, setShowDrawerMobileMenu] = useShowDrawerMobileMenu();

  function toggleDrawerMenu() {
    setShowDrawerMobileMenu(!showDrawerMobileMenu);
  }

  const navigate = useNavigate();
  const profilePicture = account?.profile_image;
  const username = account?.first_name;

  const [navMenu, setNavMenu] = useState<BaseMenuEntity[]>([]);

  const location = useLocation();
  const { pathname } = location;

  const [wifiTooltip, setWifiTooltip] = useState(
    window.navigator.onLine ? 'No internet connection' : 'Connected to internet',
  );

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    window.addEventListener('offline', () => setIsOnline(window.navigator.onLine));
    window.addEventListener('online', () => setIsOnline(window.navigator.onLine));
  }, []);

  useEffect(() => {
    if (!isOnline) setWifiTooltip('No internet connection');
    else setWifiTooltip('Connected to internet');
  }, [isOnline]);

  function makeClassNameHeader(): string {
    if (!showSidebar || navbarPosition === 'outside_the_layout') return 'no-sidebar';
    else if (collapsed) return 'collapsed-header';
    return 'not-collapsed-header';
  }

  function identificationKey(pathname: string): string {
    if (!pathname) return '';
    return pathname.split('/')[1];
  }

  function makeActiveMenuKey(pathname: string): string {
    const pageActions: string[] = ['create', 'update', 'duplicate', 'detail'];
    if (!pathname) return '';
    const pathnameArray = pathname.split('/');
    const lastPathname: string = pathnameArray.splice(-1)[0];
    const keyOfPathname = pathnameArray.splice(0, pathnameArray.length).join('/');
    if (pageActions.includes(lastPathname)) return keyOfPathname;
    return pathname;
  }

  useEffect(() => {
    if (pathname) {
      if (identificationKey(pathname) === identificationKey(WebUrlData.activity)) setNavMenu(MenuActivities);
      else if (identificationKey(pathname) === identificationKey(WebUrlData.data)) setNavMenu(MenuData);
      else if (identificationKey(pathname) === identificationKey(WebUrlData.configuration))
        setNavMenu(MenuConfiguration);
      else if (identificationKey(pathname) === identificationKey(WebUrlData.maintenance)) setNavMenu(MenuMaintenance);
      else if (identificationKey(pathname) === identificationKey(WebUrlData.template)) setNavMenu(MenuTemplate);
      else setNavMenu([]);
    }
  }, [pathname]);

  function makeImage() {
    const previewUrl = `${process.env.REACT_APP_BASE_ASSET_URL}${profilePicture}`;
    return previewUrl;
  }

  async function handleLogout() {
    await authHelper.clearLogout();
    navigate(WebUrlData.login);
  }

  function makeAvatarMenu() {
    return (
      <Menu style={{ width: '150px' }}>
        <Menu.Item icon={<SignOut />} onClick={() => handleLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="custom-navbar-wrapper">
      <DrawerMobileMenu visible={showDrawerMobileMenu} onClose={toggleDrawerMenu} />
      <Header className={`custom-layout-header ${makeClassNameHeader()}`}>
        <Button
          type="text"
          size="large"
          icon={<List size={20} />}
          hidden={!showMobileMenu}
          onClick={() => toggleDrawerMenu()}
        ></Button>

        {showSidebar &&
          showCollapsibleControl &&
          React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}

        {showMenuNavbar && (
          <div className="navbar-custom-menu">
            <MenuComponent
              dataMenu={navMenu}
              theme={sidebarTheme}
              mode="horizontal"
              selectedKeys={[makeActiveMenuKey(pathname)]}
              style={{
                paddingRight: '10px',
                paddingLeft: '10px',
              }}
            />
          </div>
        )}
        <div className="navbar-right-content">
          {/* <div className="navbar-right-item">
            <Popover content={wifiTooltip}>
              <WifiOutlined style={{ color: isOnline ? 'green' : 'red' }} />
            </Popover>
          </div> */}
          <div className="navbar-right-item">
            {/* <PopNotification /> */}
            <NotificationComponent />
          </div>

          <div className="navbar-right-item">
            <Dropdown overlay={makeAvatarMenu()} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
              {profilePicture ? (
                <Avatar className="custom-avatar" src={makeImage()} />
              ) : (
                <Avatar className="custom-avatar">{username?.split('')?.splice(0, 2)?.join('')?.toUpperCase()}</Avatar>
              )}
            </Dropdown>
          </div>
        </div>
      </Header>
    </div>
  );
}
