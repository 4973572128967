import moment from 'moment';
import React, { useState } from 'react';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  Brush,
  Sector,
} from 'recharts';

const colors = ['#1e81b0', '#e28743', '#76b5c5', '#063970', '#154c79', '#873e23', '#B95F89'];

import numeral from 'numeral';
import { Card } from 'antd';
const numberFormatter = (item) => numeral(item).format('0,0');
const dateFormatter = (item) => {
  if (moment(item).isValid()) {
    return moment(item).format('MMM');
  }
  return item;
};
const tooltipDateFormatter = (item) => {
  const neItem = item
    ?.split('')
    ?.filter((a) => a !== ',')
    ?.join('');

  if (moment(neItem).isValid()) {
    if (item.includes(',')) return moment(neItem).format('DD-MM-YYYY') + ',';
    return moment(neItem).format('DD-MM-YYYY');
  }
  return item;
};

const xAxisFormatter = (item) => {
  if (moment(item).isValid()) {
    return dateFormatter(item);
  } else {
    return item;
  }
};

interface Props {
  cubeData: any;
}

const CustomTooltip = (params: any) => {
  const { active, payload, label } = params;
  if (active) {
    return (
      <Card>
        <div className="label"> {`${dateFormatter(label)}`}</div>
        <p className="desc">
          {payload?.map((item, index) => {
            return (
              <p key={index} style={{ color: item.color }}>
                {`${item.name
                  ?.split(' ')
                  ?.map((iName) => tooltipDateFormatter(iName))
                  .join(' ')} : ${item.value}`}
                {/* {`${item.name?.split(',')?.splice(1).join(',')}. Total:${item.value}`} */}
              </p>
            );
          })}
        </p>
      </Card>
    );
  }

  return null;
};

function CartesianChart({ data, ChartComponent, children }) {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <ChartComponent height={350} data={data} margin={{ left: -30 }}>
        <XAxis axisLine={false} tickLine={false} tickFormatter={xAxisFormatter} dataKey="x" minTickGap={20} />
        <YAxis axisLine={false} tickLine={false} tickFormatter={numberFormatter} />
        <CartesianGrid strokeDasharray="3 3" />
        {children}
        <Brush />
        <Tooltip
          content={<CustomTooltip />}
          // labelFormatter={dateFormatter}
          // formatter={numberFormatter}
        />
      </ChartComponent>
    </ResponsiveContainer>
  );
}

export function LineChartRender(props: Props) {
  const { cubeData } = props;
  const data = cubeData?.resultSet?.chartPivot() ?? [];
  const seriesName = cubeData?.resultSet?.seriesNames() ?? [];
  return (
    <div>
      <CartesianChart data={data} ChartComponent={LineChart}>
        {seriesName.map((series, i) => (
          <Line key={series?.key} dataKey={series?.key} name={series.title} type="monotone" stroke={colors[i]} />
        ))}
      </CartesianChart>
    </div>
  );
}

export function AreaChartRender(props: Props) {
  const { cubeData } = props;
  const data = cubeData?.resultSet?.chartPivot() ?? [];
  const seriesName = cubeData?.resultSet?.seriesNames() ?? [];

  return (
    <div>
      <CartesianChart data={data} ChartComponent={AreaChart}>
        {seriesName.map((series, i) => (
          <Area
            key={series?.key}
            stackId="a"
            dataKey={series?.key}
            name={series.title}
            stroke={colors[i]}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    </div>
  );
}

export function BarChartRender(props: Props) {
  const { cubeData } = props;
  const data = cubeData?.resultSet?.chartPivot() ?? [];
  const seriesName = cubeData?.resultSet?.seriesNames() ?? [];

  return (
    <div>
      <CartesianChart data={data} ChartComponent={BarChart}>
        {seriesName.map((series, i) => (
          <Bar key={series?.key} stackId="a" dataKey={series?.key} name={series.title} fill={colors[i]} />
        ))}
      </CartesianChart>
    </div>
  );
}

export function PieChartRender(props: Props) {
  const { cubeData } = props;
  const data = cubeData?.resultSet?.chartPivot() ?? [];
  const seriesName = cubeData?.resultSet?.seriesNames() ?? [];

  const newData = data?.map((item) => {
    return {
      ...item,
      x: moment(item.x).isValid() ? moment(item.x).format('MMM YYYY') : item.x,
    };
  });
  return (
    <div>
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <Pie data={newData} nameKey="x" dataKey={seriesName[0]?.key} fill="#8884d8">
            {data.map((e, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Brush />
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
