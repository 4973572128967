import React from 'react';
import { Col, Form, Row, Image } from 'antd';
import { DetailBuilderProp, DetailColumnProps, DetailFieldProps } from '.';
import { RenderCurrency, RenderLink, RenderText, RenderTag, RenderImage } from '../index';
import './detail-builder.less';
import * as _ from 'lodash';
import { RenderAddress } from '../render-address';
import { TextEditorDraftWysiwyg } from '../../form-components';

const FormItemIgnore = [
  'customHidden',
  'renderField',
  'fieldType',
  'gridColumn',
  'dependencies',
  'shouldUpdate',
  'noStyle',
  'renderTextProps',
  'renderCurrencyProps',
  'renderTagProps',
  'renderLinkProps',
  'renderAddressProps',
  'renderTextEditorDraftWysiwygProps',
];

function makeGrid(itemField: any) {
  if (itemField?.gridColumn) return itemField.gridColumn;
  return { xs: 24, sm: 6, md: 4, lg: 3, xl: 3 };
}

function defaultCustomHidden(form: any): boolean {
  return false;
}

export function DetailBuilder(props: DetailBuilderProp) {
  const { title: RowTitle, columns = [] } = props;

  return (
    <div className="detail-builder-component">
      <div className="main-title">{RowTitle}</div>
      <Row gutter={[8, 2]} wrap={true}>
        {columns.map((itemColumn: DetailColumnProps, index: number) => {
          const { title: ColumnTitle, gridColumn: gridColumnGroup, fields = [] } = itemColumn;
          return (
            <Col {...(gridColumnGroup ?? { span: 24 })} key={index}>
              <div className="sub-title">{ColumnTitle}</div>
              <Row gutter={[8, 2]} wrap={true}>
                {fields.map((itemField: DetailFieldProps, index: number) => {
                  const {
                    customHidden = defaultCustomHidden,
                    renderField,
                    fieldType,
                    shouldUpdate,
                    dependencies,
                    renderCurrencyProps = {},
                    renderLinkProps = {},
                    renderTagProps = {},
                    renderTextProps = {},
                    renderImageProps = {},
                    renderAddressProps = {},
                    renderTextEditorDraftWysiwygProps = {},
                  } = itemField;

                  const formItemProps = _.omit(itemField, FormItemIgnore) ?? {};
                  const formItemWrapperProps = {
                    shouldUpdate,
                    dependencies: dependencies ?? [],
                  };

                  return (
                    <React.Fragment key={index}>
                      <Form.Item {...formItemWrapperProps} noStyle>
                        {(form) => {
                          if (renderField) {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                {renderField(form)}
                              </Col>
                            );
                          } else if (fieldType === 'text') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderText {...renderTextProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'tag') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderTag {...renderTagProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'currency') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderCurrency {...renderCurrencyProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'link') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderLink {...renderLinkProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'image') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderImage {...renderImageProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'address') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <RenderAddress {...renderAddressProps} />
                                </Form.Item>
                              </Col>
                            );
                          } else if (fieldType === 'textEditorDraftWysiwyg') {
                            return (
                              <Col {...makeGrid(itemField)} hidden={customHidden(form)}>
                                <Form.Item {...formItemProps}>
                                  <TextEditorDraftWysiwyg
                                    {...renderTextEditorDraftWysiwygProps}
                                    useRecordChange={false}
                                    toolbarHidden
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                        }}
                      </Form.Item>
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
