import React, { useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Row, Skeleton, Tag, Tooltip } from 'antd';
import moment from 'moment';

import { ApiUrlData } from '@base-configs';
import { makeCommonDataSource } from '@base-factories';
import { usePageProviderAction } from '@base-hooks';

import './style.less';
import { FormBuilder, SelectPaginate } from '@base-components';
import { makeArrayIds } from '@base-helpers';
import { STYLE_INDICATOR } from '@pages/configuration-pages/metric-indicator/domain/entities';

import {
  CompareIndicator,
  ScoreIndicator,
  SingleIndicator,
  TrendingIndicator,
} from '@pages/dashboard-pages/presentation/components/layout-indicator/preview-indicator/indicator-content';
import '@pages/dashboard-pages/presentation/components/layout-indicator/preview-indicator/style.less';
import { RiArrowLeftRightFill } from 'react-icons/ri';

interface Props {
  dataIndicator?: any;
}
export function TestIndicator(props: Props): JSX.Element {
  const { dataIndicator } = props;
  const metricDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.metric_indicator_example });
  const [showRunButton, setShowRunButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [dataExample, setDataExample] = useState(null);
  const { transformer } = usePageProviderAction();

  const [form] = Form.useForm();

  async function handleGetDataIndicator(): Promise<void> {
    const filterPayload = form.getFieldsValue();
    setLoading(true);

    const newFilter = {
      ...filterPayload,
      date_from: filterPayload?.date_from ?? moment(),
      date_to: filterPayload?.date_to ?? moment(),
      region_ids: undefined,
      sub_region_ids: undefined,
    };

    if (dataIndicator?.scope === 'region' && filterPayload?.region_ids) {
      Object.assign(newFilter, {
        region_ids: makeArrayIds({ data: filterPayload?.region_ids }).map((item) => item.toString()),
      });
    } else if (dataIndicator?.scope === 'sub_region' && filterPayload?.sub_region_ids) {
      Object.assign(newFilter, {
        sub_region_ids: makeArrayIds({ data: filterPayload?.sub_region_ids }).map((item) => item.toString()),
      });
    }

    const payload = { ...newFilter, ...dataIndicator };
    await metricDataSource.handleCreate(transformer.transformerCreate(payload), {
      onSuccess({ response }: any) {
        console.log({ response });
        setLoading(false);
        setShowRunButton(false);
        setDataExample(response);
      },
      onFailed: ({ message }: any): any => {
        setLoading(false);
        setError(message);
        setShowRunButton(false);
      },
    });
  }

  useEffect(() => {
    setShowRunButton(true);
    setError(undefined);
  }, [dataIndicator]);

  const [isCompare, setIsCompare] = useState(false);
  const [showCompare, setShowCompare] = useState(false);

  function IndicatorContent() {
    if (error) {
      return (
        <div>
          <div style={{ marginBottom: '5px' }}>Error:</div>
          <Tag color="error" className="error-area">
            {error}
          </Tag>
        </div>
      );
    } else if (dataIndicator?.style === STYLE_INDICATOR.COMPARE_METRIC) {
      setShowCompare(true);
      // return <div></div>;
      return <CompareIndicator indicatorContent={dataExample} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.TRENDING_METRIC) {
      setShowCompare(true);
      return <TrendingIndicator indicatorContent={dataExample} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.SINGLE_METRIC) {
      setShowCompare(true);
      // return <div></div>;
      return <SingleIndicator indicatorContent={dataExample} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.SCORES_LABEL_METRIC) {
      setShowCompare(false);
      // return <div></div>;
      return <ScoreIndicator indicatorContent={dataExample} isCompare={isCompare} />;
    }
  }

  const handleValueFilter = () => {
    setShowRunButton(true);
    setError(undefined);
  };

  return (
    <>
      <Form form={form} component={false} onValuesChange={handleValueFilter}>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 4 },
                  customHidden: () => dataIndicator?.scope === 'global' || !dataIndicator?.scope,
                  renderField(form) {
                    const key = JSON.stringify([form.getFieldsValue() ?? {}]);
                    const scope = dataIndicator?.scope;
                    const region = scope === 'region';
                    const sub_region = scope === 'sub_region';
                    if (region) {
                      return (
                        <Form.Item name="region_ids" label="Region">
                          <SelectPaginate
                            key={key}
                            dataSourceUrl={ApiUrlData.region}
                            customLabel={(value) => {
                              return value?.rc_dpw_name;
                            }}
                          />
                        </Form.Item>
                      );
                    }

                    if (sub_region) {
                      return (
                        <Form.Item name="sub_region_ids" label="Church">
                          <SelectPaginate
                            key={key}
                            dataSourceUrl={ApiUrlData.sub_region}
                            customLabel={(value) => {
                              return value?.church_name;
                            }}
                          />
                        </Form.Item>
                      );
                    }
                  },
                },

                {
                  name: 'period_type',
                  label: 'Period Type',
                  fieldType: 'radioGroup',
                  gridColumn: { span: 4 },
                  radioGroupProps: {
                    options: [
                      { label: 'Year', value: 'year' },
                      { label: 'Month', value: 'month' },
                      { label: 'Week', value: 'week' },
                    ],
                  },
                },
                // {
                //   gridColumn: { span: 4 },
                //   renderField(form) {
                //     const type = form.getFieldValue('period_type');
                //     return (
                //       <Form.Item name="date_from" label="Start Date">
                //         <DatePicker picker={type} style={{ width: '100%' }} />
                //       </Form.Item>
                //     );
                //   },
                // },
                {
                  gridColumn: { span: 4 },
                  renderField(form) {
                    const type = form.getFieldValue('period_type');
                    return (
                      <Form.Item name="date_to" label="End Period">
                        <DatePicker picker={type} style={{ width: '100%' }} />
                      </Form.Item>
                    );
                  },
                },
              ],
            },
          ]}
        />
        <div style={{ marginBottom: '20px' }}></div>
        <Row>
          <Col sm={24} xs={24} md={12} lg={12} span={12}>
            {showRunButton ? (
              <Card>
                <div className="indicator-component ">
                  <div className="run-area">
                    <Button loading={loading} icon={<CaretRightOutlined />} onClick={() => handleGetDataIndicator()}>
                      Run
                    </Button>
                  </div>
                </div>
              </Card>
            ) : (
              <Card
                className="card-content-wrapper card-dashboard-wrapper metric-indicator-wrapper"
                style={{
                  // width: 'fit-content',
                  padding: '5px 25px 25px 25px',
                }}
              >
                {loading && <Skeleton active paragraph={{ rows: 4 }} style={{ marginTop: 15, height: 100 }} />}
                {!loading && (
                  <React.Fragment>
                    {showCompare && (
                      <div className="switch-compare-button" onClick={() => setIsCompare(!isCompare)}>
                        <Tooltip title={`${isCompare ? 'Year on Year ' : 'This year'}`} placement="bottom">
                          <RiArrowLeftRightFill
                            key={1}
                            style={{
                              fontSize: 18,
                              cursor: 'pointer',
                              right: 0,
                              color: isCompare ? 'orange' : 'black',
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                    <div style={{ marginBottom: 10 }}></div>
                    <IndicatorContent />
                  </React.Fragment>
                )}
              </Card>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}
