import React from 'react';
import { InfinityDataTable } from '@base-components';
import { useDataTableAction } from '@base-hooks';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { makeCommonNavigation } from '@base-navigation';
import { useNavigate } from 'react-router-dom';

export default function DataTable() {
  const navigateCrm = makeCommonNavigation({ webUrl: WebUrlData.crm_board, navigate: useNavigate() });
  const dataSourceNotification = makeCommonDataSource({ apiUrl: ApiUrlData.notification });

  async function handleClick(row) {
    const cardCode = row.content?.split(' ')[0];
    if (row.read_status) {
      console.log({ row, cardCode });
      navigateCrm.goToIndex({ search: { code: cardCode } });
    } else {
      await dataSourceNotification.handleUpdate(
        row.id,
        { read_status: true },
        {
          onSuccess({ message }: any) {
            console.log({ message });
            navigateCrm.goToIndex({ search: { code: cardCode } });
          },
        },
      );
    }
  }

  const columns = [
    {
      title: 'Message',
      dataIndex: 'content',
    },
    {
      title: 'Status',
      width: 100,
      render(_, row) {
        const status = row.read_status ? 'Read' : 'Unread';
        return status;
      },
    },
  ];

  return (
    <div>
      <InfinityDataTable
        columns={columns}
        ignoreDefaultColumn={['status']}
        // className=""
        onRow={(record) => {
          return {
            onClick: () => handleClick(record),
          };
        }}
        rowClassName={(record) => {
          // if (!record.read_status) return 'no-read-notification';
          return 'read-notification';
        }}
        rowAction={{
          showDuplicate: () => false,
          showUpdate: () => false,
        }}
      />
    </div>
  );
}
