import { e, typeOf } from 'mathjs';
import { CSSProperties, ReactNode } from 'react';

export function defaultRenderDetail(value: any): ReactNode {
  return '';
}

export function makeValueForRender(value: any) {
  if (!value) return [];
  else if (typeOf(value) !== 'Array') return [value];
  return value;
}

export function makeItemValue(value: any, key: string) {
  if (!value) return '';
  else if (typeOf(value) === 'Object') return value[key];
  return value;
}

export interface BaseRenderProps {
  value?: any;
  keyValue?: string;
  styleItem?: CSSProperties;
  styleWrapper?: CSSProperties;
  classNameItem?: string;
  classNameWrapper?: string;
  renderType?: 'multi' | 'single';
  renderValue?(value: any): JSX.Element | ReactNode;
  defaultRender?(value: any): JSX.Element | ReactNode;
}
