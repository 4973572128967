import React, { useState } from 'react';
import { InfinityDataTable, RowActionProps, validateMessages } from '@base-components';
import { Button, Form, Modal, Tooltip } from 'antd';
import { LockKey, LockKeyOpen, PlusCircle, TrashSimple } from 'phosphor-react';
import { DataTableContextEntity, DataTableProvider } from '@base-providers';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';
import { useDataTableAction, useFilterDataTable, useMetaDataTable } from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';
import { IBaseDataSourceRepository, IBaseTransformerRepository } from '@base-repositories';
import { ColumnsType } from 'antd/lib/table';
import { FormFieldProps } from '../../form-components';
import { EnumStatusData } from '@base-entities';
type DefaultColumnType = 'status' | 'action' | 'created_at' | 'updated_at';

const defaultInitialFormValue = {
  key: uuidV4(),
};

interface FormField extends FormFieldProps {
  columnKey: string;
}

export interface ExpandableFormTableProps {
  moduleKey: string;
  dataSource: IBaseDataSourceRepository<any>;
  ignoreKeyDuplicate?: string[];
  ignoreKeyUpdate?: string[];
  recordData: any;
  setRecordData(data: any): void;
  selectedData: any;
  setSelectedData(data: any): void;
  meta: any;
  setMeta(data: any): void;
  filter: any;
  setFilter(data: any): void;
  loading: any;
  setLoading(data: any): void;
  columns: ColumnsType;
  inlineFormFields: FormField[];
  initialFormValue?: { key: string; [key: string]: any };
  transformer?: IBaseTransformerRepository;
  transformerFilterGetIndex?(filter: any): any;
  handleGetData?(filter?: any, isLoadMore?: boolean): void;
  tooltipTitleAddButton?: string;

  titleConfirmDelete?: JSX.Element | React.ReactNode;
  titleConfirmActivate?: JSX.Element | React.ReactNode;
  titleConfirmDeactivate?: JSX.Element | React.ReactNode;

  contentConfirmDelete?: JSX.Element | React.ReactNode;
  contentConfirmActivate?: JSX.Element | React.ReactNode;
  contentConfirmDeactivate?: JSX.Element | React.ReactNode;

  showBatchDelete?: boolean;
  showBatchActivate?: boolean;
  showBatchDeactivate?: boolean;
  onChangeTable?: any;
  additionalToolbarContent?: React.ReactNode;
  rowAction?: RowActionProps;
  ignoreDefaultColumn?: DefaultColumnType[];
}

const defaultTitleConfirmDelete = 'Delete Confirmation';
const defaultTitleConfirmActivate = 'Activate Confirmation';
const defaultTitleConfirmDeactivate = 'Deactivate Confirmation';

const defaultContentConfirmDelete = 'Are you sure you want to delete this?';
const defaultContentConfirmActivate = 'Are you sure you want to activate this?';
const defaultContentConfirmDeactivate = 'Are you sure you want to deactivate this?';

export function ServerSideExpandableFormTable(props: ExpandableFormTableProps) {
  const {
    moduleKey,
    ignoreKeyDuplicate = [],
    ignoreKeyUpdate = [],
    dataSource,
    recordData,
    setRecordData,
    selectedData,
    setSelectedData,
    meta,
    setMeta,
    filter,
    setFilter,
    columns,
    inlineFormFields,
    initialFormValue,
    transformer,
    transformerFilterGetIndex,
    loading,
    setLoading,
    handleGetData,
    tooltipTitleAddButton = 'Add',

    contentConfirmDelete = defaultContentConfirmDelete,
    contentConfirmActivate = defaultContentConfirmActivate,
    contentConfirmDeactivate = defaultContentConfirmDeactivate,

    titleConfirmDelete = defaultTitleConfirmDelete,
    titleConfirmActivate = defaultTitleConfirmActivate,
    titleConfirmDeactivate = defaultTitleConfirmDeactivate,

    showBatchDelete = true,
    showBatchActivate = true,
    showBatchDeactivate = true,
    onChangeTable,
    additionalToolbarContent,
    rowAction = {},
    ignoreDefaultColumn,
  } = props;

  const tableType = 'inline-form';
  const { setDisableAddNew } = useDataTableAction();

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');

  const [formAction, setFormAction] = useState<'create' | 'update' | 'duplicate'>('create');
  const isEditing = (record: any) => record.key === editingKey;

  function manipulationKeyData(response: any[]): any[] {
    let data = response ?? [];
    if (transformer?.transformerGetIndex) data = transformer.transformerGetIndex(response);
    const newData = data.map((item: any) => {
      return {
        ...item,
        key: item.id,
      };
    });
    return newData;
  }

  function transformerFilterIndexTable(filter) {
    if (transformerFilterGetIndex) return transformerFilterGetIndex(filter);
    return filter;
  }

  async function handleGetDataIndex(filter?: any, isLoadMore?: boolean): Promise<void> {
    if (handleGetData) await handleGetData(filter, isLoadMore);
    else {
      setLoading(true);
      await dataSource.handleGetIndex({
        params: transformerFilterIndexTable(filter),
        onSuccess: ({ response }: any) => {
          if (isLoadMore) {
            const currentData = recordData ?? [];
            const manipulateData = manipulationKeyData(response.items) ?? [];
            const newData = [...currentData, ...manipulateData];
            setRecordData(newData);
          } else {
            setRecordData(manipulationKeyData(response.items));
          }
          setMeta(response.meta);
          setFilter(filter);
          setLoading(false);
        },
        onFailed: ({ message }: any) => {
          setLoading(false);
        },
      });
    }
  }

  async function handleDelete(payload): Promise<void> {
    if (!payload.id) {
      setRecordData(recordData.filter((item: any) => item.key !== payload.key));
      setMeta({ ...meta, totalItems: meta.totalItems - 1 });
    } else {
      await dataSource.handleDelete(payload, {
        onSuccess: ({ response }: any) => {
          notificationSuccess([response]);
          setRecordData(recordData.filter((item: any) => item.id !== payload.id));
          setMeta({ ...meta, totalItems: meta.totalItems - 1 });
        },
        onFailed: ({ message }: any) => {
          notificationFailed([message]);
        },
      });
    }
  }

  async function handleActivate(payload): Promise<void> {
    await dataSource.handleActivate(payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        notificationSuccess(['Successfully activated']);
        setRecordData(
          recordData.map((item: any) => {
            if (item.id !== response.id) return item;
            return {
              ...cleanResponse,
              key: response.id,
            };
          }),
        );
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  function makeDataForm(payload: any, action: string): void {
    let formPayload = payload;
    const isUpdate = action === 'update';
    const isDuplicate = action === 'duplicate';

    if (isUpdate) formPayload = _.omit(formPayload, ignoreKeyUpdate);
    else if (isDuplicate) formPayload = _.omit(formPayload, ignoreKeyDuplicate);
    form.setFieldsValue({ form_action: action, ...(formPayload ?? {}) });
  }

  async function handleDeactivate(payload): Promise<void> {
    await dataSource.handleDeactivate(payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        notificationSuccess(['Successfully deactivated']);
        setRecordData(
          recordData.map((item: any) => {
            if (item.id !== response.id) return item;
            return {
              ...cleanResponse,
              key: response.id,
            };
          }),
        );
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  function handleUpdate(row: any) {
    setDisableAddNew(true);
    form.resetFields();
    makeDataForm(row, 'update');
    setEditingKey(row.key);
    setFormAction('update');
  }

  function handleDuplicate(row: any) {
    const initData = _.omit(row, ['id', ...ignoreKeyDuplicate]);
    Object.assign(initData, {
      key: uuidV4(),
    });
    handleAddNew(initData, 'duplicate');
  }

  async function handleBatchDelete(payload = []): Promise<void> {
    await dataSource.handleBatchDelete(payload, {
      onSuccess: ({ response }: any) => {
        const successMessage = response?.success ? response?.success?.map((item: any) => item.message) : [];
        const failedMessage = response?.failed ? response?.failed?.map((item: any) => item.message) : [];
        const successId = response?.success ? response?.success?.map((item: any) => item.id) : [];
        if (successMessage.length > 0) notificationSuccess(successMessage);
        if (failedMessage.length > 0) notificationFailed(failedMessage);
        const newData = recordData.filter((item: any) => !successId.includes(item.id.toString()));
        const newDataSelectedData = selectedData.filter((item: any) => !successId.includes(item.id.toString()));
        setRecordData(newData);
        setSelectedData(newDataSelectedData);
        setMeta({ ...meta, totalItems: meta.totalItems - successId.length });
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleBatchActivate(payload = []): Promise<void> {
    await dataSource.handleBatchActivate(payload, {
      onSuccess: ({ response }: any) => {
        const successMessage = response?.success ? response?.success?.map((item: any) => item.message) : [];
        const failedMessage = response?.failed ? response?.failed?.map((item: any) => item.message) : [];
        const successId = response?.success ? response?.success?.map((item: any) => item.id) : [];
        if (successMessage.length > 0) notificationSuccess(successMessage);
        if (failedMessage.length > 0) notificationFailed(failedMessage);
        const newData = recordData.map((item: any) => {
          if (!successId.includes(item.id.toString())) return item;
          return {
            ...item,
            status: EnumStatusData.active,
          };
        });
        setRecordData(newData);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleBatchDeactivate(payload = []): Promise<void> {
    await dataSource.handleBatchDeactivate(payload, {
      onSuccess: ({ response }: any) => {
        const successMessage = response?.success ? response?.success?.map((item: any) => item.message) : [];
        const failedMessage = response?.failed ? response?.failed?.map((item: any) => item.message) : [];
        const successId = response?.success ? response?.success?.map((item: any) => item.id) : [];
        if (successMessage.length > 0) notificationSuccess(successMessage);
        if (failedMessage.length > 0) notificationFailed(failedMessage);
        const newData = recordData.map((item: any) => {
          if (!successId.includes(item.id.toString())) return item;
          return {
            ...item,
            status: EnumStatusData.inactive,
          };
        });
        setRecordData(newData);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  function onCancelEditing() {
    const id = recordData.find((item: any) => item.id === editingKey)?.id;
    if (id) {
      setDisableAddNew(false);
      form.resetFields();
      setEditingKey('');
    } else {
      setDisableAddNew(false);
      setRecordData(recordData.filter((item: any) => item.key !== editingKey));
      setMeta({ ...meta, totalItems: meta.totalItems - 1 });
      form.resetFields();
      setEditingKey('');
    }
  }

  async function create(payload: any): Promise<void> {
    await dataSource.handleCreate(payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        setRecordData((recordData) =>
          recordData.map((item) => {
            if (item.key !== editingKey) return item;
            return {
              ...cleanResponse,
              key: cleanResponse.id,
              id: cleanResponse.id,
            };
          }),
        );
        setEditingKey('');
        setDisableAddNew(false);
        form.resetFields();
        notificationSuccess(['Successfully created data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function update(id: string, payload: any): Promise<void> {
    await dataSource.handleUpdate(id, payload, {
      onSuccess: ({ response }: any) => {
        const cleanResponse = transformer?.transformerGetData?.(response) ?? response;
        setRecordData((recordData) =>
          recordData.map((item) => {
            if (item.key !== editingKey) return item;
            return {
              ...cleanResponse,
              key: cleanResponse.id,
              id: cleanResponse.id,
            };
          }),
        );
        setEditingKey('');
        setDisableAddNew(false);
        form.resetFields();
        notificationSuccess(['Successfully updated data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  function transformerCreate(payload: any) {
    if (transformer?.transformerCreate) return transformer.transformerCreate(payload);
    return payload;
  }

  function transformerUpdate(payload: any) {
    if (transformer?.transformerUpdate) return transformer.transformerUpdate(payload);
    return payload;
  }

  function transformerDuplicate(payload: any) {
    if (transformer?.transformerDuplicate) return transformer.transformerDuplicate(payload);
    return payload;
  }

  async function onConfirmSave(): Promise<void> {
    try {
      const values = await form.validateFields();

      const isUpdate = formAction === 'update';
      const isDuplicate = formAction === 'duplicate';
      const isCreate = formAction === 'create';
      let payload = values;
      if (isUpdate) payload = transformerUpdate(payload);
      else if (isCreate) payload = transformerCreate(payload);
      else if (isDuplicate) payload = transformerDuplicate(payload);

      const cleanVal = _.omit(payload, ['id', 'form_action']);
      if (isUpdate) await update(values?.id, cleanVal);
      else await create(cleanVal);
      setDisableAddNew(false);
    } catch (error) {}
  }

  function handleAddNew(initial?: any, action?: 'create' | 'update' | 'duplicate') {
    const actionForm = action ?? 'create';
    setDisableAddNew(true);
    const row = initial ?? initialFormValue ?? defaultInitialFormValue;
    setEditingKey(row.key);
    setRecordData([row, ...recordData]);
    setFormAction(actionForm);
    setMeta({ ...meta, totalItems: meta.totalItems + 1 });
    makeDataForm(row, actionForm);
  }

  const initValueDataTableProvider: DataTableContextEntity = {
    editingKey,
    setEditingKey,
    recordData,
    setRecordData,
    selectedData,
    setSelectedData,
    form,
    meta,
    filter,
    setMeta,
    loading,
    setFilter,
    tableType,
    formAction,
    isEditing,
    setLoading,
    handleDelete,
    setFormAction,
    handleActivate,
    handleDeactivate,
    handleGetDataIndex,
    handleUpdate,
    handleDuplicate,
    onCancelEditing,
    onConfirmSave,
  };

  return (
    <DataTableProvider initialValue={initValueDataTableProvider}>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        {selectedData?.length > 0 && showBatchDelete && (
          <Tooltip placement="bottom" title="Delete">
            <Button
              type="text"
              icon={<TrashSimple size={17} />}
              onClick={() => {
                Modal.confirm({
                  mask: true,
                  okText: 'Confirm',
                  title: titleConfirmDelete,
                  content: contentConfirmDelete,
                  onOk: () => handleBatchDelete(selectedData),
                });
              }}
            />
          </Tooltip>
        )}

        {selectedData?.length > 0 && showBatchActivate && (
          <Tooltip placement="bottom" title="Activate">
            <Button
              type="text"
              icon={<LockKeyOpen size={17} />}
              onClick={() => {
                Modal.confirm({
                  mask: true,
                  okText: 'Confirm',
                  title: titleConfirmActivate,
                  content: contentConfirmActivate,
                  onOk: () => handleBatchActivate(selectedData),
                });
              }}
            />
          </Tooltip>
        )}
        {selectedData?.length > 0 && showBatchDeactivate && (
          <Tooltip placement="bottom" title="Deactivate">
            <Button
              type="text"
              icon={<LockKey size={17} />}
              onClick={() => {
                Modal.confirm({
                  mask: true,
                  okText: 'Confirm',
                  title: titleConfirmDeactivate,
                  content: contentConfirmDeactivate,
                  onOk: () => handleBatchDeactivate(selectedData),
                });
              }}
            />
          </Tooltip>
        )}

        {additionalToolbarContent}
      </div>

      <Form form={form} component={false} name={`form-inline-table-${moduleKey}`} validateMessages={validateMessages}>
        <InfinityDataTable
          style={{ background: '#fff' }}
          columns={columns}
          useConfirmCancel={false}
          ignoreDefaultColumn={ignoreDefaultColumn}
          rowAction={{
            prefixExtensionAction(record) {
              const index = recordData.findIndex((item) => item.id === record.id);
              if (isEditing(record) || index !== 0) return;
              return (
                <React.Fragment>
                  <Tooltip placement="bottom" title={tooltipTitleAddButton}>
                    <Button
                      type="text"
                      shape="circle"
                      size="small"
                      style={{ marginRight: '3px' }}
                      icon={<PlusCircle size={17} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddNew();
                      }}
                    />
                  </Tooltip>
                </React.Fragment>
              );
            },
            ...rowAction,
          }}
          onRow={(record) => {
            return {
              onClick: () => undefined,
            };
          }}
          inlineFormFields={inlineFormFields}
          locale={{
            emptyText: (
              <div style={{ height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Click
                <span
                  style={{ color: 'black', marginLeft: '5px', marginRight: '5px', fontStyle: 'italic' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddNew();
                  }}
                >
                  here
                </span>
                to add new data.
              </div>
            ),
          }}
          {...(onChangeTable ? { onChange: onChangeTable } : {})}
        />
      </Form>
    </DataTableProvider>
  );
}
