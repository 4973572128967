import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthRoutes, NoAuthRoutes, PublicRoutes, DefaultPath } from '@main/routes/router';
import AuthLayout from '@main/layouts/auth-layout';
import PublicLayout from '@main/layouts/public-layout';
import AuthRouterProtector from '@main/routes/protector/auth-router.protector';
import NoAuthRouterProtector from '@main/routes/protector/no-auth-router.protector';
import NotFoundPage from '@pages/result-pages/404';

export default function AppRoutes(): JSX.Element {
  return (
    <Routes>
      {AuthRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <AuthRouterProtector routeItem={{ ...item, basePath: item.path }}>
                <AuthLayout>
                  <item.element />
                </AuthLayout>
              </AuthRouterProtector>
            }
          ></Route>
          {item.path !== '/' && (
            <Route
              path={item.path + '/:action'}
              element={
                <AuthRouterProtector routeItem={{ ...item, path: `${item.path}:/action`, basePath: item.path }}>
                  <AuthLayout>
                    <item.element />
                  </AuthLayout>
                </AuthRouterProtector>
              }
            ></Route>
          )}
        </React.Fragment>
      ))}
      {NoAuthRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <NoAuthRouterProtector>
                <PublicLayout>
                  <item.element />
                </PublicLayout>
              </NoAuthRouterProtector>
            }
          ></Route>
        </React.Fragment>
      ))}
      {PublicRoutes.map((item) => (
        <React.Fragment key={item.path}>
          <Route
            path={item.path}
            element={
              <PublicLayout>
                <item.element />
              </PublicLayout>
            }
          ></Route>
        </React.Fragment>
      ))}
      <Route path="/" element={<Navigate to={DefaultPath} replace />}></Route>
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
}
