import { RouteEntity } from '@base-entities';
import { WebUrlData } from '@base-configs';
import PreviewComponent from '@pages/testing-pages/preview';
import { LoginBlank } from '@pages/authentication-pages/login-blank';

export const PublicRoutes: RouteEntity[] = [
  {
    label: 'Preview',
    path: WebUrlData.preview,
    element: PreviewComponent,
  },
  {
    label: 'Main App',
    path: WebUrlData.main_app,
    element: LoginBlank,
  },
];
