import React from 'react';
import { RenderCurrencyProps } from './render-currency.entity';
import { IntlProvider, FormattedNumber } from 'react-intl';
import * as _ from 'lodash';
import { defaultRenderDetail, makeItemValue, makeValueForRender } from '../base-render';
import './render-currency.less';
import { typeOf } from 'mathjs';

export function RenderCurrency(props: RenderCurrencyProps) {
  const {
    value,
    makeValue,
    withColon = true,
    keyValue = 'code',
    renderType = 'single',
    classNameItem = '',
    classNameWrapper = '',
    defaultRender = defaultRenderDetail,
    currencyOptions,
    styleWrapper,
    styleItem,
  } = props;

  if (value === null || value === undefined) return <div>{defaultRender(value)}</div>;
  else if (typeOf(value) === 'Array' && value?.length == 0) return <div>{defaultRender(value)}</div>;

  const valueForRender = makeValueForRender(value);
  const singleValueForRender = valueForRender[0];
  const currencyConfig: any = {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 2,
    ...currencyOptions,
  };

  function Rendering() {
    if (renderType === 'single') {
      const numberValue = makeValue ? makeValue(singleValueForRender) : singleValueForRender;
      return (
        <div className={`${classNameItem}`} style={styleItem}>
          <FormattedNumber {...currencyConfig} value={numberValue} />
        </div>
      );
    }
    return (
      <div className={`render-currency-wrapper ${classNameWrapper}`} style={styleWrapper}>
        {valueForRender.map((item, idx) => {
          const itemValue = makeItemValue(item, keyValue);
          const numberValue = makeValue ? makeValue(item) : itemValue;

          return (
            <div
              key={idx}
              className={`${withColon ? '' : 'render-currency-item-gap'} ${classNameItem}`}
              style={styleItem}
            >
              <FormattedNumber {...currencyConfig} value={numberValue} />
              {idx !== valueForRender.length - 1 && withColon && <span className="render-currency-colon">,</span>}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="render-currency-component">
      <IntlProvider locale={navigator.language} defaultLocale="en">
        <Rendering />
      </IntlProvider>
    </div>
  );
}
