import React, { useEffect, useState } from 'react';
import { Button, Card, Drawer, Dropdown, Form, Menu } from 'antd';
import { useRecoilValue } from 'recoil';
import { ArrowSquareIn, Calendar } from 'phosphor-react';

import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { notificationFailed, stringFormatter } from '@base-helpers';
import { loginAccountState } from '@base-states';
import { useFilterDataTable, useMediaQuery, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { ApiUrlData } from '@base-configs';

import UploadImportFile from '../component/upload';
import { activityColumns } from '../helpers';

export default function PageIndex(): JSX.Element {
  const isMobile = useMediaQuery({ media: 'mobile' });
  const shortWidth = isMobile ? 0 : 10;
  const longWidth = isMobile ? 0 : 30;

  const { authState } = useRecoilValue(loginAccountState);
  const { moduleKey } = usePageProviderData();
  const { dataSource } = usePageProviderAction();
  const generalConfig = authState.getGeneralConfig() ?? [];
  const user = authState.getAccount() ?? [];

  const scope = user?.scope;
  const submission_type = generalConfig[0]?.periode_submission;

  const [viewPeriod, setViewPeriod] = useState(submission_type);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataUpload, setDataUpload] = useState([]);
  const [filter, setFilter] = useFilterDataTable(moduleKey);
  const [form] = Form.useForm();

  const formBuilderProps: any = {
    columns: [
      {
        fields: [
          {
            name: 'activity_ids',
            label: 'ID',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              isMulti: true,
              dataSourceUrl: ApiUrlData.activity_activities,
              customLabel: (value) => value?.activity_id,
            },
          },
          {
            name: 'region_ids',
            label: 'Region',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              isMulti: true,
              dataSourceUrl: ApiUrlData.region,
              customLabel: (value) => value?.rc_dpw_name,
              customFilterRequest: () => ({
                status: 'Active',
              }),
            },
          },
          {
            name: 'sub_region_ids',
            label: 'Local Church & Satellite',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              isMulti: true,
              dataSourceUrl: ApiUrlData.sub_region,
              customLabel: (value) => value?.church_name,
              customFilterRequest: () => ({
                status: 'Active',
              }),
            },
          },
          {
            name: 'period',
            label: 'Period',
            fieldType: 'dateRangePicker',
            gridColumn: { span: 12 },
            dateRangePickerProps: {
              picker: viewPeriod === 'monthly' ? 'month' : 'week',
            },
          },
          {
            name: 'status',
            label: 'Status',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              placeholder: 'Select status',
              options: [
                { label: 'posted', value: 'posted' },
                { label: 'obsolete', value: 'obsolete' },
              ],
            },
          },
          {
            name: 'created_at',
            label: 'Created At',
            gridColumn: { span: 12 },
            fieldType: 'dateRangePicker',
          },
          {
            name: 'created_ids',
            label: 'Created By',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              isMulti: true,
              placeholder: 'select user',
              dataSourceUrl: ApiUrlData.users,
              customLabel: (value) => value?.first_name,
            },
          },
        ],
      },
    ],
  };

  function showImportDrawer() {
    setShowDrawer(true);
  }

  function closeImportDrawer() {
    setShowDrawer(false);
  }

  async function onChangePeriod(period) {
    setViewPeriod(period);
    setFilter({ ...(filter ?? {}), period_submission: period }); //TODO open comment if backend ready
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 0);
  }

  function PeriodMenu(): JSX.Element {
    return (
      <Menu style={{ width: '100%' }}>
        <Menu.Item onClick={() => onChangePeriod('monthly')}>Monthly</Menu.Item>
        <Menu.Item onClick={() => onChangePeriod('weekly')}>Weekly</Menu.Item>
      </Menu>
    );
  }

  useEffect(() => {
    onChangePeriod(viewPeriod);
  }, []);

  function CustomActivityToolbar(): JSX.Element {
    return (
      <>
        <Dropdown overlay={<PeriodMenu />} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
          <Button
            type="text"
            shape="circle"
            icon={<Calendar size={16} style={{ marginLeft: 5 }} />}
            style={{
              marginLeft: shortWidth,
              marginRight: shortWidth,
              display: 'flex',
              alignItems: 'center',
              marginTop: '-5px',
            }}
          >
            {!isMobile && <div style={{ marginLeft: '5px' }}>{stringFormatter.capitalizeEachWord(viewPeriod)}</div>}
          </Button>
        </Dropdown>
        <div className="separator-content"></div>
        {scope === 'global' && (
          <React.Fragment>
            <Button
              type="text"
              shape="circle"
              icon={<ArrowSquareIn size={16} style={{ marginLeft: 5 }} />}
              style={{
                marginLeft: shortWidth,
                marginRight: shortWidth,
                display: 'flex',
                alignItems: 'center',
                marginTop: '-5px',
              }}
              onClick={showImportDrawer}
            >
              {!isMobile && <div style={{ marginLeft: 5 }}>Import</div>}
            </Button>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
      </>
    );
  }

  function reloadContent() {
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 0);
  }

  async function handleOnUpload(payload: any): Promise<void> {
    setLoading(true);

    if (payload?.file_activity) {
      const importID = payload?.file_activity?.id;
      await dataSource.handleCustomRequest({
        paramRequest: {
          url: `${process.env.REACT_APP_BASE_URL}v1/activity/import/process/${importID}`,
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: '*/*',
          },
        },
        onSuccess: ({ response }: any) => {
          const dataCSV = response?.detail_csv;
          setDataUpload(dataCSV);
          setLoading(false);
          setShowDrawer(false);
          form.resetFields();
          reloadContent();
        },
        onFailed: ({ message }: any) => {
          notificationFailed([message]);
          setLoading(false);
          setDataUpload([]);
          // reloadContent();
        },
      });
    }
  }

  if (!showContent) return <div></div>;

  return (
    <IndexPageWrapper
      showButtonBack={false}
      showBatchActivate={false}
      showBatchDeactivate={false}
      toolbarFixRightContent={<CustomActivityToolbar />}
      drawerFilterProp={{
        formBuilderProps,
      }}
    >
      <Drawer
        title="Import File"
        placement="right"
        width={isMobile ? '100%' : 528}
        onClose={closeImportDrawer}
        visible={showDrawer}
        // closable={false}
      >
        <Form form={form} onFinish={handleOnUpload}>
          <div className="import">
            <Form.Item name="file_activity" rules={[{ required: true, message: 'Please upload your file first' }]}>
              <UploadImportFile />
            </Form.Item>
          </div>
        </Form>
      </Drawer>

      <Card className="card-content-wrapper">
        <InfinityDataTable
          columns={activityColumns}
          loading={loading}
          {...(user?.scope === 'region'
            ? { ignoreDefaultColumn: ['action'], scroll: { x: 'fit-content', y: 500 } }
            : {})}
          rowAction={{
            showActivate: () => false,
            showDeactivate: () => false,
            disableDelete: () => user?.scope === 'region',
            disableDuplicate: () => user?.scope === 'region',
            disableUpdate: () => true,
            showUpdate: () => false,
            showDelete: (row) => row.status !== 'Posted',
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
