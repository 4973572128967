import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import moment from 'moment';
import * as _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { omit, pick } from 'lodash';
import { stringFormatter } from '@base-helpers';

export class ActivityTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action', 'period', 'activity_detail']);
    const submission_type = payload?.submission_type;
    const month = submission_type === 'monthly' ? moment(payload?.period).toISOString() : undefined;
    const week = submission_type === 'weekly' ? moment(payload?.period).toISOString() : undefined;
    const region = pick(cleanVal.region, ['id', 'rc_dpw_name']);
    const sub_region = pick(cleanVal.sub_region, ['id', 'church_name']);
    const activity_detail = payload?.activity_detail.map((item) => {
      const itemValue = pick(item, ['note', 'value']);
      const newItem = pick(item, ['category_id', 'category_name']);
      const subCategory = item?.children ?? [{ ...itemValue, value: itemValue.value ? Number(itemValue.value) : 0 }];
      const activity_sub_category = subCategory?.map((child) => {
        return {
          ..._.omit(child, ['key', 'keyParent', 'category_id', 'category_name']),
          value: child.value ? Number(child.value) : 0,
          sub_category_id: child.category_id,
          sub_category_name: child.category_name,
        };
      });

      Object.assign(newItem, { activity_sub_category });
      return newItem;
    });

    Object.assign(cleanVal, {
      month,
      week,
      region,
      sub_region,
      submission_type,
      activity_detail,
    });
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action', 'period', 'activity_detail']);
    const submission_type = payload?.submission_type;
    const month = submission_type === 'monthly' ? moment(payload?.period).toISOString() : undefined;
    const week = submission_type === 'weekly' ? moment(payload?.period).toISOString() : undefined;
    const region = pick(cleanVal.region, ['id', 'rc_dpw_name']);
    const sub_region = pick(cleanVal.sub_region, ['id', 'church_name']);
    const activity_detail = payload?.activity_detail.map((item) => {
      const itemValue = pick(item, ['note', 'value']);
      const newItem = pick(item, ['category_id', 'category_name']);
      const subCategory = item?.children ?? [{ ...itemValue, value: itemValue.value ? Number(itemValue.value) : 0 }];
      const activity_sub_category = subCategory?.map((child) => {
        return {
          ..._.omit(child, ['key', 'keyParent', 'category_id', 'category_name', 'id']),
          value: child.value ? Number(child.value) : 0,
          sub_category_id: child.category_id,
          sub_category_name: child.category_name,
        };
      });

      Object.assign(newItem, { activity_sub_category });
      return newItem;
    });

    Object.assign(cleanVal, {
      month,
      week,
      region,
      sub_region,
      submission_type,
      activity_detail,
    });
    return cleanVal;
  }

  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    const cleanVal = payload;
    const newVal = {
      ...cleanVal,
      period: moment(cleanVal?.month ?? cleanVal?.week),
      activity_detail: cleanVal?.activity_detail?.map((item) => {
        const keyParent = item.id ?? uuidV4();
        const itemValue = item?.activity_sub_category?.find((itemSub) => !itemSub.sub_category_id);
        const itemSubValue = item?.activity_sub_category
          ?.filter((itemSub) => itemSub.sub_category_id)
          ?.map((itemAc) => {
            return {
              ...itemAc,
              keyParent: keyParent,
              key: itemAc?.id ?? uuidV4(),
              category_id: itemAc?.sub_category_id,
              category_name: itemAc?.sub_category_name,
            };
          });

        const newItemVal = {
          ...item,
          ...itemValue,
          key: keyParent,
          children: itemSubValue,
        };
        if (newItemVal?.children?.length == 0) return omit(newItemVal, ['children', 'activity_sub_category']);
        return omit(newItemVal, ['activity_sub_category']);
      }),
    };
    return newVal;
  }
  transformerGetIndex(payload: E[]): E[] {
    const cleanVal = payload.map((item) => {
      const cleanVal = item;

      const newVal = {
        ...cleanVal,
        period: moment(cleanVal?.month ?? cleanVal?.week),
        activity_detail: cleanVal?.activity_detail?.map((item) => {
          const keyParent = item.id ?? uuidV4();
          const itemValue = item?.activity_sub_category?.find((itemSub) => !itemSub.sub_category_id);
          const itemSubValue = item?.activity_sub_category
            ?.filter((itemSub) => itemSub.sub_category_id)
            ?.map((itemAc) => {
              return {
                ...itemAc,
                keyParent: keyParent,
                key: itemAc?.id ?? uuidV4(),
                category_id: itemAc?.sub_category_id,
                category_name: itemAc?.sub_category_name,
              };
            });

          const newItemVal = {
            ...item,
            ...itemValue,
            key: keyParent,
            children: itemSubValue,
          };
          if (newItemVal?.children?.length == 0) return omit(newItemVal, ['children', 'activity_sub_category']);
          return omit(newItemVal, ['activity_sub_category']);
        }),
      };
      return newVal;
    });
    return cleanVal;
  }

  transformerFilterIndexTable(payload: any) {
    const activity_ids = payload?.activity_ids?.map((item) => item.id);
    const created_at = payload?.created_at?.map((item) => item.format()) ?? [];
    const created_ids = payload?.created_ids?.map((item) => String(item.id));
    // const period = payload?.period?.map((item) => item.format());
    const period = payload?.period;
    const region_ids = payload?.region_ids?.map((item) => String(item.id));
    const status = stringFormatter.capitalize(payload?.status);
    const sub_region_ids = payload?.sub_region_ids?.map((item) => String(item.id));
    const submission_type = payload.period_submission;

    let period_from = undefined;
    let period_to = undefined;

    if (period) {
      if (submission_type === 'monthly') {
        period_from = moment(period[0]).startOf('month').toISOString();
        period_to = moment(period[1]).endOf('month').toISOString();
      } else if (submission_type === 'weekly') {
        period_from = moment(period[0]).startOf('week').toISOString();
        period_to = moment(period[1]).endOf('week').toISOString();
      } else {
        period_from = moment(period[0]).toISOString();
        period_to = moment(period[1]).toISOString();
      }
    }

    const transformedPayload = {
      ...payload,
      activity_ids,
      created_ids,
      region_ids,
      status,
      sub_region_ids,
      created_at: [],
      created_from: created_at ? created_at[0] : undefined,
      created_to: created_at ? created_at[1] : undefined,
      period: [],
      period_from,
      period_to,
    };

    return transformedPayload;
  }
}
