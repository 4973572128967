import React, { useEffect, useState } from 'react';
import { Card, Form, Input, InputNumber } from 'antd';
import { v4 as uuidV4 } from 'uuid';

import '../../../styles/index.less';

interface Props {
  loading: boolean;
  value?: any;
  onChange?: (data: any) => void;
}

const keyInput = uuidV4();

export default function MobileFormat({ loading, onChange, value }: Props): JSX.Element {
  const [dataActivity, setDataActivity] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    setDataActivity(value);
  }, [value]);

  function onChangeValues(payload): void {
    if (onChange) onChange(payload);
  }

  function updateData({ key, value, row }: { key: string; value: any; row: any }) {
    if (row?.keyParent) {
      const parentData = dataActivity.find((data) => data.key === row.keyParent);
      const newParentData = {
        ...parentData,
        children: parentData.children.map((child) => {
          if (child.key !== row.key) return child;
          return {
            ...child,
            [key]: value,
          };
        }),
      };

      const newData = dataActivity.map((data) => {
        if (data.key !== parentData.key) return data;

        return newParentData;
      });

      onChangeValues(newData);
    } else {
      const newData = dataActivity?.map((item) => {
        if (item.key !== row.key) return item;
        return {
          ...item,
          [key]: value,
        };
      });

      onChangeValues(newData);
    }
  }

  function renderForm(row) {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Value: </label>
          <InputNumber
            placeholder="Input value"
            onBlur={(evt) => updateData({ key: 'value', row, value: evt.target.value })}
            style={{ width: '100%' }}
            defaultValue={row?.value}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Notes: </label>
          <Input
            placeholder="Input note"
            defaultValue={row?.note}
            onBlur={(evt) => updateData({ key: 'note', row, value: evt.target.value })}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={{ marginTop: 20 }}>
      {dataActivity?.map((data) => {
        return (
          <Card loading={loading} key={data.key} className="card__category">
            <div className="card__category-title">{data?.category_name}</div>
            {!data.hasOwnProperty('children') ? (
              <div className="card__category-content">{renderForm(data)}</div>
            ) : (
              <ul>
                {data?.children.map((child) => {
                  return (
                    <li key={child.key} className="card__subcategory-list">
                      <div className="card__subcategory-item">{child?.category_name}</div>
                      <div className="card__subcategory-body">{renderForm(child)}</div>
                    </li>
                  );
                })}
              </ul>
            )}
          </Card>
        );
      })}
    </div>
  );
}
