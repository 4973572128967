import { IAuthHelper } from '@base-helpers';
import { atom } from 'recoil';

interface LoginAccountStateEntity {
  authState: IAuthHelper;
}
export const loginAccountState = atom<LoginAccountStateEntity>({
  key: 'loginAccountState',
  default: undefined,
});
