import { v4 as uuidV4 } from 'uuid';

export enum TYPE_VALUE {
  BRACKET = 'bracket',
  CATEGORY = 'category',
  COLOR = 'color',
  PERIOD_RANGE = 'period_range',
  PERIOD_RANGE_EACH = 'period_range_each',
  COMPARISON_OPERATOR = 'comparison_operator',
  LOGICAL_EXP = 'logical_exp',
  MATH_OPERATOR = 'math_operator',
  NUMBER = 'number',
  SUB_CATEGORY = 'sub_category',
  VARIABLE = 'variable',
  ALIAS = 'alias',
}

export const BracketTypeValue = ['(', ')', '[', ']', ','];
export const MathOperatorTypeValue = ['+', '-', '*', '/', '%'];
export const ComparisonOperatorTypeValue = ['=', '>', '<', '>=', '<='];
export const AvailableTypeValue = [...BracketTypeValue, ...MathOperatorTypeValue, ...ComparisonOperatorTypeValue];

export const LogicalExpressions = [
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'and' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'as' },
];

export const FunctionExpressions = [
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'sum' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'avg' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'min' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'max' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'count' },
  { key: uuidV4(), type: TYPE_VALUE.LOGICAL_EXP, value: 'get_point' },
];

export const AliasExpressions = Array.from(Array(20)).map((_, i) => {
  return { key: uuidV4(), type: TYPE_VALUE.ALIAS, value: `VAR${i + 1}` };
});

export const PeriodRangeExpressions = [
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE, value: 'last_month' },
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE, value: 'last_year' },

  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE, value: 'current_month' },
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE, value: 'current_year' },
];

export const PeriodRangeEachExpressions = [
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE_EACH, value: 'in_each_month' },
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE_EACH, value: 'in_each_year' },

  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE_EACH, value: 'in_month' },
  { key: uuidV4(), type: TYPE_VALUE.PERIOD_RANGE_EACH, value: 'in_year' },
];

export function makeValueFromInput(item) {
  let type: TYPE_VALUE;
  if (BracketTypeValue.includes(item)) type = TYPE_VALUE.BRACKET;
  else if (ComparisonOperatorTypeValue.includes(item)) type = TYPE_VALUE.COMPARISON_OPERATOR;
  else if (MathOperatorTypeValue.includes(item)) type = TYPE_VALUE.MATH_OPERATOR;
  return {
    key: uuidV4(),
    value: item,
    type,
  };
}
export function makeSingleOptions(options) {
  return options.map((item) => {
    return {
      label: String(item.value)?.split('_')?.join(' ')?.toUpperCase(),
      value: JSON.stringify(item),
    };
  });
}

export function makeCategoryOptions(category) {
  return category.map((item) => {
    return {
      label: item?.value?.name,
      value: JSON.stringify(item),
    };
  });
}

export function makeSubCategoryOptions(subCategory) {
  return subCategory.map((item) => {
    return {
      label: `${item?.value?.category_name} - ${item?.value?.name}`,
      value: JSON.stringify(item),
    };
  });
}

export type AvailableOptionsType =
  | 'logical_expression'
  | 'function_expression'
  | 'alias_expression'
  | 'period_range_expression'
  | 'period_range_each_expression'
  | 'category'
  | 'sub_category';

export type AliasCondition = 'always' | 'after_alias_type';

export interface InputFormulaProps {
  [key: string]: any;
  category?: any[];
  subCategory?: any[];
  value?: any;
  onChange?(value: any): void;
  ignoreOptionType?: AvailableOptionsType[];
  additionalOptions?: any[];
  showAliasOn?: AliasCondition;
  filterAliasOptions?(alias: any): any;
}
