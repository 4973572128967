import React, { useMemo } from 'react';

import { Tag } from 'antd';
import moment from 'moment';
import { uniq } from 'lodash';

import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { TbEqual } from 'react-icons/tb';
import { round } from 'mathjs';
import { CompareCard, SingleCard, SingleCardMultiData, SingleCardSingleData, TrendingCard } from './component';
import { parsePeriod } from '../../helper';

function hexToRgb(hex): any {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},0.6)`
    : undefined;
}

interface IndicatorProps {
  indicatorContent: any;
  isCompare: boolean;
}

export function TrendingIndicator(props: IndicatorProps) {
  const { indicatorContent, isCompare } = props;

  const name = useMemo(() => {
    return indicatorContent?.name ?? '';
  }, [indicatorContent]);

  const cards = useMemo(() => {
    return indicatorContent?.cards ?? [];
  }, [indicatorContent]);

  const compare = useMemo(() => {
    return indicatorContent?.compare ?? [];
  }, [indicatorContent]);

  function Icon({ grow }) {
    if (grow === 'up') {
      return <AiFillCaretUp style={{ fontSize: 20, color: 'green' }} />;
    } else if (grow === 'down') {
      return <AiFillCaretDown style={{ fontSize: 20, color: 'red' }} />;
    } else {
      return <TbEqual style={{ fontSize: 20, color: 'blue' }} />;
    }
  }

  return (
    <div className="new-indicator-wrapper">
      <div className="indicator-title">{name}</div>
      <div style={{ marginBottom: 3 }}></div>
      <div className="card-metric-wrapper">
        {(!cards || cards?.length === 0) && <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>}
        {cards.map((itemCard, index) => {
          const color = itemCard?.color;
          const label = itemCard?.label;
          const score = isCompare ? itemCard?.percent_result_yoy : itemCard?.percent_result;
          const scoreValue = itemCard?.scoreValue;
          const grow = isCompare ? itemCard?.trend_yoy : itemCard?.trend_condition;
          const month = itemCard?.month?.split(' - ');
          const periods = parsePeriod(month);

          return (
            <TrendingCard
              key={index}
              score={score}
              scoreValue={scoreValue}
              grow={grow}
              color={color}
              periods={periods}
              label={label}
              Icon={Icon}
            />
          );
        })}
      </div>
      {isCompare && (
        <React.Fragment>
          <div style={{ marginBottom: 20 }}></div>
          <div className="card-metric-wrapper">
            {(!compare || compare?.length === 0) && (
              <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>
            )}

            {compare.map((itemCard, index) => {
              const color = itemCard?.color;
              const label = itemCard?.label;
              const score = isCompare ? itemCard?.percent_result_yoy : itemCard?.percent_result;
              const scoreValue = itemCard?.scoreValue;
              const grow = isCompare ? itemCard?.trend_yoy : itemCard?.trend_condition;
              const month = itemCard?.month?.split(' - ');
              const periods = parsePeriod(month);

              return (
                <div key={index} className="transparent-content">
                  <TrendingCard
                    score={score}
                    scoreValue={scoreValue}
                    grow={grow}
                    color={color}
                    periods={periods}
                    label={label}
                    Icon={Icon}
                  />
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export function CompareIndicator(props: IndicatorProps) {
  const { indicatorContent, isCompare } = props;

  const name = useMemo(() => {
    return indicatorContent?.name ?? '';
  }, [indicatorContent]);

  const cards = useMemo(() => {
    return indicatorContent?.cards ?? [];
  }, [indicatorContent]);

  const compare = useMemo(() => {
    return indicatorContent?.compare ?? [];
  }, [indicatorContent]);

  return (
    <div className="new-indicator-wrapper">
      <div className="indicator-title">{name}</div>
      <div className="compare-metric-indicator-wrapper">
        {!cards || (cards?.length === 0 && <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>)}
        {cards.map((itemCard, index) => {
          const compareItem = compare[index];
          const isLastIndex = index === cards.length - 1;

          const color = itemCard?.color;
          const label = itemCard?.label;
          const month = itemCard?.month?.split(' - ');
          const periods = parsePeriod(month);
          const variables = itemCard?.variables ?? [];

          const compareColor = compareItem?.color;
          const compareLabel = compareItem?.label;
          const compareMonth = compareItem?.month?.split(' - ');
          const comparePeriods = parsePeriod(compareMonth);
          const compareVariables = compareItem?.variables ?? [];

          return (
            <React.Fragment key={index}>
              <CompareCard
                isLastIndex={isLastIndex}
                isCompare={isCompare}
                periods={periods}
                label={label}
                color={color}
                variables={variables}
                isLastYear={false}
              />
              {isCompare && (
                <React.Fragment>
                  <div style={{ marginBottom: 20 }}></div>
                  <CompareCard
                    isLastIndex={isLastIndex}
                    isCompare={isCompare}
                    periods={comparePeriods}
                    label={compareLabel}
                    color={compareColor}
                    variables={compareVariables}
                    isLastYear={true}
                  />
                  {/* <div style={{ marginBottom: 50 }}></div> */}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export function ScoreIndicator(props: IndicatorProps) {
  const { indicatorContent } = props;

  const name = useMemo(() => {
    return indicatorContent?.name ?? '';
  }, [indicatorContent]);

  const cards = useMemo(() => {
    return indicatorContent?.cards ?? [];
  }, [indicatorContent]);

  return (
    <div className="new-indicator-wrapper">
      <div className="indicator-title text-center">{name}</div>
      <div className="card-metric-wrapper flex-center">
        {!cards || (cards?.length === 0 && <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>)}
        {cards.map((itemCard, index) => {
          const color = itemCard?.color;
          const label = itemCard?.label;
          const month = itemCard?.month?.split(' - ');
          const score = itemCard?.score;

          const periods = uniq(
            month?.map((item) => {
              if (!item) return;
              return moment(item).format('MMM YY');
            }) ?? [],
          ).join(' - ');

          return (
            <div className="score-metric" key={index}>
              <div className="score-metric-label text-center" style={{ color }}>
                {label}
              </div>
              <div className="horizontal-divider"></div>
              <table className="indicator-card">
                <tr>
                  <td className="indicator">
                    <div className="indicator-value">{periods}</div>
                    <div className="indicator-label">Period</div>
                  </td>
                  <td className="vertical-divider"> </td>
                  <td className="indicator">
                    <div className="indicator-value percent-value">{score}</div>
                    <div className="indicator-label">Score</div>
                  </td>
                </tr>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function SingleIndicator(props: IndicatorProps) {
  const { indicatorContent, isCompare } = props;

  const name = useMemo(() => {
    return indicatorContent?.name ?? '';
  }, [indicatorContent]);

  const cards = useMemo(() => {
    return indicatorContent?.cards ?? [];
  }, [indicatorContent]);

  const compare = useMemo(() => {
    return indicatorContent?.compare ?? [];
  }, [indicatorContent]);

  return (
    <div className="new-indicator-wrapper">
      <div className="indicator-title text-center">{name}</div>

      {!isCompare && (
        <div className="card-metric-wrapper flex-center">
          {(!cards || cards?.length === 0) && (
            <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>
          )}
          {cards.map((itemCard, index) => {
            const color = itemCard?.color;
            const label = itemCard?.label;
            const month = itemCard?.month?.split(' - ');
            const score = itemCard?.percent_result;
            const scoreValue = itemCard?.scoreValue;
            const periods = parsePeriod(month);

            return (
              <SingleCard
                key={index}
                color={color}
                label={label}
                score={score}
                scoreValue={scoreValue}
                periods={periods}
              />
            );
          })}
        </div>
      )}

      {isCompare && (
        <React.Fragment>
          {(!cards || cards?.length === 0) && (
            <div style={{ textAlign: 'center', marginTop: 50 }}>No data to show.</div>
          )}
          {cards?.length === 1 && <SingleCardSingleData card={cards} compare={compare} />}
          {cards?.length > 1 && <SingleCardMultiData card={cards} compare={compare} />}
        </React.Fragment>
      )}
    </div>
  );
}
