import { authHelper, makeKeyGridLayout } from '@base-helpers';

export function getDraggableIndicatorLayoutFromLS(key) {
  const account = authHelper.getAccount();
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(makeKeyGridLayout({ account, prefix: 'draggable-indicator' }))) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

export function saveDraggableIndicatorLayoutToLS(key, value) {
  const account = authHelper.getAccount();
  // console.log({ account });
  if (global.localStorage) {
    global.localStorage.setItem(
      makeKeyGridLayout({ account, prefix: 'draggable-indicator' }),
      JSON.stringify({
        [key]: value,
      }),
    );
  }
}

export function resetDraggableIndicatorLayout() {
  const account = authHelper.getAccount();
  if (global.localStorage) {
    global.localStorage.removeItem(makeKeyGridLayout({ account, prefix: 'draggable-indicator' }));
  }
}
