import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { NotificationEntity } from '../../domain/entities';
import { makeNotificationTransformer } from '../../domain/factories';

import PageIndex from '../pages';

export default function NotificationFactory(): JSX.Element {
  const transformer = makeNotificationTransformer<NotificationEntity>();
  const initialValue: InitialValuePageProviderEntity<NotificationEntity> = {
    moduleKey: 'notification-page',
    webUrl: WebUrlData.notification,
    apiUrl: ApiUrlData.notification,
    transformer,
  };

  return (
    <PageProvider initialValue={initialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
