import React from 'react';
import { Button, Form, Row } from 'antd';
import { FormGenerator, FromPageWrapper } from '@base-components';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';

import { AdvanceTemplateEntity } from '../../domain/entities';
import { AdvanceTemplateDataSourceRepository } from '../../domain/repositories';

export default function PageForm() {
  const [currentRoute] = useCurrentRoute();
  const { action, showData } = usePageProviderData();
  const { navigation, dataSource } = usePageProviderAction<
    AdvanceTemplateEntity,
    AdvanceTemplateDataSourceRepository<AdvanceTemplateEntity>
  >();

  async function getData() {
    await dataSource.handleCustomRequest({
      params: {
        page: 1,
        limit: 10,
      },
      onSuccess: ({ response }) => {
        console.log({ response }, 'custom request');
      },
      onFailed: ({ message, statusCode }) => {
        console.log({ message, statusCode }, 'custom request');
      },
    });
  }

  return (
    <FromPageWrapper padding={[]}>
      {/* <div>Advance Template Page {`${action}`}:</div>
      <Button onClick={() => navigation.goBack(currentRoute)}>Back</Button>
      <Button onClick={() => navigation.goToIndex()}>Index</Button>
      <Button onClick={() => navigation.goToDetail({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Detail</Button>
      <Button onClick={() => navigation.goToCreate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Create</Button>
      <Button onClick={() => navigation.goToDuplicate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>
        Duplicate
      </Button>
      <Button onClick={() => navigation.goToUpdate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Update</Button>
      <Button onClick={() => getData()}>Get Data</Button>
      <Button onClick={() => getData()}>Get Data</Button> */}

      <Form.Item noStyle name="config_form" valuePropName="formData">
        <FormGenerator />
      </Form.Item>
    </FromPageWrapper>
  );
}
