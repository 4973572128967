import React, { useContext } from 'react';

export interface BoardContextValueEntity {
  keyTitleCard?: string;
  keyBodyCard?: string;
  keyTitleBadgeCard?: string;
  renderTitleCard?(row: any): JSX.Element | React.ReactNode;
  renderBodyCard?(row: any): JSX.Element | React.ReactNode;
  renderTitleBadgeCard?(row: any): JSX.Element | React.ReactNode;
  makeColorBadgeCard?(row: any): string;
  onShowCard?(card: any, parentCard: any): void;
  onShowTable?(table: any): void;
  onCloseShowCard?(card: any, parentCard: any): void;
  onCloseShowTable?(table: any): void;
  onDeleteCard?(card: any, parentCard: any): void;
  handleDeleteCard?(payload: any): void;
  actionForm?: string;
  createActionForm?(): void;
  updateActionForm?(): void;
  onHandleUpdate?(): void;
  onUpdateCard?(item: any, id: any): void;
  dataOnDrawer?: any;
  dataParentOnDrawer?: any;
  onCloseForm?(): void;
  showDrawerForm?(): void;
  setFieldsUpdate?(item: any): void;
  onShowDrawer?(): void;
  onCloseDrawer?(): void;
  setDataDrawer?(data): void;
  loadingGetDetail?: boolean;
  setLoadingGetDetail?(value: boolean);
}

interface BoardProviderEntity {
  children?: JSX.Element | React.ReactNode;
  value: BoardContextValueEntity;
}

const BoardContext = React.createContext<BoardContextValueEntity>(null);

export function BoardProvider(props: BoardProviderEntity) {
  const { children, value = {} } = props;
  const contextValue = {
    ...value,
  };

  return (
    <React.Fragment>
      <BoardContext.Provider value={contextValue}>{children}</BoardContext.Provider>
    </React.Fragment>
  );
}

export function useBoardContextValue(): BoardContextValueEntity {
  return useContext<BoardContextValueEntity>(BoardContext);
}
