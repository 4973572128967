/* * Default Size screen */
export const minMobileSize = 0;
export const maxMobileSize = 480;

export const minTabletSize = 481;
export const maxTabletSize = 1024;

export const minDesktopSize = 1025;
export const maxDesktopSize = 1280;

export const minLargeScreen = 1281;

/**
 * Match query function for add custom size window between device
 * @param minSize
 * @param maxSize
 * @returns window size query
 */

interface ScreenSizeProps {
  minSize?: number;
  maxSize?: number;
}
export function makeScreenSize(props: ScreenSizeProps): string {
  const { maxSize, minSize } = props;
  if (minSize && maxSize) return `(min-width: ${minSize}px) and (max-width: ${maxSize}px)`;
  else if (minSize) return `(min-width: ${minSize}px)`;
  else if (maxSize) return `(max-width: ${maxSize}px)`;
}
