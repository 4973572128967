import React, { useState } from 'react';
import { IndexPageWrapper, SelectPaginate } from '@base-components';
import { MapRendering } from '../components';
import { ApiUrlData } from '@base-configs';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { makeArrayIds } from '@base-helpers';
import { electronMassDependencies, typeOf } from 'mathjs';
import { Card, DatePicker, Form } from 'antd';

export default function PageIndex(): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const scope = authUser.scope;
  const region = authUser?.data_regions ?? [];
  const sub_region = authUser?.data_sub_regions ?? [];
  const sub_region_tpi = authUser?.data_sub_regions_tpi ?? [];
  const hasDataRegion = authUser.hasOwnProperty('data_regions') && authUser.hasOwnProperty('data_sub_regions');

  const subRegionFromRegion = region?.map((data: any) => data?.sub_region) ?? [];
  // const regionFromSubRegion = sub_region?.map((data: any) => data?.region) ?? [];
  // const regionFromSubRegionTpi = sub_region_tpi?.map((data: any) => data?.region) ?? [];

  const defaultRegion = [
    ...region,
    // ...regionFromSubRegion, ...regionFromSubRegionTpi
  ];
  const defaultSubRegion = [...sub_region, ...sub_region_tpi, ...(subRegionFromRegion[0] ?? [])];

  const [filter, setFilter] = useState({
    // region_ids: defaultRegion,
    // sub_region_ids: defaultSubRegion,
  });

  return (
    <IndexPageWrapper
      showButtonAddNew={false}
      showButtonBack={false}
      showSearch={false}
      drawerFilterProp={{
        defaultValueFilter: filter,
        onSubmitFilter(value) {
          const value_region = value?.region_ids ?? [];
          const value_sub_region = value?.sub_region_ids ?? [];
          const value_region_ids = value_region?.filter((item: any) => typeOf(item) === 'Object');
          const value_sub_region_ids = value_sub_region?.filter((item: any) => typeOf(item) === 'Object');

          const newFilter = {
            ...value,
            region_ids: value_region_ids,
            sub_region_ids: value_sub_region_ids,
          };
          setFilter(newFilter);

          // if (scope !== 'global' && (newFilter?.region_ids?.length === 0 || !newFilter?.region_ids)) {
          //   Object.assign(newFilter, { region_ids: defaultRegion });
          // }

          // if (newFilter.sub_region_ids?.length === 0 || !newFilter.sub_region_ids) {
          //   if (scope === 'global') {
          //     setFilter(newFilter);
          //   }
          // } else {
          //   setFilter(newFilter);
          // }
        },
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    const subRegion = form.getFieldValue('sub_region_ids');

                    return (
                      <Form.Item label="Region" name="region_ids">
                        <SelectPaginate
                          isMulti
                          // isDisabled={subRegion?.length > 0}
                          dataSourceUrl={ApiUrlData.region}
                          customLabel={(row) => row?.rc_dpw_name}
                          filterRequest={{
                            is_strict_region: true,
                            status: 'Active',
                          }}
                        />
                      </Form.Item>
                    );
                  },
                  // label: 'Region',
                  // name: 'region_ids',
                  // fieldType: 'selectPaginate',
                  // selectPaginateProps: {
                  //   // isDisabled: scope !== 'global',
                  //   isMulti: true,
                  //   dataSourceUrl: ApiUrlData.region,
                  //   customLabel: (row) => row?.rc_dpw_name,
                  //   // transformOptions(options) {
                  //   //   const regionIds = defaultRegion?.map((item: any) => item?.id) ?? [];
                  //   //   return options
                  //   //     .map((item) => {
                  //   //       if (regionIds.includes(item?.id)) return item;
                  //   //       return undefined;
                  //   //     })
                  //   //     .filter(Boolean);
                  //   // },
                  // },
                },
                {
                  gridColumn: { span: 12 },
                  renderField(form) {
                    const region = form.getFieldValue('region_ids');
                    return (
                      <Form.Item label="Church" name="sub_region_ids">
                        <SelectPaginate
                          isMulti
                          // isDisabled={region?.length > 0}
                          filterRequest={{ status: 'Active' }}
                          dataSourceUrl={ApiUrlData.sub_region}
                          customLabel={(row) => row?.church_name}
                        />
                      </Form.Item>
                    );
                  },

                  // label: 'Church',
                  // name: 'sub_region_ids',
                  // fieldType: 'selectPaginate',
                  // selectPaginateProps: {
                  //   isMulti: true,
                  //   customKey(form) {
                  //     const payload = form.getFieldsValue();
                  //     return JSON.stringify(payload ?? []);
                  //   },
                  //   dataSourceUrl: ApiUrlData?.sub_region,
                  //   customLabel: (row) => row?.church_name,
                  // },
                },
                {
                  name: 'period_type',
                  label: 'Period Type',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    options: [
                      { label: 'monthly', value: 'monthly' },
                      { label: 'weekly', value: 'weekly' },
                    ],
                  },
                },

                {
                  // name: 'period',
                  // label: 'Period',
                  // fieldType: 'dateRangePicker',
                  gridColumn: { span: 12 },
                  // dateRangePickerProps: {
                  //   picker: 'month',
                  // },

                  renderField(form) {
                    const periodType = form.getFieldValue('period_type');
                    return (
                      <Form.Item name="period" label="Period">
                        <DatePicker.RangePicker
                          disabled={!periodType}
                          picker={periodType === 'monthly' ? 'month' : 'week'}
                        />
                      </Form.Item>
                    );
                  },
                },
              ],
            },
          ],
        },
      }}
    >
      <Card className="card-content-wrapper">
        <MapRendering filters={filter} />
      </Card>
    </IndexPageWrapper>
  );
}
