import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { AdvanceTemplateEntity } from '../../domain/entities';
import { makeAdvanceTemplateDataSource, makeAdvanceTemplateTransformer } from '../../domain/factories';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

export function AdvanceTemplateFactory() {
  const transformer = makeAdvanceTemplateTransformer<AdvanceTemplateEntity>();
  const dataSource = makeAdvanceTemplateDataSource<AdvanceTemplateEntity>({ apiUrl: ApiUrlData.sales_customer_group });
  const InitialValue: InitialValuePageProviderEntity<AdvanceTemplateEntity> = {
    dataSource,
    transformer,
    webUrl: WebUrlData.advance_template,
    moduleKey: 'advance-template', // make sure this key unique each module
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
