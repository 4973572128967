import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, Tooltip } from 'antd';
import { List as ListIcon } from 'phosphor-react';

const SortableCardHandler = SortableHandle(
  (): JSX.Element => (
    <Tooltip placement="top" title="Click and hold to drag card">
      <Button type="text" shape="circle" icon={<ListIcon size={14} className="Workflow__handler" />} />
    </Tooltip>
  ),
);

export default SortableCardHandler;
