import React, { useEffect, useState } from 'react';
import { Card, DatePicker, Form } from 'antd';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { FromPageWrapper, FormBuilder } from '@base-components';
import { ApiUrlData } from '@base-configs';
import { useMediaQuery, usePageProviderData } from '@base-hooks';
import { makeCommonDataSource } from '@base-factories';

import TableFormat from '../component/form/table-format';
import MobileFormat from '../component/form/mobile-format';
import { omit } from 'lodash';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

interface GetDataProp {
  page?: number;
  limit?: number;
  category?: any[];
}

export default function PageForm(): JSX.Element {
  const { isCreate, isUpdate } = usePageProviderData();
  const { authState } = useRecoilValue(loginAccountState);
  const generalConfig = authState.getGeneralConfig() ?? [];
  const submission_type = generalConfig[0]?.periode_submission;

  const [dummyData, setDummyData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const isMobileScreen = useMediaQuery({ media: 'mobile' });
  const isTabletScreen = useMediaQuery({ media: 'tablet' });
  const isDesktopScreen = useMediaQuery({ media: 'desktop' });
  const isLargeScreen = useMediaQuery({ media: 'large' });

  const dataSourceCategory = makeCommonDataSource({ apiUrl: ApiUrlData.category });
  function makeDataCategory(data) {
    const newData = data.map((item) => {
      const keyParent = item.id ?? uuidV4();
      const newData = {
        key: keyParent,
        category_id: item?.id,
        category_name: item?.name,
        note: '',
        value: null,
        children: item?.childs?.map((itemChild) => {
          return {
            keyParent,
            key: itemChild.id ?? uuidV4(),
            category_id: itemChild?.id,
            category_name: itemChild?.name,
            note: '',
            value: null,
          };
        }),
      };
      if (newData.children?.length === 0) return omit(newData, ['children']);
      return newData;
    });
    return newData;
  }

  async function handleGetCategory(props?: GetDataProp) {
    const { page = 1, limit = 3, category = [] } = props;
    setLoading(true);
    await dataSourceCategory.handleGetIndex({
      params: { page, limit, status: 'Active', child_status: 'Active' },
      async onSuccess({ response }: any) {
        const items = response.items ?? [];
        const meta = response?.meta;
        const newCategory = [...category, ...makeDataCategory(items)];
        setDummyData({
          ...(dummyData ?? {}),
          submission_type,
          activity_detail: newCategory,
        });

        if (meta && meta.currentPage < meta.totalPages) {
          await handleGetCategory({
            page: meta.currentPage + 1,
            category: newCategory,
          });
        } else {
          setLoading(false);
        }
      },
      onFailed(err) {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (isCreate) handleGetCategory({ page: 1 });
  }, [isCreate]);

  return (
    <FromPageWrapper dataDummy={dummyData} ignoreKeyDuplicate={['activity_id']}>
      <Card className="card-content-wrapper">
        <Form.Item noStyle shouldUpdate name="submission_type"></Form.Item>
        <Form.Item noStyle dependencies={['region', 'sub_region']}>
          {(form) => {
            return (
              <FormBuilder
                columns={[
                  {
                    title: 'ACTIVITY INFORMATION',
                    fields: [
                      {
                        gridColumn: { span: 5, xs: 24, sm: 12, lg: 3, xl: 3 },
                        label: 'ID',
                        name: 'activity_id',
                        fieldType: 'inputText',
                        inputTextProps: {
                          placeholder: 'Auto Generate ID',
                          disabled: true,
                        },
                      },
                      {
                        gridColumn: { span: 5, xs: 24, sm: 12, lg: 5, xl: 5 },
                        label: 'Local Church & Satellite',
                        name: 'sub_region',
                        fieldType: 'selectPaginate',
                        rules: [{ required: true }],
                        selectPaginateProps: {
                          isDisabled: isUpdate,
                          dataSourceUrl: ApiUrlData.sub_region,
                          customLabel: (value) => value.church_name,
                          customFilterRequest() {
                            return {
                              status: 'Active',
                            };
                          },
                          onChange: (value) => {
                            form.setFieldsValue({
                              region: value?.region,
                            });
                          },
                        },
                      },
                      {
                        gridColumn: { span: 4, xs: 24, sm: 12, lg: 4, xl: 4 },
                        label: 'Region',
                        name: 'region',
                        fieldType: 'selectPaginate',
                        selectPaginateProps: {
                          dataSourceUrl: ApiUrlData.region,
                          customLabel: (value) => value.rc_dpw_name,
                          isDisabled: true,
                        },
                      },
                      {
                        gridColumn: { span: 4, xs: 24, sm: 12, lg: 4, xl: 4 },
                        renderField(form) {
                          const submission_type = form.getFieldValue('submission_type');
                          return (
                            <Form.Item name="period" label="Period" rules={[{ required: true }]}>
                              <DatePicker
                                style={{ width: '100%' }}
                                picker={`${submission_type === 'weekly' ? 'week' : 'month'}`}
                                disabledDate={(current) => current && current > moment().endOf('day')}
                                disabled={isUpdate}
                              />
                            </Form.Item>
                          );
                        },
                      },
                    ],
                  },
                ]}
              />
            );
          }}
        </Form.Item>

        <div style={{ marginTop: 10 }}></div>
        <div className="form-builder-component" style={{ display: 'flex', marginBottom: '-20px' }}>
          {/* <div style={{ color: '#ff4d4f', marginRight: 5 }}>*</div> */}
          <div className="sub-title">Proof of CO Transaction</div>
        </div>
        <FormBuilder
          columns={[
            {
              // title: 'Proof of CO Transaction',
              fields: [
                {
                  // rules: [{ required: true, message: 'Profile image is required!' }],
                  gridColumn: { span: 16 },
                  name: 'activity_image',
                  fieldType: 'uploadImage',
                  valuePropName: 'imageUrl',
                  // rules: [{ required: true, message: 'Proof of CO transaction is required.' }],
                  uploadImageProps: {
                    notes: 'Max size 500kb, Image type JPG.',
                    showRemove: true,
                    imageSize: 0.5,
                    errorSizeMessage: 'Maximum size is 500kB!',
                    imageType: ['image/jpeg', 'image/png'],
                    className: 'avatar-uploader',
                    requestUrl: ApiUrlData.activity_upload,
                    requestFileKey: 'file_activity',
                    transformResponseRequest(response) {
                      return response.path;
                    },
                  },
                },
              ],
            },
          ]}
        />
      </Card>
      <div style={{ marginTop: 20 }}></div>
      <Card className="card-content-wrapper">
        <Form.Item
          name="activity_detail"
          style={{ marginTop: -25 }}
          rules={[
            () => ({
              validator(_, value) {
                let isNullValue = false;
                value?.forEach((category) => {
                  if (category.hasOwnProperty('children')) {
                    category?.children.forEach((subcategory) => {
                      if (!subcategory?.value && subcategory.value !== 0) {
                        isNullValue = true;
                      }
                    });
                  } else {
                    if (!category?.value && category.value !== 0) {
                      isNullValue = true;
                    }
                  }
                });

                if (isNullValue) {
                  return Promise.reject(
                    new Error(
                      '* There are values that have not been filled in from the top category / sub category, make sure they are filled in!',
                    ),
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        ></Form.Item>

        {(isTabletScreen || isDesktopScreen || isLargeScreen) && (
          <Form.Item name="activity_detail">
            <TableFormat loading={loading} />
          </Form.Item>
        )}
        {isMobileScreen && (
          <Form.Item name="activity_detail">
            <MobileFormat loading={loading} />
          </Form.Item>
        )}
      </Card>
    </FromPageWrapper>
  );
}
