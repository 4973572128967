import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Form, Input, Radio, Row, Tag } from 'antd';
import { Plus, TrashSimple } from 'phosphor-react';
import { FromPageWrapper, FormBuilder, ColorPicker } from '@base-components';
import { ScopeOptions } from '../helpers';
import { InputFormula, FormTask } from '../components/form';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';
import { pick } from 'lodash';
const { List, Item } = Form;
const { Panel } = Collapse;
import { v4 as uuidV4 } from 'uuid';
import { usePageProviderData } from '@base-hooks';
import { STYLE_INDICATOR } from '../../domain/entities';
import {
  TYPE_VALUE,
  ComparisonOperatorTypeValue,
  FunctionExpressions,
  LogicalExpressions,
  MathOperatorTypeValue,
  PeriodRangeEachExpressions,
  PeriodRangeExpressions,
} from '../components/form/input-formula/rules';
import { TestIndicator } from '../components/test-indicator';

interface GetDataProp {
  page?: number;
  limit?: number;
  category?: any[];
  subCategory?: any[];
}

const defaultShowFormula = {
  color: '#FFFFFF',
  label: null,
  show_formula: [],
  task: [],
};

export default function PageForm(): JSX.Element {
  const { isCreate } = usePageProviderData();

  const [dataCategory, setDataCategory] = useState([]);
  const [dataSubCategory, setDataSubCategory] = useState([]);
  const dataSourceCategory = makeCommonDataSource({ apiUrl: ApiUrlData.category });

  function makeDataCategory(items = []) {
    const newCategory = [];
    const newSubCategory = [];

    items.forEach((item) => {
      newCategory.push({
        key: uuidV4(),
        type: TYPE_VALUE.CATEGORY,
        value: pick(item, ['id', 'name']),
      });
      item.childs?.map((itemChild) => {
        newSubCategory.push({
          key: uuidV4(),
          type: TYPE_VALUE.SUB_CATEGORY,
          value: {
            ...pick(itemChild, ['id', 'name']),
            category_name: item.name,
          },
        });
      });
    });

    return { newCategory, newSubCategory };
  }

  async function handleGetCategory(props?: GetDataProp) {
    const { page = 1, limit = 3, category = [], subCategory = [] } = props;
    await dataSourceCategory.handleGetIndex({
      params: { page, limit },
      async onSuccess({ response }: any) {
        const items = response.items ?? [];
        const meta = response?.meta;
        const { newCategory, newSubCategory } = makeDataCategory(items);
        const newCat = [...category, ...newCategory];
        const newSubCat = [...subCategory, ...newSubCategory];
        setDataCategory(newCat);
        setDataSubCategory(newSubCat);

        if (meta && meta.currentPage < meta.totalPages) {
          await handleGetCategory({
            page: meta.currentPage + 1,
            category: newCat,
            subCategory: newSubCat,
          });
        }
      },
      onFailed(err) {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    handleGetCategory({ page: 1 });
  }, []);

  return (
    <FromPageWrapper
      dataDummy={
        isCreate
          ? {
              default_indicator: false,
              report_indicator: false,
              show_on_dashboard: false,
              style: 'compare_metric',
            }
          : undefined
      }
    >
      <Card className="card-content-wrapper">
        <Row gutter={24} style={{ marginBottom: '20px' }}>
          <Col xs={24} sm={16} md={8} lg={8} xl={8}>
            <FormBuilder
              columns={[
                {
                  fields: [
                    {
                      name: 'name',
                      label: 'Name',
                      fieldType: 'inputText',
                      gridColumn: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                      rules: [{ required: true }],
                      inputTextProps: {
                        placeholder: 'Input indicator name',
                      },
                    },
                    {
                      name: 'scope',
                      label: 'Scope',
                      fieldType: 'select',
                      gridColumn: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                      rules: [{ required: true }],
                      selectProps: {
                        options: ScopeOptions,
                        customLabel(value) {
                          return ScopeOptions.find((item) => item.value === value)?.label;
                        },
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>

          <Col xs={24} sm={12} md={8} lg={12} xl={12}>
            <FormBuilder
              columns={[
                {
                  fields: [
                    {
                      name: 'default_indicator',
                      label: 'Default Indicator',
                      fieldType: 'switch',
                      valuePropName: 'checked',
                      gridColumn: { xs: 8, sm: 10, md: 10, lg: 7, xl: 5 },
                      switchProps: {
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                      },
                    },
                    {
                      name: 'show_on_dashboard',
                      label: 'Show on Dashboard',
                      fieldType: 'switch',
                      valuePropName: 'checked',
                      gridColumn: { xs: 8, sm: 14, md: 14, lg: 8, xl: 6 },
                      switchProps: {
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                      },
                    },
                    {
                      name: 'report_indicator',
                      label: 'Report Indicator',
                      fieldType: 'switch',
                      valuePropName: 'checked',
                      gridColumn: { xs: 8, sm: 14, md: 14, lg: 8, xl: 6 },
                      switchProps: {
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item name="style" label="Indicator Style" rules={[{ required: true }]} style={{ marginTop: '10px' }}>
              <Radio.Group>
                <Radio value={STYLE_INDICATOR.COMPARE_METRIC}>Compare Metrics</Radio>
                <Radio value={STYLE_INDICATOR.TRENDING_METRIC}>Trending Metrics</Radio>
                <Radio value={STYLE_INDICATOR.SINGLE_METRIC}>Single Metrics</Radio>
                <Radio value={STYLE_INDICATOR.SCORES_LABEL_METRIC}>Scores Label Metric</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormBuilder
              columns={[
                {
                  fields: [
                    {
                      name: 'description',
                      label: 'Description',
                      fieldType: 'textArea',
                      gridColumn: { xs: 24, sm: 24, md: 18, lg: 21, xl: 21 },
                      textAreaProps: {
                        placeholder: 'Input Description',
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <Card className="card-content-wrapper">
        <Row>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} style={{ marginBottom: '20px' }}>
            <h4>Formula Expression</h4>
            <Collapse bordered={false} style={{ background: '#fff' }}>
              <Panel key="1" header="Logical Expression">
                {LogicalExpressions.map((item, index) => (
                  <Tag style={{ marginBottom: '7px' }} key={index}>
                    {item.value?.split('_')?.join(' ')?.toUpperCase()}
                  </Tag>
                ))}
              </Panel>
              <Panel key="2" header="Math Operator">
                {[...MathOperatorTypeValue, ...ComparisonOperatorTypeValue].map((item, index) => (
                  <Tag style={{ marginBottom: '7px' }} key={index}>
                    {item}
                  </Tag>
                ))}
              </Panel>
              <Panel key="3" header="Functions">
                {FunctionExpressions.map((item, index) => (
                  <Tag style={{ marginBottom: '7px' }} key={index}>
                    {item.value?.split('_')?.join(' ')?.toUpperCase()}
                  </Tag>
                ))}
              </Panel>
              <Panel key="4" header="Periods">
                {[...PeriodRangeExpressions, ...PeriodRangeEachExpressions].map((item, index) => (
                  <Tag style={{ marginBottom: '7px' }} key={index}>
                    {item.value?.split('_')?.join(' ')?.toUpperCase()}
                  </Tag>
                ))}
              </Panel>
              <Panel key="5" header="Data Categories">
                {dataCategory.map((item, index) => (
                  <Tag style={{ marginBottom: '7px' }} key={index}>
                    {item.value?.name}
                  </Tag>
                ))}
              </Panel>
              <Panel key="6" header="Data Sub Categories">
                {dataSubCategory.map((item, index) => (
                  <Tag style={{ marginBottom: '10px' }} key={index}>
                    {`${item.value?.category_name} - ${item.value?.name}`}
                  </Tag>
                ))}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Card>
      <div style={{ marginBottom: 10 }}></div>
      <Card className="card-content-wrapper">
        <Row>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} style={{ marginBottom: '20px' }}>
            <FormBuilder
              columns={[
                {
                  fields: [
                    {
                      gridColumn: { span: 24 },
                      renderField({ getFieldValue }) {
                        const dataToCount = getFieldValue('data_count') ?? [];
                        const aliasValue = dataToCount?.filter((item) => item.type === TYPE_VALUE.ALIAS);
                        const aliasValueName = aliasValue.map((item) =>
                          item.value?.split('_')?.join(' ').toUpperCase(),
                        );
                        return (
                          <Form.Item
                            name="data_count"
                            label="Data to Count"
                            rules={[{ required: true, message: 'Data to count is required!' }]}
                          >
                            <InputFormula
                              category={dataCategory}
                              subCategory={dataSubCategory}
                              ignoreOptionType={['function_expression']}
                              filterAliasOptions={(alias) => {
                                return alias?.filter((item) => !aliasValueName.includes(item.label));
                              }}
                            />
                          </Form.Item>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={19} lg={19} xl={19} style={{ marginBottom: '20px' }}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const show = getFieldValue('show');
                const length = show?.length ?? 0;
                return (
                  <Row gutter={12} hidden={length === 0}>
                    <Col span={2} md={2}>
                      <div className="custom-label-stye">Color</div>
                    </Col>
                    <Col span={3} md={3}>
                      <div className="custom-label-stye">Label</div>
                    </Col>
                    <Col span={3} md={3}>
                      <div className="custom-label-stye">Score</div>
                    </Col>
                    <Col span={14} md={13} sm={13}>
                      <div className="custom-label-stye">Show</div>
                    </Col>
                    <Col span={2} md={1}>
                      <div className="custom-label-stye">Action</div>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const dataToCount = getFieldValue('data_count') ?? [];
                const scope = getFieldValue('scope');
                const aliasValue = dataToCount?.filter((item) => item.type === TYPE_VALUE.ALIAS);
                const aliasValueName = aliasValue.map((item) => item.value?.split('_')?.join(' ').toUpperCase());
                return (
                  <List name="show">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row key={key} gutter={12}>
                            <Col span={2} md={2}>
                              <Item {...restField} name={[name, 'color']}>
                                <ColorPicker />
                              </Item>
                            </Col>

                            <Col span={3} md={3}>
                              <Item {...restField} name={[name, 'label']}>
                                <Input placeholder="Input Label" />
                              </Item>
                            </Col>

                            <Col span={3} md={3}>
                              <Item
                                {...restField}
                                name={[name, 'score']}
                                rules={[
                                  {
                                    pattern: new RegExp(/^\d{1,9}(\.\d{1,2})?%?$/),
                                    message: 'Invalid value.',
                                  },
                                ]}
                              >
                                <Input placeholder="Input Score" />
                              </Item>
                            </Col>

                            <Col span={14} md={13} sm={13}>
                              <Item {...restField} name={[name, 'formula']}>
                                <Input style={{ fontFamily: 'Courier' }} placeholder="Input Formula" />
                              </Item>
                            </Col>
                            <Col span={2} md={1} style={{ display: 'flex', alignItems: 'start' }}>
                              <Item {...restField} name={[name, 'task']}>
                                <FormTask scope={scope} />
                              </Item>
                              <Item>
                                <Button size="small" type="text" onClick={() => remove(name)}>
                                  <TrashSimple style={{ marginRight: '3px' }} /> Delete
                                </Button>
                              </Item>

                              <Item hidden={index + 1 !== fields.length}>
                                <Button size="small" type="text" onClick={() => add(defaultShowFormula)}>
                                  <Plus style={{ marginRight: '3px' }} /> Add
                                </Button>
                              </Item>
                            </Col>
                          </Row>
                        ))}

                        {fields.length === 0 && (
                          <Button
                            type={'default'}
                            onClick={() => add(defaultShowFormula)}
                            style={{ marginBottom: '16px' }}
                          >
                            Add Formula
                          </Button>
                        )}
                      </>
                    )}
                  </List>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <div>
          <h4>Preview Formula</h4>
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              const values = form.getFieldsValue();
              return <TestIndicator dataIndicator={values} />;
            }}
          </Form.Item>
        </div>
      </Card>
    </FromPageWrapper>
  );
}
