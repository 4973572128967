import React, { useState } from 'react';
import { Card, Modal } from 'antd';

import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { dateFormatter } from '@base-helpers';
import { ApiUrlData } from '@base-configs';

import RunDeleteAction from '../components';
import { StatusOptions, TableOptions } from '../helpers/options';
import { usePageProviderAction } from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';

const column: any = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    width: 150,
    render: (_: any, row: any) => dateFormatter(row?.created_at).toLocal(),
  },
  {
    title: 'Table',
    dataIndex: 'table_delete',
    key: 'table_delete',
    sorter: true,
    editable: true,
    width: 150,
  },
  {
    title: 'Date Range',
    dataIndex: 'date_range',
    key: 'date_range',
    sorter: true,
    editable: true,
    width: 200,
    render: (_: any, row: any) =>
      row?.date_range
        ? `${dateFormatter(row?.date_range[0]).toLocal('DD-MM-YYYY')} - ${dateFormatter(row?.date_range[1]).toLocal(
            'DD-MM-YYYY',
          )}`
        : null,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    sorter: true,
    editable: true,
    width: 300,
  },
  {
    title: 'By',
    dataIndex: ['created_by', 'first_name'],
    key: 'created_by',
    sorter: true,
    width: 150,
  },
];

export default function PageIndex(): JSX.Element {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const { dataSource } = usePageProviderAction();

  function handleRunAction(row: any) {
    Modal.confirm({
      title: 'Run Delete Action',
      content: 'Are you sure you want to run action ?',
      onOk: () => runAction(row),
    });
  }

  async function runAction(row: any) {
    const ID = row?.id;

    setIsRunning(true);
    await dataSource.handleCustomRequest({
      paramRequest: {
        url: `${process.env.REACT_APP_BASE_URL}v1/delete-data/${ID}/run`,
        method: 'POST',
        data: {
          id: ID,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      },
      onSuccess: (): void => {
        setTimeout(() => {
          setIsRunning(false);
          notificationSuccess(['Succesfully delete Database']);
        }, 5000);
      },
      onFailed: ({ message }: any): any => {
        setIsRunning(false);
        notificationFailed([`Error when deleting database: ${message}`]);
      },
    });
  }

  const formBuilderProps: any = {
    columns: [
      {
        fields: [
          {
            name: 'table_delete',
            label: 'Table',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              options: TableOptions,
              customLabel: (value: any) => TableOptions.find((item) => value === item.value).label,
            },
          },
          {
            name: 'date_range',
            label: 'Date Range',
            fieldType: 'dateRangePicker',
            gridColumn: { span: 12 },
          },
          {
            name: 'status',
            label: 'Status',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              options: StatusOptions,
              customLabel: (value: any) => StatusOptions.find((item) => value === item.value).value,
            },
          },
          {
            name: 'created_ids',
            label: 'Created By',
            fieldType: 'selectPaginate',
            gridColumn: { span: 12 },
            selectPaginateProps: {
              dataSourceUrl: ApiUrlData.users,
              customLabel: (value: any) => value?.first_name,
              customFilterRequest: () => ({
                scope: 'global',
              }),
            },
          },
        ],
      },
    ],
  };

  return (
    <IndexPageWrapper
      showButtonBack={false}
      tableType="inline-form"
      title="Database Deletion"
      drawerFilterProp={{
        formBuilderProps,
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable
          columns={column}
          useConfirmCancel={false}
          rowAction={{
            disableUpdate: () => isRunning,
            disableDuplicate: () => isRunning,
            disableDelete: () => isRunning,
            showUpdate: (row) => row.status !== 'Posted',
            showDuplicate: () => false,
            postfixExtensionAction: (row) => {
              if (row.status === 'Posted') return;
              return <RunDeleteAction row={row} handleRunAction={handleRunAction} isRunning={isRunning} />;
            },
          }}
          onRow={() => {
            return {
              onClick: () => undefined,
            };
          }}
          inlineFormFields={[
            {
              columnKey: 'date_range',
              name: 'date_range',
              fieldType: 'dateRangePicker',
            },
            {
              columnKey: 'table_delete',
              name: 'table_delete',
              fieldType: 'select',
              rules: [{ required: true, message: 'Table is required!' }],
              selectProps: {
                menuPortalTarget: document.body,
                options: TableOptions,
                customLabel: (value) => TableOptions.find((item) => value === item.value).label,
              },
            },
            {
              columnKey: 'note',
              name: 'note',
              fieldType: 'inputText',
              inputTextProps: {
                placeholder: 'Input Note',
              },
            },
          ]}
        />
      </Card>
    </IndexPageWrapper>
  );
}
