import React, { useState, useEffect } from 'react';

import { Table, Tag, Select } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import * as _ from 'lodash';

import { Button, Row, Tooltip, Popconfirm, Form, Input } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import SelectComponent from './select';
import { TypeOptions, OperatorOptions, DataSourceOptions, DataStatusOptions } from './options';

import './additional-filter.less';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export default function AdditionalFilter(props: any) {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [ReferenceKeyOptions, setReferenceKeyOptions] = useState([]);
  const [ReferenceKeyOptionsAll, setReferenceKeyOptionsAll] = useState([]);

  useEffect(() => {
    if (props?.availableFilters) {
      const availableFilters = props?.availableFilters;
      const refOptions = [];
      availableFilters.forEach((element) => {
        element?.members?.forEach((item) => {
          refOptions.push(item);
        });
      });
      setReferenceKeyOptions(refOptions);
    }

    if (props?.availableFilterMembers) {
      const availableFilters = props?.availableFilterMembers;
      const refOptions = [];
      availableFilters.forEach((element) => {
        element?.members?.forEach((item) => {
          refOptions.push(item);
        });
      });
      setReferenceKeyOptionsAll(refOptions);
    }
  }, [props?.availableFilters, props?.availableFilterMembers]);

  useEffect(() => {
    if (props.value)
      setDataSource(
        props.value.map((item, index) => {
          return {
            ...item,
            key: item.key ?? uuidv4(),
            index,
          };
        }),
      );
    else setDataSource([]);
  }, [props?.value]);

  function onChangeValue(value: any) {
    if (props.onChange)
      props.onChange(
        value?.map((item) => {
          return _.omit(item, 'index');
        }),
      );
  }

  function onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter((el) => !!el);
      onChangeValue(newData);
    }
  }

  function DraggableBodyRow({ className, style, ...restProps }) {
    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }

  function DraggableContainer(props) {
    return <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />;
  }

  function onDelete(row: any) {
    const newData = dataSource.filter((item) => item.key !== row?.key);
    onChangeValue(newData);
    if (row.key === editingKey) setEditingKey('');
  }

  function onCreate() {
    const newData = [{ key: uuidv4() }, ...dataSource];
    setEditingKey(newData[0].key);
    onChangeValue(newData);
  }

  function updateRow(record) {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  }

  function EditableCell({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) {
    return (
      <td {...restProps}>
        {editing ? (
          <React.Fragment>
            {dataIndex == 'label_field' && (
              <Form.Item name={dataIndex} noStyle rules={[{ required: true, message: '' }]}>
                <Input placeholder="Input label" />
              </Form.Item>
            )}
            {dataIndex == 'member' && (
              <Form.Item name={dataIndex} noStyle rules={[{ required: true, message: '' }]}>
                <SelectComponent
                  allowClear
                  placeholder="Choose key reference"
                  selectOptions={ReferenceKeyOptions.map((item) => ({ label: item.title, value: item.name }))}
                />
              </Form.Item>
            )}
            {dataIndex == 'type' && (
              <Form.Item name={dataIndex} noStyle rules={[{ required: true, message: '' }]}>
                <SelectComponent placeholder="Choose type" allowClear selectOptions={TypeOptions} />
              </Form.Item>
            )}
            {dataIndex == 'operator' && (
              <Form.Item name={dataIndex} noStyle rules={[{ required: true, message: '' }]}>
                <SelectComponent placeholder="Choose operator" allowClear selectOptions={OperatorOptions} />
              </Form.Item>
            )}
            {dataIndex == 'data_source' && (
              <Form.Item noStyle dependencies={['type']}>
                {({ getFieldValue }) => {
                  const type = getFieldValue('type');
                  return (
                    <Form.Item name={dataIndex} noStyle rules={[{ required: type === 'select', message: '' }]}>
                      <SelectComponent
                        disabled={type !== 'select'}
                        placeholder="Choose data source"
                        allowClear
                        selectOptions={DataSourceOptions}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}

            {dataIndex == 'custom_options' && (
              <Form.Item noStyle dependencies={['type', 'data_source']}>
                {({ getFieldValue }) => {
                  const type = getFieldValue('type');
                  const dataSource = getFieldValue('data_source');
                  const isRequired =
                    type === 'select' && (dataSource === 'custom_options' || dataSource === 'status_options');
                  return (
                    <Form.Item name={dataIndex} noStyle rules={[{ required: isRequired, message: '' }]}>
                      <Select allowClear mode="tags" disabled={!isRequired} placeholder="Create options" />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}
          </React.Fragment>
        ) : (
          children
        )}
      </td>
    );
  }

  async function saveRow(key) {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        onChangeValue(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        onChangeValue(newData);
        setEditingKey('');
      }
      form.resetFields();
    } catch (errInfo) {}
  }

  function renderAction(item: any, row: any) {
    const editable = isEditing(row);
    const indexData = dataSource.findIndex((itemData) => itemData.key === row.key);

    return (
      <Row>
        {editable && (
          <React.Fragment>
            <Tooltip title="Save">
              <Button type="text" size="small" icon={<CheckOutlined />} onClick={() => saveRow(row.key)}></Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this row?"
                onConfirm={() => onDelete(row)}
                okText="Yes"
                trigger="click"
                cancelText="No"
                overlayInnerStyle={{ padding: '10px' }}
              >
                <Button type="text" size="small" icon={<DeleteOutlined />}></Button>
              </Popconfirm>
            </Tooltip>
          </React.Fragment>
        )}
        {!editable && (
          <React.Fragment>
            {indexData === 0 && (
              <Tooltip title="Add">
                <Button
                  type="text"
                  size="small"
                  icon={<PlusOutlined />}
                  disabled={editingKey !== ''}
                  onClick={() => onCreate()}
                ></Button>
              </Tooltip>
            )}

            <Tooltip title="Update">
              <Button
                type="text"
                size="small"
                icon={<EditOutlined />}
                disabled={editingKey !== ''}
                onClick={() => updateRow(row)}
              ></Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this row?"
                onConfirm={() => onDelete(row)}
                okText="Yes"
                trigger="click"
                cancelText="No"
                overlayInnerStyle={{ padding: '10px' }}
              >
                <Button type="text" size="small" icon={<DeleteOutlined />} disabled={editingKey !== ''}></Button>
              </Popconfirm>
            </Tooltip>
          </React.Fragment>
        )}
      </Row>
    );
  }

  function renderKeyReference(item) {
    return ReferenceKeyOptionsAll.find((itemOpt) => itemOpt.name === item)?.title ?? '';
  }

  function renderOptions(item, options) {
    return options.find((itemOpt) => itemOpt.value === item)?.label ?? '';
  }

  function renderCustomOptions(item) {
    const data = item ?? [];
    return (
      <React.Fragment>
        {data.map((item, idx) => (
          <Tag key={idx} style={{ margin: '2px' }}>
            {item}
          </Tag>
        ))}
      </React.Fragment>
    );
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },

    {
      title: 'Label',
      dataIndex: 'label_field',
      className: 'drag-visible',
      width: 150,
      editable: true,
    },

    {
      title: 'Value Reference',
      dataIndex: 'member',
      editable: true,
      width: 200,
      render: (item) => renderKeyReference(item),
    },

    {
      title: 'Type',
      dataIndex: 'type',
      editable: true,
      width: 200,
      render: (item) => renderOptions(item, TypeOptions),
    },

    {
      title: 'Operator',
      dataIndex: 'operator',
      editable: true,
      width: 200,
      render: (item) => renderOptions(item, OperatorOptions),
    },
    {
      title: 'Data Source',
      dataIndex: 'data_source',
      editable: true,
      width: 200,
      render: (item) => renderOptions(item, DataSourceOptions),
    },
    {
      title: 'Options',
      dataIndex: 'custom_options',
      editable: true,
      width: 200,
      render: (item) => renderCustomOptions(item),
    },
    {
      title: 'Action',
      className: 'drag-visible',
      render: renderAction,
      width: 100,
      fix: true,
    },
  ];

  const isEditing = (record) => record.key === editingKey;

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  function onValuesChange(item) {
    const keys = Object.keys(item);
    if (keys.includes('type')) form.setFieldsValue({ data_source: null, custom_options: [] });
    else if (keys.includes('data_source')) {
      if (item.data_source === 'status_options') {
        form.setFieldsValue({ custom_options: DataStatusOptions });
      } else {
        form.setFieldsValue({ custom_options: [] });
      }
    }
  }
  return (
    <div className="additional-filter">
      <Form form={form} component={false} onValuesChange={onValuesChange}>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={mergedColumns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
              cell: EditableCell,
            },
          }}
          locale={{
            emptyText: () => (
              <div style={{ height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center  ' }}>
                <span>
                  Click
                  <Button type="text" size="small" onClick={() => onCreate()}>
                    <i style={{ fontWeight: 'bold' }}>here</i>
                  </Button>
                  for create data.
                </span>
              </div>
            ),
          }}
        />
      </Form>
    </div>
  );
}
