import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { DashboardEntity } from '../../domain/entities';

import PageIndex from '../pages/dashboard';
import PageForm from '../pages/form';
import { makeDashboardTransformer } from '@pages/dashboard-pages/domain/factories';

export function DashboardFactory() {
  const transformer = makeDashboardTransformer<DashboardEntity>();
  const InitialValue: InitialValuePageProviderEntity<DashboardEntity> = {
    transformer,
    moduleKey: 'dashboard-page', // make sure this key unique each module
    webUrl: WebUrlData.dashboard,
    apiUrl: ApiUrlData.dashboard,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} PageCreate={PageForm} PageUpdate={PageForm} PageDuplicate={PageForm} />
    </PageProvider>
  );
}
