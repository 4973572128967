import React, { useEffect, useState } from 'react';
import { Card, Form } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';

import { DetailPageWrapper } from '@base-components';
import {
  useDataTable,
  useFilterDataTable,
  useMetaDataTable,
  usePageProviderAction,
  usePageProviderData,
} from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import ConfigTable from '../components/config-table';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

export default function PageIndex() {
  const { dataSource } = usePageProviderAction();
  const { moduleKey } = usePageProviderData();
  const { authState } = useRecoilValue(loginAccountState);

  // const [currentRoute] = useCurrentRoute();
  const [, setDataTableSate] = useDataTable(moduleKey);
  const [, setMetaTableState] = useMetaDataTable(moduleKey);
  const [, setFilterTableState] = useFilterDataTable(moduleKey);

  const [dataConfig, setDataConfig] = useState(undefined);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  async function getData() {
    const params = {
      page: 1,
      limit: 10,
    };

    setLoading(true);

    await dataSource.handleGetIndex({
      params,
      onSuccess: ({ response }: any) => {
        authState.setGeneralConfig(response.items);
        const emailSender = response.items.map((item) => ({
          ...item,
          id: item.id,
          key: uuidV4(),
          configuration: 'Email Sender',
          value: item.email_sender,
        }));

        const gmailToken = response.items.map((item) => ({
          ...item,
          id: item.id,
          key: uuidV4(),
          configuration: 'Gmail Token Auth',
          value: item.gmail_token,
        }));

        const periodSubmission = response.items.map((item) => ({
          ...item,
          id: item.id,
          key: uuidV4(),
          configuration: 'Period Submission',
          value: item.periode_submission,
        }));

        const taskCreator = response.items.map((item) => ({
          ...item,
          id: item.id,
          key: uuidV4(),
          configuration: 'Task Creator',
          value: item.task_creator.split(','),
        }));

        const configID = response.items[0].id;
        const newConfig = [...emailSender, ...gmailToken, ...periodSubmission, ...taskCreator];

        setDataConfig({
          id: configID,
          general_config: newConfig,
        });
        setDataTableSate(response.items);
        setMetaTableState(response.meta);
        setFilterTableState(params);
        setLoading(false);
      },
      onFailed: ({ message, statusCode }) => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function handleConfigUpdate() {
    setShowInput(!showInput);
  }

  async function onConfirmSave() {
    const fieldsValue = await form.validateFields();
    const configData = { ...dataConfig };
    const id = configData?.id;
    const newValue = { ...fieldsValue, id };
    await dataSource.handleCreate(newValue, {
      onSuccess: async ({ response }): Promise<void> => {
        await getData();
        notificationSuccess(['Successfully updated data.']);
        setShowInput(false);
      },
      onFailed: ({ message, statusCode }: any) => {
        notificationFailed(message);
        setShowInput(false);
      },
    });
  }

  async function onReload() {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setShowInput(false);
    await getData();
  }

  return (
    <DetailPageWrapper
      onReload={onReload}
      showButtonAddNew={false}
      showButtonBack={false}
      showDelete={false}
      showDuplicate={false}
      showActivate={false}
      showDeactivate={false}
      showUpdate={!showInput}
      showButtonSave={showInput}
      handleUpdate={handleConfigUpdate}
      titleConfirmSave="Update Confirmation"
      contentConfirmSave="Are you sure you want to update this ?"
      onConfirmSave={onConfirmSave}
      dataDummy={dataConfig}
    >
      <Card className="card-content-wrapper">
        <Form.Item name="general_config">
          <ConfigTable form={form} isShowInput={showInput} data={dataConfig} loading={loading} />
        </Form.Item>
      </Card>
    </DetailPageWrapper>
  );
}
