import React from 'react';
import { Button, Form } from 'antd';
import { FromPageWrapper, FormBuilder, DetailBuilder } from '@base-components';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { ApiUrlData } from '@base-configs';

export default function PageForm() {
  const [currentRoute] = useCurrentRoute();
  const { action, showData } = usePageProviderData();
  const { navigation, dataSource } = usePageProviderAction();

  return (
    <FromPageWrapper
    // onChangeFormValues={(_, values) => console.log({ values })}
    >
      <FormBuilder
        title="FORM GENERAL"
        columns={[
          {
            fields: [
              {
                rules: [{ required: true }],
                name: 'text_area',
                fieldType: 'textArea',
                gridColumn: { span: 24 },
              },
            ],
          },
        ]}
      />

      <FormBuilder
        title="FORM GENERAL"
        columns={[
          {
            title: 'INDIVIDUAL INFORMATION',
            fields: [
              {
                label: 'Select Paginate',
                name: 'select_paginate',
                fieldType: 'selectPaginate',
                rules: [{ required: true }],
                selectPaginateProps: {
                  // useOptionAllScheme: true,
                  keySearch: 'q_index',
                  customLabel: ({ product_master, code }) => product_master?.code ?? code,
                  dataSourceUrl: ApiUrlData.sub_region,
                },
              },
              {
                label: 'Select',
                name: 'select',
                rules: [{ required: true }],
                fieldType: 'select',
                selectProps: {
                  customLabel: (value) => value.toUpperCase(),
                  options: [
                    { label: 'AAA', value: 'aaa' },
                    { label: 'BBB', value: 'bbb' },
                    { label: 'CCC', value: 'ccc' },
                  ],
                },
              },
              {
                label: 'Multi Select Paginate',
                rules: [{ required: true }],
                name: 'multi_select_paginate',
                fieldType: 'selectPaginate',
                dependencies: ['select'],
                selectPaginateProps: {
                  useOptionAllScheme: true,
                  isMulti: true,
                  keySearch: 'q_index',
                  dataSourceUrl: ApiUrlData.sales_basic_price,
                  customFilterRequest: (payload) => {
                    return {
                      code: '1',
                    };
                  },
                  customLabel: ({ product_master, code }) => product_master?.code ?? code,
                  customKey: ({ getFieldValue }) => {
                    const select = getFieldValue('select');
                    const multiSelectPaginate = getFieldValue('multi_select_paginate');
                    return JSON.stringify([select, multiSelectPaginate]);
                  },
                },
              },
              {
                label: 'Multi Select',
                name: 'multi_select',
                rules: [{ required: true }],
                fieldType: 'select',
                selectProps: {
                  isMulti: true,
                  customLabel: (value) => value.toUpperCase(),
                  options: [
                    { label: 'AAA', value: 'aaa' },
                    { label: 'BBB', value: 'bbb' },
                    { label: 'CCC', value: 'ccc' },
                  ],
                },
              },

              {
                label: 'Input Text',
                rules: [{ required: true }],
                name: 'input_text',
                fieldType: 'inputText',
              },
              {
                rules: [{ required: true }],
                label: 'Input Number',
                name: 'input_number',
                fieldType: 'inputNumber',
              },
              {
                label: 'Input Email',
                rules: [{ required: true }, { type: 'email' }],
                name: 'input_email',
                fieldType: 'inputText',
              },
              {
                label: 'Password',
                rules: [{ required: true }],
                name: 'password',
                fieldType: 'inputPassword',
              },
              {
                rules: [{ required: true }],
                label: 'Date Picker',
                name: 'date_picker',
                fieldType: 'datePicker',
              },
              {
                label: 'Date Range Picker',
                rules: [{ required: true }],
                name: 'date_range_picker',
                fieldType: 'dateRangePicker',
              },
              {
                label: 'Time Picker',
                rules: [{ required: true }],
                name: 'time_picker',
                fieldType: 'timePicker',
              },
              {
                label: 'Time Range Picker',
                rules: [{ required: true }],
                name: 'time_range_picker',
                fieldType: 'timeRangePicker',
              },
              {
                label: 'Checkbox',
                rules: [{ required: true }],
                name: 'checkbox',
                fieldType: 'checkbox',
                gridColumn: { span: 3 },
                checkboxProps: {
                  children: 'Checkbox title',
                },
              },

              {
                label: 'Switch',
                rules: [{ required: true }],
                name: 'switch',
                fieldType: 'switch',
                gridColumn: { span: 2 },
              },

              {
                label: 'Radio Group',
                rules: [{ required: true }],
                name: 'radio_group',
                fieldType: 'radioGroup',
                radioGroupProps: {
                  options: [
                    { label: 'Value 1', value: 1 },
                    { label: 'Value 2', value: 2 },
                    { label: 'Value 3', value: 3 },
                    { label: 'Value 4', value: 4 },
                  ],
                },
              },
              {
                label: 'Color Picker',
                rules: [{ required: true }],
                name: 'colorPicker',
                fieldType: 'colorPicker',
              },
              // {
              //   shouldUpdate: true,
              //   gridColumn: { span: 3 },
              //   renderField: ({ getFieldsValue }) => {
              //     const values = getFieldsValue();
              //     return <React.Fragment>{JSON.stringify(values ?? {})}</React.Fragment>;
              //   },
              // },
            ],
          },
          {
            title: 'NOTE INFORMATION',
            fields: [
              {
                label: 'Text Area',
                rules: [{ required: true }],
                name: 'text_area',
                fieldType: 'textArea',
                gridColumn: { span: 12 },
              },
            ],
          },
        ]}
      />

      <DetailBuilder
        title="DETAIL GENERAL"
        columns={[
          {
            title: 'INDIVIDUAL INFORMATION',
            fields: [
              {
                label: 'Render Text',
                name: 'input_text',
                fieldType: 'text',
              },
              {
                label: 'Render Text Multi Text',
                name: 'multi_select',
                fieldType: 'text',
                renderTextProps: {
                  // withColon: false,
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Tag Multi Object',
                name: 'multi_select_paginate',
                fieldType: 'text',
                renderTextProps: {
                  // withColon: false,
                  renderValue: ({ product_master, code }) => product_master?.code ?? code,
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Tag',
                name: 'input_text',
                fieldType: 'tag',
              },
              {
                label: 'Render Tag Multi Text',
                name: 'multi_select',
                fieldType: 'tag',
                renderTagProps: {
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Tag Multi Object',
                name: 'multi_select_paginate',
                fieldType: 'tag',
                renderTagProps: {
                  renderValue: ({ product_master, code }) => product_master?.code ?? code,
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Link',
                name: 'input_text',
                fieldType: 'link',
              },
              {
                label: 'Render Link Multi Text',
                name: 'multi_select',
                fieldType: 'link',
                renderLinkProps: {
                  // withColon: false,
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Link Multi Object',
                name: 'multi_select_paginate',
                fieldType: 'link',
                renderLinkProps: {
                  // withColon: false,
                  renderValue: ({ product_master, code }) => product_master?.code ?? code,
                  renderType: 'multi',
                },
              },

              {
                label: 'Render Currency',
                name: 'input_text',
                fieldType: 'currency',
              },
              {
                label: 'Render Currency Multi Text',
                name: 'multi_select',
                fieldType: 'currency',
                renderCurrencyProps: {
                  // withColon: false,
                  makeValue: () => 10000,
                  renderType: 'multi',
                },
              },
              {
                label: 'Render Currency Multi Object',
                name: 'multi_select_paginate',
                fieldType: 'currency',
                renderCurrencyProps: {
                  // withColon: false,
                  makeValue: () => 20000,
                  renderType: 'multi',
                },
              },
            ],
          },
        ]}
      />
    </FromPageWrapper>
  );
}
