import { STYLE_INDICATOR } from '../../domain/entities';

export enum ScopeValue {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

enum CategoryOptions {
  send_email = 'send_email',
  meeting = 'meeting',
  training = 'training',
  call = 'call',
  other = 'other',
}

export const ScopeOptions = [
  { label: 'Global', value: ScopeValue.global },
  { label: 'Region', value: ScopeValue.region },
  { label: 'Church', value: ScopeValue.sub_region },
];

export const IndicatoryStyleOptions = [
  { label: 'Compare Metrics', value: STYLE_INDICATOR.COMPARE_METRIC },
  { label: 'Trending Metrics', value: STYLE_INDICATOR.TRENDING_METRIC },
  { label: 'Single Metrics', value: STYLE_INDICATOR.SINGLE_METRIC },
  { label: 'Score Metrics', value: STYLE_INDICATOR.SCORES_LABEL_METRIC },
];

export const TaskCategoryOptions = [
  { label: 'Send Email', value: CategoryOptions.send_email },
  { label: 'Meeting', value: CategoryOptions.meeting },
  { label: 'Training', value: CategoryOptions.training },
  { label: 'Call', value: CategoryOptions.call },
  { label: 'Other', value: CategoryOptions.other },
];

export const YesOrNoOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
