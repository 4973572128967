import { BaseEntity } from '@base-entities';

export interface MetricIndicatorEntity extends BaseEntity {
  [key: string]: any;
}

export enum STYLE_INDICATOR {
  COMPARE_METRIC = 'compare_metric',
  TRENDING_METRIC = 'trending_metric',
  SINGLE_METRIC = 'single_metric',
  SCORES_LABEL_METRIC = 'score_metric',
}
