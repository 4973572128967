import { atom } from 'recoil';

export const indicatorLayoutState = atom({
  default: undefined,
  key: 'indicatorLayoutState',
});

export const chartLayoutState = atom({
  default: undefined,
  key: 'chartLayoutState',
});
