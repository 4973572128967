import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import moment from 'moment';
import * as _ from 'lodash';

export class DatabaseDeletionTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action']);
    const date_range = payload?.date_range?.map((item: any) => item.toISOString());

    const transformedPayload = {
      ...cleanVal,
      date_range,
      date_start: date_range ? date_range[0] : undefined,
      date_end: date_range ? date_range[1] : undefined,
    };

    return transformedPayload;
  }

  transformerGetIndex(payload: E[]): E[] {
    const transformedPayload: any = payload?.map((item) => {
      const cleanItem = _.omit(item, ['date_start', 'date_end']);
      return {
        ...cleanItem,
        date_range: [moment(item?.date_start), moment(item?.date_end)],
      };
    });

    return transformedPayload;
  }

  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetData(payload: E): E {
    console.log(payload);
    const cleanItem: any = _.omit(payload, ['date_start', 'date_end']);
    return {
      ...cleanItem,
      date_range: [moment(payload?.date_start), moment(payload?.date_end)],
    };
  }
}
