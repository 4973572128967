import React from 'react';
import { Spin, Tooltip } from 'antd';
import { dateFormatter, stringFormatter } from '@base-helpers';

import { useBoardContextValue } from '../../../context';
import { TaskCategoryOptions } from '@pages/crm-pages/board/presentation/helpers/options';

export default function DetailList(): JSX.Element {
  const { dataOnDrawer, loadingGetDetail } = useBoardContextValue();

  const activityTriggered = dataOnDrawer?.activity_triggered
    ? stringFormatter.capitalizeEachWord(dataOnDrawer?.activity_triggered.activity_id)
    : '-';
  const region = dataOnDrawer?.region ? dataOnDrawer?.region?.rc_dpw_name : '-';
  const scope = dataOnDrawer?.scope;
  const size = dataOnDrawer?.size;
  const subRegion = dataOnDrawer?.sub_region ? dataOnDrawer?.sub_region?.church_name : '-';
  const taskCategory = dataOnDrawer?.task_category;
  const venueType = dataOnDrawer?.venue_type;

  const triggeredFrom = dataOnDrawer?.activity_triggered
    ? `${activityTriggered} | ${(scope === 'region' && region) || (scope === 'sub_region' && subRegion)}`
    : '-';

  const category = TaskCategoryOptions?.find((item) => item?.value === taskCategory);

  function DetailsDetail(): JSX.Element {
    return (
      <div className="details">
        <div className="detail-item">
          <span>Status</span>
          <span>:</span>
          <span>{dataOnDrawer?.workflow.name}</span>
        </div>
        <div className="detail-item">
          <span>Category</span>
          <span>:</span>
          <span>{category?.label}</span>
        </div>
        {taskCategory === 'meeting' && (
          <div className="detail-item">
            <span>Size</span>
            <span>:</span>
            <span>{!size ? '-' : stringFormatter.capitalizeEachWord(dataOnDrawer?.size.split('_').join(' '))}</span>
          </div>
        )}
        <div className="detail-item">
          <span>Triggered from</span>
          <span>:</span>
          <span>{triggeredFrom}</span>
        </div>
        <div className="detail-item">
          <span>Subject</span>
          <span>:</span>
          <span>{dataOnDrawer?.summary || '-'}</span>
        </div>
        <div className="detail-item">
          <span>Indicator name</span>
          <span>:</span>
          <span>{dataOnDrawer?.indicator && dataOnDrawer?.indicator.name}</span>
        </div>
      </div>
    );
  }

  function createTooltipTask(users: any, key: string): JSX.Element {
    const name = users?.map((user) => {
      return user[key];
    });

    return <span>{name?.join(', ')}</span>;
  }

  function makeEllipsisData(data = [], keyValue = 'first_name') {
    if (data.length == 0) return '-';
    let stringData = data
      ?.slice(0, 3)
      ?.map((item) => item[keyValue])
      .join(', ');
    if (data.length > 3) stringData = stringData + ', ...';
    return stringData;
  }

  function makeEllipsisFullName(data = []) {
    if (data.length == 0) return '-';
    let stringData = data
      ?.slice(0, 3)
      ?.map((item) => `${item['first_name']} ${item['last_name'] ?? ''}`)
      .join(', ');
    if (data.length > 3) stringData = stringData + ', ...';
    return stringData;
  }

  function PeopleDetail(): JSX.Element {
    return (
      <div className="people">
        <div className="people-item">
          <span>Assignee</span>
          <span>:</span>
          <Tooltip placement="bottom" title={createTooltipTask(dataOnDrawer?.assignee, 'first_name')}>
            <span>{makeEllipsisFullName(dataOnDrawer?.assignee)}</span>
          </Tooltip>
        </div>
        <div className="people-item">
          <span>Reporter</span>
          <span>:</span>
          <span>{makeEllipsisFullName(dataOnDrawer?.reporter)}</span>
        </div>
        {dataOnDrawer?.task_category !== 'send_email' && (
          <div className="people-item">
            <span>Participant</span>
            <span>:</span>
            <Tooltip placement="bottom" title={createTooltipTask(dataOnDrawer?.participant, 'first_name')}>
              <span>{makeEllipsisFullName(dataOnDrawer?.participant)}</span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  function DateDetail(): JSX.Element {
    return (
      <>
        <div className="date-item">
          <span>Created</span>
          <span>:</span>
          <span>{dataOnDrawer?.created_at ? dateFormatter(dataOnDrawer?.created_at).toLocal() : '-'}</span>
        </div>
        <div className="date-item">
          <span>Updated</span>
          <span>:</span>
          <span>{dataOnDrawer?.updated_at ? dateFormatter(dataOnDrawer?.updated_at).toLocal() : '-'}</span>
        </div>
        <div className="date-item">
          <span>Plan</span>
          <span>:</span>
          <span>
            {dataOnDrawer?.date_plan
              ? dateFormatter(dataOnDrawer?.date_plan).toLocal()
              : dateFormatter(dataOnDrawer?.created_at).toLocal()}
          </span>
        </div>
        <div className="date-item">
          <span>Completed</span>
          <span>:</span>
          <span>{dataOnDrawer?.completed_date ? dateFormatter(dataOnDrawer?.completed_date).toLocal() : '-'}</span>
        </div>
      </>
    );
  }

  function EmailDetail(): JSX.Element {
    return (
      <div className="body-drawer-emails">
        <h4 style={{ fontWeight: 700 }}>Email Details</h4>
        <div className="email-item">
          <span>Send Method</span>
          <span>:</span>
          <span>{stringFormatter.capitalizeEachWord(dataOnDrawer?.send_method || '-')}</span>
        </div>
        {dataOnDrawer?.send_method === 'automatic' && (
          <div className="email-item">
            <span>Email Template</span>
            <span>:</span>
            <span>{dataOnDrawer?.email_template?.template_name || '-'}</span>
          </div>
        )}
        <div className="email-item">
          <span>Recipient</span>
          <span>:</span>
          <Tooltip placement="bottom" title={createTooltipTask(dataOnDrawer?.recipient, 'email')}>
            <span>{makeEllipsisData(dataOnDrawer?.recipient, 'email')}</span>
          </Tooltip>
        </div>
        <div className="email-item">
          <span>Cc</span>
          <span>:</span>
          <Tooltip placement="bottom" title={createTooltipTask(dataOnDrawer?.cc, 'email')}>
            <span>{makeEllipsisData(dataOnDrawer?.cc, 'email')}</span>
          </Tooltip>
        </div>
        <div className="email-item">
          <span>Bcc</span>
          <span>:</span>
          <Tooltip placement="bottom" title={createTooltipTask(dataOnDrawer?.bcc, 'email')}>
            <span>{makeEllipsisData(dataOnDrawer?.bcc, 'email')}</span>
          </Tooltip>
        </div>
      </div>
    );
  }

  function VenueDetail(): JSX.Element {
    return (
      <>
        {venueType === 'online' && (
          <>
            <div className="venue-item">
              <span>Location</span>
              <span>:</span>
              <span>{'Online Meeting'}</span>
            </div>
            <div className="venue-item">
              <span>Link</span>
              <span>:</span>
              <span>{dataOnDrawer?.link}</span>
            </div>
            <div className="venue-item">
              <span>Meeting ID</span>
              <span>:</span>
              <span>{dataOnDrawer?.meeting_id}</span>
            </div>
            <div className="venue-item">
              <span>Password</span>
              <span>:</span>
              <span>{dataOnDrawer?.password}</span>
            </div>
          </>
        )}

        {venueType === 'on_site' && (
          <>
            <div className="venue-item">
              <span>Location</span>
              <span>:</span>
              <span>
                {stringFormatter.capitalizeEachWord(
                  dataOnDrawer?.address ? dataOnDrawer?.address.split('_').join(' ') : '-',
                )}
              </span>
            </div>
            {dataOnDrawer?.address === 'other' && (
              <>
                <div className="venue-item">
                  <span>Google Map Link</span>
                  <span>:</span>
                  <span>{dataOnDrawer?.google_map_link ? dataOnDrawer?.google_map_link : '-'}</span>
                </div>
                <div className="venue-item">
                  <span>Address</span>
                  <span>:</span>
                  <span>{dataOnDrawer?.location ? dataOnDrawer?.location : '-'}</span>
                </div>
              </>
            )}
            {dataOnDrawer?.address === 'sub_region' && (
              <div className="venue-item">
                <span>Address</span>
                <span>:</span>
                <span>{dataOnDrawer?.location || '-'}</span>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Spin spinning={loadingGetDetail ?? false}>
      <section className="body-drawer-details">
        <h4>Details</h4>
        <DetailsDetail />
      </section>
      <section className="body-drawer-description">
        <h4>Message Body</h4>
        <p>{dataOnDrawer?.description}</p>
      </section>
      <section className="body-drawer-people">
        <h4>People</h4>
        <PeopleDetail />
      </section>
      <section className="body-drawer-dates">
        <h4>Dates</h4>
        <DateDetail />
      </section>
      {taskCategory === 'send_email' && <EmailDetail />}
      {taskCategory !== 'send_email' && taskCategory !== 'call' && (
        <section className="body-drawer-venue">
          <h4>Venue</h4>
          <VenueDetail />
        </section>
      )}
    </Spin>
  );
}
