import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { SummaryEntity } from '../../domain/entities';

import PageIndex from '../pages';
import { makeActivitySummaryTransformer } from '../../domain/factories';

export function SummaryFactory(): JSX.Element {
  const transformer = makeActivitySummaryTransformer<SummaryEntity>();
  const InitialValue: InitialValuePageProviderEntity<SummaryEntity> = {
    transformer,
    moduleKey: 'activity-summary-page', // make sure this key unique each module
    webUrl: WebUrlData.activity_summary,
    apiUrl: ApiUrlData.activity_summary,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
