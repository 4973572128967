import { ApiUrlData } from '@base-configs';
import { TaskCategoryOptions } from '../helpers/options';

export const formBuilderProps: any = {
  columns: [
    {
      fields: [
        {
          name: 'task_ids',
          label: 'Task ID',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.crm_task,
            customLabel: (value) => value?.task_id,
          },
        },

        {
          name: 'region_ids',
          label: 'Region',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.region,
            customLabel: (value) => value?.rc_dpw_name,
            customFilterRequest: () => ({
              status: 'Active',
            }),
          },
        },
        {
          name: 'sub_region_ids',
          label: 'Local Church & Satellite',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.sub_region,
            customLabel: (value) => value?.church_name,
            customFilterRequest: () => ({
              status: 'Active',
            }),
          },
        },
        {
          name: 'task_categories',
          label: 'Task Category',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            isMulti: true,
            options: TaskCategoryOptions,
            customLabel(value) {
              return TaskCategoryOptions.find((item) => value === item.value)?.label;
            },
          },
        },
        {
          name: 'assignee_ids',
          label: 'Assignee',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.users,
            customLabel: (row) => row?.first_name,
          },
        },
        {
          name: 'reporter_ids',
          label: 'Recipient',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.users,
            customLabel: (row) => row?.first_name,
          },
        },
        {
          name: 'participant',
          label: 'Participant',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.users,
            customLabel: (row) => row?.first_name,
          },
        },
        {
          name: 'workflow_ids',
          label: 'Status',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.workflow,
            customLabel: (row) => row?.name,
          },
        },
        {
          name: 'created_at',
          label: 'Created At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'updated_at',
          label: 'Last Updated At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'created_ids',
          label: 'Created By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            placeholder: 'select user',
            dataSourceUrl: ApiUrlData.users,
            customLabel: (value) => value?.first_name,
          },
        },
        {
          name: 'updated_ids',
          label: 'Last Updated By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            placeholder: 'select user',
            dataSourceUrl: ApiUrlData.users,
            customLabel: (value) => value?.first_name,
          },
        },
      ],
    },
  ],
};
