import React from 'react';
import * as _ from 'lodash';
import { AsyncPaginate } from 'react-select-async-paginate';
import { SelectPaginateProps } from '../entities';
import { defaultAdditional, AllOptionItem } from '../helpers';
import { DropdownIndicator, IndicatorSeparator, ClearIndicator, MultiValueRemove } from '../components/indicator';
import { BaseStyleSelect } from '../style/style';
import { onChangeSelect, transformValue, filterOptions } from '../helpers';
import { makeCommonDataSource } from '@base-factories';
import '../style/react-select.less';

export function SelectPaginate(props: SelectPaginateProps) {
  const {
    key,
    value,
    isMulti,
    customKey,
    customLabel,
    defaultValue,
    dataSourceUrl,
    defaultOptions,
    keySearch = 'q',
    transformOptions,
    keyLabel = 'code',
    customLoadOptions,
    baseDataSourceUrl,
    filterRequest = {},
    useOptionAllScheme,
    isClearable = true,
    debounceTimeout = 300,
    placeholder = 'Choose',
    styles = BaseStyleSelect,
    itemAllOption = AllOptionItem,
    additional = defaultAdditional,
    classNamePrefix = 'react-select-custom-prefix',
  } = props;

  const dataSource = makeCommonDataSource({ apiUrl: dataSourceUrl, baseUrl: baseDataSourceUrl });

  function makeFilterRequest(page: number, limit: number, search: string): any {
    return {
      page,
      limit,
      [keySearch]: search,
      ...filterRequest,
    };
  }

  function makeOptions(options) {
    const newOptions = options ?? [];
    return newOptions.map((item) => {
      const keys = Object.keys(item ?? {});
      if (keys.length === 2 && keys.includes('label') && keys.includes('value')) return item;
      else
        return {
          label: customLabel ? customLabel(item) : item[keyLabel],
          value: item,
        };
    });
  }

  function makeTransformOptions(options: any[]): any {
    if (transformOptions) return transformOptions(options);
    return options;
  }

  async function loadDataSource(search, prevOptions, { page, limit }) {
    let options = [];
    let hasMore = false;
    let newAdditional = additional;
    await dataSource.handleGetIndex({
      params: makeFilterRequest(page, limit, search),
      onSuccess: ({ response }: any) => {
        const message = response.items;
        const meta = response.meta;
        const rawOptions = makeOptions(makeTransformOptions(message));
        options = filterOptions(rawOptions, transformValue(value ?? defaultValue, customLabel, keyLabel));
        hasMore = meta.currentPage < meta.totalPages && options.length > 0;
        newAdditional = {
          page: meta.currentPage + 1,
          limit: 10,
        };
      },
      onFailed: ({ message }) => {
        console.log({ message });
      },
    });
    return {
      hasMore,
      options,
      additional: newAdditional,
    };
  }

  async function loadOptions(search, prevOptions, { page, limit }) {
    if (customLoadOptions) return customLoadOptions(search, prevOptions, { page, limit });
    else {
      const realOptions = await loadDataSource(search, prevOptions, { page, limit });
      if (!useOptionAllScheme) return realOptions;
      else {
        if (isMulti) {
          const hasAllData = value?.find((item) => item.code?.toUpperCase() === 'ALL') ?? undefined;
          if (hasAllData) {
            return {
              hasMore: false,
              options: [],
              additional,
            };
          } else if (value?.length > 0) {
            return realOptions;
          }
        }
        return {
          ...realOptions,
          options: [itemAllOption, ...realOptions.options],
        };
      }
    }
  }

  function makeKey() {
    try {
      if (customKey) return btoa(customKey(value));
      // else if (!key) return key;
      else if (!key) return btoa(JSON.stringify(value ?? {}));
      return window.btoa(key);
    } catch (error) {
      return '';
    }
  }

  return (
    <AsyncPaginate
      {...props}
      styles={styles}
      key={makeKey()}
      additional={additional}
      isClearable={isClearable}
      placeholder={placeholder}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      classNamePrefix={classNamePrefix}
      value={transformValue(value, customLabel, keyLabel)}
      onChange={(value: any) => onChangeSelect(value, props)}
      defaultValue={transformValue(defaultValue, customLabel, keyLabel)}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, IndicatorSeparator }}
    />
  );
}
