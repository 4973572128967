import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { SimpleTemplateEntity } from '../../domain/entities';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

export function SimpleTemplateFactory() {
  const InitialValue: InitialValuePageProviderEntity<SimpleTemplateEntity> = {
    moduleKey: 'simple-template', // make sure this key unique each module
    webUrl: WebUrlData.simple_template,
    apiUrl: ApiUrlData.sales_basic_price,
    baseApiUrl: 'http://api.testing.erp.eigen.co.id/api/',
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
