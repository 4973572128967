import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Tabs, List } from 'antd';
import { Plus } from 'phosphor-react';
import { v4 as uuidV4 } from 'uuid';

import { makeCommonDataSource } from '@base-factories';

import FormJournal from '../form-journal';
import JournalContent from './journal-content';
import StatusContent from './status-content';

import { useBoardContextValue } from '../../../context';
import { notificationFailed, notificationSuccess } from '@base-helpers';

const { TabPane } = Tabs;

interface GetDataProp {
  page?: number;
  limit?: number;
  journal?: any[];
}

export default function DetailJournal(): JSX.Element {
  const [journalData, setJournalData] = useState<any[]>([]);
  const [showFormJournal, setShowFormJournal] = useState<boolean>(false);
  const [actionForm, setActionForm] = useState<string>('create');
  const [itemID, setItemID] = useState<string>('');
  const [form] = Form.useForm();

  const dataJournal = makeCommonDataSource({ apiUrl: 'v1/crm/task/jurnal' });
  const { dataOnDrawer } = useBoardContextValue();

  const showJournal = () => {
    setShowFormJournal(true);
  };

  const hideJournal = () => {
    if (actionForm === 'create') {
      setShowFormJournal(false);
    }
    if (actionForm === 'update') {
      setShowFormJournal(false);
      setActionForm('create');
      form.resetFields();
    }
  };

  async function getJournal(props?: GetDataProp) {
    const { page = 1, limit = 50, journal = [] } = props;
    await dataJournal.handleGetIndex({
      params: {
        page,
        limit,
        status: 'Active',
        task_ids: [dataOnDrawer.id],
      },
      onSuccess: async ({ response }: any): Promise<any> => {
        const items = response.items ?? [];
        const meta = response?.meta;
        const transformResponse = items.map((item) => ({
          key: uuidV4(),
          ...item,
        }));
        const newJournal = [...journal, ...transformResponse];
        setJournalData(newJournal);
        if (meta && meta.currentPage < meta.totalPages) {
          await getJournal({
            page: meta.currentPage + 1,
            journal: newJournal,
          });
        } else {
        }
      },
      onFailed: ({ message }: any): any => {
        console.log(message);
      },
    });
  }

  useEffect(() => {
    getJournal({ page: 1 });
  }, [dataOnDrawer]);

  const handleOnFinish = async (payload) => {
    try {
      await form.validateFields();
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: 'Save Confirmation',
        content: 'Are you sure you want to save data ?',
        onOk: () => handleOnOk(payload),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnOk = async (payload): Promise<void> => {
    const transformPayload = {
      ...payload,
      task: {
        id: dataOnDrawer?.id,
        task_id: dataOnDrawer?.task_id || '',
      },
      participant: payload?.participant?.map((person) => ({
        id: person.id,
        first_name: person.first_name,
        last_name: person.last_name,
      })),
    };

    if (actionForm === 'create') {
      await dataJournal.handleCreate(transformPayload, {
        onSuccess: ({ response }: any): any => {
          notificationSuccess(['Journal Created Successfully']);
          // setShowFormJournal(false);
          setJournalData([response, ...journalData]);
          form.resetFields();
        },
        onFailed: ({ message }: any): any => {
          notificationFailed(message);
        },
      });
    }

    if (actionForm === 'update') {
      await dataJournal.handleUpdate(itemID, transformPayload, {
        onSuccess: ({ response }: any): any => {
          const newJournal = journalData?.map((item) => {
            if (item.id !== response.id) return item;
            return response;
          });
          setJournalData(newJournal);
          notificationSuccess(['Journal Updated Successfully']);
          form.resetFields();
        },
        onFailed: ({ message }: any): any => {
          notificationFailed(message);
        },
      });
    }
  };

  const handleDelete = (id): void => {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Delete Confirmation',
      content: 'Are you sure you want to delete data ?',
      onOk: () => handleOnDelete(id),
    });
  };

  const handleOnDelete = async (id): Promise<void> => {
    await dataJournal.handleDelete(
      { id },
      {
        onSuccess: ({ response }: any): any => {
          const newJournalData = journalData.filter((item) => item.id !== id);
          notificationSuccess(['Successfully deleted data.']);
          setJournalData(newJournalData);
        },
        onFailed: ({ message }: any): any => {
          console.log(message);
          setJournalData(journalData);
          notificationFailed(message);
        },
      },
    );
  };

  const handleUpdate = (item, id): void => {
    setActionForm('update');
    if (item.id === id) {
      showJournal();
      setItemID(id);
      form.setFieldsValue({
        description: item.description,
        participant: item.participant,
      });
    } else {
      form.resetFields();
    }
  };

  return (
    <>
      <div className="body-drawer-journal">
        <div className="journal-content">
          <Tabs defaultActiveKey="1" style={{ fontSize: 12 }}>
            <TabPane tab={`Journal (${journalData.length})`} key="1">
              {!showFormJournal && (
                <div className="journal-submit" style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    type="primary"
                    icon={<Plus style={{ marginRight: '5px' }} />}
                    onClick={() => {
                      setActionForm('create');
                      form.resetFields();
                      showJournal();
                    }}
                  >
                    Add Journal
                  </Button>
                </div>
              )}
              {showFormJournal && (
                <div>
                  <Form form={form} layout="vertical" onFinish={handleOnFinish}>
                    <FormJournal hideJournal={hideJournal} />
                  </Form>
                </div>
              )}

              <div style={{ marginBottom: '30px' }}></div>
              <div
                style={{
                  height: 400,
                  overflow: 'auto',
                  padding: '0 16px',
                  borderTop: '1px solid rgba(140, 140, 140, 0.35)',
                  borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                }}
              >
                <List
                  dataSource={journalData ?? []}
                  renderItem={(data) => (
                    <List.Item>
                      <JournalContent
                        key={data.key}
                        handleDelete={handleDelete}
                        handleUpdate={handleUpdate}
                        data={data}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </TabPane>
            <TabPane tab="Status" key="2">
              <div
                style={{
                  height: 500,
                  overflow: 'auto',
                  padding: '0 16px',
                  borderTop: '1px solid rgba(140, 140, 140, 0.35)',
                  borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                }}
              >
                <StatusContent />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
