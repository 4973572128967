import React from 'react';
import { FromPageWrapper, FormBuilder } from '@base-components';
import { RegionScopeOptions, ScopeValue } from '../helpers';
import { ApiUrlData } from '@base-configs';
import { Card, Col, message, Row } from 'antd';
import { usePageProviderAction, usePageProviderData } from '@base-hooks';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { makeArrayIds } from '@base-helpers';
import { pick } from 'lodash';

function hiddenRegion({ getFieldValue }) {
  const scope = getFieldValue('scope');
  if (scope !== ScopeValue.region_subregion) return true;
  return false;
}

export default function PageForm() {
  const { navigation } = usePageProviderAction();
  const { isCreate } = usePageProviderData();
  const { authState } = useRecoilValue(loginAccountState);

  function handleAfterUpdate(account) {
    const existAccount = authState.getAccount();
    const accountId = account?.id;
    const existAccountId = existAccount?.id;
    if (account && accountId === existAccountId) authState.updateAccount(account);
    navigation.goToDetail({ id: account.id });
  }

  function messageImage() {
    return <p>{'Image format must be jpg, jpeg, or png and max should be less than 100kB.'}</p>;
  }

  function onChangeForm(item, values) {
    if (Object.keys(item).includes('scope')) {
      const scope = item.scope;
      if (scope === 'global') {
        return {
          ...values,
          scope_regions: undefined,
          scope_sub_regions: undefined,
          scope_sub_regions_tpi: undefined,
        };
      }
    } else if (Object.keys(item).includes('scope_regions')) {
      return {
        ...values,
        scope_sub_regions: undefined,
        scope_sub_regions_tpi: undefined,
      };
    } else if (Object.keys(item).includes('scope_sub_regions')) {
      return {
        ...values,
        scope_sub_regions_tpi: undefined,
      };
    }
    return undefined;
  }

  return (
    <FromPageWrapper
      onChangeFormValues={onChangeForm}
      handleAfterUpdate={handleAfterUpdate}
      ignoreKeyUpdate={['password', 'region', 'sub_region']}
      ignoreKeyDuplicate={['password', 'email', 'region', 'sub_region']}
    >
      <Card className="card-content-wrapper">
        <Row>
          <Col xs={24} sm={8} md={8} lg={5} xl={4}>
            <FormBuilder
              columns={[
                {
                  title: 'PROFILE PICTURE',
                  fields: [
                    {
                      // rules: [{ required: true, message: 'Profile image is required!' }],
                      gridColumn: { span: 16 },
                      name: 'profile_image',
                      fieldType: 'uploadImage',
                      valuePropName: 'imageUrl',

                      uploadImageProps: {
                        notes: messageImage(),
                        imageSize: 0.1,
                        disabled: true,
                        errorSizeMessage: 'Maximum size is 100kB!',
                        imageType: ['image/jpeg', 'image/png'],
                        className: 'avatar-uploader',
                        requestUrl: ApiUrlData.user_upload,
                        requestFileKey: 'photo_profile',
                        transformResponseRequest(response) {
                          return response.path;
                        },
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
          <Col xs={24} sm={18} md={18} lg={19} xl={20}>
            <FormBuilder
              columns={[
                {
                  title: 'INDIVIDUAL INFORMATION',
                  fields: [
                    {
                      label: 'Name',
                      name: 'first_name',
                      fieldType: 'inputText',
                      rules: [{ required: true }],
                      inputTextProps: {
                        disabled: true,
                        allowClear: true,
                      },
                    },
                    {
                      label: 'Phone Number',
                      name: 'phone',
                      rules: [{ pattern: /^[0-9]*$/, message: 'Phone number is invalid!' }],
                      fieldType: 'inputText',
                      inputTextProps: {
                        disabled: true,
                        allowClear: true,
                      },
                    },

                    {
                      label: 'Email',
                      name: 'email',
                      fieldType: 'inputText',
                      rules: [{ type: 'email' }, { required: true }],
                      inputTextProps: {
                        disabled: true,
                        allowClear: true,
                      },
                    },
                    {
                      label: 'Password',
                      name: 'password',
                      fieldType: 'inputPassword',
                      rules: [
                        { required: isCreate, message: '' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const action = getFieldValue('form_action');
                            if (action === 'create' || action === 'duplicate') {
                              if (!value) return Promise.reject(new Error('Password is required!'));
                              else return Promise.resolve();
                            } else return Promise.resolve();
                          },
                        }),
                      ],
                      inputPasswordProps: {
                        placeholder: '',
                        disabled: true,
                        allowClear: true,
                        autoComplete: 'new-password',
                      },
                    },
                    {
                      label: 'Confirm Password',
                      name: 'retype_password',
                      fieldType: 'inputPassword',
                      dependencies: ['password'],
                      gridColumn: { xs: 24, sm: 12, md: 8, lg: 6, xl: 5 },
                      hasFeedback: true,
                      rules: [
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const password = getFieldValue('password');
                            if (!password) return Promise.resolve();
                            else if (password === value) return Promise.resolve();
                            else if (!value) return Promise.reject(new Error('Please confirm your password!'));
                            else return Promise.reject(new Error("Passwords doesn't match!"));
                          },
                        }),
                      ],
                      inputPasswordProps: {
                        placeholder: '',
                        disabled: true,
                        allowClear: true,
                        autoComplete: 'new-password',
                      },
                    },
                  ],
                },
                {
                  title: 'This user will be admin for:',
                  fields: [
                    {
                      label: 'Scope',
                      name: 'scope',
                      fieldType: 'select',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 12, md: 12, lg: 6, xl: 5 },
                      selectProps: {
                        options: RegionScopeOptions,
                        customLabel(value) {
                          return RegionScopeOptions.find((item) => item.value === value)?.label;
                        },
                      },
                    },
                    {
                      label: 'Region',
                      name: 'scope_regions',
                      fieldType: 'selectPaginate',
                      customHidden: hiddenRegion,
                      gridColumn: { xs: 24, sm: 12, md: 12, lg: 6, xl: 5 },
                      rules: [
                        ({ getFieldValue }) => ({
                          validator() {
                            const scope = getFieldValue('scope');
                            const region = getFieldValue('scope_regions') ?? [];
                            const sub_region = getFieldValue('scope_sub_regions') ?? [];
                            const sub_region_tpi = getFieldValue('scope_sub_regions_tpi') ?? [];

                            const isRequired =
                              scope === 'region' &&
                              region.length === 0 &&
                              sub_region.length === 0 &&
                              sub_region_tpi.length === 0;

                            console.log({ isRequired, region, sub_region, sub_region_tpi });
                            if (isRequired)
                              return Promise.reject(new Error('Choose region or local church or satellite!'));
                            else return Promise.resolve();
                          },
                        }),
                      ],
                      selectPaginateProps: {
                        isMulti: true,
                        keySearch: 'q',
                        customLabel: (value) => value.rc_dpw_name,
                        dataSourceUrl: ApiUrlData.region,
                        transformOptions(options = []) {
                          return options.map((item) => pick(item, ['id', 'rc_dpw_name']));
                        },
                      },
                    },
                    {
                      label: 'Subregion (Local Church)',
                      name: 'scope_sub_regions',
                      fieldType: 'selectPaginate',
                      customHidden: hiddenRegion,
                      shouldUpdate: true,
                      gridColumn: { xs: 24, sm: 12, md: 12, lg: 6, xl: 5 },
                      rules: [
                        ({ getFieldValue }) => ({
                          validator() {
                            const scope = getFieldValue('scope');
                            const region = getFieldValue('scope_regions') ?? [];
                            const sub_region = getFieldValue('scope_sub_regions') ?? [];
                            const sub_region_tpi = getFieldValue('scope_sub_regions_tpi') ?? [];

                            const isRequired =
                              scope === 'region' &&
                              region.length === 0 &&
                              sub_region.length === 0 &&
                              sub_region_tpi.length === 0;
                            if (isRequired)
                              return Promise.reject(new Error('Choose region or local church or satellite!'));
                            else return Promise.resolve();
                          },
                        }),
                      ],
                      selectPaginateProps: {
                        isMulti: true,
                        keySearch: 'q',
                        customLabel: (value) => value.church_name,
                        dataSourceUrl: ApiUrlData.sub_region,
                        transformOptions(options = []) {
                          return options.map((item) => {
                            return {
                              ...pick(item, ['id', 'church_name']),
                              rc_dpw_id: item?.region?.id,
                              church_local_id: item?.parent?.id,
                            };
                          });
                        },

                        customKey(form) {
                          const value = form.getFieldsValue();
                          return JSON.stringify(value);
                        },
                        customFilterRequest(value) {
                          const without_region_ids = value?.scope_regions?.map((item) => item.id);
                          return {
                            without_region_ids,
                            is_sub_region_tpi: '0',
                          };
                        },
                      },
                    },
                    {
                      label: 'Subregion (Satellite)',
                      name: 'scope_sub_regions_tpi',
                      fieldType: 'selectPaginate',
                      customHidden: hiddenRegion,
                      shouldUpdate: true,
                      gridColumn: { xs: 24, sm: 12, md: 12, lg: 6, xl: 5 },
                      rules: [
                        ({ getFieldValue }) => ({
                          validator() {
                            const scope = getFieldValue('scope');
                            const region = getFieldValue('scope_regions') ?? [];
                            const sub_region = getFieldValue('scope_sub_regions') ?? [];
                            const sub_region_tpi = getFieldValue('scope_sub_regions_tpi') ?? [];

                            const isRequired =
                              scope === 'region' &&
                              region.length === 0 &&
                              sub_region.length === 0 &&
                              sub_region_tpi.length === 0;
                            if (isRequired)
                              return Promise.reject(new Error('Choose region or local church or satellite!'));
                            else return Promise.resolve();
                          },
                        }),
                      ],
                      selectPaginateProps: {
                        isMulti: true,
                        keySearch: 'q',
                        customLabel: (value) => value.church_name,
                        dataSourceUrl: ApiUrlData.sub_region,
                        transformOptions(options = []) {
                          return options.map((item) => {
                            return {
                              ...pick(item, ['id', 'church_name']),
                              rc_dpw_id: item?.region?.id,
                              church_local_id: item?.parent?.id,
                            };
                          });
                        },
                        customKey(form) {
                          const value = form.getFieldsValue();
                          return JSON.stringify(value);
                        },
                        customFilterRequest(value) {
                          const without_parent_ids = makeArrayIds({ data: value?.scope_sub_regions });
                          const without_region_ids = makeArrayIds({ data: value?.scope_regions });
                          return {
                            without_parent_ids,
                            without_region_ids,
                            is_sub_region_tpi: '1',
                          };
                        },
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Card>
    </FromPageWrapper>
  );
}
