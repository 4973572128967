import moment from 'moment';

export const activityColumns = [
  {
    title: 'ID',
    dataIndex: 'activity_id',
    key: 'activity_id',
    sorter: true,
    width: 100,
  },
  {
    title: 'Region',
    dataIndex: ['region', 'rc_dpw_name'],
    key: 'region.rc_dpw_name',
    sorter: true,
    width: 150,
  },
  {
    title: 'Local Church & Satellite',
    dataIndex: ['sub_region', 'church_name'],
    key: 'sub_region.church_name',
    sorter: true,
    width: 200,
  },
  {
    title: 'Periods',
    dataIndex: 'period',
    key: 'month',
    sorter: true,
    width: 100,
    render(item, row) {
      if (!item) return '';
      else if (row?.submission_type === 'monthly') return moment(item).format('YYYY-MM');
      return `${moment(item).format('YYYY-MM-DD')} (Week ${moment(item).week()})`;
    },
  },
];
