import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { CategoryEntity } from '../../domain/entities';

import PageIndex from '../pages';
import { makeCategoryTransformer } from '../../domain/factories';

export function CategoryConfigurationFactory() {
  const transformer = makeCategoryTransformer<CategoryEntity>();
  const InitialValue: InitialValuePageProviderEntity<CategoryEntity> = {
    transformer,
    moduleKey: 'configuration-category-page',
    webUrl: WebUrlData.configuration_category,
    apiUrl: ApiUrlData.category,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
