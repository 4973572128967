import React, { useState } from 'react';
import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { CheckOutlined } from '@ant-design/icons';

import DataTable from './data-table';
import { Button, Card, Modal, Tooltip } from 'antd';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';
import { formBuilderProps } from '../helpers/options';
import { useRecoilState } from 'recoil';
import { notificationCountState } from '@main/layouts/auth-layout/navbar/new-notification';

export default function PageIndex(): JSX.Element {
  const dataSourceNotification = makeCommonDataSource({ apiUrl: ApiUrlData.notification });
  const [loadingMakeAllRead, setLoadingMakeAllRead] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [, setTotalNotification] = useRecoilState(notificationCountState);

  async function getTotalNotification() {
    await dataSourceNotification.handleCustomRequest({
      paramRequest: {
        baseURL: process.env.REACT_APP_BASE_URL,
        url: ApiUrlData.notification + '/total-notification',
        method: 'GET',
      },
      onSuccess({ response }: any) {
        const total = response.notification ?? 0;
        setTotalNotification(total);
      },
    });
  }
  function reloadContent() {
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 0);
  }
  async function handleMakeAllRead() {
    setLoadingMakeAllRead(true);
    await dataSourceNotification.handleCustomRequest({
      paramRequest: {
        baseURL: process.env.REACT_APP_BASE_URL,
        url: ApiUrlData.notification + '/mark-as-read',
        method: 'GET',
      },
      onSuccess() {
        setLoadingMakeAllRead(false);
        reloadContent();
        getTotalNotification();
      },
      onFailed() {
        setLoadingMakeAllRead(false);
        getTotalNotification();
      },
    });
  }

  return (
    <IndexPageWrapper
      showButtonAddNew={false}
      toolbarFixRightContent={
        <React.Fragment>
          <Tooltip placement="bottom" title="Make all read">
            <Button
              type="text"
              shape="circle"
              loading={loadingMakeAllRead}
              icon={<CheckOutlined size={17} />}
              onClick={() => {
                Modal.confirm({
                  title: 'Make all read confirmation',
                  content: 'Are you sure you want to set "read" to all data?',
                  onOk: handleMakeAllRead,
                });
              }}
              style={{ marginLeft: 10, marginRight: 40 }}
            >
              Make All Read
            </Button>
          </Tooltip>
          <div className="separator-content"></div>
        </React.Fragment>
      }
      drawerFilterProp={{
        formBuilderProps: formBuilderProps,
      }}
    >
      <Card className="card-content-wrapper">{showContent && <DataTable />}</Card>
    </IndexPageWrapper>
  );
}
