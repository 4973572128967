import React from 'react';
import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { SubCategoryTable } from '../components';
import { ApiUrlData } from '@base-configs';
import { StatusDataOptions } from '@base-helpers';
import { Card } from 'antd';

export default function PageIndex() {
  const columns: any = [
    {
      title: 'Data Category Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
      editable: true,
      width: 250,
    },
  ];

  return (
    <IndexPageWrapper
      showButtonBack={false}
      tableType="inline-form"
      title={'Data Category / Sub Category'}
      drawerFilterProp={{
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  name: 'status',
                  label: 'Status',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    options: StatusDataOptions,
                    customLabel(value) {
                      return StatusDataOptions.find((item) => item.value === value)?.label;
                    },
                  },
                },
                {
                  name: 'created_at',
                  label: 'Created At',
                  gridColumn: { span: 12 },
                  fieldType: 'dateRangePicker',
                },
                {
                  name: 'updated_at',
                  label: 'Last Updated At',
                  fieldType: 'dateRangePicker',
                  gridColumn: { span: 12 },
                },
                {
                  name: 'created_ids',
                  label: 'Created By',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    placeholder: 'select user',
                    dataSourceUrl: ApiUrlData.users,
                    customLabel: (value) => value?.first_name,
                  },
                },
                {
                  name: 'updated_ids',
                  label: 'Last Updated By',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    placeholder: 'select user',
                    dataSourceUrl: ApiUrlData.users,
                    customLabel: (value) => value?.first_name,
                  },
                },
              ],
            },
          ],
        },
      }}
      formBuilderConfig={{
        columns: [
          {
            fields: [
              {
                name: 'name',
                fieldType: 'inputText',
                rules: [{ required: true, message: 'Category name is required!' }],
              },
            ],
          },
        ],
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable
          columns={columns}
          useConfirmCancel={false}
          onRow={(record) => {
            return {
              onClick: () => undefined,
            };
          }}
          inlineFormFields={[
            {
              columnKey: 'name',
              name: 'name',
              fieldType: 'inputText',
              noStyle: true,
              rules: [{ required: true, message: 'Category name is required!' }],
            },
          ]}
          // rowAction={{
          //   showUpdate(row) {
          //     return !row.childs || row?.childs?.length === 0;
          //   },
          // }}
          expandable={{
            rowExpandable: (record) => {
              return !!record.id;
            },
            expandedRowRender(record) {
              return <SubCategoryTable category={record} />;
            },
          }}
        />
      </Card>
    </IndexPageWrapper>
  );
}
