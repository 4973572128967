import React from 'react';
import { useDataTable } from '@base-hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeCommonNavigation } from '@base-navigation';
import { stringPramsToObject } from '@base-helpers';
import { makeCommonDataSource, makeCommonTransformer } from '@base-factories';
import { IBaseDataSourceRepository, IBaseNavigationRepository, IBaseTransformerRepository } from '@base-repositories';
import {
  BaseEntity,
  PageActionEntity,
  PageProviderPropsEntity,
  PageProviderActionEntity,
  PageProviderDataEntity,
} from '@base-entities';

export const PageProviderActionContext = React.createContext<PageProviderActionEntity<any, any, any, any>>(null);
export const PageProviderDataContext = React.createContext<PageProviderDataEntity<any>>(null);

export function PageProvider<
  PayloadEntity extends BaseEntity = BaseEntity,
  IDataSource extends IBaseDataSourceRepository<PayloadEntity> = IBaseDataSourceRepository<PayloadEntity>,
  ITransformer extends IBaseTransformerRepository = IBaseTransformerRepository,
  INavigation extends IBaseNavigationRepository = IBaseNavigationRepository,
>(props: PageProviderPropsEntity<PayloadEntity, IDataSource, ITransformer, INavigation>): JSX.Element {
  const { initialValue, children } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const action: PageActionEntity = params?.action as any;
  const isCreate = action === 'create';
  const isUpdate = action === 'update';
  const isDetail = action === 'detail';
  const isDuplicate = action === 'duplicate';

  const search = location.search;
  const searchParams = stringPramsToObject(search);

  const [dataTable] = useDataTable(initialValue?.moduleKey);
  const showData = searchParams?.id ? dataTable.find((item) => item.id === searchParams?.id) : undefined;

  const commonTransformer = makeCommonTransformer<PayloadEntity>();
  const commonNavigation = makeCommonNavigation({ webUrl: initialValue?.webUrl ?? '', navigate });
  const commonDataSource = makeCommonDataSource<PayloadEntity>({
    apiUrl: initialValue?.apiUrl ?? '',
    baseUrl: initialValue?.baseApiUrl,
  });

  const PageProviderActionValue: PageProviderActionEntity = {
    transformer: initialValue?.transformer ?? commonTransformer,
    navigation: initialValue?.navigation ?? commonNavigation,
    dataSource: initialValue?.dataSource ?? commonDataSource,
  };

  const PageProviderDataValue: PageProviderDataEntity = {
    action,
    isCreate,
    isDetail,
    isUpdate,
    isDuplicate,
    id: searchParams?.id,
    showData,
    queryPrams: searchParams,
    apiUrl: initialValue?.apiUrl,
    webUrl: initialValue?.webUrl,
    baseApiUrl: initialValue?.baseApiUrl,
    moduleKey: initialValue?.moduleKey,
  };

  return (
    <React.Fragment>
      <PageProviderActionContext.Provider value={PageProviderActionValue}>
        <PageProviderDataContext.Provider value={PageProviderDataValue}>{children}</PageProviderDataContext.Provider>
      </PageProviderActionContext.Provider>
    </React.Fragment>
  );
}
