import React, { useEffect, useRef, useState } from 'react';
import { Modal, Table, TableProps, Tag } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import { useDataTableAction } from '@base-hooks';
import { RowAction, RowActionProps } from '../row-action-data-table';
import { FormFieldProps } from '../../form-components';
import { EditableForm } from './editable';
import { dateFormatter, stringFormatter } from '@base-helpers';
import { EnumColorStatusData, EnumStatusData } from '@base-entities';
import { omit } from 'lodash';

const defaultTitleConfirmSave = 'Save Confirmation';
const defaultTitleConfirmCancel = 'Delete Cancel';
const defaultTitleConfirmDelete = 'Delete Confirmation';
const defaultTitleConfirmActivate = 'Activate Confirmation';
const defaultTitleConfirmDeactivate = 'Deactivate Confirmation';

const defaultContentConfirmSave = 'Are you sure you want to save this?';
const defaultContentConfirmCancel = 'Are you sure you want to cancel this?';
const defaultContentConfirmDelete = 'Are you sure you want to delete this?';
const defaultContentConfirmActivate = 'Are you sure you want to activate this?';
const defaultContentConfirmDeactivate = 'Are you sure you want to deactivate this?';

type DefaultColumnType = 'status' | 'action' | 'created_at' | 'updated_at';

interface FormField extends FormFieldProps {
  columnKey: string;
}
export interface DataTableProps<Payload> extends TableProps<Payload> {
  rowAction?: RowActionProps;

  titleConfirmSave?: JSX.Element | React.ReactNode;
  titleConfirmCancel?: JSX.Element | React.ReactNode;
  titleConfirmDelete?: JSX.Element | React.ReactNode;
  titleConfirmActivate?: JSX.Element | React.ReactNode;
  titleConfirmDeactivate?: JSX.Element | React.ReactNode;

  contentConfirmSave?: JSX.Element | React.ReactNode;
  contentConfirmCancel?: JSX.Element | React.ReactNode;
  contentConfirmDelete?: JSX.Element | React.ReactNode;
  contentConfirmActivate?: JSX.Element | React.ReactNode;
  contentConfirmDeactivate?: JSX.Element | React.ReactNode;

  useRowSelection?: boolean;
  ignoreDefaultColumn?: DefaultColumnType[];
  userPointerCursor?: boolean;
  inlineFormFields?: FormField[];

  useConfirmSave?: boolean;
  useConfirmCancel?: boolean;
  useDirectDetailOnRowClick?: boolean;
  getRowSelection?(payload: any): void;
  scrollX?: string;
}

export enum SORTER_TYPE {
  'descend' = 'DESC',
  'ascend' = 'ASC',
}

export function InfinityDataTable<Payload = any>(props: DataTableProps<Payload>) {
  const {
    rowAction,

    contentConfirmSave = defaultContentConfirmSave,
    contentConfirmCancel = defaultContentConfirmCancel,
    contentConfirmDelete = defaultContentConfirmDelete,
    contentConfirmActivate = defaultContentConfirmActivate,
    contentConfirmDeactivate = defaultContentConfirmDeactivate,

    titleConfirmSave = defaultTitleConfirmSave,
    titleConfirmCancel = defaultTitleConfirmCancel,
    titleConfirmDelete = defaultTitleConfirmDelete,
    titleConfirmActivate = defaultTitleConfirmActivate,
    titleConfirmDeactivate = defaultTitleConfirmDeactivate,
    useRowSelection = true,
    ignoreDefaultColumn,
    userPointerCursor = true,
    inlineFormFields = [],
    useConfirmCancel = true,
    useConfirmSave = true,
    useDirectDetailOnRowClick = true,
    getRowSelection,
    scrollX = 'max-content',
  } = props;

  const {
    recordData,
    selectedData,
    setSelectedData,
    form,
    meta,
    filter,
    loading,
    tableType,
    formAction,
    isEditing,
    handleDelete,
    handleActivate,
    editingKey,
    handleDeactivate,
    handleGetDataIndex,
    handleUpdate,
    handleDetail,
    handleDuplicate,
    onCancelEditing,
    onConfirmSave,
  } = useDataTableAction();

  const [top, setTop] = useState(0);
  const y: any = props?.scroll?.y ?? 500;
  const [vt, setComponent] = useVT(() => {
    return {
      scroll: { y },
      onScroll: ({ isEnd, top }) => {
        if (isEnd) setTop(top);
      },
    };
  }, []);

  setComponent({
    body: {
      cell: EditableForm,
    },
  });

  function checkShowActivate(row) {
    if (rowAction?.showActivate) return rowAction?.showActivate(row);
    else if (row.status === EnumStatusData.inactive) return true;
    return false;
  }

  function checkShowDeactivate(row) {
    if (rowAction?.showDeactivate) return rowAction.showDeactivate(row);
    else if (row.status === EnumStatusData.active) return true;
    return false;
  }

  function makeColorStatus(status) {
    if (status === EnumStatusData.active) return EnumColorStatusData.active;
    else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
    return '';
  }

  const RowActionConfig: RowActionProps = {
    ...(rowAction ?? {}),
    handleUpdate,
    handleDuplicate,
    handleSave: async () => {
      try {
        await form.validateFields();
        if (!useConfirmSave) onConfirmSave();
        else {
          Modal.confirm({
            mask: true,
            okText: 'Confirm',
            title: titleConfirmSave,
            content: contentConfirmSave,
            onOk: () => onConfirmSave(),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCancel: () => {
      if (!useConfirmCancel) onCancelEditing();
      else {
        Modal.confirm({
          mask: true,
          okText: 'Confirm',
          title: titleConfirmCancel,
          content: contentConfirmCancel,
          onOk: () => onCancelEditing(),
        });
      }
    },
    handleDelete: (payload: any) => {
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: titleConfirmDelete,
        content: contentConfirmDelete,
        onOk: () => handleDelete(payload),
      });
    },
    handleActivate: (payload: any) => {
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: titleConfirmActivate,
        content: contentConfirmActivate,
        onOk: () => handleActivate(payload),
      });
    },
    handleDeactivate: (payload: any) => {
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: titleConfirmDeactivate,
        content: contentConfirmDeactivate,
        onOk: () => handleDeactivate(payload),
      });
    },
    showActivate: (row) => checkShowActivate(row),
    showDeactivate: (row) => checkShowDeactivate(row),
  };

  useEffect(() => {
    if (getRowSelection) getRowSelection(selectedData);
  }, [selectedData]);

  const columns = [...(props.columns ?? [])];
  const defaultColumn = [
    {
      title: 'Status',
      key: 'status',
      width: 100,
      sorter: true,
      dataIndex: 'status',
      render(item) {
        if (!item) return <div></div>;
        return <Tag color={makeColorStatus(item)}>{stringFormatter.capitalizeEachWord(item)}</Tag>;
      },
    },
    {
      title: 'Created',
      key: 'created_at',
      width: 120,
      dataIndex: 'created_at',
      ellipsis: true,
      sorter: true,
      render(item, row) {
        return `${dateFormatter(item).toLocal()} ${row.created_by ? ' by ' + row.created_by?.first_name : ''}`;
      },
    },
    {
      title: 'Updated',
      key: 'updated_at',
      width: 120,
      dataIndex: 'updated_at',
      ellipsis: true,
      sorter: true,
      render(item, row) {
        return `${dateFormatter(item).toLocal()} ${row.updated_by ? ' by ' + row.updated_by?.first_name : ''}`;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render(_, record: any) {
        if (tableType === 'normal') return <RowAction record={record} {...RowActionConfig} />;
        else {
          const config = {
            showSave: () => isEditing(record),
            showCancel: () => isEditing(record),
            showUpdate: () => (RowActionConfig.showUpdate ? RowActionConfig.showUpdate(record) : !isEditing(record)),
            showDuplicate: () =>
              RowActionConfig.showDuplicate ? RowActionConfig.showDuplicate(record) : !isEditing(record),
            showActivate: (row) => (isEditing(record) ? false : RowActionConfig.showActivate(row)),
            showDeactivate: (row) => (isEditing(record) ? false : RowActionConfig.showDeactivate(row)),
            showDelete: () => (RowActionConfig.showDelete ? RowActionConfig.showDelete(record) : !isEditing(record)),

            disableUpdate: () => (editingKey === '' ? false : !isEditing(record)),
            disableDuplicate: () => (editingKey === '' ? false : !isEditing(record)),
            disableActivate: () => (editingKey === '' ? false : !isEditing(record)),
            disableDeactivate: () => (editingKey === '' ? false : !isEditing(record)),
            disableDelete: () => (editingKey === '' ? false : !isEditing(record)),

            ...omit(RowActionConfig ?? {}, [
              'showSave',
              'showCancel',
              'showUpdate',
              'showDuplicate',
              'showActivate',
              'showDeactivate',
              'showDelete',
            ]),
          };

          return <RowAction record={record} {...config} />;
        }
      },
      fixed: 'right',
      width: 130,
    },
  ];

  useEffect(() => {
    if (top === 0) {
      handleGetDataIndex({ ...filter, page: 1 }, false);
    } else if (meta.currentPage < meta.totalPages) {
      handleGetDataIndex({ ...filter, page: filter.page + 1 }, true);
    }
  }, [top]);

  const rowSelection = {
    selectedRowKeys: selectedData?.map((item) => item.id),
    onChange: (_, selectedRowsPayload) => {
      const selectData = selectedRowsPayload?.filter((item) => item.id);
      const dataKeys = recordData.map((item) => item.id ?? item.uuid);
      const otherKeys = selectedData?.filter((item) => !dataKeys.includes(item.id)) ?? [];
      const newData = Array.from(new Set([...otherKeys, ...selectData]));
      setSelectedData(newData);
    },
    getCheckboxProps: (row) => {
      if (row.id) return;
      return {
        style: { display: 'none' },
      };
    },
  };

  const DataTableProps: any = props;

  function makeColumn() {
    const ignore = ignoreDefaultColumn ?? [];
    const defaultColumnFiler = defaultColumn.filter((item) => !ignore.includes(item.key as DefaultColumnType));
    const newColumn = [...columns, ...defaultColumnFiler];
    return newColumn;
  }

  const mergeColumn = makeColumn().map((col: any) => {
    let sortOrder;

    if (col.key && filter.order_by) {
      if (col.key === filter.order_by) {
        if (filter.order_type === 'ASC') sortOrder = 'ascend';
        else if (filter.order_type === 'DESC') sortOrder = 'descend';
      }
    }
    if (!col.editable) return { ...col, sortOrder };
    return {
      ...col,
      sortOrder,
      onCell: (record: any) => {
        return {
          form,
          record,
          columnFormKey: col.key,
          inlineFormFields,
          dataIndex: col.dataIndex,
          editing: isEditing(record),
        };
      },
    };
  });

  return (
    <Table
      bordered
      size="middle"
      dataSource={recordData}
      loading={loading}
      pagination={false}
      scroll={{ x: scrollX, y: y - 50 }}
      className={`table-master-style ${userPointerCursor ? 'pointer-row' : ''}`}
      rowSelection={useRowSelection ? rowSelection : undefined}
      onRow={(record) => {
        return {
          onClick: () => useDirectDetailOnRowClick && handleDetail(record),
        };
      }}
      footer={() => <div className="footer-table">{`Displaying ${recordData.length} out of ${meta.totalItems}`}</div>}
      onChange={({ current, pageSize }, _, sorter: any) => {
        const sorterValue = {
          order_by: sorter?.columnKey && SORTER_TYPE[sorter?.order] ? sorter?.columnKey : undefined,
          order_type: SORTER_TYPE[sorter?.order] ?? undefined,
        };
        handleGetDataIndex({ ...filter, ...sorterValue, page: 1 }, false);
      }}
      {...DataTableProps}
      components={vt}
      columns={mergeColumn}
    />
  );
}
