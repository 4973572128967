import * as _ from 'lodash';
import { atom, useRecoilState } from 'recoil';

interface SelectedDataTableEntity {
  [key: string]: any[];
}

const selectedDataTableRecoil = atom<SelectedDataTableEntity>({
  key: 'selectedDataTableRecoil',
  default: {},
});

export function useSelectedDataTable(key: string): any {
  const [selectedData, setSelectedData] = useRecoilState(selectedDataTableRecoil);
  const currentData = _.pick(selectedData, [key])[key] ?? [];
  function setCurrentData(data: any): void {
    setSelectedData((old: any) => ({ ...old, [key]: data }));
  }
  return [currentData, setCurrentData];
}
