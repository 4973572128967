import React from 'react';
import { Card, Col, Descriptions, Form, Row, Tag } from 'antd';
import { DetailBuilder, DetailPageWrapper, RenderTag, RenderText } from '@base-components';
import { IndicatoryStyleOptions, ScopeOptions } from '../helpers';
import { InputFormula, FormTask } from '../components/form';
import '../styles/index.less';
import { TestIndicator } from '../components/test-indicator';

export default function PageDetail(): JSX.Element {
  return (
    <DetailPageWrapper>
      <Card className="card-content-wrapper">
        <DetailBuilder
          title="GENERAL INFORMATION"
          columns={[
            {
              fields: [
                {
                  name: ['detail_data', 'name'],
                  label: 'Name',
                  fieldType: 'text',
                },
                {
                  name: ['detail_data', 'scope'],
                  label: 'Scope',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => ScopeOptions.find((item) => item.value === value)?.label,
                  },
                },
                {
                  name: ['detail_data', 'style'],
                  label: 'Indicator Style',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => IndicatoryStyleOptions.find((item) => item.value === value)?.label,
                  },
                },
                {
                  name: ['detail_data', 'default_indicator'],
                  label: 'Default Indicator',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => (value ? 'Yes' : 'No'),
                  },
                },
                {
                  name: ['detail_data', 'show_on_dashboard'],
                  label: 'Show On Dashboard',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => (value ? 'Yes' : 'No'),
                  },
                },
                {
                  name: ['detail_data', 'report_indicator'],
                  label: 'Report Indicator',
                  fieldType: 'text',
                  renderTextProps: {
                    renderValue: (value) => (value ? 'Yes' : 'No'),
                  },
                },
              ],
            },
          ]}
        />
      </Card>

      <div style={{ marginBottom: '20px' }}></div>
      <Card className="card-content-wrapper">
        <DetailBuilder
          title="FORMULA"
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 16 },
                  name: ['detail_data', 'data_count'],
                  label: 'Data to Count',
                  fieldType: 'tag',
                  renderTagProps: {
                    renderType: 'multi',
                    renderValue: (value) => {
                      if (value.type === 'sub_category') return `${value.value?.category_name} - ${value.value?.name}`;
                      return value.value?.name ?? value?.value?.split('_').join(' ').toUpperCase();
                    },
                  },
                },
              ],
            },
          ]}
        />
        <DetailBuilder
          columns={[
            {
              fields: [
                {
                  gridColumn: { span: 16 },
                  renderField() {
                    return (
                      <React.Fragment>
                        <Row gutter={12}>
                          <Col span={2} md={2}>
                            <div className="custom-label-stye">Color</div>
                          </Col>
                          <Col span={3} md={3}>
                            <div className="custom-label-stye">Label</div>
                          </Col>
                          <Col span={3} md={3}>
                            <div className="custom-label-stye">Score</div>
                          </Col>
                          <Col span={14} md={13} sm={13}>
                            <div className="custom-label-stye">Show</div>
                          </Col>
                          <Col span={2} md={1}>
                            <div className="custom-label-stye">Action</div>
                          </Col>
                        </Row>
                        <Form.List name={['detail_data', 'show']}>
                          {(fields) => (
                            <>
                              {fields.map(({ key, name, ...restField }, index) => (
                                <Row key={key} gutter={12}>
                                  <Col span={2} md={2}>
                                    <Form.Item {...restField} name={[name, 'color']} valuePropName="color">
                                      <Tag style={{ height: '20px', width: '100%' }}></Tag>
                                    </Form.Item>
                                  </Col>

                                  <Col span={3} md={3}>
                                    <Form.Item {...restField} name={[name, 'label']}>
                                      <RenderText />
                                    </Form.Item>
                                  </Col>

                                  <Col span={3} md={3}>
                                    <Form.Item {...restField} name={[name, 'score']}>
                                      <RenderText defaultRender={() => '-'} />
                                    </Form.Item>
                                  </Col>

                                  <Col span={14} md={13} sm={13}>
                                    <Form.Item {...restField} name={[name, 'formula']}>
                                      <RenderText styleItem={{ fontFamily: 'Courier' }} allowMarkupRender={false} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2} md={1} style={{ display: 'flex', alignItems: 'start' }}>
                                    <Form.Item {...restField} name={[name, 'task']}>
                                      <FormTask showAddTask={false} showDeleteTask={false} showEditTask={false} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </React.Fragment>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </Card>

      {/* <Form.Item>
        <Descriptions title="Example" bordered layout="vertical">
          <Descriptions.Item label="Indicator">
            <h4>Care Group</h4>
          </Descriptions.Item>
          <Descriptions.Item label="1st Month">
            <h4>100</h4>
          </Descriptions.Item>
          <Descriptions.Item label="2nd Month">
            <h4>200</h4>
          </Descriptions.Item>
        </Descriptions>
      </Form.Item>

      <Form.Item>
        <Card style={{ width: '300px' }}>
          <h4 className="metric-title">Care Group Growth Health</h4>
          <div className="metric-comparison">
            <div className="metric-comparison-item">
              <h5 className="metric-value">100</h5>
              <span className="metric-month">Feb 22</span>
            </div>
            <div className="metric-separator">:</div>
            <div className="metric-comparison-item">
              <h5 className="metric-value">200</h5>
              <span className="metric-month">March 22</span>
            </div>
          </div>
          <div className="metric-percentage">
            <Tag color="red">50%</Tag>
          </div>
        </Card>
      </Form.Item> */}

      {/* <div style={{ marginTop: '20px' }}></div>
      <Row>
        <div>
          <div className="custom-label-stye">Preview Formula</div>
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              const values = form.getFieldValue('detail_data');
              return <TestIndicator dataIndicator={values} />;
            }}
          </Form.Item>
        </div>
      </Row> */}
    </DetailPageWrapper>
  );
}
