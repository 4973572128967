import React from 'react';
import { PageProviderActionContext } from '@base-providers';
import { BaseEntity, PageProviderActionEntity } from '@base-entities';
import { IBaseDataSourceRepository, IBaseNavigationRepository, IBaseTransformerRepository } from '@base-repositories';

export function usePageProviderAction<
  PayloadEntity extends BaseEntity = BaseEntity,
  IDataSource extends IBaseDataSourceRepository<PayloadEntity> = IBaseDataSourceRepository<PayloadEntity>,
  ITransformer extends IBaseTransformerRepository = IBaseTransformerRepository,
  INavigation extends IBaseNavigationRepository = IBaseNavigationRepository,
>() {
  return React.useContext<PageProviderActionEntity<PayloadEntity, IDataSource, ITransformer, INavigation>>(
    PageProviderActionContext,
  );
}
