import { notification } from 'antd';

export function notificationSuccess(messages: string[]): void {
  messages.forEach((msg) => {
    notification.success({
      message: 'Success',
      description: msg,
    });
  });
}

export function notificationFailed(messages: string[]): void {
  messages.forEach((msg) => {
    notification.error({
      message: 'Failed',
      description: msg,
    });
  });
}
