import React from 'react';
import { Button, Card, Row, Col, Switch, Divider, Tooltip } from 'antd';
import { useFormGeneratorContent } from '../contexts/form-generator.context';
import { DeleteOutlined, CopyOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';

interface Props {
  itemField: any;
}
export function FormGeneratorField(props: Props) {
  const { itemField } = props;
  const { onDelete, onDuplicate, onUpdate, currentRecordEditing, setCurrentRecordEditing } = useFormGeneratorContent();

  function onEdit(record: any): void {
    setCurrentRecordEditing(record);
  }
  function onSaveEdit(record: any): void {
    setCurrentRecordEditing(undefined);
  }

  function disableAction(): boolean {
    if (!currentRecordEditing) return false;
    else if (currentRecordEditing.key === itemField.key) return false;
    else return true;
  }

  function hideSave(): boolean {
    if (!currentRecordEditing) return true;
    else if (currentRecordEditing.key === itemField.key) return false;
    else return true;
  }

  return (
    <Card className="form-rendering-item">
      <div className="form-rendering-item-action">
        <Row>
          <Col span={20}></Col>
          <Col span={1} hidden={!hideSave()}>
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ minHeight: '5px', padding: '5px', fontSize: '12px' }}
              title="Edit"
            >
              <Button
                type="text"
                disabled={disableAction()}
                onClick={() => onEdit(itemField)}
                icon={<EditOutlined style={{ fontSize: '12px' }} />}
              ></Button>
            </Tooltip>
          </Col>
          <Col span={1} hidden={hideSave()}>
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ minHeight: '5px', padding: '5px', fontSize: '12px' }}
              title="Save"
            >
              <Button
                type="text"
                onClick={() => onSaveEdit(itemField)}
                icon={<CheckOutlined style={{ fontSize: '12px' }} />}
              ></Button>
            </Tooltip>
          </Col>
          <Col span={1}>
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ minHeight: '5px', padding: '5px', fontSize: '12px' }}
              title="Duplicate"
            >
              <Button
                disabled={disableAction() || !hideSave()}
                type="text"
                onClick={() => onDuplicate(itemField)}
                icon={<CopyOutlined style={{ fontSize: '12px' }} />}
              ></Button>
            </Tooltip>
          </Col>
          <Col span={1}>
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ minHeight: '5px', padding: '5px', fontSize: '12px' }}
              title="Delete"
            >
              <Button
                disabled={disableAction() || !hideSave()}
                type="text"
                onClick={() => onDelete(itemField)}
                icon={<DeleteOutlined style={{ fontSize: '12px' }} />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="form-rendering-item-body">{itemField.label}</div>
    </Card>
  );
}
