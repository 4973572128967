import { atom, useRecoilState } from 'recoil';

const sidebarThemeLayoutRecoil = atom<'dark' | 'light'>({
  key: 'sidebarThemeLayoutRecoil',
  default: 'light',
});

export function useSidebarThemeLayout(): any {
  const [sidebarTheme, setSidebarTheme] = useRecoilState(sidebarThemeLayoutRecoil);
  return [sidebarTheme, setSidebarTheme];
}
