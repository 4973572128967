import React, { useState } from 'react';
import { Button, Card, Modal, Tooltip } from 'antd';
import { User, Users } from 'phosphor-react';
import { IndexPageWrapper, InfinityDataTable, RenderImage, RenderText } from '@base-components';
import { formBuilderProps, makeUrlImageUser, RegionScopeOptions } from '../helpers';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';

const columns: any = [
  {
    title: 'Name',
    key: 'first_name',
    width: 200,
    dataIndex: 'first_name',
    ellipsis: true,
    fixed: true,
    sorter: true,
    render(_, row): JSX.Element {
      const fullName = `${row?.first_name} ${row?.last_name ?? ' '}`;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RenderImage
            preview={false}
            width={20}
            height={20}
            fallbackType="user"
            imageUrl={row?.profile_image}
            makeImageUrl={(props) => makeUrlImageUser(props.imageUrl)}
            style={{ borderRadius: '100%', objectFit: 'cover', border: '1px solid #f0f0f0' }}
          />
          <div style={{ marginLeft: '10px' }}>{fullName}</div>
        </div>
      );
    },
  },
  {
    title: 'Email Address',
    key: 'email',
    width: 200,
    dataIndex: 'email',
    sorter: true,
    ellipsis: true,
  },
  {
    title: 'Scope',
    key: 'scope',
    width: 150,
    dataIndex: 'scope',
    sorter: true,
    render(value) {
      return RegionScopeOptions.find((item) => item.value === value)?.label;
    },
  },
  {
    title: 'Region',
    key: 'scope_regions',
    width: 200,
    dataIndex: ['scope_regions'],
    // sorter: true,
    render(value) {
      return (
        <RenderText
          renderType="multi"
          value={value}
          defaultRender={() => '-'}
          renderValue={(item) => item?.rc_dpw_name}
        />
      );
    },
  },
  {
    title: 'Local Church',
    key: 'scope_sub_regions',
    width: 200,
    dataIndex: ['scope_sub_regions'],
    // sorter: true,
    render(value) {
      return (
        <RenderText
          renderType="multi"
          value={value}
          defaultRender={() => '-'}
          renderValue={(item) => item?.church_name}
        />
      );
    },
  },
  {
    title: 'Satellite',
    key: 'scope_sub_regions_tpi',
    width: 200,
    dataIndex: ['scope_sub_regions_tpi'],
    // sorter: true,
    render(value) {
      return (
        <RenderText
          renderType="multi"
          value={value}
          defaultRender={() => '-'}
          renderValue={(item) => item?.church_name}
        />
      );
    },
  },
];

export default function PageIndex(): JSX.Element {
  const [showContentUser, setShowContentUser] = useState<boolean>(true);
  const synchronize = makeCommonDataSource({ apiUrl: ApiUrlData.synchron });

  const refreshUserData = (): void => {
    setShowContentUser(false);
    setTimeout(() => {
      setShowContentUser(true);
    }, 0);
  };

  const onClickSynchronizeAdminProcess = (): void => {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Synchronize User Admin Confirmation',
      content: 'Are you sure you want to synchronize ?',
      onOk: handleUserAdminSync,
    });
  };

  const onClickSynchronizeUserProcess = (): void => {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Synchronize User Confirmation',
      content: 'Are you sure you want to synchronize ?',
      onOk: handleUserSync,
    });
  };

  const handleUserAdminSync = async (): Promise<void> => {
    const payload = {
      ids: [],
      action: 'update',
      module: 'user_admin',
    };
    await synchronize.handleCreate(payload, {
      onSuccess: ({ response }: any): any => {
        console.log(response);
        refreshUserData();
      },
      onFailed: ({ message }: any): any => {
        console.log(message);
      },
    });
  };

  const handleUserSync = async (): Promise<void> => {
    const payload = {
      ids: [],
      action: 'update',
      module: 'user',
    };

    await synchronize.handleCreate(payload, {
      onSuccess: ({ response }: any): any => {
        console.log(response);
        refreshUserData();
      },
      onFailed: ({ message }: any): any => {
        console.log(message);
      },
    });
  };

  function UserAdminSyncToolbar(): JSX.Element {
    return (
      <>
        <Tooltip title="Sync Admin User">
          <Button
            type="text"
            icon={
              <span role="img" aria-label="arrow-left" className="anticon ">
                <User size={16} />
              </span>
            }
            style={{ marginLeft: 10, marginRight: 10 }}
            onClick={onClickSynchronizeAdminProcess}
          >
            Sync Admin User
          </Button>
        </Tooltip>
        <div className="separator-content"></div>

        <Tooltip title="Sync User">
          <Button
            type="text"
            icon={
              <span role="img" aria-label="arrow-left" className="anticon ">
                <Users size={16} />
              </span>
            }
            onClick={onClickSynchronizeUserProcess}
            style={{ marginLeft: '10px', marginRight: '25px' }}
          >
            Sync User
          </Button>
        </Tooltip>

        <div className="separator-content"></div>
      </>
    );
  }

  return (
    <IndexPageWrapper
      showButtonBack={false}
      showButtonAddNew={false}
      showBatchDeactivate={false}
      showBatchActivate={false}
      showBatchDelete={false}
      toolbarFixRightContent={<UserAdminSyncToolbar />}
      drawerFilterProp={{
        formBuilderProps,
      }}
    >
      <Card className="card-content-wrapper">
        {showContentUser && (
          <InfinityDataTable
            rowAction={{
              showActivate: () => false,
              showDeactivate: () => false,
              showDuplicate: () => false,
              showDelete: () => false,
            }}
            ignoreDefaultColumn={['action']}
            useRowSelection={false}
            columns={columns}
          />
        )}
      </Card>
    </IndexPageWrapper>
  );
}
