import { IBaseNavigationRepository } from '@base-repositories';
import { NavigateFunction, Location } from 'react-router-dom';
import { CommonNavigation } from './common.navigation';

interface CommonNavigationParams {
  webUrl: string;
  navigate: NavigateFunction;
}

export function makeCommonNavigation(props: CommonNavigationParams): IBaseNavigationRepository {
  const { webUrl, navigate } = props;
  return new CommonNavigation(webUrl, navigate);
}
