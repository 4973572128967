import React, { useEffect, useState } from 'react';
import { Form, Radio, Select, Table, Input } from 'antd';
import { RenderTag } from '@base-components';

enum PeriodSubmission {
  weekly = 'weekly',
  monthly = 'monthly',
}

enum TaskCreator {
  admin_global = 'admin global',
  admin_sub_region = 'admin sub region',
  admin_sub_region_tpi = 'admin sub region tpi',
}

const optionsTaskCreator = [
  { label: 'Admin Global', value: TaskCreator.admin_global },
  { label: 'Admin Church', value: TaskCreator.admin_sub_region },
  { label: 'Admin Church Satellite/TPI', value: TaskCreator.admin_sub_region_tpi },
];

interface Props {
  loading?: boolean;
  value?: any;
  onChange?: (data: any) => void;
  data: any;
  isShowInput?: boolean;
  form?: any;
}

export default function ConfigTable({ onChange, form, loading, data, isShowInput }: Props): JSX.Element {
  const [dataGeneral, setDataGeneral] = useState<any[]>([]);

  useEffect(() => {
    setDataGeneral(data?.general_config);
    onChangeValues(data?.general_config);
  }, [data]);

  function onChangeValues(payload: any): void {
    if (onChange) onChange(payload);
  }

  const renderActionValue = (record) => {
    if (isShowInput) {
      if (record.configuration === 'Email Sender') {
        form.setFieldsValue({
          email_sender: record.value,
        });
        return (
          <Form.Item
            name="email_sender"
            rules={[
              { required: true, message: 'Email sender in required.' },
              { type: 'email', message: 'Email sender is not a valid email.' },
            ]}
          >
            <Input placeholder="Enter valid email address" />
          </Form.Item>
        );
      }
      if (record.configuration === 'Period Submission') {
        form.setFieldsValue({
          periode_submission: record.value,
        });
        return (
          <Form.Item name="periode_submission" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value="weekly">{PeriodSubmission.weekly}</Radio>
              <Radio value="monthly">{PeriodSubmission.monthly}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      }
      if (record.configuration === 'Task Creator') {
        form.setFieldsValue({
          task_creator: record.value,
        });
        return (
          <Form.Item name="task_creator" rules={[{ required: true, message: 'Task creator in required.' }]}>
            <Select mode="multiple">
              {optionsTaskCreator.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      } else {
        form.setFieldsValue({
          gmail_token: record.value,
        });
        return (
          <Form.Item name="gmail_token" rules={[{ required: true, message: 'Gmail token in required.' }]}>
            <Input placeholder="enter email token" />
          </Form.Item>
        );
      }
    }

    if (record.configuration === 'Task Creator')
      return RenderTag({
        value: record.value,
        renderType: 'multi',
        renderValue: (value) => optionsTaskCreator.find((item) => item.value === value)?.label,
      });
    return record.value;
  };

  const columns = [
    {
      title: 'Configuration',
      dataIndex: 'configuration',
      key: 'configuration',
      // sorter: true,
      width: 300,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      // sorter: true,
      // width: 150,
      render: (_, record) => renderActionValue(record),
    },
  ];

  return (
    <Form form={form} layout="vertical" component={false}>
      <Table
        pagination={false}
        className="table-master-style "
        dataSource={dataGeneral}
        columns={columns}
        loading={loading}
      />
    </Form>
  );
}
