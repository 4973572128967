import React, { useEffect, useState } from 'react';

import { PageWrapper } from './page-wrapper';
import { PageHeader } from '../page-header';
import { PageWrapperActionEntity } from './page-wrapper.entity';
import { Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import { EnumStatusData } from '@base-entities';

export interface DetailPageWrapperProps extends PageWrapperActionEntity {
  getCurrentData?: (payload: any) => void;
}

export function DetailPageWrapper(props: DetailPageWrapperProps) {
  const {
    children,
    padding,
    showHeader = true,
    title,

    showButtonBack = true,
    showButtonReload = true,
    showButtonHistory = false,
    showButtonAddNew = true,
    showButtonMoreAction = false,
    showRightAction = true,
    showConfirmProcess = false,
    showUpdate = true,
    showDelete = true,
    showDuplicate = true,
    showPrint = false,
    showLog = false,
    showNote = false,
    showApproval = false,
    showActivate,
    showDeactivate,

    showButtonSave = false,
    forceRequest = false,
    onReload,

    showTagStatus = true,
    contentConfirmProcess,
    contentConfirmDelete,
    contentConfirmSave,

    titleConfirmProcess,
    titleConfirmDelete,
    titleConfirmSave,

    onConfirmProcess,
    onConfirmDelete,
    onConfirmActivate,
    onConfirmDeactivate,
    className = '',
    dataDummy,
    handleUpdate,
    handleDuplicate,
    handleAddNew,
    toolbarFixRightContent,
    onConfirmSave,
    getCurrentData,
  } = props;

  const [form] = Form.useForm();
  const [showContent, setShowContent] = useState(true);
  const { action, showData, id, moduleKey } = usePageProviderData();
  const { navigation, dataSource, transformer } = usePageProviderAction();
  const { transformerGetData } = transformer;

  const [rowData, setRowData] = useState(undefined);

  async function onReloadPage(): Promise<void> {
    setShowContent(false);
    if (onReload) await onReload();
    else await new Promise((resolve) => setTimeout(resolve, 1000));
    requestScheme();
    setShowContent(true);
  }

  function makeDataDetail(payload: any): void {
    const detailPayload = dataDummy ?? payload;
    form.setFieldsValue({ id, detail_data: detailPayload });
    if (getCurrentData) getCurrentData(detailPayload);
  }

  function transformerPayload(payload: any): void {
    let resultPayload = payload;
    if (transformerGetData) resultPayload = transformerGetData(payload);
    makeDataDetail(resultPayload);
    setRowData(resultPayload);
  }

  async function handleGetData(): Promise<void> {
    await dataSource.handleGetData(id, {
      onSuccess: ({ response }: any) => {
        transformerPayload(response);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  function requestScheme(): void {
    if ((forceRequest || !showData) && id) handleGetData();
    else if (showData) transformerPayload(showData);
    else if (dataDummy) transformerPayload(dataDummy);
    else form.setFieldsValue({ form_action: action });
  }

  useEffect(() => {
    requestScheme();
  }, [forceRequest, showData, id, dataDummy]);

  async function handleDelete(): Promise<void> {
    const payload = form.getFieldValue('detail_data');
    await dataSource.handleDelete(payload, {
      onSuccess: ({ response }: any) => {
        notificationSuccess([response]);
        navigation.goToIndex();
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleActivate(): Promise<void> {
    const payload = form.getFieldValue('detail_data');
    await dataSource.handleActivate(payload, {
      onSuccess: ({ response }: any) => {
        transformerPayload(response);
        notificationSuccess(['Successfully activate data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleDeactivate(): Promise<void> {
    const payload = form.getFieldValue('detail_data');
    await dataSource.handleDeactivate(payload, {
      onSuccess: ({ response }: any) => {
        transformerPayload(response);
        notificationSuccess(['Successfully deactivate data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleConfirmProcess(): Promise<void> {
    const payload = form.getFieldValue('detail_data');
    //TODO this logic for multi confirm process
    // await dataSource.handleMultiConfirmProcess([payload], {
    //   onSuccess: ({ response }: any) => {
    //     notificationSuccess(response.success);
    //     notificationFailed(response.failed);
    //   },
    //   onFailed: ({ message }: any) => {
    //     notificationFailed(message);
    //   },
    // });

    await dataSource.handleConfirmProcess(payload, {
      onSuccess: ({ response }: any) => {
        transformerPayload(response);
        notificationSuccess(['Successfully confirm process data.']);
      },
      onFailed: ({ message }: any) => {
        notificationFailed(message);
      },
    });
  }

  async function onDelete(): Promise<void> {
    if (onConfirmDelete) await onConfirmDelete();
    else await handleDelete();
  }
  async function onActivate(): Promise<void> {
    if (onConfirmActivate) await onConfirmActivate();
    else await handleActivate();
  }

  async function onDeactivate(): Promise<void> {
    if (onConfirmDeactivate) await onConfirmDeactivate();
    else await handleDeactivate();
  }

  async function onConfirmProcessAction(): Promise<void> {
    if (onConfirmProcess) await onConfirmProcess();
    else await handleConfirmProcess();
  }

  function customHandleAddNew(initData?: any, action?: 'create' | 'update' | 'duplicate') {
    if (handleAddNew) handleAddNew();
    else navigation.goToCreate();
  }

  function checkShowActivate(row): boolean {
    if (showActivate || showActivate === false) return showActivate;
    else if (row?.status === EnumStatusData.inactive) return true;
    return false;
  }

  function checkShowDeactivate(row): boolean {
    if (showDeactivate || showActivate === false) return showDeactivate;
    else if (row?.status === EnumStatusData.active) return true;
    return false;
  }

  return (
    <div className={className}>
      {showHeader && (
        <PageHeader
          rowData={rowData}
          onReload={() => onReloadPage()}
          title={title}
          showButtonBack={showButtonBack}
          showRightAction={showRightAction}
          showButtonAddNew={showButtonAddNew}
          showButtonMoreAction={showButtonMoreAction}
          showConfirmProcess={showConfirmProcess}
          showUpdate={showUpdate}
          showDelete={showDelete}
          showDuplicate={showDuplicate}
          showPrint={showPrint}
          showLog={showLog}
          showNote={showNote}
          showApproval={showApproval}
          showButtonSave={showButtonSave}
          contentConfirmProcess={contentConfirmProcess}
          contentConfirmDelete={contentConfirmDelete}
          contentConfirmSave={contentConfirmSave}
          titleConfirmProcess={titleConfirmProcess}
          titleConfirmDelete={titleConfirmDelete}
          titleConfirmSave={titleConfirmSave}
          onConfirmProcess={onConfirmProcessAction}
          onConfirmDelete={onDelete}
          showButtonHistory={showButtonHistory}
          showButtonReload={showButtonReload}
          handleUpdate={handleUpdate}
          handleDuplicate={handleDuplicate}
          showActivate={checkShowActivate(rowData)}
          showDeactivate={checkShowDeactivate(rowData)}
          onConfirmActivate={onActivate}
          onConfirmDeactivate={onDeactivate}
          handleAddNew={customHandleAddNew}
          showTagStatus={showTagStatus && rowData?.status}
          toolbarFixRightContent={toolbarFixRightContent}
          onConfirmSave={onConfirmSave}
        />
      )}
      <PageWrapper padding={padding} showHeader={showHeader}>
        <Spin spinning={!showContent} indicator={<LoadingOutlined />} style={{ minHeight: '50vh' }}>
          <Form layout="vertical" form={form} name={`form-detail-${moduleKey}`}>
            <Form.Item noStyle hidden name="id"></Form.Item>
            <Form.Item noStyle hidden name="detail_data"></Form.Item>
            {showContent && children}
          </Form>
        </Spin>
      </PageWrapper>
    </div>
  );
}
