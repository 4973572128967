import React, { useEffect, useState } from 'react';
import { UploadImageProps } from './entity';
import { Upload, Image, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { makeCommonDataSource } from '@base-factories';
import './style.less';
const defaultErrorTypeMessage = 'File should be an image!';
const defaultErrorSizeMessage = 'The image maximum size is ';

export function UploadImage(props: UploadImageProps) {
  const {
    image,
    imageUrl,
    onChange,
    transformPayloadRequest,
    transformResponseRequest,
    customValidateImage,
    uploadImageAction,
    imageType,
    imageSize,
    baseRequestUrl,
    requestUrl,
    requestFileKey = 'file',
    baseImageUrl = process.env.REACT_APP_BASE_ASSET_URL,
    style,
    className,
    dummyImage,
    errorSizeMessage,
    errorTypeMessage,
    getValidationMessage,
    resetValidationMessage,
    notes,
    showRemove = false,
    disabled = false,
  } = props;

  const dataSource = makeCommonDataSource<any>({
    apiUrl: requestUrl ?? '',
    baseUrl: baseRequestUrl,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [hasValidation, setHasValidation] = useState(false);

  function validationFeedback(message: string): void {
    setHasValidation(true);
    if (getValidationMessage) getValidationMessage(message);
    else setValidationMessage(message);
  }

  function beforeUpload(file) {
    setHasValidation(false);
    setValidationMessage(undefined);
    if (resetValidationMessage) resetValidationMessage(undefined);
    if (customValidateImage) return customValidateImage(file);
    let validTye = true;
    let validSize = true;
    if (imageType) validTye = imageType?.includes(file.type);
    if (imageSize) validSize = file.size / 1024 / 1024 < imageSize;

    if (!validTye) validationFeedback(errorTypeMessage ?? defaultErrorTypeMessage);
    else if (!validSize) validationFeedback(errorSizeMessage ?? defaultErrorSizeMessage + imageSize + 'MB!');

    return validTye && validSize;
  }

  async function uploadImage(options) {
    const { onSuccess, file } = options;
    let value = undefined;
    if (uploadImageAction) {
      setLoading(true);
      value = await uploadImageAction(options);
      setLoading(false);
    } else {
      setLoading(true);

      const formData = new FormData();
      formData.append(requestFileKey, file);
      const payload = transformPayloadRequest ? transformPayloadRequest(file) : formData;
      await dataSource.handleCreate(payload, {
        onSuccess: ({ response }: any) => {
          value = transformResponseRequest ? transformResponseRequest(response) : response;
          onSuccess('ok');
          setLoading(false);
        },
        onFailed: ({ message }: any) => {
          setLoading(false);
        },
      });
    }
    onChange(value);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function makeImage() {
    const previewUrl = `${baseImageUrl}${imageUrl}`;
    if (dummyImage) return dummyImage;
    else if (imageUrl) return previewUrl;
    else return image;
  }

  return (
    <div className="image-uploader">
      <Button
        hidden={!imageUrl || !showRemove}
        size="small"
        onClick={() => {
          if (onChange) onChange(null);
        }}
        style={{
          marginTop: '110px',
          position: 'absolute',
          zIndex: '10',
          width: '130px',
          color: 'white',
          background: 'rgba(242, 39, 46, 0.7)',
        }}
      >
        Remove
      </Button>

      <Upload
        customRequest={uploadImage}
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeUpload}
        style={style}
        className={className}
        disabled={disabled}
      >
        {imageUrl ? (
          <div style={{ padding: '5px' }}>
            <Image preview={false} src={makeImage()} alt="image" style={{ maxHeight: '120px', maxWidth: '120px' }} />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
      {/* <div className="note-message">{notes}</div> */}
      <div className="error-message">{validationMessage}</div>
    </div>
  );
}
