import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Card } from 'antd';
import { Warning } from 'phosphor-react';
import { usePageProviderAction } from '@base-hooks';
import { makeArrayIds } from '@base-helpers';
import './index.less';
import moment from 'moment';
interface Props {
  filters: any;
}
function errorMaps({ className, isError }): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
        height: '80vh',
        background: '#F0F0F0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="tex-center">
        {isError && (
          <React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
              <Warning size={50} color="black" weight="fill" />
            </div>
            <div>
              <p style={{ fontSize: '15px' }}>Failed to display maps, please check your connection!</p>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export function MapRendering(props: Props): JSX.Element {
  const { filters } = props;
  const { dataSource } = usePageProviderAction();
  const [markers, setMarkers] = useState([]);
  const [showMarker, setShowMarker] = useState(false);
  const [center, setCenter] = useState({
    lat: -6.9146766,
    lng: 107.5931527,
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ['places'],
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) return;
    setActiveMarker(marker.id);
    setCenter({ lat: marker.lat, lng: marker.lng });
  };

  async function handleGetDataIndex(filter?: any): Promise<void> {
    setActiveMarker(null);
    const newFilters = filter ?? {};

    const period = filter?.period;
    const submission_type = filter.period_type;

    let period_from = undefined;
    let period_to = undefined;

    if (period) {
      if (submission_type === 'monthly') {
        period_from = moment(period[0]).startOf('month').toISOString();
        period_to = moment(period[1]).endOf('month').toISOString();
      } else if (submission_type === 'weekly') {
        period_from = moment(period[0]).startOf('week').toISOString();
        period_to = moment(period[1]).endOf('week').toISOString();
      } else {
        period_from = moment(period[0]).toISOString();
        period_to = moment(period[1]).toISOString();
      }
    }

    if (newFilters.region_ids) {
      Object.assign(newFilters, { region_ids: makeArrayIds({ data: newFilters.region_ids }) });
    } else {
      Object.assign(newFilters, { region_ids: undefined });
    }

    if (newFilters.sub_region_ids) {
      Object.assign(newFilters, { sub_region_ids: makeArrayIds({ data: newFilters.sub_region_ids }) });
    } else {
      Object.assign(newFilters, { sub_region_ids: undefined });
    }

    Object.assign(newFilters, { period: [], period_from, period_to, period_type: submission_type });

    console.log({ newFilters });
    await dataSource.handleGetIndex({
      params: newFilters,
      onSuccess: ({ response }: any) => {
        const transformData = response.items.map((item) => {
          return {
            ...item,
            position: {
              lat: item?.position?.lat ? Number(item?.position?.lat) : 0,
              lng: item?.position?.lng ? Number(item?.position?.lng) : 0,
            },
          };
        });
        setMarkers(transformData);
      },
      onFailed: ({ message }: any) => {
        console.log({ message });
      },
    });
  }

  useEffect(() => {
    const newFilters = filters ?? {};
    handleGetDataIndex({ page: 1, limit: 0, ...newFilters });
  }, [filters]);

  function svgMarker(color = 'red') {
    return {
      path: 'M 10 0 C 5.160156 0 2.417969 4.171875 3.441406 8.601562 C 4.167969 11.757812 8.570312 20 10 20 C 11.335938 20 15.816406 11.757812 16.554688 8.601562 C 17.585938 4.214844 14.863281 0 10 0 Z M 10 9.136719 C 8.605469 9.136719 7.472656 8.003906 7.472656 6.609375 C 7.472656 5.210938 8.605469 4.082031 10 4.082031 C 11.398438 4.082031 12.527344 5.210938 12.527344 6.609375 C 12.527344 8.003906 11.398438 9.136719 10 9.136719 Z M 10 9.136719 ',
      fillColor: color,
      strokeColor: color,
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      size: 2,
      anchor: new google.maps.Point(15, 30),
    };
  }

  function reloadMarker() {
    setShowMarker(false);
    setTimeout(() => {
      setShowMarker(true);
    }, 0);
  }

  useEffect(() => {
    reloadMarker();
  }, [markers]);

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  if (loadError) return errorMaps({ className: '', isError: true });
  if (!isLoaded) return errorMaps({ className: '', isError: true });

  if (!showMarker) {
    return (
      <GoogleMap
        zoom={4}
        center={center}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: '100%', height: '80vh' }}
      />
    );
  }
  return (
    <div>
      <GoogleMap
        zoom={4}
        center={center}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: '100%', height: '80vh' }}
        onLoad={handleOnLoad}
      >
        {markers.map((marker) => (
          <Marker
            icon={svgMarker(marker.color)}
            key={marker.id}
            position={marker.position}
            onClick={() => handleActiveMarker(marker)}
          >
            {activeMarker === marker.id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <Card className="card-preview-map">
                  <div className="marker-information">
                    <div className="marker-header">
                      <div className="marker-title">{marker.name}</div>
                      <div className="marker-color" style={{ backgroundColor: marker.color }} />
                    </div>
                    {marker?.address && (
                      <div className="marker-body-information">
                        <p>{marker?.address}</p>
                      </div>
                    )}
                    <div>
                      <p>Health: {marker?.label ?? '-'}</p>
                      <p>Period: {marker?.week ?? marker?.month ?? '-'}</p>
                    </div>
                  </div>
                </Card>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
    </div>
  );
}
