export const ApiUrlData = {
  sales_basic_price: 'v1/pricings/sales/basic-price',
  sales_customer_group: 'v1/sales/customer-groups',

  dashboard: 'v1/dashboard',
  activity_activities: 'v1/activity',
  activity_upload: 'v1/activity/upload',
  activity_import_upload: 'v1/activity/import/upload',
  activity_summary: 'v1/activity/summary',
  category: 'v1/category',
  crm: 'v1/crm/task',
  crm_task: 'v1/crm/task',
  crm_task_kanban: 'v1/crm/task-kanban',
  crm_journal: 'v1/crm/task/jurnal',
  email_template: 'v1/email-template',
  delete_data: 'v1/delete-data',
  general_config: 'v1/general-config',
  headquarter: 'v1/headquarter',
  logs: 'v1/logs',
  map: 'v1/sub-region/map',
  metric_indicator: 'v1/metric-indicator',
  metric_indicator_example: 'v1/metric-indicator/example-formula',
  notification: 'v1/notification',
  region: 'v1/region',
  sub_region: 'v1/sub-region',
  synchron: 'v1/synchron/internal',
  user_login_token: 'v1/users/login-token',
  user_login: 'v1/users/login',
  user_upload: 'v1/users/upload',
  users: 'v1/users',
  workflow: 'v1/workflow',
};
