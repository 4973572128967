import React, { useState } from 'react';
import { FromPageWrapper, FormBuilder } from '@base-components';
import { Card } from 'antd';

export default function PageForm() {
  const [defaultEmail, setDefaultEmail] = useState(undefined);
  function getCurrentData(payload) {
    setDefaultEmail(payload?.email_body);
  }

  return (
    <FromPageWrapper getCurrentData={getCurrentData}>
      <Card className="card-content-wrapper">
        <FormBuilder
          columns={[
            {
              title: 'GENERAL INFORMATION',
              fields: [
                {
                  gridColumn: { xs: 24, sm: 12, md: 10, lg: 6, xl: 4 },
                  name: 'template_name',
                  fieldType: 'inputText',
                  label: 'Template Name',
                  rules: [{ required: true }],
                },
                {
                  name: 'email_subject',
                  label: 'Email Subject',
                  fieldType: 'inputText',
                  rules: [{ required: true }],
                  gridColumn: { xs: 24, sm: 12, md: 10, lg: 6, xl: 4 },
                },
              ],
            },
          ]}
        />
      </Card>
      <div style={{ marginBottom: 20 }}></div>
      <Card className="card-content-wrapper">
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  name: 'email_body',
                  label: 'Email Body',
                  fieldType: 'textEditorDraftWysiwyg',
                  rules: [{ required: true, message: 'Email body must not be empty!' }],
                  gridColumn: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                  textEditorDraftWysiwygProps: {
                    defaultHtmlValue: defaultEmail,
                    ignoreToolbarOptionsInline: ['strikethrough', 'monospace', 'superscript', 'subscript'],
                    ignoreToolbarOptions: [
                      'fontSize',
                      'fontFamily',
                      'colorPicker',
                      'link',
                      'embedded',
                      'emoji',
                      'image',
                      'remove',
                      'history',
                    ],
                  },
                },
                {
                  name: 'variable_list',
                  label: 'Variable List',
                  gridColumn: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                  renderField() {
                    return (
                      <React.Fragment>
                        <div>Variable List:</div>
                        <ul>
                          <li>{`Recipient name: {{recipient.name}}`}</li>
                        </ul>

                        <div>Activity:</div>
                        <ul>
                          <li>{`Month: {{activity.month}}`}</li>
                          <li> {`Week: {{activity.week}}`}</li>
                          <li> {`Scope: {{activity.scope}}`}</li>
                          <li> {`Region: {{activity.region}}`}</li>
                          <li> {`Sub region: {{activity.sub_region}}`}</li>
                          <li> {`Uploaded file: {{activity.uploaded_file}}`}</li>
                        </ul>
                        <div>Task:</div>
                        <ul>
                          <li> {`ID: {{task.id}}`}</li>
                          <li> {`Scope: {{task.scope}}`} </li>
                          <li> {`Summary: {{task.summary}}`} </li>
                          <li> {`Description: {{task.description}}`} </li>
                          <li> {`Reporter: {{task.reporter}}`} </li>
                          <li> {`Assignee: {{task.assignee}}`} </li>
                          <li> {`Recipient: {{task.recipient}}`} </li>
                          <li> {`Date plan: {{task.dateplan}}`} </li>
                          <li> {`Type: {{task.type}}`} </li>
                          <li> {`Region: {{task.region}}`} </li>
                          <li> {`Sub region: {{task.sub_region}}`} </li>
                        </ul>
                        <div>Indicator:</div>
                        <ul>
                          <li>{`Name: {{indicator.name}}`}</li>
                          <li>{`Description: {{indicator.description}}`}</li>
                          <li>{`Score: {{indicator.score}}`}</li>
                          <li>{`Label: {{indicator.label}}`}</li>
                          <li>{`Scope: {{indicator.scope}}`}</li>
                        </ul>
                      </React.Fragment>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </Card>
    </FromPageWrapper>
  );
}
