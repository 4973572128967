import { stringFormatter } from '@base-helpers';
import { Popover } from 'antd';
import { typeOf } from 'mathjs';
import React from 'react';
import { defaultRenderDetail, makeItemValue, makeValueForRender } from '../base-render';
import { RenderTextProps } from './render-text.entity';
import './render-text.less';

function DefaultRenderText({
  text,
  allowMarkupRender,
}: {
  text: string;
  allowMarkupRender?: boolean;
}): React.ReactNode {
  if (!allowMarkupRender) return text;
  else return <pre>{text}</pre>;
}

export function RenderText(props: RenderTextProps) {
  const {
    value,
    renderValue,
    withColon = true,
    keyValue = 'code',
    renderType = 'single',
    classNameItem = '',
    classNameWrapper = '',
    defaultRender = defaultRenderDetail,
    styleItem,
    styleWrapper,
    usePopover = false,
    popoverContent,
    limitString,

    allowMarkupRender = true,
  } = props;
  if (value === null || value === undefined) return <div>{defaultRender(value)}</div>;
  else if (typeOf(value) === 'Array' && value?.length == 0) return <div>{defaultRender(value)}</div>;

  const valueForRender = makeValueForRender(value);
  const singleValueForRender = valueForRender[0];

  function Rendering() {
    if (renderType === 'single') {
      return (
        <div className={`render-text-item ${classNameItem}`} style={styleItem}>
          {renderValue
            ? renderValue(singleValueForRender)
            : DefaultRenderText({
                allowMarkupRender,
                text: stringFormatter.stringLimiter(singleValueForRender, limitString),
              })}
        </div>
      );
    }
    return (
      <div className={`render-text-wrapper ${classNameWrapper}`} style={styleWrapper}>
        {valueForRender.map((item, idx) => {
          const itemValue = makeItemValue(item, keyValue);
          return (
            <div
              key={idx}
              className={`render-text-item ${withColon ? '' : 'render-text-item-gap'} ${classNameItem}`}
              style={styleItem}
            >
              <span>
                {renderValue
                  ? renderValue(item)
                  : DefaultRenderText({
                      allowMarkupRender,
                      text: stringFormatter.stringLimiter(itemValue, limitString),
                    })}
              </span>
              {idx !== valueForRender.length - 1 && withColon && <span className="render-text-colon">,</span>}
            </div>
          );
        })}
      </div>
    );
  }

  function makeContentPopover(value) {
    if (popoverContent) return popoverContent(value);
    return <div style={{ maxWidth: '200px', wordWrap: 'break-word' }}> {value}</div>;
  }

  if (usePopover) {
    return (
      <Popover content={makeContentPopover(value)} placement="bottomLeft">
        <div>
          <Rendering />
        </div>
      </Popover>
    );
  }
  return (
    <div className="render-text-component">
      <Rendering />
    </div>
  );
}
