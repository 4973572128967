import React from 'react';
import AppRoutes from '@main/routes/app-routes';
import { RecoilRoot } from 'recoil';
import { loginAccountState } from '@base-states';
import { authHelper } from '@base-helpers';

export default function App(): JSX.Element {
  return (
    <RecoilRoot initializeState={({ set }) => set(loginAccountState, { authState: authHelper })}>
      <AppRoutes />
    </RecoilRoot>
  );
}
