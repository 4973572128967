import moment from 'moment';
import _ from 'lodash';

import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';

export class NotificationTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerCreate(payload: E): E {
    return payload;
  }

  transformerFilterIndexTable(payload: any): any {
    const created_ids = payload?.created_ids?.map((data: any) => data?.id.toString());
    const updated_ids = payload?.updated_ids?.map((data: any) => data?.id.toString());
    const momentCreated = payload?.created_at?.map((data: any) => moment(data).toLocaleString()) ?? [];
    const momentUpdated = payload?.updated_at?.map((data: any) => moment(data).toLocaleString()) ?? [];

    const transformedPayload = {
      ...payload,
      created_ids,
      updated_ids,
      created_at: [],
      created_from: momentCreated[0],
      created_to: momentCreated[1],
      updated_at: [],
      updated_from: momentUpdated[0],
      updated_to: momentUpdated[1],
    };

    return transformedPayload;
  }
}
