import React, { useState } from 'react';
import { FormGeneratorSidebar } from './components/form-generator-sidebar';
import { Layout, Card } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormGeneratorProps } from './form-generator.entity';
import { v4 as uuidV4 } from 'uuid';
import './form-generator.less';
import { FormGeneratorField } from './components/form-generator-field';
import { FormGeneratorContext } from './contexts/form-generator.context';

const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
export function FormGenerator(props: FormGeneratorProps) {
  const { formData = [], onChange } = props;

  const [currentRecordEditing, setCurrentRecordEditing] = useState(undefined);

  function onChangeValue(value: any[]): void {
    if (onChange) onChange(value);
  }

  async function onDragEnd(result: any): Promise<void> {
    const destination: any = result.destination;
    const source: any = result.source;
    const itemField: any = JSON.parse(atob(result.draggableId));
    const newData = formData;
    if (formData.length === 0) {
      onChangeValue([{ ...itemField, key: uuidV4(), name: `${itemField.default_name}__1` }]);
    } else {
      let newItemField = itemField;
      if (destination.droppableId !== source.droppableId) {
        const sameName = newData
          .filter((item) => item.default_name === itemField.default_name)
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        const lastName = sameName[sameName.length - 1]?.name;
        const lastNameArr = lastName ? lastName?.split('__') : [];
        const lastIdName = lastNameArr[lastNameArr.length - 1] ?? 0;
        newItemField = {
          ...itemField,
          name: `${itemField.default_name}__${parseInt(lastIdName) + 1}`,
          key: uuidV4(),
        };
      }

      const filteredData = newData.filter((item) => item.key !== newItemField.key);
      const newFormData = insert(filteredData, destination.index, newItemField);
      onChangeValue(newFormData);
    }
  }

  function onDelete(record: any): void {
    const newData = formData.filter((item) => item.key !== record.key);
    onChangeValue(newData);
  }

  function onUpdate(record: any): void {
    const newData = formData.map((item) => {
      if (item.key !== record.key) return item;
      return record;
    });
    onChangeValue(newData);
  }

  function onDuplicate(record: any): void {
    const sameName = formData
      .filter((item) => item.default_name === record.default_name)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const lastName = sameName[sameName.length - 1]?.name;
    const lastNameArr = lastName ? lastName?.split('__') : [];
    const lastIdName = lastNameArr[lastNameArr.length - 1] ?? 0;
    const newRecord = {
      ...record,
      name: `${record.default_name}__${parseInt(lastIdName) + 1}`,
      key: uuidV4(),
    };
    const index = formData.findIndex((item) => item.key === record.key);
    const newData = insert(formData, index, newRecord);
    onChangeValue(newData);
  }

  return (
    <FormGeneratorContext.Provider
      value={{
        onDelete,
        onUpdate,
        onDuplicate,
        setCurrentRecordEditing,
        currentRecordEditing,
      }}
    >
      <div className="form-generator-component">
        <DragDropContext onDragEnd={onDragEnd}>
          <Layout hasSider>
            <Droppable droppableId="form-toolbox">
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <FormGeneratorSidebar />
                  </div>
                );
              }}
            </Droppable>
            <div className="form-rendering-wrapper">
              <Droppable droppableId="form-rendering">
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="form-rendering-droppable-area">
                      {formData.length === 0 && (
                        <div className="no-content-field">
                          <i>Drop field form here!</i>
                        </div>
                      )}
                      {formData.map((itemField, indexForm) => {
                        const icon: any = itemField.icon;

                        return (
                          <div key={indexForm}>
                            <Draggable
                              key={itemField.key}
                              draggableId={btoa(JSON.stringify(itemField))}
                              index={indexForm}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={provided.draggableProps.style}
                                  >
                                    <FormGeneratorField itemField={itemField} />
                                  </div>
                                );
                              }}
                            </Draggable>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </Layout>
        </DragDropContext>
      </div>
    </FormGeneratorContext.Provider>
  );
}
