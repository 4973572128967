import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  useCollapsibleLayout,
  useMediaQuery,
  userNavbarPosition,
  useShowDrawerMobileMenu,
  useShowMenuOnNavbar,
  useShowMobileMenu,
  useShowSidebarLayout,
} from '@base-hooks';

import { Layout } from 'antd';

import Sidebar from './sidebar';
import Navbar from './navbar';
import './styles/auth-layout.less';
import { useShowCollapsibleControlLayout } from 'base/presentation/hooks/hook-auth-layout/collapsible-control-layout.hook';
import { useLocation } from 'react-router-dom';

export default function AuthLayout({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useCollapsibleLayout();
  const [, setShowCollapsibleControl] = useShowCollapsibleControlLayout();
  const [, setShowMenuNavbar] = useShowMenuOnNavbar();
  const [, setShowMobileMenu] = useShowMobileMenu();
  const [, setShowDrawerMobileMenu] = useShowDrawerMobileMenu();

  const [showSidebar, setShowSideBar] = useShowSidebarLayout();
  const [navbarPosition] = userNavbarPosition();
  const isNavbarOutOfLayout = navbarPosition === 'outside_the_layout';

  const isMobileScreen = useMediaQuery({ media: 'mobile' });
  const isTabletScreen = useMediaQuery({ media: 'tablet' });
  const isDesktopScreen = useMediaQuery({ media: 'desktop' });
  const isLargeScreen = useMediaQuery({ media: 'large' });

  function makeClassNameLayout(): string {
    if (!showSidebar) return '';
    else if (collapsed) return 'margin-sidebar-collapsed';
    return 'margin-sidebar-not-collapsed';
  }

  useEffect(() => {
    setShowDrawerMobileMenu(false);

    if (isMobileScreen) {
      setShowSideBar(false);
      setShowMobileMenu(true);
      setShowMenuNavbar(false);
      setShowCollapsibleControl(false);
    } else {
      setShowSideBar(true);
      setShowMenuNavbar(true);
      setShowMobileMenu(false);
      setShowCollapsibleControl(true);
    }

    if (isMobileScreen || isTabletScreen) setCollapsed(true);
    else setCollapsed(collapsed);
  }, [isMobileScreen, isTabletScreen, isDesktopScreen, isLargeScreen]);

  useEffect(() => {
    setShowDrawerMobileMenu(false);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <React.Fragment>
      <Outlet />
      <Layout hasSider>
        {isNavbarOutOfLayout && <Navbar />}
        {showSidebar && <Sidebar />}
        <Layout className={`${makeClassNameLayout()} custom-layout-background`}>
          {!isNavbarOutOfLayout && <Navbar />}
          <div className="auth-layout-content-wrapper">{children}</div>
        </Layout>
      </Layout>
    </React.Fragment>
  );
}
