import { NavigateFunction } from 'react-router-dom';
import { IBaseNavigationRepository } from '@base-repositories';
import { BaseNavigationEntity, RouteEntity } from '@base-entities';
import { historyRoute, makeQueryParam } from '@base-helpers';

export abstract class BaseNavigation implements IBaseNavigationRepository {
  public actionDetailPage: string[] = ['detail'];
  public actionFromPage: string[] = ['create', 'update', 'duplicate'];

  constructor(public webUrl: string, public navigate: NavigateFunction) {}

  protected makeQuerySearch(query: any): string {
    const queryParams = makeQueryParam(query);
    if (queryParams.length === 1) return '';
    return queryParams;
  }

  goBack(currentRoute: RouteEntity): void {
    const history = historyRoute.popHistoryRoute(2, false)[0];
    const pathHistory = history?.path ?? '';
    const basePathHistory = history?.basePath ?? '';

    const pathHistoryArr = pathHistory.split('/');
    const lastPathHistory = pathHistoryArr[pathHistoryArr.length - 1];
    const isHistoryFormPage = this.actionFromPage.includes(lastPathHistory);

    const path = currentRoute.path;
    const pathArr = path.split('/');
    const lastPath = pathArr[pathArr.length - 1];
    const isDetailPage = this.actionDetailPage.includes(lastPath);
    const basePath = currentRoute?.basePath ?? '';

    const isIdenticPath = basePath === basePathHistory;

    if (!history) this.navigate('/');
    else if (isHistoryFormPage && isDetailPage && isIdenticPath) this.goToIndex();
    else this.navigate(-1);
  }

  goForward(currentRoute: RouteEntity): void {
    this.navigate(+1);
  }

  goToIndex(params?: BaseNavigationEntity): void {
    const search = params?.search ?? {};
    this.navigate(this.webUrl + this.makeQuerySearch(search));
  }

  goToDetail(params: BaseNavigationEntity): void {
    const id = params?.id;
    const search = params?.search ?? {};
    this.navigate(`${this.webUrl}/detail${this.makeQuerySearch({ id, ...search })}`);
  }

  goToCreate(params?: BaseNavigationEntity): void {
    const search = params?.search ?? {};
    this.navigate(`${this.webUrl}/create${this.makeQuerySearch(search)}`);
  }

  goToUpdate(params: BaseNavigationEntity): void {
    const id = params?.id;
    const search = params?.search ?? {};
    this.navigate(`${this.webUrl}/update${this.makeQuerySearch({ id, ...search })}`);
  }

  goToDuplicate(params: BaseNavigationEntity): void {
    const id = params?.id;
    const search = params?.search ?? {};
    this.navigate(`${this.webUrl}/duplicate${this.makeQuerySearch({ id, ...search })}`);
  }
}
