import React, { useEffect } from 'react';
import { BoardEntity } from '../../entities';
import './column-board.less';
import Card from '../card';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import * as _ from 'lodash';
import { useBoardContextValue } from '../../context';
import { makeCommonDataSource } from '@base-factories';

interface Props {
  boardColumns: BoardEntity[];
  groupItem: any;
  cardItem: any[];
}

export default function index(props: Props): JSX.Element {
  const { boardColumns = [], groupItem, cardItem = [] } = props;
  const { setDataDrawer, onShowDrawer, dataOnDrawer, setLoadingGetDetail } = useBoardContextValue();

  function setLoading(value) {
    if (setLoadingGetDetail) setLoadingGetDetail(value);
  }
  const dataSourceTask = makeCommonDataSource({ apiUrl: 'v1/crm/task' });

  async function showDetailCard(id) {
    setLoading(true);
    await dataSourceTask.handleGetData(id, {
      onSuccess: ({ response }: any): any => {
        setDataDrawer(response);
        setLoading(false);
      },
      onFailed: ({ message }: any): any => {
        setDataDrawer(undefined);
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (dataOnDrawer) {
      cardItem.forEach((item) => {
        if (item.id === dataOnDrawer.id) {
          showDetailCard(dataOnDrawer.id);
        }
      });
    }
  }, [cardItem]);
  return (
    <div className="column-board">
      {boardColumns.map((itemBoardColumn, indexBoardColumn) => {
        return (
          <div key={itemBoardColumn.id ?? indexBoardColumn} className="column-board-wrapper">
            <div className="separator-column"></div>
            <Droppable
              droppableId={btoa(JSON.stringify({ itemBoardColumn, groupItem: _.omit(groupItem, 'cards') }))}
              key={indexBoardColumn}
            >
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={`column-board-item-wrapper `}>
                    <div className={`column-board-item ${snapshot.isDraggingOver ? 'column-board-dragging-over' : ''}`}>
                      {cardItem.map((cardItem, indexCard) => {
                        if (cardItem.board_column_id === itemBoardColumn.id) {
                          return (
                            <div className="card-item" key={cardItem.id ?? indexCard}>
                              <Draggable
                                key={cardItem.id}
                                draggableId={btoa(JSON.stringify(cardItem))}
                                index={indexCard}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={`card-item-wrapper `}
                                      style={provided.draggableProps.style}
                                      onClick={() => {
                                        onShowDrawer();
                                        showDetailCard(cardItem.id);
                                        // onShowCard(dataOnDrawer, groupItem);
                                      }}
                                    >
                                      <Card cardItem={cardItem} groupItem={groupItem} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      })}
    </div>
  );
}
