import { atom, useRecoilState } from 'recoil';

const drawerMobileMenuRecoil = atom<boolean>({
  key: 'drawerMobileMenuRecoil',
  default: false,
});

export function useShowDrawerMobileMenu(): any {
  const [showDrawerMobileMenu, setShowDrawerMobileMenu] = useRecoilState(drawerMobileMenuRecoil);
  return [showDrawerMobileMenu, setShowDrawerMobileMenu];
}
