import React from 'react';
import { ApiUrlData } from '@base-configs';
import { RenderText } from '@base-components';

import { IndicatoryStyleOptions, ScopeOptions, YesOrNoOptions } from '../helpers';

export const databaseDeletioncolumns: any = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    key: 'name',
    sorter: true,
    render(value: any): JSX.Element {
      return <RenderText value={value} limitString={50} usePopover />;
    },
  },
  {
    title: 'Indicator Style',
    dataIndex: 'style',
    key: 'style',
    sorter: true,
    width: 150,
    render(value: any): string {
      return IndicatoryStyleOptions.find((item) => item.value === value)?.label;
    },
  },
  {
    title: 'Default Indicator',
    dataIndex: 'default_indicator',
    key: 'default_indicator',
    sorter: true,
    width: 150,
    render(value: any): string {
      return value ? 'Yes' : 'No';
    },
  },
  {
    title: 'Activity Scope',
    dataIndex: 'scope',
    key: 'scope',
    sorter: true,
    width: 150,
    render(value: any): string {
      return ScopeOptions.find((item) => item.value === value)?.label;
    },
  },
  {
    title: 'Show on Dashboard',
    dataIndex: 'show_on_dashboard',
    key: 'show_on_dashboard',
    sorter: true,
    width: 150,
    render(value: any): string {
      return value ? 'Yes' : 'No';
    },
  },
];

export const formBuilderProps: any = {
  columns: [
    {
      fields: [
        {
          name: 'styles',
          label: 'Indicator Style',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            isMulti: true,
            options: IndicatoryStyleOptions,
            customLabel(value: any): string {
              return IndicatoryStyleOptions.find((item) => item.value === value)?.label;
            },
          },
        },
        {
          name: 'default_indicator',
          label: 'Default Indicator',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            options: YesOrNoOptions,
            customLabel(value: any): string {
              return YesOrNoOptions.find((item) => item.value === value)?.label;
            },
          },
        },
        {
          name: 'scopes',
          label: 'Scope',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            isMulti: true,
            options: ScopeOptions,
            customLabel(value: any): string {
              return ScopeOptions.find((item) => item.value === value)?.label;
            },
          },
        },
        {
          name: 'show_on_dashboard',
          label: 'Show On Dashboard',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            options: YesOrNoOptions,
            customLabel(value: any): string {
              return YesOrNoOptions.find((item) => item.value === value)?.label;
            },
          },
        },
        {
          name: 'status',
          label: 'Status',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            placeholder: 'Status',
            options: [
              { label: 'Active', value: 'Active' },
              { label: 'Inactive', value: 'Inactive' },
            ],
          },
        },
        {
          name: 'created_at',
          label: 'Created At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'updated_at',
          label: 'Last Updated At',
          fieldType: 'dateRangePicker',
          gridColumn: { span: 12 },
        },
        {
          name: 'created_ids',
          label: 'Created By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            placeholder: 'select user',
            dataSourceUrl: ApiUrlData.users,
            customLabel: (value: any): string => value?.first_name,
          },
        },
        {
          name: 'updated_ids',
          label: 'Last Updated By',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            placeholder: 'select user',
            dataSourceUrl: ApiUrlData.users,
            customLabel: (value: any): string => value?.first_name,
          },
        },
      ],
    },
  ],
};
