import React from 'react';
import { Form } from 'antd';

import { FormBuilder } from '@base-components';
import { ApiUrlData } from '@base-configs';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

enum Address {
  global_hq = 'global_hq',
  sub_region = 'sub_region',
  other = 'other',
}

enum CategoryOptions {
  send_email = 'send_email',
  meeting = 'meeting',
  training = 'training',
  call = 'call',
  other = 'other',
}

enum Scope {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

enum SendMethod {
  automatic = 'automatic',
  manual = 'manual',
}

enum Size {
  huddle = 'huddle',
  town_hall = 'town_hall',
}

enum VenueType {
  online = 'online',
  on_site = 'on_site',
}

const AddressOptions = [
  { label: 'Global HQ', value: Address.global_hq },
  { label: 'Church', value: Address.sub_region },
  { label: 'Other', value: Address.other },
];

const ScopeOptions = [
  { label: 'Global', value: Scope.global },
  { label: 'Region', value: Scope.region },
  { label: 'Church', value: Scope.sub_region },
];

const SendMethodOptions = [
  { label: 'Automatic', value: SendMethod.automatic },
  { label: 'Manual', value: SendMethod.manual },
];

const SizeOptions = [
  { label: 'Huddle', value: Size.huddle },
  { label: 'Town Hall', value: Size.town_hall },
];

const TaskCategoryOptions = [
  { label: 'Send Email', value: CategoryOptions.send_email },
  { label: 'Meeting', value: CategoryOptions.meeting },
  { label: 'Training', value: CategoryOptions.training },
  { label: 'Call', value: CategoryOptions.call },
  { label: 'Other', value: CategoryOptions.other },
];

const VenueTypeOptions = [
  { label: 'Online', value: VenueType.online },
  { label: 'Onsite', value: VenueType.on_site },
];

export default function FormTaskDrawer(): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const scope_ls = authUser?.scope;

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          return (
            <FormBuilder
              className="drawer-form-general"
              columns={[
                {
                  fields: [
                    {
                      name: 'task_category',
                      label: 'Task Category',
                      fieldType: 'select',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      selectProps: {
                        options: TaskCategoryOptions,
                        customLabel(value) {
                          return TaskCategoryOptions.find((item) => value === item.value)?.label;
                        },
                      },
                    },
                    {
                      name: 'summary',
                      label: 'Subject',
                      fieldType: 'inputText',
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      inputTextProps: {
                        placeholder: 'Enter your subject',
                      },
                    },
                    {
                      name: 'scope',
                      label: 'Scope',
                      fieldType: 'select',
                      rules: [{ required: true }],
                      gridColumn: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 },
                      selectProps: {
                        isDisabled: true,
                        options: ScopeOptions,
                        customLabel(value) {
                          return ScopeOptions.find((item) => value === item.value)?.label;
                        },
                      },
                    },
                    {
                      name: 'description',
                      label: 'Message Body',
                      fieldType: 'textArea',
                      gridColumn: { span: 24 },
                      textAreaProps: {
                        placeholder: 'Enter your message body here',
                      },
                    },
                  ],
                },
              ]}
            />
          );
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const value = getFieldsValue();
          const address = value?.address;
          const taskCategory = value?.task_category;
          const venueType = value?.venue_type;

          return (
            <>
              <FormBuilder
                className="drawer-form-general"
                columns={[
                  {
                    fields: [
                      {
                        name: 'send_method',
                        label: 'Send Method',
                        fieldType: 'select',
                        // customHidden() {
                        //   return scope_ls !== 'global';
                        // },
                        rules: [{ required: taskCategory === 'send_email' && scope_ls === 'global' }],
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectProps: {
                          placeholder: 'Choose One',
                          defaultValue: 'automatic',
                          options: SendMethodOptions,
                          customLabel(value) {
                            return SendMethodOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                      {
                        name: 'size',
                        label: 'Size',
                        fieldType: 'select',
                        style: { display: taskCategory !== 'meeting' && 'none' },
                        gridColumn: { span: taskCategory === 'meeting' ? 8 : 0 },
                        selectProps: {
                          placeholder: 'Choose One',
                          options: SizeOptions,
                          customLabel(value) {
                            return SizeOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                      {
                        name: 'participant',
                        label: 'Participant',
                        fieldType: 'selectPaginate',
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 8 : 0 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => row?.first_name,
                        },
                      },
                      {
                        name: 'email_template',
                        label: 'Email Template',
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one',
                          dataSourceUrl: ApiUrlData.email_template,
                          customLabel: (row) => row?.template_name,
                          customFilterRequest: (payload) => {
                            return {
                              is_droplist: true,
                            };
                          },
                        },
                      },
                      {
                        name: 'date_plan',
                        label: 'Date Plan',
                        fieldType: 'datePicker',
                        style: { display: !taskCategory ? 'none' : 'block' },
                        gridColumn: { span: 8 },
                        datePickerProps: {
                          showTime: true,
                        },
                      },
                      {
                        name: 'cc',
                        label: 'Cc',
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => row?.first_name,
                        },
                      },
                      {
                        name: 'bcc',
                        label: 'Bcc',
                        fieldType: 'selectPaginate',
                        style: { display: taskCategory !== 'send_email' && 'none' },
                        gridColumn: { span: taskCategory !== 'send_email' ? 0 : 8 },
                        selectPaginateProps: {
                          placeholder: 'Choose one or more',
                          isMulti: true,
                          dataSourceUrl: ApiUrlData.users,
                          customLabel: (row) => row?.first_name,
                        },
                      },
                      {
                        name: 'venue_type',
                        label: 'Venue Type',
                        fieldType: 'select',
                        style: {
                          display: taskCategory === 'meeting' || taskCategory === 'training' ? 'block' : 'none',
                        },
                        gridColumn: { span: taskCategory === 'meeting' || taskCategory === 'training' ? 8 : 0 },
                        selectProps: {
                          placeholder: 'Choose one',
                          options: VenueTypeOptions,

                          customLabel(value) {
                            return VenueTypeOptions.find((item) => value === item.value)?.label;
                          },
                        },
                      },
                    ],
                  },
                ]}
              />

              {taskCategory && (
                <FormBuilder
                  title={venueType === 'online' || venueType === 'on_site' ? 'Venue Type' : ''}
                  columns={[
                    {
                      fields: [
                        {
                          name: 'link',
                          label: 'Link',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Link',
                          },
                        },
                        {
                          name: 'meeting_id',
                          label: 'Meeting ID',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Meeting ID',
                          },
                        },
                        {
                          name: 'password',
                          label: 'Password',
                          fieldType: 'inputText',
                          style: { display: venueType === 'online' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'online' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Password',
                          },
                        },
                        {
                          name: 'address',
                          label: 'Address',
                          fieldType: 'select',
                          style: { display: venueType === 'on_site' ? 'block' : 'none' },
                          gridColumn: { span: venueType === 'on_site' ? 8 : 0 },
                          selectProps: {
                            placeholder: 'Choose One',
                            options: AddressOptions,
                            customLabel(value) {
                              return AddressOptions.find((item) => value === item.value)?.label;
                            },
                          },
                        },
                        {
                          name: 'sub_region',
                          label: 'Church Location',
                          fieldType: 'selectPaginate',
                          gridColumn: { span: venueType === 'on_site' && address === 'sub_region' ? 8 : 0 },
                          selectPaginateProps: {
                            placeholder: 'Choose One',
                            dataSourceUrl: ApiUrlData.sub_region,
                            customLabel: (row) => row?.church_address,
                            transformOptions(options) {
                              if (!options) return [];
                              return options.map((item) => {
                                return {
                                  ...item,
                                  id: String(item.id),
                                };
                              });
                            },
                          },
                        },
                        {
                          name: 'location',
                          label: 'Other Location',
                          fieldType: 'inputText',
                          gridColumn: { span: venueType === 'on_site' && address === 'other' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Location',
                          },
                        },
                        {
                          name: 'google_map_link',
                          label: 'Google Map Link',
                          fieldType: 'inputText',
                          gridColumn: { span: venueType === 'on_site' && address === 'other' ? 8 : 0 },
                          inputTextProps: {
                            placeholder: 'Input Link',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
}
