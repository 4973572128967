import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Table } from 'antd';

interface Props {
  loading: boolean;
  value?: any;
  onChange?: (data: any) => void;
}
export default function TableFormat({ onChange, loading, value }: Props): JSX.Element {
  const [form] = Form.useForm();
  const [dataActivity, setDataActivity] = useState<any[]>([]);

  useEffect(() => {
    setDataActivity(value);
  }, [value]);

  function onChangeValues(payload): void {
    if (onChange) onChange(payload);
  }

  function updateData({ key, value, row }: { key: string; value: any; row: any }) {
    if (row?.keyParent) {
      const parentData = dataActivity.find((data) => data.key === row.keyParent);
      const newParentData = {
        ...parentData,
        children: parentData.children.map((child) => {
          if (child.key !== row.key) return child;
          return {
            ...child,
            [key]: value,
          };
        }),
      };

      const newData = dataActivity.map((data) => {
        if (data.key !== parentData.key) return data;

        return newParentData;
      });

      onChangeValues(newData);
    } else {
      const newData = dataActivity?.map((item) => {
        if (item.key !== row.key) return item;
        return {
          ...item,
          [key]: value,
        };
      });

      onChangeValues(newData);
    }
  }

  const column: any = [
    {
      dataIndex: 'category_name',
      key: 'category_name',
      width: 250,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      editable: true,
      width: 200,
      render: (_, row) => {
        if (!row.hasOwnProperty('children'))
          return (
            <InputNumber
              placeholder="Value"
              defaultValue={row.value}
              onBlur={(evt) => updateData({ key: 'value', row, value: evt.target.value })}
              style={{ width: '100%' }}
            />
          );
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      editable: true,
      render: (_, row) => {
        if (!row.hasOwnProperty('children'))
          return (
            <Input
              placeholder="Note"
              defaultValue={row.note}
              onBlur={(evt) => updateData({ key: 'note', row, value: evt.target.value })}
            />
          );
      },
    },
  ];

  return (
    <Form form={form} component={false}>
      <Table
        className="inline-form-table table-master-style"
        style={{ marginTop: 20 }}
        columns={column}
        dataSource={dataActivity}
        loading={loading}
        expandIcon={() => <span />}
        pagination={false}
        expandedRowKeys={dataActivity?.map((data) => data.key)}
      />
    </Form>
  );
}
