import React, { useEffect, useState } from 'react';

import { makeCommonDataSource } from '@base-factories';
import { dateFormatter } from '@base-helpers';
import { useBoardContextValue } from '../../../../context';
import { List } from 'antd';

interface GetDataProp {
  page?: number;
  limit?: number;
  statusData?: any[];
}

export default function StatusContent(): JSX.Element {
  const [statusData, setStatusData] = useState<any[]>([]);

  const dataStatus = makeCommonDataSource({ apiUrl: 'v1/crm/task/history' });
  const { dataOnDrawer } = useBoardContextValue();

  async function getStatusData(props?: GetDataProp): Promise<void> {
    const { page = 1, limit = 50, statusData = [] } = props;
    await dataStatus.handleGetIndex({
      params: {
        page,
        limit,
        status: 'Active',
        task_ids: [dataOnDrawer.id],
      },
      onSuccess: async ({ response }: any): Promise<any> => {
        const items = response.items ?? [];
        const meta = response?.meta;
        const newStatusData = [...statusData, ...items];
        setStatusData(newStatusData);
        if (meta && meta.currentPage < meta.totalPages) {
          await getStatusData({
            page: meta.currentPage + 1,
            statusData: newStatusData,
          });
        } else {
        }
      },
      onFailed: ({ message, statusCode }: any): any => {
        console.log({ message, statusCode });
      },
    });
  }

  useEffect(() => {
    getStatusData({ page: 1 });
  }, [dataOnDrawer]);

  return (
    <div className="status">
      <List
        dataSource={statusData ?? []}
        renderItem={(data) => (
          <List.Item>
            <div className="status-item" key={data.id}>
              <div className="status-date">
                <div className="status-date-item">{dateFormatter(data?.created_at).toLocal()}</div>
                <div className="status-date-item">{dataOnDrawer?.updated_by?.first_name}</div>
              </div>
              <div className="status-change">{data?.history}</div>
            </div>
          </List.Item>
        )}
      ></List>
    </div>
  );
}
