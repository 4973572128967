import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import {
  useCurrentRoute,
  useDataTable,
  useFilterDataTable,
  useMetaDataTable,
  usePageProviderAction,
  usePageProviderData,
} from '@base-hooks';
import { IndexPageWrapper } from '@base-components';

export default function PageIndex() {
  const { navigation, dataSource } = usePageProviderAction();
  const { moduleKey, showData } = usePageProviderData();

  const [currentRoute] = useCurrentRoute();
  const [, setDataTableSate] = useDataTable(moduleKey);
  const [, setMetaTableState] = useMetaDataTable(moduleKey);
  const [, setFilterTableState] = useFilterDataTable(moduleKey);

  const [dataSourceTable, setDatSourceTable] = useState([]);

  async function getData() {
    const params = {
      page: 1,
      limit: 10,
    };
    await dataSource.handleGetIndex({
      params,
      onSuccess: ({ response }: any) => {
        setDataTableSate(response.items);
        setDatSourceTable(response.items);
        setMetaTableState(response.meta);
        setFilterTableState(params);
      },
      onFailed: ({ message, statusCode }) => {
        console.log({ message, statusCode });
      },
    });
  }

  return (
    <IndexPageWrapper>
      <div>Simple Template Page Index:</div>
      <Button onClick={() => navigation.goBack(currentRoute)}>Back</Button>
      <Button onClick={() => navigation.goToIndex()}>Index</Button>
      <Button onClick={() => navigation.goToDetail({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Detail</Button>
      <Button onClick={() => navigation.goToCreate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Create</Button>
      <Button onClick={() => navigation.goToDuplicate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>
        Duplicate
      </Button>
      <Button onClick={() => navigation.goToUpdate({ id: '87a1c617-0e85-4eec-a7a7-f11240cfe321' })}>Update</Button>
      <Button onClick={() => getData()}>Get Data</Button>
      <Table
        dataSource={dataSourceTable}
        columns={[
          {
            title: 'Status',
            dataIndex: 'status',
          },
          {
            title: 'Request Info',
            dataIndex: 'request_info',
          },
          {
            title: 'Creator Name',
            dataIndex: 'creator_name',
          },
          {
            title: 'Last Update',
            dataIndex: 'updated_at',
          },
        ]}
      ></Table>
    </IndexPageWrapper>
  );
}
