import React, { useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import ComponentTableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import Plot from 'react-plotly.js';
import { omit, pick } from 'lodash';

const ComponentPlotlyRenderers = createPlotlyRenderers(Plot);
const TableRenderers: any = pick(ComponentTableRenderers, ['Table']);
const PlotlyRenderers: any = omit(ComponentPlotlyRenderers, ['Scatter Chart']);
import { stringFormatter } from '@base-helpers';
import '../../styles/chart-render.less';
import { useCubeQuery } from '@cubejs-client/react';
import moment from 'moment';
import tableToExcel from '@linways/table-to-excel';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { typeOf } from 'mathjs';
import { getCubeKey } from '@pages/dashboard-pages/presentation/helpers/cube-key';
interface Props {
  chartItem: any;
  filter?: any;
  showExport?: boolean;
}

const transformFilteredDashboard = (filter, itemData) => {
  if (!filter) return [];
  const filterObject = {};
  const listKey = [...(itemData?.query?.measures ?? []), ...(itemData?.query?.dimensions ?? [])];
  const dateKey = getCubeKey(listKey[0]);

  if (filter?.scope === 'region' && filter?.region_ids) {
    const typeRegion = typeOf(filter?.region_ids);

    let regionIds = [];
    if (typeRegion === 'Object') {
      regionIds = [String(filter?.region_ids.id)];
    } else if (typeRegion === 'Array') {
      regionIds = filter?.region_ids?.map((item) => String(item.id));
    }

    Object.assign(filterObject, {
      member: `${dateKey}.region_id`,
      operator: 'equals',
      values: regionIds,
    });
  }
  if (filter?.scope === 'sub_region' && filter?.sub_region_ids) {
    const typeSubRegion = typeOf(filter?.sub_region_ids);
    let subRegionIds = [];
    if (typeSubRegion === 'Object') {
      subRegionIds = [String(filter?.sub_region_ids.id)];
    } else if (typeSubRegion === 'Array') {
      subRegionIds = filter?.sub_region_ids?.map((item) => String(item.id));
    }

    Object.assign(filterObject, {
      member: `${dateKey}.sub_region_id`,
      operator: 'equals',
      values: subRegionIds,
    });
  }

  const filters = [
    {
      member: `${dateKey}.date`,
      operator: 'inDateRange',
      values: [
        moment(filter?.date_from).startOf('month').format('YYYY-MM-DD'),
        moment(filter?.date_to).endOf('month').format('YYYY-MM-DD'),
      ],
    },
    filterObject,
  ];

  if (filter?.scope === 'global') {
    filters.pop();
  }

  return filters;
};

function makeQuery(itemData, filter) {
  const query = pick(itemData, ['query']);
  const newFilter = transformFilteredDashboard(filter, itemData);
  const newQuery = {
    ...(query?.query ?? {}),
    filters: [...(query?.query?.filters ?? []), ...newFilter],
  };

  return newQuery;
}

export function ChartRender(props: Props): JSX.Element {
  const { chartItem, filter, showExport = true } = props;
  const [settings, setSettings] = useState({});
  const resultQuery: any = useCubeQuery(makeQuery(chartItem, filter));
  const rowData = resultQuery?.resultSet?.tablePivot() ?? [];

  function onChangeSettingCustom(newSetting) {
    const set = {
      ...newSetting,
      rows: [...new Set(newSetting.rows ?? [])],
      cols: [...new Set(newSetting.cols ?? [])],
    };
    setSettings(set);
  }

  useEffect(() => {
    if (chartItem?.settingsPivotTable !== settings) {
      setSettings(chartItem?.settingsPivotTable ?? {});
    }
  }, [chartItem?.settingsPivotTable]);

  function makeData(data) {
    return data.map((item) => {
      const keys = Object.keys(item);
      const newItems = {};
      keys.forEach((itemKey) => {
        Object.assign(newItems, {
          [`${stringFormatter.capitalizeEachWord(itemKey.split('.').join(' ').split('_').join(' '))}`]: item[itemKey],
        });
      });
      return newItems;
    });
  }

  const tableRef = React.useRef<any>();

  function handleClickExport() {
    if (tableRef.current) {
      const htmlTable: any = tableRef.current.querySelector('.pvtTable').cloneNode(true);
      const htmlTableHead = htmlTable.querySelector('thead');
      const htmlHeadRows = htmlTableHead.querySelectorAll('tr');
      htmlHeadRows.forEach((headRow) => {
        const htmlHeadCells = headRow.querySelectorAll('th');
        htmlHeadCells.forEach((htmlCell) => {
          const isAxisLabel = htmlCell.classList.contains('pvtAxisLabel');
          const isColLabel = htmlCell.classList.contains('pvtColLabel');
          const isTotalLabel = htmlCell.classList.contains('pvtTotalLabel');

          if (isAxisLabel) {
            htmlCell.setAttribute('data-a-h', 'left');
            htmlCell.setAttribute('data-a-v', 'middle');
          }
          if (isColLabel) {
            htmlCell.setAttribute('data-a-h', 'center');
            htmlCell.setAttribute('data-a-v', 'middle');
          }
          // if (isTotalLabel) {
          //   htmlCell.setAttribute('data-exclude', 'true');
          // }
        });
      });

      const htmlTableBody = htmlTable.querySelector('tbody');
      const htmlBodyRows = htmlTableBody.querySelectorAll('tr');
      htmlBodyRows.forEach((bodyRow) => {
        const htmlBodyCells = bodyRow.querySelectorAll('th, td');
        htmlBodyCells.forEach((htmlCell) => {
          const isRowLabel = htmlCell.classList.contains('pvtRowLabel');
          const isValue = htmlCell.classList.contains('pvtVal');
          const isTotal = htmlCell.classList.contains('pvtTotal');
          const isTotalLabel = htmlCell.classList.contains('pvtTotalLabel');
          const isGrandTotal = htmlCell.classList.contains('pvtGrandTotal');

          if (isRowLabel) {
            htmlCell.setAttribute('data-a-h', 'left');
            htmlCell.setAttribute('data-a-v', 'middle');
          }
          if (isValue) {
            htmlCell.setAttribute('data-a-h', 'right');
            htmlCell.setAttribute('data-a-v', 'middle');
            // htmlCell.setAttribute('data-t', 'n');
          }
          // if (isTotal || isTotalLabel || isGrandTotal) {
          //   htmlCell.setAttribute('data-exclude', 'true');
          // }
        });
      });
      tableToExcel.convert(htmlTable, { name: `${chartItem?.configuration?.name}.xlsx` });
    }
  }

  function setAttributeY() {
    for (let index = 0; index < document.getElementsByClassName('titletext')?.length ?? 0; index++) {
      document.getElementsByClassName('titletext')[index].setAttribute('y', '-5');
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAttributeY();
    }, 2000);
  }, [chartItem]);

  return (
    <div className="custom-chart-render-wrapper" ref={tableRef}>
      {showExport && (
        <Button icon={<FileExcelOutlined />} onClick={() => handleClickExport()}>
          Export to excel
        </Button>
      )}
      <div style={{ marginBottom: '10px' }}></div>
      <PivotTableUI
        aggregatorName="Integer Sum"
        onChange={(s) => onChangeSettingCustom(s)}
        renderers={Object.assign({}, TableRenderers)}
        // renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        {...settings}
        data={makeData(rowData)}
        hiddenFromAggregators={['id']}
      />
    </div>
  );
}
