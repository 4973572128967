import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Drawer, Dropdown, Form, Menu, Modal } from 'antd';
import { Kanban, Table } from 'phosphor-react';
import { useRecoilValue } from 'recoil';

import { IndexPageWrapper, validateMessages } from '@base-components';
import { loginAccountState } from '@base-states';
import { notificationFailed, notificationSuccess, stringFormatter } from '@base-helpers';
import { makeCommonDataSource } from '@base-factories';
import { useFilterDataTable, usePageProviderAction, usePageProviderData } from '@base-hooks';

import Board from '../components/crm-kanban';
import DrawerFormBody from '../components/crm-kanban/components/drawer-form-body';
import TableView from '../components/table-view';
import { BoardContextValueEntity, BoardProvider } from '../components/crm-kanban/context';

import { sourceDataHardCode } from './dummy';
import { formBuilderProps } from './filter';
import moment from 'moment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { omit } from 'lodash';
import { CategoryOptions, SendMethod, TaskCategoryOptions } from '../helpers/options';

export default function PageIndex(): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const { dataSource, transformer } = usePageProviderAction();
  const { moduleKey, queryPrams } = usePageProviderData();
  const { transformerFilterIndexTable } = transformer;

  const [filter, setFilter] = useFilterDataTable(moduleKey);

  const workflows: any = authState.getWorkflow() ?? [];
  const authUser: any = authState.getAccount();

  const scope_ls = authUser?.scope;

  const [viewMode, setViewMode] = useState('kanban');
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [actionForm, setActionForm] = useState<string>('create');
  const [isCreateAnother, setIsCreateAnother] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [dataCRM, setDataCRM] = useState<any[]>([]);
  const dataSourceCRM = makeCommonDataSource({ apiUrl: 'v1/crm/task-kanban' });
  const dataSourceCRMAll = makeCommonDataSource({ apiUrl: 'v1/crm/task' });

  const onChange = (e: CheckboxChangeEvent) => {
    setIsCreateAnother(e.target.checked);
  };

  function filterScopeUser(data) {
    const scope = authUser?.scope ?? 'global';
    if (scope === 'global') return data;
    return data.filter((item) => item.scope !== 'global');
  }

  async function handleGetData({ scope, dataSource, filter }): Promise<any> {
    let resultData = dataSource;
    let newFilter = filter;
    // if (scope === 'global') newFilter = omit(filter, ['region_ids', 'sub_region_ids']);
    if (scope === 'region') newFilter = omit(filter, ['sub_region_ids']);
    else if (scope === 'sub_region') newFilter = omit(filter, ['region_ids']);

    await dataSourceCRM.handleGetIndex({
      params: transformerFilterIndexTable({ ...newFilter, scope, is_unlimit: 1 }),
      onSuccess: ({ response }: any): any => {
        const items = response?.items ?? [];
        const newData = dataSource.map((item) => {
          if (item.scope !== scope) return item;
          return {
            ...item,
            cards: items.map((item) => {
              return {
                ...item,
                board_column_id: item?.workflow.id,
              };
            }),
          };
        });
        resultData = newData;
        setFilter(filter);
      },
      onFailed: () => {
        resultData = dataSource;
      },
    });
    return resultData;
  }

  function handleDeleteCard(payload) {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Delete Confirmation',
      content: 'Are you sure you want to delete data ?',
      onOk: () => handleOnDelete(payload),
    });
  }

  async function handleOnDelete(payload): Promise<void> {
    await dataSourceCRMAll.handleDelete(
      { id: payload.id },
      {
        onSuccess: async ({ response }: any): Promise<any> => {
          const countData = await handleGetData({ scope: payload.scope, dataSource: dataCRM, filter });
          setDataCRM(countData);
          notificationSuccess(['Successfully deleted data.']);
        },
        onFailed: ({ message }: any): any => {
          notificationFailed(message);
          setDataCRM(dataCRM);
        },
      },
    );
  }

  async function handleGetDataLoop(filterData: any) {
    const sourceDataFilter = filterScopeUser(sourceDataHardCode);

    let countData = sourceDataFilter;
    setLoading(true);
    for (let index = 0; index < sourceDataFilter.length; index++) {
      const scope = sourceDataFilter[index].scope;
      countData = await handleGetData({ scope, dataSource: countData, filter: filterData });
      setDataCRM(countData);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (viewMode === 'kanban') {
      const sortWorkflow = workflows.sort((a, b) => b.position - a.position);
      const defaultFilter = {
        created_at: [moment().subtract(30, 'days').startOf('day'), moment().endOf('day')],
        workflow_ids: sortWorkflow?.filter((_, i) => i !== 0),
      };
      handleGetDataLoop({ ...defaultFilter, ...(filter ?? {}) });
    }
  }, [viewMode]);

  async function onCardMove(source: any, destination: any, cardItem: any) {
    setLoading(true);
    const cardID = cardItem.id;
    const workflow = {
      id: destination?.column?.id,
      position: destination?.column?.position,
      name: destination?.column?.name,
    };
    const position = destination.index;

    const payload = {
      workflow,
      position,
    };

    await dataSource.handleCustomRequest({
      paramRequest: {
        url: `${process.env.REACT_APP_BASE_URL}v1/crm/task/${cardID}/position`,
        method: 'PUT',
        data: payload,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      },
      onSuccess: async (): Promise<void> => {
        const countData = await handleGetData({ scope: cardItem.scope, dataSource: dataCRM, filter });
        setDataCRM(countData);
      },
      onFailed: ({ message }: any): any => {
        notificationFailed(message);
      },
    });
    setLoading(false);
  }

  function ViewModeOptions(): JSX.Element {
    return (
      <Menu
        style={{ width: '100px' }}
        onClick={({ domEvent }) => setViewMode(domEvent.currentTarget.innerText.toLowerCase())}
      >
        <Menu.Item icon={<Table />}>Table</Menu.Item>
        <Menu.Item icon={<Kanban />}>Kanban</Menu.Item>
      </Menu>
    );
  }

  function CustomToolbar(): JSX.Element {
    return (
      <React.Fragment>
        <Dropdown overlay={<ViewModeOptions />} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
          <Button
            type="text"
            shape="circle"
            icon={viewMode === 'kanban' ? <Kanban size={16} /> : <Table size={16} />}
            style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', alignItems: 'center' }}
          >
            <div style={{ marginLeft: '5px' }}>{stringFormatter.capitalizeEachWord(viewMode)}</div>
          </Button>
        </Dropdown>
        <div className="separator-content"></div>
      </React.Fragment>
    );
  }

  const onHandleFinish = async (payload) => {
    try {
      await form.validateFields();
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: 'Save Confirm',
        content: 'Are you sure you want to save data',
        onOk: () => handleOnOk(payload),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnOk = async (payload) => {
    const transformPayload = {
      address: payload?.address ?? undefined,
      assignee: payload?.assignee?.length > 0 ? payload?.assignee : undefined,
      bcc: payload?.bcc?.length > 0 ? payload?.bcc : undefined,
      cc: payload?.cc?.length > 0 ? payload?.cc : undefined,
      date_plan: payload?.date_plan?.format() ?? undefined,
      description: payload?.description ?? undefined,
      email_template: payload?.email_template ?? undefined,
      google_map_link: payload?.google_map_link ?? undefined,
      link: payload?.link ?? undefined,
      location: payload?.location ? payload?.location : payload?.sub_region_location?.church_address,
      meeting_id: payload?.meeting_id ?? undefined,
      participant: payload?.participant?.length > 0 ? payload?.participant : undefined,
      password: payload?.password ?? undefined,
      region: payload?.region
        ? {
            ...payload?.region,
            id: String(payload?.region?.id),
          }
        : null,
      recipient: payload?.recipient?.length > 0 ? payload?.recipient : undefined,
      scope: payload?.scope,
      send_method: payload?.send_method ?? undefined,
      size: payload?.size ?? undefined,
      status: payload?.status ?? undefined,
      sub_region: payload?.sub_region
        ? {
            ...payload?.sub_region,
            id: String(payload?.sub_region?.id),
          }
        : null,
      summary: payload?.summary ?? undefined,
      task_category: payload?.task_category ?? undefined,
      venue_type: payload?.venue_type ?? undefined,
      workflow: payload?.workflow ?? undefined,
    };

    if (actionForm === 'create') {
      await dataSource.handleCreate(transformPayload, {
        onSuccess: async ({ response }): Promise<void> => {
          console.log(response);
          const countData = await handleGetData({ scope: payload.scope, dataSource: dataCRM, filter });
          if (isCreateAnother) {
            form.resetFields();
            setVisible(true);
            setDataCRM(countData);
          } else {
            notificationSuccess(['Successfully Created Data']);
            onCloseForm();
            setDataCRM(countData);
          }
        },
        onFailed: ({ message }: any) => {
          notificationFailed(message);
        },
      });
    }
    if (actionForm === 'update') {
      await dataSource.handleUpdate(payload.id, transformPayload, {
        onSuccess: async (): Promise<void> => {
          const countData = await handleGetData({ scope: payload.scope, dataSource: dataCRM, filter });
          notificationSuccess(['Successfully Updated Data']);
          onCloseForm();
          setDataCRM(countData);
        },
        onFailed: ({ message }: any): any => {
          notificationFailed(message);
        },
      });
    }
  };

  const showDrawerForm = () => {
    setVisible(true);
  };

  const onCloseForm = () => {
    setVisible(false);
    setIsCreateAnother(false);
    createActionForm();
    form.resetFields();
  };

  const createActionForm = () => {
    setActionForm('create');
  };

  const updateActionForm = () => {
    setActionForm('update');
  };

  function generateTitleForm() {
    if (actionForm === 'create') {
      return 'Create Task';
    }
    if (actionForm === 'update') {
      return 'Update Task';
    }
  }

  async function setFieldsUpdate(item) {
    await dataSourceCRMAll.handleGetData(item.id, {
      onSuccess: ({ response }: any): any => {
        const newResponse = {
          ...(response ?? {}),
          date_plan: response?.date_plan ? moment(response?.date_plan) : undefined,
        };
        form.setFieldsValue(newResponse);
      },
      onFailed: ({ message }: any): any => {
        console.log(message);
        form.setFieldsValue(undefined);
      },
    });
  }

  const handleValuesChange = (item, values) => {
    const scope = Object.keys(item).includes('scope');
    const task_category = Object.keys(item).includes('task_category');

    if (scope) {
      const scopeValue = item?.scope;
      if (scopeValue !== 'region') {
        const newValue = {
          ...values,
          assignee: [],
          region: null,
        };

        form.setFieldsValue(newValue);
      } else if (scopeValue !== 'sub_region') {
        const newValue = {
          ...values,
          assignee: [],
          sub_region: null,
        };

        form.setFieldsValue(newValue);
      } else {
        form.resetFields();
      }
    } else if (task_category) {
      if (scope_ls === 'global') {
        form.setFieldsValue({
          ...values,
          venue_type: null,
        });
      } else {
        form.setFieldsValue({
          ...values,
          venue_type: null,
          send_method: item?.task_category === CategoryOptions.send_email ? SendMethod.manual : undefined,
        });
      }
    }
  };

  const contextValue: BoardContextValueEntity = {
    actionForm,
    createActionForm,
    updateActionForm,
    onCloseForm,
    showDrawerForm,
    setFieldsUpdate,
  };

  return (
    <>
      <BoardProvider value={contextValue}>
        <Drawer
          title={generateTitleForm()}
          width={700}
          onClose={onCloseForm}
          visible={visible}
          footer={
            <>
              <Button type="primary" onClick={() => form.submit()} style={{ marginRight: 20 }}>
                Save
              </Button>
              {actionForm === 'create' && (
                <Checkbox checked={isCreateAnother} onChange={onChange}>
                  Create Another
                </Checkbox>
              )}
            </>
          }
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onHandleFinish}
            validateMessages={validateMessages}
            onValuesChange={handleValuesChange}
            initialValues={{
              workflow: workflows[0],
            }}
          >
            <Form.Item name="id" noStyle></Form.Item>
            <DrawerFormBody />
          </Form>
        </Drawer>

        {viewMode === 'kanban' && (
          <IndexPageWrapper
            padding={['top', 'bottom']}
            toolbarFixRightContent={<CustomToolbar />}
            showButtonBack={false}
            handleAddNew={showDrawerForm}
            handleSearch={(q) => handleGetDataLoop({ ...(filter ?? {}), q })}
            drawerFilterProp={{
              onSubmitFilter(payload = {}) {
                handleGetDataLoop({ ...(filter ?? {}), ...(payload ?? {}) });
              },
              formBuilderProps,
            }}
          >
            <div style={{ background: '#fff' }}>
              <Board
                loading={loading}
                keyHeaderLabel="date"
                dataSource={dataCRM}
                onCardMove={onCardMove}
                handleDeleteCard={handleDeleteCard}
                renderLabelGroup={(item) => item?.label}
                boardColumns={workflows?.map((item) => ({ ...item, title: item.name?.toUpperCase() ?? '' }))}
              />
            </div>
          </IndexPageWrapper>
        )}

        {viewMode === 'table' && (
          <IndexPageWrapper
            // padding={['top', 'bottom']}
            toolbarFixRightContent={<CustomToolbar />}
            showButtonBack={false}
            handleAddNew={showDrawerForm}
            handleUpdate={(row) => {
              showDrawerForm();
              setFieldsUpdate(row);
            }}
            drawerFilterProp={{
              formBuilderProps,
            }}
          >
            <Card className="card-content-wrapper">
              <TableView />
            </Card>
          </IndexPageWrapper>
        )}
      </BoardProvider>
    </>
  );
}
