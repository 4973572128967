import React from 'react';
import { DetailPageWrapper, DetailBuilder } from '@base-components';
import { Card, Form } from 'antd';

export default function PageDetail() {
  return (
    <DetailPageWrapper>
      <Form.Item noStyle dependencies={['template_name']}>
        {({ getFieldValue }) => {
          const defaultEmail = getFieldValue(['detail_data', 'email_body']);
          return (
            <React.Fragment>
              <Card className="card-content-wrapper">
                <DetailBuilder
                  columns={[
                    {
                      title: 'GENERAL INFORMATION',
                      fields: [
                        {
                          fieldType: 'text',
                          label: 'Template Name',
                          name: ['detail_data', 'template_name'],
                          gridColumn: { span: 8, xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
                          renderTextProps: {
                            defaultRender: () => '-',
                          },
                        },
                        {
                          fieldType: 'text',
                          label: 'Email Subject',
                          name: ['detail_data', 'email_subject'],
                          gridColumn: { span: 8, xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
                          renderTextProps: {
                            defaultRender: () => '-',
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Card>
              <div style={{ marginBottom: 20 }}></div>
              <Card className="card-content-wrapper">
                <DetailBuilder
                  columns={[
                    {
                      title: 'EMAIL BODY',
                      fields: [
                        {
                          fieldType: 'textEditorDraftWysiwyg',
                          name: ['detail_data', 'email_body'],
                          gridColumn: { span: 16, xs: 24, sm: 24, md: 16, lg: 14, xl: 14 },
                          renderTextEditorDraftWysiwygProps: {
                            defaultHtmlValue: defaultEmail,
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Card>
            </React.Fragment>
          );
        }}
      </Form.Item>
    </DetailPageWrapper>
  );
}
