import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Badge, Button, Card, Dropdown, List } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BellOutlined } from '@ant-design/icons';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { makeCommonNavigation } from '@base-navigation';

export const notificationCountState = atom({
  default: 0,
  key: 'notificationCountState',
});

export function NotificationComponent() {
  const socket = io(process.env.REACT_APP_BASE_NOTIFICATION_URL);
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const userId = authUser?.id;
  const notificationTopic = 'notification' + userId;
  const [totalNotification, setTotalNotification] = useRecoilState(notificationCountState);
  const [listNotification, setListNotification] = useState([]);
  const [loadingMakeAllRead, setLoadingMakeAllRead] = useState(false);
  const navigateCrm = makeCommonNavigation({ webUrl: WebUrlData.crm_board, navigate: useNavigate() });

  const navigate = useNavigate();
  const dataSourceNotification = makeCommonDataSource({ apiUrl: ApiUrlData.notification });

  async function getNotification({ params }: { params?: any }) {
    await dataSourceNotification.handleGetIndex({
      params,
      onSuccess({ response }: any) {
        const items = response.items;
        setListNotification(items);
      },
      onFailed({ message }) {
        setListNotification([]);
      },
    });
  }

  async function getTotalNotification() {
    await dataSourceNotification.handleCustomRequest({
      paramRequest: {
        baseURL: process.env.REACT_APP_BASE_URL,
        url: ApiUrlData.notification + '/total-notification',
        method: 'GET',
      },
      onSuccess({ response }: any) {
        const total = response.notification ?? 0;
        setTotalNotification(total);
      },
    });
  }

  async function handleMakeAllRead() {
    setLoadingMakeAllRead(true);
    await dataSourceNotification.handleCustomRequest({
      paramRequest: {
        baseURL: process.env.REACT_APP_BASE_URL,
        url: ApiUrlData.notification + '/mark-as-read',
        method: 'GET',
      },
      onSuccess({ response }: any) {
        setLoadingMakeAllRead(false);
        getNotification({
          params: {
            read_status: false,
          },
        });
        getTotalNotification();
      },
      onFailed() {
        setLoadingMakeAllRead(false);
      },
    });
  }

  function consumeNotification() {
    socket.on(notificationTopic, (message = '{}') => {
      try {
        const notificationMsg = JSON.parse(message);
        console.log({ notificationMsg });
        getTotalNotification();
        getNotification({
          params: {
            read_status: false,
          },
        });
      } catch (error) {}
    });
  }

  // useEffect(() => {
  //   consumeNotification();
  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //     socket.off(notificationTopic);
  //   };
  // }, []);

  useEffect(() => {
    getTotalNotification();
    getNotification({
      params: {
        read_status: false,
      },
    });
  }, []);

  async function handleClick(row) {
    const cardCode = row.content?.split(' ')[0];
    if (row.read_status) {
      navigateCrm.goToIndex({ search: { code: cardCode } });
    } else {
      await dataSourceNotification.handleUpdate(
        row.id,
        { read_status: true },
        {
          onSuccess({ response }: any) {
            getTotalNotification();
            navigateCrm.goToIndex({ search: { code: cardCode } });
            getNotification({
              params: {
                read_status: false,
              },
            });
          },
        },
      );
    }
  }

  function DropdownContent() {
    return (
      <Card title="Notification" className="card-notification">
        <List
          style={{ width: '350px', maxHeight: '300px', overflow: 'auto', marginTop: '10px', marginBottom: '10px' }}
          itemLayout="horizontal"
          dataSource={listNotification}
          renderItem={(item) => {
            return (
              <List.Item
                style={{
                  background: !item.read_status ? '#bae7ff' : '#fff',
                  cursor: 'pointer',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item);
                }}
              >
                <List.Item.Meta title={item.title} description={item.content} />
              </List.Item>
            );
          }}
        />
        <div style={{ padding: '10px', borderTop: '1px solid #eee' }}>
          <Button
            disabled={listNotification.length === 0}
            type="text"
            loading={loadingMakeAllRead}
            onClick={(e) => {
              e.stopPropagation();
              handleMakeAllRead();
            }}
          >
            Make all read
          </Button>
          <Button type="text" onClick={() => navigate('/notification')}>
            Show more
          </Button>
        </div>
      </Card>
    );
  }
  return (
    <Dropdown overlay={DropdownContent} placement="bottomRight">
      <Button type="text">
        <Badge size="small" count={totalNotification} overflowCount={99}>
          <BellOutlined style={{ fontSize: '16px' }} className="custom-icon-item" />
        </Badge>
      </Button>
    </Dropdown>
  );
}
