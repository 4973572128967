import React, { Fragment } from 'react';

import LayoutIndicatorGrid from './index-grid';
import LayoutIndicatorManual from './index-manual';
import { DragIndicator } from './drag-indicator';
import ReactDraggable from './react-draggable';
interface Props {
  data: any[];
  loading: boolean;
}

export default function index(props: Props) {
  const { data, loading } = props;

  return (
    <Fragment>
      {/* <DragIndicator data={data} loading={loading} /> */}
      {/* <LayoutIndicatorGrid data={data} loading={loading} /> */}
      {/* <div style={{ marginBottom: 10 }}></div> */}
      {/* <LayoutIndicatorManual data={data} loading={loading} /> */}
      <ReactDraggable data={data} loading={loading} />
    </Fragment>
  );
}
