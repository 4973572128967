import { authHelper, makeKeyGridLayout } from '@base-helpers';

export function getChartLayoutFromLS(key) {
  const account = authHelper.getAccount();
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(makeKeyGridLayout({ account, prefix: 'chart' }))) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

export function saveChartLayoutToLS(key, value) {
  const account = authHelper.getAccount();
  if (global.localStorage) {
    global.localStorage.setItem(
      makeKeyGridLayout({ account, prefix: 'chart' }),
      JSON.stringify({
        [key]: value,
      }),
    );
  }
}

export function resetChartLayout() {
  const account = authHelper.getAccount();
  if (global.localStorage) {
    global.localStorage.removeItem(makeKeyGridLayout({ account, prefix: 'chart' }));
  }
}
