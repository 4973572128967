import React, { useMemo } from 'react';
import { Button, Card, Tooltip } from 'antd';
import { PencilSimple, TrashSimple, Eye } from 'phosphor-react';
import { AntdIcon } from '@base-components';
import { pick } from 'lodash';
import moment from 'moment';
import { getCubeKey } from '../../../helpers/cube-key';
import { useCubeQuery } from '@cubejs-client/react';
import { LineChartRender, AreaChartRender, BarChartRender, PieChartRender } from './chart-render';
import { usePageProviderAction } from '@base-hooks';
import { RiDragDropLine } from 'react-icons/ri';
import { typeOf } from 'mathjs';

interface Props {
  dataChart: any;
  showDelete: boolean;
  showUpdate: boolean;
  handleDelete(payload: any): Promise<void>;
}
export default function index(props: Props) {
  const { dataChart, showDelete, showUpdate, handleDelete } = props;
  const transformFilteredDashboard = (filter, itemData) => {
    const filterObject = {};
    const listKey = [...(itemData?.query?.measures ?? []), ...(itemData?.query?.dimensions ?? [])];
    const dateKey = getCubeKey(listKey[0]);

    if (filter?.scope === 'region' && filter?.region_ids) {
      Object.assign(filterObject, {
        member: `${dateKey}.region_id`,
        operator: 'equals',
        values: [String(filter?.region_ids.id)],
      });
    }
    if (filter?.scope === 'sub_region' && filter?.sub_region_ids) {
      const subRegionIds = [];

      if (typeOf(filter?.sub_region_ids) === 'Object') {
        subRegionIds.push(String(filter?.sub_region_ids.id));
      } else if (typeOf(filter?.sub_region_ids) === 'Array' && filter?.sub_region_ids?.length >= 1) {
        filter?.sub_region_ids?.forEach((item) => {
          subRegionIds.push(String(item?.id));
        });
      }
      Object.assign(filterObject, {
        member: `${dateKey}.sub_region_id`,
        operator: 'equals',
        values: subRegionIds,
      });
    }

    const period_type = filter?.period_type;
    const chart_period = filter.chart_period;
    const yoyDate = filter?.yoy_chart_date_time?.map((item) => item.date).filter(Boolean) ?? [];

    if (yoyDate.length > 0) {
      const newFilter = Object.keys(filterObject).length > 1 ? filterObject : undefined;
      return [newFilter].filter(Boolean);
    } else {
      let date_from = chart_period[0] ?? moment();
      let date_to = chart_period[1] ?? moment();

      if (period_type === 'month') {
        date_from = moment(date_from).startOf('month').format('YYYY-MM-DD');
        date_to = moment(date_to).endOf('month').format('YYYY-MM-DD');
      } else if (period_type === 'week') {
        date_from = moment(date_from).startOf('week').format('YYYY-MM-DD');
        date_to = moment(date_to).endOf('week').format('YYYY-MM-DD');
      } else {
        date_from = moment(date_from);
        date_to = moment(date_to);
      }

      const newFilter = Object.keys(filterObject).length > 1 && filter?.scope !== 'global' ? filterObject : undefined;
      const filters = [
        {
          member: `${dateKey}.date`,
          operator: 'inDateRange',
          values: [date_from, date_to],
        },
        newFilter,
      ].filter(Boolean);

      return filters;
    }
  };

  function makeTimeDimensionFilter(filter, itemData, timeDimensions) {
    const listKey = [...(itemData?.query?.measures ?? []), ...(itemData?.query?.dimensions ?? [])];
    const dateKey = getCubeKey(listKey[0]);
    const period_type = filter?.period_type;

    const yoy_chart_date_time = filter?.yoy_chart_date_time ?? [];
    if (yoy_chart_date_time.length === 0) return timeDimensions;

    const compareDateRange = yoy_chart_date_time
      ?.map((item) => {
        if (!item.date) return undefined;
        if (period_type === 'month') {
          return [
            moment(item.date[0]).startOf('month').format('YYYY-MM-DD'),
            moment(item.date[1]).startOf('month').format('YYYY-MM-DD'),
          ];
        } else if (period_type === 'week') {
          return [
            moment(item.date[0]).startOf('week').format('YYYY-MM-DD'),
            moment(item.date[1]).startOf('week').format('YYYY-MM-DD'),
          ];
        } else {
          return [moment(item.date[0]), moment(item.date[1])];
        }
      })
      .filter(Boolean);

    const time = [
      {
        dimension: `${dateKey}.date`,
        granularity: period_type ?? 'month',
        compareDateRange: compareDateRange ?? [],
      },
    ];
    return time;
  }

  function makeQuery(itemData, filter) {
    const query = pick(itemData, ['chartType', 'query']);
    const newFilter = transformFilteredDashboard(filter, itemData);
    const newQuery = {
      ...query,
      query: {
        ...(query?.query ?? {}),
        filters: [...(query?.query?.filters ?? []), ...newFilter],
        timeDimensions: makeTimeDimensionFilter(filter, itemData, query?.query?.timeDimensions),
      },
    };

    return newQuery;
  }

  const query = useMemo(() => {
    return makeQuery({ ...dataChart, ...(dataChart?.configuration ?? {}) }, dataChart?.filter_dashboard);
  }, [dataChart]);

  const chartType = query.chartType;
  const cubeData = useCubeQuery(query.query);
  const loading = cubeData?.isLoading;
  const { navigation } = usePageProviderAction();

  return (
    <Card
      loading={loading}
      title={dataChart?.configuration?.name}
      className="card-content-wrapper card-dashboard-wrapper metric-indicator-wrapper"
      extra={[
        <Tooltip key={1} title="Update" placement="bottom">
          <Button
            hidden={!showUpdate}
            style={{ marginLeft: 5 }}
            onClick={() => navigation.goToUpdate({ id: dataChart.id })}
            icon={
              <AntdIcon>
                <PencilSimple />
              </AntdIcon>
            }
          />
        </Tooltip>,
        <Tooltip key={2} title="Delete" placement="bottom">
          <Button
            hidden={!showDelete}
            style={{ marginLeft: 5 }}
            onClick={() => handleDelete(dataChart)}
            icon={
              <AntdIcon>
                <TrashSimple />
              </AntdIcon>
            }
          />
        </Tooltip>,
        // <Tooltip key={3} title="Drag" placement="bottom">
        <Button
          key={3}
          style={{ marginLeft: 5, cursor: 'grab' }}
          className="dashboard-item-drag-control"
          icon={
            <AntdIcon>
              <RiDragDropLine />
            </AntdIcon>
          }
        />,
        // </Tooltip>,
      ]}
    >
      <div className="dashboard-item-no-drag" style={{ padding: 20 }}>
        {chartType === 'line' && <LineChartRender cubeData={cubeData} />}
        {chartType === 'area' && <AreaChartRender cubeData={cubeData} />}
        {chartType === 'bar' && <BarChartRender cubeData={cubeData} />}
        {chartType === 'pie' && <PieChartRender cubeData={cubeData} />}
        {/* </div> */}
      </div>
    </Card>
  );
}
