import React, { useState } from 'react';
import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { SubRegionTable } from '../components';
import { ApiUrlData } from '@base-configs';
import { StatusDataOptions } from '@base-helpers';
import { Button, Card, Modal, Tooltip } from 'antd';
import { ArrowsClockwise } from 'phosphor-react';
import { makeCommonDataSource } from '@base-factories';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

export default function PageIndex(): JSX.Element {
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);
  const [showContentRegion, setShowContentRegion] = useState(true);
  const synchronize = makeCommonDataSource({ apiUrl: ApiUrlData.synchron });

  const columns: any = [
    {
      title: 'Region Name',
      key: 'rc_dpw_name',
      dataIndex: 'rc_dpw_name',
      sorter: true,
      ellipsis: true,
      editable: true,
      width: 250,
    },
  ];

  function SyncRegionToolbar(): JSX.Element {
    return (
      <>
        {selectedRegion?.length > 0 && (
          <>
            <Tooltip title="Sync Region">
              <Button
                type="text"
                icon={
                  <span role="img" aria-label="arrow-left" className="anticon ">
                    <ArrowsClockwise size={16} />
                  </span>
                }
                style={{ marginLeft: 10, marginRight: 25 }}
                onClick={() => onClickSynchronizeProcess()}
              >
                Sync Region
              </Button>
            </Tooltip>
            <div className="separator-content"></div>
          </>
        )}
      </>
    );
  }

  const refreshRegionData = () => {
    setShowContentRegion(false);
    setTimeout(() => {
      setShowContentRegion(true);
    }, 0);
  };

  function onClickSynchronizeProcess(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: 'Synchronize Region Confirmation',
      content: 'Are you sure you want to synchronize ?',
      onOk: handleSyncRegion,
    });
  }

  const handleSyncRegion = async (): Promise<void> => {
    if (selectedRegion) {
      const selectedRegionID = selectedRegion?.map((data) => data?.id);
      const payload = {
        ids: selectedRegionID,
        action: 'update',
        module: 'region',
      };

      await synchronize.handleCreate(payload, {
        onSuccess: ({ response }: any): any => {
          console.log(response);
          refreshRegionData();
        },
        onFailed: ({ message }: any): any => {
          console.log(message);
        },
      });
    }
  };

  return (
    <IndexPageWrapper
      showButtonBack={false}
      showButtonAddNew={false}
      showBatchDeactivate={false}
      showBatchActivate={false}
      showBatchDelete={false}
      tableType="inline-form"
      title={'Region & Church'}
      toolbarFixRightContent={<SyncRegionToolbar />}
      formBuilderConfig={{
        columns: [
          {
            fields: [
              {
                name: 'rc_dpw_name',
                fieldType: 'inputText',
                rules: [{ required: true, message: 'Region name is required!' }],
              },
            ],
          },
        ],
      }}
      drawerFilterProp={{
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  label: 'Region',
                  name: 'region_ids',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    isMulti: true,
                    dataSourceUrl: ApiUrlData.region,
                    customLabel: (value) => value?.rc_dpw_name,
                  },
                },
                {
                  label: 'Status',
                  name: 'status',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    options: StatusDataOptions,
                    customLabel(value) {
                      return StatusDataOptions.find((item) => item.value === value)?.label;
                    },
                  },
                },
              ],
            },
          ],
        },
      }}
    >
      <Card className="card-content-wrapper">
        {showContentRegion && (
          <InfinityDataTable
            columns={columns}
            useConfirmCancel={false}
            ignoreDefaultColumn={['action']}
            scrollX="fit-content"
            rowAction={{
              showActivate: () => false,
              showDeactivate: () => false,
              showDuplicate: () => false,
              showDelete: () => false,
              showUpdate: () => false,
            }}
            getRowSelection={(payload) => {
              setSelectedRegion(payload);
            }}
            onRow={(record) => {
              return {
                onClick: () => undefined,
              };
            }}
            inlineFormFields={[
              {
                columnKey: 'rc_dpw_name',
                name: 'rc_dpw_name',
                fieldType: 'inputText',
                noStyle: true,
                rules: [{ required: true, message: 'Region name is required!' }],
              },
            ]}
            expandable={{
              expandedRowRender(record) {
                return <SubRegionTable region={record} />;
              },
            }}
          />
        )}
      </Card>
    </IndexPageWrapper>
  );
}
