import React, { useState } from 'react';
import { FormBuilder, FromPageWrapper } from '@base-components';
import { CubeQueryBuilder } from '../components/cube-query-builder';
import { Form } from 'antd';
import { ScopeOptions } from '../helpers';
import { usePageProviderAction } from '@base-hooks';

export default function PageForm() {
  const [form, setForm] = useState(undefined);
  const { navigation } = usePageProviderAction();
  function onChangeQueryBuilder(props: any): void {
    if (form) form.setFieldsValue(props);
  }
  const [defaultData, setDefaultData] = useState(undefined);
  return (
    <FromPageWrapper
      getFormInstance={setForm}
      handleAfterCreate={() => navigation.goToIndex()}
      handleAfterUpdate={() => navigation.goToIndex()}
      getCurrentData={(show) => {
        setDefaultData(show);
      }}
    >
      <div style={{ background: '#fff', padding: '10px 15px 10px 15px', marginBottom: '20px' }}>
        <FormBuilder
          columns={[
            {
              fields: [
                {
                  name: 'name',
                  label: 'Name',
                  fieldType: 'inputText',
                  rules: [{ required: true }],
                  inputTextProps: {
                    placeholder: 'Input name',
                  },
                },
                {
                  name: 'scope',
                  label: 'Scope',
                  fieldType: 'select',
                  rules: [{ required: true }],
                  selectProps: {
                    options: ScopeOptions,
                    customLabel(value) {
                      return ScopeOptions.find((item) => item.value === value)?.label;
                    },
                  },
                },
                // {
                //   name: 'show_on_dashboard',
                //   label: 'Show on Dashboard',
                //   fieldType: 'switch',
                //   valuePropName: 'checked',
                //   switchProps: {
                //     checkedChildren: 'Yes',
                //     unCheckedChildren: 'No',
                //   },
                // },
              ],
            },
          ]}
        />
      </div>

      <Form.Item name="chartType" hidden noStyle />
      <Form.Item name="dimensions" hidden noStyle />
      <Form.Item name="filters" hidden noStyle />
      <Form.Item name="measures" hidden noStyle />
      <Form.Item name="pivotConfig" hidden noStyle />
      <Form.Item name="segments" hidden noStyle />
      <Form.Item name="settingsPivotTable" hidden noStyle />
      <Form.Item name="timeDimensions" hidden noStyle />
      <Form.Item name="query" hidden noStyle />
      <Form.Item name="filterModule" hidden noStyle />

      <CubeQueryBuilder {...(defaultData ? { defaultValue: defaultData } : {})} onChange={onChangeQueryBuilder} />
    </FromPageWrapper>
  );
}
