import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { DatabaseDeletionEntity } from '../../domain/entities';
import { makeDatabaseDeletionTransformer } from '../../domain/factories';

import PageIndex from '../pages';

export function DatabaseDeletionFactory(): JSX.Element {
  const transformer = makeDatabaseDeletionTransformer<DatabaseDeletionEntity>();
  const InitialValue: InitialValuePageProviderEntity<DatabaseDeletionEntity> = {
    transformer,
    moduleKey: 'maintenance-database-deletion-pages', // make sure this key unique each module
    webUrl: WebUrlData.maintenance_database_deletion,
    apiUrl: ApiUrlData.delete_data,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
