import React, { useState } from 'react';
import { ServerSideExpandableFormTable, SORTER_TYPE } from '@base-components';
import { makeCommonDataSource } from '@base-factories';
import { ApiUrlData } from '@base-configs';
import * as _ from 'lodash';
import { CategoryEntity } from '../../../domain/entities';
import { makeCategoryTransformer } from '../../..//domain/factories';
import { useDataTableAction, useFilterDataTable, useMetaDataTable } from '@base-hooks';

interface Props {
  category: CategoryEntity;
}
export function SubCategoryTable(props: Props) {
  const { category } = props;
  const setRecordDataParent = useDataTableAction()?.setRecordData;
  const recordDataParent = useDataTableAction()?.recordData;

  const moduleKey = 'expandable-sub-category-fom-table';
  const [recordData, setRecordData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [meta, setMeta] = useMetaDataTable(moduleKey);
  const [filter, setFilter] = useFilterDataTable(moduleKey);
  const [loading, setLoading] = useState(false);

  const dataSource = makeCommonDataSource({ apiUrl: ApiUrlData.category });
  const transformer = makeCategoryTransformer<CategoryEntity>(category);
  const columns: any = [
    {
      title: 'Data Sub Category Name',
      key: 'name',
      sorter: true,
      dataIndex: 'name',
      ellipsis: true,
      editable: true,
      width: 250,
    },
  ];

  async function handleGetDataIndex(filter?: any, isLoadMore?: boolean): Promise<void> {
    setLoading(true);
    setFilter(filter);
    let data = category?.childs ?? [];
    if (filter.order_by) {
      let countData = data.map((item) => item[filter.order_by]);
      if (filter.order_type === 'ASC') countData = countData.sort();
      else if (filter.order_type === 'DESC') countData = countData.sort((a, b) => (a > b ? -1 : 1));
      data = countData.map((keyValue) => data.find((itemData) => itemData[filter.order_by] === keyValue));
    } else data = category?.childs ?? [];

    const newData = data.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
    setRecordData(newData);
    setMeta({
      currentPage: 1,
      itemCount: 1,
      itemsPerPage: data.length,
      totalItems: data.length,
      totalPages: 1,
    });
    setLoading(false);
  }

  function handleChangeRecordData(data) {
    setRecordData(data);
    const newDataParent = recordDataParent?.map((item) => {
      if (item.id !== category.id) return item;
      return {
        ...item,
        childs: data,
      };
    });
    setRecordDataParent(newDataParent);
  }

  return (
    <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <ServerSideExpandableFormTable
        loading={loading}
        setLoading={setLoading}
        meta={meta}
        setMeta={setMeta}
        filter={filter}
        setFilter={setFilter}
        transformer={transformer}
        dataSource={dataSource}
        moduleKey={moduleKey}
        recordData={recordData}
        setRecordData={handleChangeRecordData}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        columns={columns}
        handleGetData={handleGetDataIndex}
        tooltipTitleAddButton="Add data sub category"
        inlineFormFields={[
          {
            columnKey: 'name',
            name: 'name',
            fieldType: 'inputText',
            noStyle: true,
            rules: [{ required: true, message: 'Sub category name is required!' }],
          },
        ]}
        onChangeTable={({ current, pageSize }, _, sorter: any) => {
          const sorterValue = {
            order_by: sorter?.columnKey && SORTER_TYPE[sorter?.order] ? sorter?.columnKey : undefined,
            order_type: SORTER_TYPE[sorter?.order] ?? undefined,
          };
          handleGetDataIndex({ ...filter, ...sorterValue, page: 1 }, false);
        }}
      />
    </div>
  );
}
