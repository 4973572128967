import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import { floatPattern } from '@base-helpers';
import {
  TYPE_VALUE,
  AvailableTypeValue,
  LogicalExpressions,
  FunctionExpressions,
  AliasExpressions,
  PeriodRangeExpressions,
  PeriodRangeEachExpressions,
  makeSingleOptions,
  makeCategoryOptions,
  makeSubCategoryOptions,
  AvailableOptionsType,
  AliasCondition,
  InputFormulaProps,
  makeValueFromInput,
} from './rules';

export function InputFormula(props: InputFormulaProps) {
  const {
    category = [],
    subCategory = [],
    value,
    onChange,
    ignoreOptionType = [],
    additionalOptions = [],
    showAliasOn = 'after_alias_type',
    filterAliasOptions,
  } = props;
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const AvailableListOptionOptions: AvailableOptionsType[] = [
    'logical_expression',
    'function_expression',
    'alias_expression',
    'period_range_expression',
    'period_range_each_expression',
    'category',
    'sub_category',
  ];

  const AvailableOptions = {
    logical_expression: makeSingleOptions(LogicalExpressions),
    function_expression: makeSingleOptions(FunctionExpressions),
    alias_expression: makeSingleOptions(AliasExpressions),
    period_range_expression: makeSingleOptions(PeriodRangeExpressions),
    period_range_each_expression: makeSingleOptions(PeriodRangeEachExpressions),
    category: makeCategoryOptions(category),
    sub_category: makeSubCategoryOptions(subCategory),
  };

  const handleChange = (value: any[]) => {
    const newValue = value
      .map((item) => {
        try {
          if (floatPattern.test(item)) throw new Error();
          const newItem = JSON.parse(item);
          return {
            ...newItem,
            key: uuidV4(),
          };
        } catch (error) {
          if (floatPattern.test(item)) {
            return {
              type: TYPE_VALUE.NUMBER,
              value: String(item),
              key: uuidV4(),
            };
          } else if (AvailableTypeValue.includes(item)) {
            return makeValueFromInput(item);
          }
        }
      })
      .filter(Boolean);
    setInputValue('');
    if (onChange) onChange(newValue);
  };

  function makeOptions() {
    let Opts = [];
    const ListOpts = AvailableListOptionOptions.filter((item) => !ignoreOptionType.includes(item));
    ListOpts.forEach((item) => {
      Opts = [...Opts, ...AvailableOptions[item]];
    });
    const newOpts = [...Opts, ...additionalOptions];
    const optsWithoutAlias = newOpts?.filter((item) => JSON.parse(item.value)?.type !== TYPE_VALUE.ALIAS);
    let realOptions = [];
    if (showAliasOn === 'after_alias_type') {
      const temValue = value ?? [];
      const lastIdx = temValue[temValue?.length - 1];
      const isTriggerAlias = lastIdx?.value === 'as';
      let aliasOpt = [];
      if (filterAliasOptions) aliasOpt = filterAliasOptions(AvailableOptions.alias_expression);
      else aliasOpt = AvailableOptions.alias_expression;
      if (isTriggerAlias) realOptions = [...aliasOpt, ...optsWithoutAlias];
      else realOptions = optsWithoutAlias;
    } else if (showAliasOn === 'always') {
      let aliasOpt = [];
      if (filterAliasOptions) aliasOpt = filterAliasOptions(AvailableOptions.alias_expression);
      else aliasOpt = AvailableOptions.alias_expression;
      realOptions = [...aliasOpt, ...optsWithoutAlias];
    } else realOptions = newOpts;
    return realOptions;
  }

  useEffect(() => {
    const options = makeOptions();
    setOptions(options);
  }, [inputValue]);

  function makeValue(value): any {
    if (!value) return undefined;
    const newValue = value.map((item) => {
      let label = item.value;
      if (item.type === TYPE_VALUE.CATEGORY) label = item?.value?.name;
      else if (item.type === TYPE_VALUE.SUB_CATEGORY) label = `${item?.value?.category_name} - ${item?.value?.name} `;
      else label = String(item.value)?.split('_')?.join(' ')?.toUpperCase();
      return {
        label,
        value: JSON.stringify(item),
      };
    });
    return newValue;
  }

  return (
    <div>
      <Select
        mode="tags"
        options={options}
        placeholder="Input formula"
        value={makeValue(value)}
        onChange={handleChange}
        searchValue={inputValue}
        onSearch={(input) => setInputValue(input)}
        onBlur={() => setInputValue('')}
        dropdownClassName={`${!inputValue || inputValue === '' ? 'd-none-input-formula-select ' : ''}`}
        filterOption={(input, option) => {
          return (
            String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      />
    </div>
  );
}
