import React from 'react';
import { Card } from 'antd';

import '../../../styles/index.less';

interface Props {
  value?: any;
}

export default function MobileDetail(props: Props) {
  const dataActivity = props?.value ?? [];

  function renderForm(row) {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Value: </label>
          {row?.value ?? '-'}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Notes: </label>
          {row?.note ?? '-'}
        </div>
      </React.Fragment>
    );
  }
  return (
    <>
      {dataActivity?.map((data, index) => {
        return (
          <Card key={index} className="card__category">
            <div className="card__category-title">{data?.category_name}</div>
            {!data.hasOwnProperty('children') ? (
              <div className="card__category-content">{renderForm(data)}</div>
            ) : (
              <ul>
                {data?.children.map((child) => {
                  return (
                    <li key={child.key} className="card__subcategory-list">
                      <div className="card__subcategory-item">{child?.category_name}</div>
                      <div className="card__subcategory-body">{renderForm(child)}</div>
                    </li>
                  );
                })}
              </ul>
            )}
          </Card>
        );
      })}
    </>
  );
}
