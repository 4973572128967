import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import * as _ from 'lodash';

export class SubRegionTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  protected region: any;
  constructor(region) {
    super();
    this.region = region;
  }
  transformerGetData(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['address', 'latitude', 'longitude']);
    Object.assign(cleanVal, {
      address: { address: payload.church_address, latitude: payload.latitude, longitude: payload.longitude },
    });
    return cleanVal;
  }

  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return [];
    return payload.map((item) => this.transformerGetData(item));
  }

  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action', 'address', 'parent']);
    Object.assign(cleanVal, {
      church_address: payload?.address?.address ?? undefined,
      latitude: payload?.address?.latitude?.toString() ?? undefined,
      longitude: payload?.address?.longitude?.toString() ?? undefined,
      region: this.region ? { id: this.region?.id, rc_dpw_name: this.region?.rc_dpw_name } : undefined,
      parent: payload?.parent ? { id: payload?.parent?.id, church_name: payload?.parent?.church_name } : undefined,
      notes: payload?.notes ?? undefined,
    });

    return cleanVal;
  }

  transformerCreate(payload: E): E {
    return this.transformerUpdate(payload);
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }
}
