import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import { TYPE_VALUE } from '../../presentation/components/form/input-formula/rules';
import * as _ from 'lodash';

export class MetricIndicatorTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerCreate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['form_action']);
    const data_count = cleanVal?.data_count?.map((item, index) => {
      if (item.type === TYPE_VALUE.CATEGORY || item.type === TYPE_VALUE.SUB_CATEGORY) {
        return {
          ...item,
          value: JSON.stringify(item.value),
          index,
        };
      }
      return {
        ...item,
        index,
      };
    });

    Object.assign(cleanVal, { data_count });
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }

  transformerGetIndex(payload: E[]): E[] {
    return payload;
  }

  transformerGetData(payload: E): E {
    const cleanVal = payload;
    const data_count = cleanVal?.data_count?.map((item, index) => {
      if (item.type === TYPE_VALUE.CATEGORY || item.type === TYPE_VALUE.SUB_CATEGORY) {
        return {
          ...item,
          value: JSON.parse(item.value),
        };
      }
      return item;
    });

    Object.assign(cleanVal, { data_count });
    return payload;
  }

  transformerFilterIndexTable(payload: any) {
    const created_at = payload?.created_at?.map((item: any) => item.format()) ?? [];
    const created_ids = payload?.created_ids?.map((item: any) => item?.id.toString());
    const updated_at = payload?.updated_at?.map((item: any) => item.format()) ?? [];
    const updated_ids = payload?.updated_ids?.map((item: any) => item?.id.toString());

    const transformedPayload = {
      ...payload,
      created_ids,
      updated_ids,
      created_at: [],
      created_from: created_at ? created_at[0] : undefined,
      created_to: created_at ? created_at[1] : undefined,
      updated_at: [],
      updated_from: updated_at ? updated_at[0] : undefined,
      updated_to: updated_at ? updated_at[1] : undefined,
    };

    return transformedPayload;
  }
}
