import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { HeadquarterEntity } from '../../domain/entities';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import { makeHeadquarterTransformer } from '../../domain/factories';

export function HeadquarterFactory() {
  const transformer = makeHeadquarterTransformer<HeadquarterEntity>();

  const InitialValue: InitialValuePageProviderEntity<HeadquarterEntity> = {
    transformer,
    moduleKey: 'data-headquarter-pages', // make sure this key unique each module
    webUrl: WebUrlData.data_headquarter,
    apiUrl: ApiUrlData.headquarter,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} PageUpdate={PageForm} />
    </PageProvider>
  );
}
