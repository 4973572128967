import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { LogEntity } from '../../domain/entities';
import { makeLogTransformer } from '../../domain/factories';

import PageIndex from '../pages';

export function LogFactory(): JSX.Element {
  const transformer = makeLogTransformer<LogEntity>();
  const InitialValue: InitialValuePageProviderEntity<LogEntity> = {
    // transformer,
    moduleKey: 'maintenance-log-pages', // make sure this key unique each module
    webUrl: WebUrlData.maintenance_log,
    apiUrl: ApiUrlData.logs,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
