import { ApiUrlData } from '@base-configs';
import { StatusDataOptions } from '@base-helpers';
import { typeOf } from 'mathjs';

export enum ScopeValue {
  global = 'global',
  region_subregion = 'region',
}

const BASE_IMAGE_URL = process.env.REACT_APP_BASE_ASSET_URL;
const IFGF_BASE_IMAGE_URL = process.env.REACT_APP_BASE_ASSET_URL_IFGF;

export const RegionScopeOptions = [
  { label: 'Global', value: ScopeValue.global },
  { label: 'Region/Church', value: ScopeValue.region_subregion },
];

export const formBuilderProps: any = {
  columns: [
    {
      fields: [
        {
          label: 'Scope',
          name: 'scope',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            options: RegionScopeOptions,
            customLabel(value) {
              return RegionScopeOptions.find((item) => item.value === value)?.label;
            },
          },
        },
        {
          label: 'Region',
          name: 'region_ids',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.region,
            customLabel: (value) => value?.rc_dpw_name,
            customFilterRequest: () => ({
              status: 'Active',
            }),
          },
        },
        {
          label: 'Local Church & Satellite',
          name: 'sub_region_ids',
          fieldType: 'selectPaginate',
          gridColumn: { span: 12 },
          selectPaginateProps: {
            isMulti: true,
            dataSourceUrl: ApiUrlData.sub_region,
            customLabel: (value) => value?.church_name,
            customFilterRequest: () => ({
              status: 'Active',
            }),
          },
        },
        {
          label: 'Status',
          name: 'status',
          fieldType: 'select',
          gridColumn: { span: 12 },
          selectProps: {
            options: StatusDataOptions,
            customLabel(value) {
              return StatusDataOptions.find((item) => item.value === value)?.label;
            },
          },
        },
      ],
    },
  ],
};

export function makeUrlImageUser(image) {
  const imageType = typeOf(image);
  if (!image || imageType !== 'string') return 'error';
  const stringUrlImage = image.split('/');
  const isStorageFirst = stringUrlImage[0] === 'storage';
  let realUrl = '';
  if (isStorageFirst) realUrl = `${IFGF_BASE_IMAGE_URL}${image}`;
  else realUrl = `${BASE_IMAGE_URL}${image}`;
  return realUrl;
}

export const dummyUser = {
  name: 'Budi doremi',
  phone: '081212121212',
  email: 'bud@gmail.com',
  scope: ScopeValue.region_subregion,
  region: {
    name: 'Jawa Barat',
  },
  sub_region: {
    name: 'Bandung',
  },
};
