import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { Menu, Dropdown } from 'antd';
import { EditOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
import { useBoardContextValue } from '../../context';
import { X } from 'phosphor-react';
import './drawer-header.less';

export default function index(): JSX.Element {
  const { dataOnDrawer, dataParentOnDrawer, onCloseShowCard, onCloseShowTable, handleDeleteCard, onUpdateCard } =
    useBoardContextValue();

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const toggleDropdown = () => {
    setVisibleDropdown(!visibleDropdown);
  };

  function generateTitleDetail(): string {
    const scope = dataOnDrawer?.scope;
    if (scope === 'region') {
      return `${dataOnDrawer?.task_id} / ${dataOnDrawer?.region?.rc_dpw_name || '-'}`;
    }

    if (scope === 'sub_region') {
      return `${dataOnDrawer?.task_id} / ${dataOnDrawer?.sub_region?.church_name || '-'} `;
    }
    return dataOnDrawer?.task_id;
  }

  function menuAction(content: any): JSX.Element {
    return (
      <Menu style={{ width: '200px' }} onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item
          icon={<EditOutlined />}
          key="0"
          onClick={() => {
            setVisibleDropdown(false);
            onUpdateCard(dataOnDrawer, dataOnDrawer.id);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          icon={<DeleteOutlined />}
          key="1"
          onClick={() => {
            setVisibleDropdown(false);
            onCloseShowCard(dataOnDrawer, dataParentOnDrawer);
            handleDeleteCard(dataOnDrawer);
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className="header-drawer-board">
      <Row>
        <Col span={20}>
          <div className="title">{generateTitleDetail()}</div>
        </Col>
        <Col span={2}>
          <Dropdown
            overlay={() => menuAction(dataOnDrawer)}
            trigger={['click']}
            visible={visibleDropdown}
            onVisibleChange={() => toggleDropdown()}
          >
            <Button size="small" type="text" icon={<EllipsisOutlined style={{ fontSize: '14px' }} />}></Button>
          </Dropdown>
        </Col>
        <Col span={2}>
          <Button
            size="small"
            type="text"
            icon={
              <X
                style={{ fontSize: '16px', color: 'grey' }}
                onClick={() => {
                  onCloseShowCard(dataOnDrawer, dataParentOnDrawer);
                  onCloseShowTable(dataOnDrawer);
                }}
              />
            }
          ></Button>
        </Col>
      </Row>
    </div>
  );
}
