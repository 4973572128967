import React, { useState } from 'react';
import { Col, Menu, Row, Tooltip } from 'antd';
import { Eye, Book } from 'phosphor-react';

import DetailList from './details';
import DetailJournal from './detail-journal';

import './index.less';

export default function BodyDrawer(): JSX.Element {
  const [showDetail, setShowDetail] = useState(true);
  const [showJournal, setShowJournal] = useState(false);

  const handleSelectMenu = (evt): void => {
    const domKey = evt.key;
    if (domKey === '1') {
      setShowDetail(true);
      setShowJournal(false);
    } else if (domKey === '2') {
      setShowDetail(false);
      setShowJournal(true);
    }
  };

  return (
    <div className="body-drawer">
      <Row style={{ height: '100%' }}>
        <Col span={3} style={{ padding: 0, display: 'flex' }}>
          <Menu
            mode="vertical"
            defaultOpenKeys={['1']}
            defaultSelectedKeys={['1']}
            style={{ height: '100%', paddingTop: '20px' }}
            onSelect={(evt) => handleSelectMenu(evt)}
          >
            <Menu.Item key={'1'}>
              <Tooltip title="Details" placement="left">
                <Eye size={18} />
              </Tooltip>
            </Menu.Item>
            <Menu.Item key={'2'}>
              <Tooltip title="Journal" placement="left">
                <Book size={18} />
              </Tooltip>
            </Menu.Item>
          </Menu>
        </Col>
        <Col span={20} style={{ padding: '9px 10px', paddingTop: '20px' }}>
          {showDetail && <DetailList />}
          {showJournal && <DetailJournal />}
        </Col>
      </Row>
    </div>
  );
}
