import React from 'react';
import { Drawer, DrawerProps } from 'antd';
import SmallImage from '@base-images/logo-extra-large.png';
import '../styles/drawer-menu.less';
import { MenuComponent } from '../menu';
import { DataMenu } from '../menu/data-menu';
import { BaseMenuEntity } from '@base-entities';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { omit } from 'lodash';
import { WebUrlData } from '@base-configs';
import { useMediaQuery } from '@base-hooks';

interface DrawerMobileMenuProps extends DrawerProps {
  [key: string]: any;
}

export function DrawerMobileMenu(props: DrawerMobileMenuProps) {
  const RegionMenu = [
    WebUrlData.map,
    WebUrlData.dashboard,
    WebUrlData.activity,
    WebUrlData.crm_board,
    WebUrlData.report,
  ];
  const { authState } = useRecoilValue(loginAccountState);
  const account = authState.getAccount();

  const isMobile = useMediaQuery({ media: 'mobile' });

  function filterMenu(menu: BaseMenuEntity[]): BaseMenuEntity[] {
    const scope = account?.scope;
    let newMenu = menu;
    if (scope === 'global') newMenu = menu;
    else newMenu = menu.filter((item) => RegionMenu.includes(item.key));
    return newMenu.map((item) => {
      if (!isMobile) return omit(item, ['children']);
      return item;
    });
  }

  return (
    <Drawer
      {...props}
      width={200}
      placement="left"
      className="drawer-mobile-menu"
      title={
        <div>
          <img src={SmallImage} height={40} />
        </div>
      }
    >
      <MenuComponent
        mode="inline"
        // theme={sidebarTheme}
        dataMenu={filterMenu(DataMenu)}
        defaultSelectedKeys={['1']}
      />
    </Drawer>
  );
}
