import React from 'react';
import { FormBuilder } from '../../form-components';

export function EditableForm({
  form,
  record,
  columnFormKey,
  inlineFormFields = [],
  dataIndex,
  editing,
  children,
  ...restProps
}: any) {
  if (!editing) return <td {...restProps}>{children}</td>;
  const columnField = inlineFormFields.find((item) => item.columnKey === columnFormKey);
  if (!columnField) return <td {...restProps}>{children}</td>;

  return (
    <td {...restProps}>
      <React.Fragment>
        <FormBuilder
          className="inline-form-table"
          columns={[
            {
              fields: [
                {
                  ...columnField,
                  gridColumn: { span: 24 },
                  noStyle: true,
                },

                {
                  name: 'id',
                  fieldType: 'inputText',
                  noStyle: true,
                  hidden: true,
                },
                {
                  name: 'form_action',
                  fieldType: 'inputText',
                  noStyle: true,
                  hidden: true,
                },
              ],
            },
          ]}
        />
      </React.Fragment>
    </td>
  );
}
