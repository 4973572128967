import React from 'react';
import { Button, message, Upload, UploadProps } from 'antd';
import { useRecoilValue } from 'recoil';
import { Tray } from 'phosphor-react';

import { loginAccountState } from '@base-states';
import { ApiUrlData } from '@base-configs';

const { Dragger } = Upload;

type Props = {
  onChange?(value: any): void;
};

export default function UploadImportFile(props: Props): JSX.Element {
  const { onChange } = props;
  const { authState } = useRecoilValue(loginAccountState);

  const authUser = authState.getAccount();

  const UploadProps: UploadProps = {
    name: 'file_activity',
    action: `${process.env.REACT_APP_BASE_URL}${ApiUrlData.activity_import_upload}`,
    headers: {
      authorization: `Bearer ${authUser?.access_token}`,
    },
    onChange(info) {
      let value = undefined;
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        value = info.file.response.message;
        onChange(value);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file: any) {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      }

      return isCSV || Upload.LIST_IGNORE;
    },
  };

  return (
    <div>
      <Dragger name="document" {...UploadProps}>
        <div className="upload-import-icon">
          <Tray size={50} />
        </div>
        <div className="upload-import-text">
          <p>Click or drag file to this area to upload</p>
        </div>
      </Dragger>
      <div style={{ marginTop: 20 }}>
        <Button htmlType="submit" type="primary">
          Generate
        </Button>
      </div>
    </div>
  );
}
