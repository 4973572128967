import { EnumStatusData } from '@base-entities';

export const StatusDataOptions = [
  {
    label: 'Active',
    value: EnumStatusData.active,
  },
  {
    label: 'Inactive',
    value: EnumStatusData.inactive,
  },
];
