import { atom, useRecoilState } from 'recoil';

const navbarPositionLayoutRecoil = atom<'outside_the_layout' | 'inside_the_layout'>({
  key: 'navbarPositionLayoutRecoil',
  // default: 'outside_the_layout',
  default: 'inside_the_layout',
});

export function userNavbarPosition(): any {
  const [navbarPosition, setNavbarPosition] = useRecoilState(navbarPositionLayoutRecoil);
  return [navbarPosition, setNavbarPosition];
}
