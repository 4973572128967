import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { IndexPageWrapper } from '@base-components';
import {
  useCurrentRoute,
  useDataTable,
  useMetaDataTable,
  usePageProviderAction,
  usePageProviderData,
} from '@base-hooks';

import { AdvanceTemplateEntity } from '../../domain/entities';
import { AdvanceTemplateDataSourceRepository } from '../../domain/repositories';

export default function PageIndex() {
  const [currentRoute] = useCurrentRoute();
  const { moduleKey, showData } = usePageProviderData();
  const { navigation, dataSource } = usePageProviderAction<
    AdvanceTemplateEntity,
    AdvanceTemplateDataSourceRepository<AdvanceTemplateEntity>
  >();

  const [, setDataTableSate] = useDataTable(moduleKey);
  const [, setMetaTableState] = useMetaDataTable(moduleKey);
  const [dataSourceTable, setDatSourceTable] = useState([]);

  async function getData() {
    await dataSource.handleCustomRequest({
      params: {
        page: 1,
        limit: 10,
      },
      onSuccess: ({ response }: any) => {
        console.log('custom request');
        setDataTableSate(response.items);
        setDatSourceTable(response.items);
        setMetaTableState(response.meta);
        console.log(response);
      },
      onFailed: ({ message, statusCode }) => {
        console.log({ message, statusCode }, 'custom request');
      },
    });
  }

  return (
    <IndexPageWrapper>
      <div>Advance Template Page Index:</div>
      <Button onClick={() => navigation.goBack(currentRoute)}>Back</Button>
      <Button onClick={() => navigation.goToIndex()}>Index</Button>
      <Button onClick={() => navigation.goToDetail({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Detail</Button>
      <Button onClick={() => navigation.goToCreate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Create</Button>
      <Button onClick={() => navigation.goToDuplicate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>
        Duplicate
      </Button>
      <Button onClick={() => navigation.goToUpdate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Update</Button>
      <Button onClick={() => getData()}>Get Data</Button>
      <Table
        dataSource={dataSourceTable}
        columns={[
          {
            title: 'Status',
            dataIndex: 'status',
          },
          {
            title: 'Request Info',
            dataIndex: 'request_info',
          },
          {
            title: 'Creator Name',
            dataIndex: 'creator_name',
          },
          {
            title: 'Last Update',
            dataIndex: 'updated_at',
          },
        ]}
      ></Table>
    </IndexPageWrapper>
  );
}
