import React, { useState } from 'react';
import { Layout } from 'antd';
const { Sider: SidebarContent } = Layout;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';

export function FormGeneratorSidebar() {
  const FieldContent = [
    { key: '1', default_name: 'header', label: 'Header', icon: 'fas fa-heading' },
    { key: '2', default_name: 'paragraph', label: 'Paragraph', icon: 'fas fa-paragraph' },
    { key: '3', default_name: 'text_field', label: 'Text Field', icon: 'fas fa-t' },
    { key: '4', default_name: 'number_field', label: 'Number Field', icon: 'fas fa-hashtag' },
    { key: '5', default_name: 'text_area', label: 'Text Area', icon: 'fas fa-align-left' },
    { key: '6', default_name: 'data_field', label: 'Date Field', icon: 'far fa-calendar-alt' },
    { key: '7', default_name: 'select', label: 'Select', icon: 'fas fa-server' },
    { key: '8', default_name: 'checkbox_group', label: 'Checkbox Group', icon: 'fas fa-list   ' },
    { key: '19', default_name: 'radio_group', label: 'Radio Group', icon: 'fas fa-list  ' },
    { key: '10', default_name: 'file_upload', label: 'File Upload', icon: 'fas fa-upload' },
    { key: '11', default_name: 'image_upload', label: 'Image Upload', icon: 'fas fa-camera' },
  ];

  return (
    <div className="form-generator-sidebar">
      <SidebarContent
        theme="light"
        trigger={null}
        collapsible={false}
        style={{ minHeight: '100vh' }}
        collapsedWidth={65}
      >
        <div className="field-item-wrapper">
          {FieldContent.map((itemField, index) => {
            const icon: any = itemField.icon;
            return (
              <div key={index}>
                <Draggable key={itemField.key} draggableId={btoa(JSON.stringify(itemField))} index={index}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`card-item-wrapper `}
                        style={provided.draggableProps.style}
                      >
                        <div className="field-item">
                          <div className="field-item-icon">
                            <FontAwesomeIcon icon={icon} />
                          </div>
                          <div>{itemField.label}</div>
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              </div>
            );
          })}
        </div>
      </SidebarContent>
    </div>
  );
}
