enum Address {
  global_hq = 'global_hq',
  sub_region = 'sub_region',
  other = 'other',
}

export enum CategoryOptions {
  send_email = 'send_email',
  meeting = 'meeting',
  training = 'training',
  call = 'call',
  other = 'other',
}

enum Scope {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

export enum SendMethod {
  automatic = 'automatic',
  manual = 'manual',
}

enum Size {
  huddle = 'huddle',
  town_hall = 'town_hall',
}

enum VenueType {
  online = 'online',
  on_site = 'on_site',
}

export const AddressOptions = [
  { label: 'Global HQ', value: Address.global_hq },
  { label: 'Church', value: Address.sub_region },
  { label: 'Other', value: Address.other },
];

export const ScopeOptions = [
  { label: 'Global', value: Scope.global },
  { label: 'Region', value: Scope.region },
  { label: 'Church', value: Scope.sub_region },
];

export const SendMethodOptions = [
  { label: 'Automatic', value: SendMethod.automatic },
  { label: 'Manual', value: SendMethod.manual },
];

export const SizeOptions = [
  { label: 'Huddle', value: Size.huddle },
  { label: 'Town Hall', value: Size.town_hall },
];

export const TaskCategoryOptions = [
  { label: 'Send Email', value: CategoryOptions.send_email },
  { label: 'Meeting', value: CategoryOptions.meeting },
  { label: 'Training', value: CategoryOptions.training },
  { label: 'Call', value: CategoryOptions.call },
  { label: 'Other', value: CategoryOptions.other },
];

export const VenueTypeOptions = [
  { label: 'Online', value: VenueType.online },
  { label: 'Onsite', value: VenueType.on_site },
];
