import React from 'react';
import { WebUrlData } from '@base-configs';
import { BaseMenuEntity } from '@base-entities';
import {
  MapPin,
  ChartLine,
  Activity,
  Kanban,
  HardDrives,
  Database,
  Gear,
  Books,
  UserList,
  Table,
} from 'phosphor-react';

import {
  FundOutlined,
  BoxPlotOutlined,
  TeamOutlined,
  SisternodeOutlined,
  ApartmentOutlined,
  NodeExpandOutlined,
  FileMarkdownOutlined,
  FunctionOutlined,
  GlobalOutlined,
  ClearOutlined,
} from '@ant-design/icons';

export const MenuActivities: BaseMenuEntity[] = [
  {
    label: 'Activity',
    key: WebUrlData.activity_activities,
    path: WebUrlData.activity_activities,
    icon: <Activity size={14} />,
  },
  {
    label: 'Summary',
    key: WebUrlData.activity_summary,
    path: WebUrlData.activity_summary,
    icon: <Books size={14} />,
  },
];

export const MenuData: BaseMenuEntity[] = [
  {
    label: 'Headquarter (Global)',
    key: WebUrlData.data_headquarter,
    path: WebUrlData.data_headquarter,
    icon: <MapPin size={14} />,
  },
  {
    label: 'Region & Church',
    key: WebUrlData.data_region,
    path: WebUrlData.data_region,
    icon: <ApartmentOutlined />,
  },

  {
    label: 'User',
    key: WebUrlData.data_user,
    path: WebUrlData.data_user,
    icon: <TeamOutlined />,
  },
];
export const MenuConfiguration: BaseMenuEntity[] = [
  {
    label: 'Email Template',
    key: WebUrlData.configuration_email_template,
    path: WebUrlData.configuration_email_template,
    icon: <FileMarkdownOutlined />,
  },
  {
    label: 'Data Category / Sub Category',
    key: WebUrlData.configuration_category,
    path: WebUrlData.configuration_category,
    icon: <SisternodeOutlined />,
  },
  {
    label: 'Metric Indicator',
    key: WebUrlData.configuration_metric_indicator,
    path: WebUrlData.configuration_metric_indicator,
    icon: <FunctionOutlined />,
  },
  {
    label: 'Workflow',
    key: WebUrlData.configuration_workflow,
    path: WebUrlData.configuration_workflow,
    icon: <NodeExpandOutlined />,
  },
  {
    label: 'General Configuration',
    key: WebUrlData.configuration_general_configuration,
    path: WebUrlData.configuration_general_configuration,
    icon: <GlobalOutlined />,
  },

  // {
  //   label: 'Dashboard',
  //   key: WebUrlData.configuration_dashboard,
  //   path: WebUrlData.configuration_dashboard,
  //   icon: <ReconciliationOutlined />,
  // },
];
export const MenuMaintenance: BaseMenuEntity[] = [
  {
    label: 'Log',
    key: WebUrlData.maintenance_log,
    path: WebUrlData.maintenance_log,
    icon: <UserList size={14} />,
  },
  {
    label: 'Database Deletion',
    key: WebUrlData.maintenance_database_deletion,
    path: WebUrlData.maintenance_database_deletion,
    icon: <ClearOutlined />,
  },
];
export const MenuTemplate: BaseMenuEntity[] = [
  {
    label: 'Simple Template',
    key: WebUrlData.simple_template,
    path: WebUrlData.simple_template,
    icon: <FundOutlined />,
  },
  {
    label: 'Advance Template',
    key: WebUrlData.advance_template,
    path: WebUrlData.advance_template,
    icon: <BoxPlotOutlined />,
  },
];

export const DataMenu: BaseMenuEntity[] = [
  {
    label: 'Map',
    key: WebUrlData.map,
    path: WebUrlData.map,
    icon: <MapPin size={16} />,
  },
  {
    label: 'Dashboard',
    key: WebUrlData.dashboard,
    path: WebUrlData.dashboard,
    icon: <ChartLine size={16} />,
  },

  {
    label: 'Report',
    key: WebUrlData.report,
    path: WebUrlData.report,
    icon: <Table size={16} />,
  },

  {
    label: 'Activity',
    key: WebUrlData.activity,
    path: WebUrlData.activity,
    icon: <Activity size={16} />,
    children: [{ items: MenuActivities }],
  },

  {
    label: 'CRM',
    key: WebUrlData.crm_board,
    path: WebUrlData.crm_board,
    icon: <Kanban size={16} />,
  },
  {
    label: 'Data',
    key: WebUrlData.data,
    path: WebUrlData.data,
    icon: <HardDrives size={16} />,
    children: [{ items: MenuData }],
  },
  {
    label: 'Configuration',
    key: WebUrlData.configuration,
    path: WebUrlData.configuration,
    icon: <Gear size={16} />,
    children: [{ items: MenuConfiguration }],
  },
  {
    label: 'Maintenance',
    key: WebUrlData.maintenance,
    path: WebUrlData.maintenance,
    icon: <Database size={16} />,
    children: [{ items: MenuMaintenance }],
  },
  // {
  //   label: 'Template',
  //   key: WebUrlData.template,
  //   path: WebUrlData.template,
  //   icon: <Copyright size={16} />,
  // },
];
