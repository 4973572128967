import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
} from 'recharts';
import styled from 'styled-components';

import moment from 'moment';
import numeral from 'numeral';

import './recharts-theme.less';
const numberFormatter = (item) => numeral(item).format('0,0');
const dateFormatter = (item) => {
  if (moment(item).isValid()) return moment(item).format('MMM YY');
  return item;
};
// const colors = ['#7DB3FF', '#49457B', '#FF7C78'];

const colors = [
  '#1e81b0',
  '#e28743',
  '#76b5c5',
  '#063970',
  '#154c79',
  '#873e23',
  '#B95F89',

  // '#efdbff',
  // '#e0c0f7',
  // '#8565c4',
  // '#b0ab7d',
  // '#bf9c77',
  // '#FFA8A8',
  //plan1
  // '#025DF4',
  // '#DB6BCF',
  // '#2498D1',
  // '#BBBDE6',
  // '#4045B2',
  // '#21A97A',
  // '#FF745A',
  // '#007E99',
  // '#FFA8A8',
  // '#2391FF',
  // '#FFC328',
  // '#A0DC2C',
  // '#946DFF',
  // '#626681',
  // '#EB4185',
  // '#CD8150',
  // '#36BCCB',
  // '#327039',
  // '#803488',
  // '#83BC99',
  //plan2
  // '#5B8FF9',
  // '#61DDAA',
  // '#CDDDFD',
  // '#CDF3E4',
  // '#65789B',
  // '#CED4DE',
  // '#F6BD16',
  // '#FCEBB9',
  // '#7262fd',
  // '#D3CEFD',
  // '#78D3F8',
  // '#D3EEF9',
  // '#9661BC',
  // '#DECFEA',
  // '#F6903D',
  // '#FFE0C7',
  // '#008685',
  // '#BBDEDE',
  // '#F08BB4',
  // '#FFE0ED',
  //plan3
  // '#5B8FF9',
  // '#5AD8A6',
  // '#E8684A',
  // '#9270CA',
  // '#F6BD16',
  // '#6DC8EC',
  // '#FF9D4D',
  // '#FF99C3',
  //plan4
  // '#BDD2FD',
  // '#BEDED1',
  // '#F6C3B7',
  // '#EFE0B5',
  // '#D3C6EA',
  // '#F2CADA',
];
const xAxisFormatter = (item) => {
  if (moment(item).isValid()) {
    return dateFormatter(item);
  } else {
    return item;
  }
};

const CartesianChart = ({ resultSet, children, ChartComponent, height }) => (
  <ResponsiveContainer width="100%" height={height}>
    <ChartComponent margin={{ left: -10 }} data={resultSet.chartPivot()}>
      <XAxis axisLine={false} tickLine={false} tickFormatter={xAxisFormatter} dataKey="x" minTickGap={20} />
      <YAxis axisLine={false} tickLine={false} tickFormatter={numberFormatter} />
      <CartesianGrid vertical={false} />
      {children}
      <Legend />
      <Tooltip labelFormatter={dateFormatter} formatter={numberFormatter} />
    </ChartComponent>
  </ResponsiveContainer>
);

const TypeToChartComponent = {
  line: ({ resultSet, height }) => {
    if (!resultSet.seriesNames() || resultSet.seriesNames()?.length === 0) {
      return (
        <div
          style={{ display: 'flex', alignItems: 'center', height: '100px', width: '100%', justifyContent: 'center' }}
        >
          No data to show.
        </div>
      );
    }

    return (
      <CartesianChart resultSet={resultSet} height={height} ChartComponent={LineChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Line key={series?.key} dataKey={series?.key} name={series.title} stroke={colors[i]} />
        ))}
      </CartesianChart>
    );
  },
  bar: ({ resultSet, height }) => {
    if (!resultSet.seriesNames() || resultSet.seriesNames()?.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          No data to show.
        </div>
      );
    }

    return (
      <CartesianChart resultSet={resultSet} height={height} ChartComponent={BarChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Bar key={series?.key} stackId="a" dataKey={series?.key} name={series.title} fill={colors[i]} />
        ))}
      </CartesianChart>
    );
  },

  area: ({ resultSet, height }) => {
    if (!resultSet.seriesNames() || resultSet.seriesNames()?.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          No data to show.
        </div>
      );
    }

    return (
      <CartesianChart resultSet={resultSet} height={height} ChartComponent={AreaChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Area
            key={series?.key}
            stackId="a"
            dataKey={series?.key}
            name={series.title}
            stroke={colors[i]}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    );
  },
  pie: ({ resultSet, height }) => {
    if (!resultSet.seriesNames() || resultSet.seriesNames()?.length === 0) {
      return (
        <div
          style={{ display: 'flex', alignItems: 'center', height: '100px', width: '100%', justifyContent: 'center' }}
        >
          No data to show.
        </div>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={height}>
        <PieChart>
          <Pie
            data={resultSet.chartPivot()}
            nameKey="x"
            dataKey={resultSet.seriesNames()[0]?.key}
            fill="#8884d8"
            // paddingAngle={5}
            // innerRadius={60}
            // outerRadius={80}
            // label
          >
            {resultSet.chartPivot().map((e, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  },
  table: ({ resultSet }) => (
    <Table
      pagination={false}
      columns={resultSet.tableColumns().map((c) => ({ ...c, dataIndex: c?.key }))}
      dataSource={resultSet.tablePivot()}
    />
  ),
  number: ({ resultSet }) => (
    <Row
      //   type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100%',
      }}
    >
      <Col>
        {resultSet.seriesNames().map((s, index) => (
          <Statistic key={index} value={resultSet.totalRow()[s.key]} />
        ))}
      </Col>
    </Row>
  ),
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const SpinContainer = styled.div`
  text-align: center;
  padding: 30px 50px;
  margin-top: 30px;
`;
const Spinner = () => (
  <SpinContainer>
    <Spin size="large" />
  </SpinContainer>
);

function renderChart(Component, { resultSet, error, height }) {
  // console.log(resultSet);
  return (
    (resultSet && <Component height={height} resultSet={resultSet} />) || (error && error.toString()) || <Spinner />
  );
}

export const ChartRenderer = ({ vizState, chartHeight = 300 }: { vizState: any; chartHeight?: number }) => {
  const { query, chartType } = vizState;
  // console.log(vizState);
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component ? renderChart(component, { height: chartHeight, ...renderProps }) : <div></div>;
};
