import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './style.less';

type OptionsToolbarType =
  | 'inline'
  | 'blockType'
  | 'fontSize'
  | 'fontFamily'
  | 'list'
  | 'textAlign'
  | 'colorPicker'
  | 'link'
  | 'embedded'
  | 'emoji'
  | 'image'
  | 'remove'
  | 'history';

type OptionsInlineType = 'bold' | 'italic' | 'underline' | 'strikethrough' | 'monospace' | 'superscript' | 'subscript';
export interface TextEditorDraftWysiwyg {
  defaultHtmlValue?: string;
  onChange?: (value: string) => void;
  useRecordChange?: boolean;
  toolbarHidden?: boolean;
  ignoreToolbarOptions?: OptionsToolbarType[];
  ignoreToolbarOptionsInline?: OptionsInlineType[];
}

export function TextEditorDraftWysiwyg(props: TextEditorDraftWysiwyg) {
  const {
    defaultHtmlValue,
    onChange,
    useRecordChange = true,
    toolbarHidden,
    ignoreToolbarOptions = [],
    ignoreToolbarOptionsInline = [],
  } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const options = [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    'emoji',
    'image',
    'remove',
    'history',
  ];

  const optionsInline = ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'];

  function onEditorStateChange(newEditorState: EditorState) {
    if (useRecordChange) {
      setEditorState(newEditorState);
      if (onChange) {
        const value = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        if (value === '<p></p>\n') onChange(undefined);
        else onChange(value);
      }
    }
  }

  useEffect(() => {
    if (defaultHtmlValue) {
      const blocksFromHtml = htmlToDraft(defaultHtmlValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [defaultHtmlValue]);

  return (
    <div className="custom-style-draft-wysiwyg">
      <Editor
        toolbar={{
          options: options.filter((item: any) => !ignoreToolbarOptions.includes(item)),
          inline: {
            options: optionsInline.filter((item: any) => !ignoreToolbarOptionsInline.includes(item)),
          },
        }}
        toolbarHidden={toolbarHidden}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />

      {/* <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} /> */}
    </div>
  );
}
