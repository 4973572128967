import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { EmailTemplateConfigurationEntity } from '../../domain/entities';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

export function EmailTemplateConfigurationFactory() {
  const InitialValue: InitialValuePageProviderEntity<EmailTemplateConfigurationEntity> = {
    moduleKey: 'configuration-email-template-pages', // make sure this key unique each module
    webUrl: WebUrlData.configuration_email_template,
    apiUrl: ApiUrlData.email_template,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
