import * as _ from 'lodash';
import { BaseFilterEntity } from '@base-entities';
import { atom, useRecoilState } from 'recoil';

interface CurrentFilterDataTableEntity {
  [key: string]: BaseFilterEntity;
}

const currentFilterDataTableRecoil = atom<CurrentFilterDataTableEntity>({
  key: 'currentFilterDataTableRecoil',
  default: {},
});

export function useFilterDataTable(key: string): any {
  const defaultData: BaseFilterEntity = {
    page: 0,
    limit: 50,
  };

  const [filterTable, setFilterTable] = useRecoilState(currentFilterDataTableRecoil);
  const currentData = _.pick(filterTable, [key])[key] ?? defaultData;

  function setCurrentData(meta: BaseFilterEntity): void {
    setFilterTable({
      ...filterTable,
      [`${key}`]: meta,
    });
  }

  return [currentData, setCurrentData];
}
