import React from 'react';
import { IndexPageWrapper, InfinityDataTable } from '@base-components';
import { databaseDeletioncolumns, formBuilderProps } from '../helpers/columns';
import { Card } from 'antd';

export default function PageIndex(): JSX.Element {
  return (
    <IndexPageWrapper
      showButtonBack={false}
      drawerFilterProp={{
        formBuilderProps,
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable columns={databaseDeletioncolumns}></InfinityDataTable>
      </Card>
    </IndexPageWrapper>
  );
}
