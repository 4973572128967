enum Table {
  activity = 'Activity',
  log = 'Log',
}

enum Status {
  draft = 'Draft',
  posted = 'Posted',
}

export const TableOptions = [
  { label: 'Activity', value: Table.activity },
  { label: 'Log', value: Table.log },
];

export const StatusOptions = [
  { label: 'Draft', value: Status.draft },
  { label: 'Posted', value: Status.posted },
];
