import * as _ from 'lodash';
import { atom, useRecoilState } from 'recoil';

interface CurrentDataTableEntity {
  [key: string]: any[];
}

const currentDataTableRecoil = atom<CurrentDataTableEntity>({
  key: 'currentDataTableRecoil',
  default: {},
});

export function useDataTable(key: string): any {
  const [dataTable, setDataTable] = useRecoilState(currentDataTableRecoil);
  const currentData = _.pick(dataTable, [key])[key] ?? [];
  function setCurrentData(data: any): void {
    setDataTable((old: any) => ({ ...old, [key]: data }));
    // if (!dataTable[key]) setDataTable((old: any) => ({ ...old, [key]: data }));
    //TODO cari tau bagaimana cara mengupdate data di state sejauh ini masih breaking change kalo di update
  }
  return [currentData, setCurrentData];
}
