import React, { useEffect } from 'react';
import { RouteEntity } from '@base-entities';
import { useCurrentRoute } from '@base-hooks';
import { authHelper, historyRoute } from '@base-helpers';
import { Navigate } from 'react-router-dom';
import { WebUrlData } from '@base-configs';

interface AuthProtectorProps {
  children: JSX.Element | React.ReactNode;
  routeItem: RouteEntity;
}

export default function AuthRouterProtector(props: AuthProtectorProps) {
  const { children, routeItem } = props;
  const [currentRoute] = useCurrentRoute(routeItem);
  const account = authHelper.getAccount();
  useEffect(() => {
    if (currentRoute) historyRoute.pushHistoryRoute(currentRoute);
  }, [currentRoute]);

  if (!account) return <Navigate to={WebUrlData.login} replace />;
  return <div>{children}</div>;
}
