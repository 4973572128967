import React from 'react';
import { Button, Tooltip } from 'antd';
import { Check, LockKeyOpen, CopySimple, LockKey, NotePencil, TrashSimple, X } from 'phosphor-react';

export interface RowActionProps {
  record?: any;
  showSave?: (row?: any) => boolean;
  showCancel?: (row?: any) => boolean;
  showUpdate?: (row?: any) => boolean;
  showDuplicate?: (row?: any) => boolean;
  showActivate?: (row?: any) => boolean;
  showDeactivate?: (row?: any) => boolean;
  showDelete?: (row?: any) => boolean;

  disableSave?: (row?: any) => boolean;
  disableCancel?: (row?: any) => boolean;
  disableUpdate?: (row?: any) => boolean;
  disableDuplicate?: (row?: any) => boolean;
  disableActivate?: (row?: any) => boolean;
  disableDeactivate?: (row?: any) => boolean;
  disableDelete?: (row?: any) => boolean;

  handleSave?: (row?: any) => void;
  handleCancel?: (row?: any) => void;
  handleUpdate?: (row?: any) => void;
  handleDuplicate?: (row?: any) => void;
  handleActivate?: (row?: any) => void;
  handleDeactivate?: (row?: any) => void;
  handleDelete?: (row?: any) => void;
  postfixExtensionAction?: (row?: any) => JSX.Element | React.ReactNode;
  prefixExtensionAction?: (row?: any) => JSX.Element | React.ReactNode;
}

export function RowAction(props: RowActionProps) {
  const {
    record,
    showSave,
    showCancel,
    showUpdate,
    showDuplicate,
    showActivate,
    showDeactivate,
    showDelete,

    disableSave,
    disableCancel,
    disableUpdate,
    disableDuplicate,
    disableActivate,
    disableDeactivate,
    disableDelete,

    handleSave,
    handleCancel,
    handleUpdate,
    handleDuplicate,
    handleActivate,
    handleDeactivate,
    handleDelete,
    postfixExtensionAction,
    prefixExtensionAction,
  } = props;

  const isShowSave = showSave ? showSave(record) : false;
  const isShowCancel = showCancel ? showCancel(record) : false;
  const isShowUpdate = showUpdate ? showUpdate(record) : true;
  const isShowDuplicate = showDuplicate ? showDuplicate(record) : true;
  const isShowActivate = showActivate ? showActivate(record) : true;
  const isShowDeactivate = showDeactivate ? showDeactivate(record) : true;
  const isShowDelete = showDelete ? showDelete(record) : true;

  const isDisableSave = disableSave ? disableSave(record) : false;
  const isDisableCancel = disableCancel ? disableCancel(record) : false;
  const isDisableUpdate = disableUpdate ? disableUpdate(record) : false;
  const isDisableDuplicate = disableDuplicate ? disableDuplicate(record) : false;
  const isDisableActivate = disableActivate ? disableActivate(record) : false;
  const isDisableDeactivate = disableDeactivate ? disableDeactivate(record) : false;
  const isDisableDelete = disableDelete ? disableDelete(record) : false;

  return (
    <div style={{ display: 'flex' }}>
      {prefixExtensionAction && prefixExtensionAction(record)}

      {isShowSave && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Save">
            <Button
              type="text"
              disabled={isDisableSave}
              onClick={(e) => {
                e.stopPropagation();
                if (handleSave) handleSave(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<Check size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}

      {isShowCancel && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Cancel">
            <Button
              type="text"
              disabled={isDisableCancel}
              onClick={(e) => {
                e.stopPropagation();
                if (handleCancel) handleCancel(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<X size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}

      {isShowUpdate && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Edit">
            <Button
              type="text"
              disabled={isDisableUpdate}
              onClick={(e) => {
                e.stopPropagation();
                if (handleUpdate) handleUpdate(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<NotePencil size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}
      {isShowDuplicate && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Duplicate">
            <Button
              type="text"
              disabled={isDisableDuplicate}
              onClick={(e) => {
                e.stopPropagation();
                if (handleDuplicate) handleDuplicate(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<CopySimple size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}

      {isShowActivate && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Activate">
            <Button
              type="text"
              disabled={isDisableActivate}
              onClick={(e) => {
                e.stopPropagation();
                if (handleActivate) handleActivate(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<LockKeyOpen size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}

      {isShowDeactivate && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Deactivate">
            <Button
              type="text"
              disabled={isDisableDeactivate}
              onClick={(e) => {
                e.stopPropagation();
                if (handleDeactivate) handleDeactivate(record);
              }}
              shape="circle"
              size="small"
              style={{ marginRight: '3px' }}
              icon={<LockKey size={17} />}
            />
          </Tooltip>
        </React.Fragment>
      )}
      {isShowDelete && (
        <React.Fragment>
          <Tooltip placement="bottom" title="Delete">
            <Button
              type="text"
              shape="circle"
              disabled={isDisableDelete}
              size="small"
              style={{ marginRight: '3px' }}
              icon={<TrashSimple size={17} />}
              onClick={(e) => {
                e.stopPropagation();
                if (handleDelete) handleDelete(record);
              }}
            />
          </Tooltip>
        </React.Fragment>
      )}

      {postfixExtensionAction && postfixExtensionAction(record)}
    </div>
  );
}
