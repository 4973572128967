import React, { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Button, Card, Col, Popconfirm, Tooltip } from 'antd';
import { NotePencil, TrashSimple } from 'phosphor-react';
import SortableCardHandler from './sortable-handler';
import UpdateCard from '../update-card';

interface Props {
  item: any;
  handleDeleteCard(payload: any): void;
  handleAfterSave(item: any): void;
}

const SortableCardItem = SortableElement(({ item, handleDeleteCard, handleAfterSave }: Props): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const id = item.id;
  const cardItem = item;

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleUpdateCard = () => {
    setShowForm(!showForm);
  };

  function updateAfterSave(item) {
    handleAfterSave(item);
    setShowForm(false);
  }

  return (
    <Col xs={24} sm={12} md={8} lg={5} xl={5}>
      <Card>
        <div className="Workflow__body">
          <div className="Workflow__body-header">
            <div className="Workflow__body-header-item">
              <SortableCardHandler />
            </div>
            <div className="Workflow__body-header-item" id="edit-card">
              <Tooltip placement="top" title="Edit">
                <Button
                  type="text"
                  shape="circle"
                  icon={<NotePencil key="edit" size={14} />}
                  onClick={() => handleUpdateCard()}
                />
              </Tooltip>
            </div>
            <div className="Workflow__body-header-item" id="delete-card">
              <Popconfirm
                placement="top"
                title="Delete Card ?"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
                onConfirm={() => handleDeleteCard(id)}
              >
                <Tooltip placement="top" title="Delete">
                  <Button type="text" shape="circle" icon={<TrashSimple key="delete" size={14} />} />
                </Tooltip>
              </Popconfirm>
            </div>
          </div>
          <div className="Workflow__body-content">
            {showForm ? (
              <UpdateCard showForm={showForm} item={cardItem} updateAfterSave={updateAfterSave} />
            ) : (
              <p>{item.name}</p>
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
});

export default SortableCardItem;
