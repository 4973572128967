import React, { CSSProperties, useEffect, useState } from 'react';
import { ApiUrlData } from '@base-configs';
import { makeCommonDataSource } from '@base-factories';
import { makeArrayIds } from '@base-helpers';
import { STYLE_INDICATOR } from '@pages/configuration-pages/metric-indicator/domain/entities';
import { Card, Skeleton, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { CompareIndicator, ScoreIndicator, SingleIndicator, TrendingIndicator } from './indicator-content';
import { RiArrowLeftRightFill } from 'react-icons/ri';
import './style.less';

interface Props {
  dataIndicator: any;
  style?: CSSProperties;
}
const metricDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.metric_indicator_example });

export default function PreviewIndicator(props: Props) {
  const { dataIndicator, style } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [resultData, setResultData] = useState(null);

  const [isCompare, setIsCompare] = useState(false);
  const [showCompare, setShowCompare] = useState(false);

  async function handleGetDataIndicator(payload): Promise<void> {
    setLoading(true);
    await metricDataSource.handleCreate(payload, {
      onSuccess({ response }: any) {
        setLoading(false);
        setResultData({ ...response });
      },
      onFailed: ({ message }: any): any => {
        setLoading(false);
        setError(message);
      },
    });
  }

  function transformFilter(filter, dataIndicator) {
    const period_type = filter?.period_type;
    let date_to = filter?.date_to ?? moment();

    if (period_type === 'month') {
      date_to = moment(filter.date_to).endOf('month');
    } else if (period_type === 'week') {
      date_to = moment(filter.date_to).endOf('week');
    } else {
      date_to = moment(filter.date_to);
    }

    const newFilter = {
      ...filter,
      date_to,
      region_ids: undefined,
      sub_region_ids: undefined,
    };

    if (dataIndicator?.scope === 'region' && filter?.region_ids) {
      Object.assign(newFilter, {
        region_ids: makeArrayIds({ data: filter?.region_ids }).map((item) => item.toString()),
      });
    } else if (dataIndicator?.scope === 'sub_region' && filter?.sub_region_ids) {
      Object.assign(newFilter, {
        sub_region_ids: makeArrayIds({ data: filter?.sub_region_ids }).map((item) => item.toString()),
      });
    }

    return { ...newFilter, ...dataIndicator };
  }

  useEffect(() => {
    if (dataIndicator) {
      handleGetDataIndicator(transformFilter(dataIndicator.filter_dashboard, dataIndicator));
      if (
        dataIndicator?.style === STYLE_INDICATOR.COMPARE_METRIC ||
        dataIndicator?.style === STYLE_INDICATOR.SINGLE_METRIC
      ) {
        setIsCompare(true);
      }
    }
  }, [dataIndicator]);

  function IndicatorContent() {
    if (error) {
      return (
        <div>
          <div style={{ marginBottom: '5px' }}>Error:</div>
          <Tag color="error" className="error-area">
            {error}
          </Tag>
        </div>
      );
    } else if (dataIndicator?.style === STYLE_INDICATOR.COMPARE_METRIC) {
      setShowCompare(true);
      // return <div></div>;
      return <CompareIndicator indicatorContent={resultData} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.TRENDING_METRIC) {
      setShowCompare(true);
      return <TrendingIndicator indicatorContent={resultData} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.SINGLE_METRIC) {
      setShowCompare(true);
      // return <div></div>;
      return <SingleIndicator indicatorContent={resultData} isCompare={isCompare} />;
    } else if (dataIndicator?.style === STYLE_INDICATOR.SCORES_LABEL_METRIC) {
      setShowCompare(false);
      // return <div></div>;
      return <ScoreIndicator indicatorContent={resultData} isCompare={isCompare} />;
    }
  }

  return (
    <Card
      className="card-content-wrapper card-dashboard-wrapper metric-indicator-wrapper"
      style={{
        ...(style ?? {}),
        padding: '10px 25px 25px 25px',
        // padding: dataIndicator?.style === STYLE_INDICATOR.COMPARE_METRIC ? '5px 15px 15px 5px' : 0,
        // minWidth: loading ? 300 : 'fit-content',
      }}
    >
      {loading && <Skeleton active paragraph={{ rows: 4 }} style={{ marginTop: 15, height: 100 }} />}
      {!loading && (
        <React.Fragment>
          {showCompare && (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div className="switch-compare-button" onClick={() => setIsCompare(!isCompare)} style={{ width: 25 }}>
                <Tooltip title={`${isCompare ? 'Year on Year ' : 'This year'}`} placement="bottom">
                  <RiArrowLeftRightFill
                    key={1}
                    style={{ fontSize: 18, cursor: 'pointer', right: 0, color: isCompare ? 'orange' : 'black' }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          <div style={{ marginBottom: 10 }}></div>
          <IndicatorContent />
        </React.Fragment>
      )}
    </Card>
  );
}
