import React from 'react';
import { Select, SelectProps } from 'antd';
const { Option } = Select;

interface OptionsEntity {
  label: string;
  value: string;
}
interface Props extends SelectProps {
  selectOptions?: OptionsEntity[];
}

export default function SelectComponent(props: Props) {
  const { selectOptions = [] } = props;
  return (
    <Select {...props} showSearch>
      {selectOptions.map((item) => (
        <Option key={item.value}>{item.label}</Option>
      ))}
    </Select>
  );
}
