import { RouteEntity } from '@base-entities';

interface IHistoryRouteHelper {
  pushHistoryRoute(route: RouteEntity): void;
  popHistoryRoute(count: number): RouteEntity[];
  clearHistoryRoute(): void;
}

class HistoryRouteHelper implements IHistoryRouteHelper {
  private historyRouteKey = 'historyRoute';

  protected encodeHistoryRoute(obj: RouteEntity[]): string {
    if (!obj) return '';
    return btoa(JSON.stringify(obj));
  }
  protected decodeHistoryRoute(str: string): RouteEntity[] {
    if (!str) return [];
    return JSON.parse(atob(str));
  }

  pushHistoryRoute(route: RouteEntity): void {
    const historyRoute = this.popHistoryRoute(9);
    const newHistoryRoute = [route, ...historyRoute];
    localStorage.setItem(this.historyRouteKey, this.encodeHistoryRoute(newHistoryRoute));
  }

  popHistoryRoute(count: number, withCurrentRoute = true): RouteEntity[] {
    const historyRoute = this.decodeHistoryRoute(localStorage.getItem(this.historyRouteKey));
    if (withCurrentRoute) return historyRoute.filter((_, index) => index < count);
    return historyRoute.filter((_, index) => index !== 0 && index < count);
  }

  clearHistoryRoute(): void {
    localStorage.setItem(this.historyRouteKey, this.encodeHistoryRoute([]));
  }
}

export const historyRoute = new HistoryRouteHelper();
