import React from 'react';
import { DetailBuilder, DetailPageWrapper } from '@base-components';
import { makeUrlImageUser, RegionScopeOptions, ScopeValue } from '../helpers';
import { Card, Col, Row } from 'antd';

const grid = { xs: 24, sm: 10, md: 7, lg: 5, xl: 5 };
function hiddenRegion({ getFieldValue }) {
  const scope = getFieldValue(['detail_data', 'scope']);
  if (scope !== ScopeValue.region_subregion) return true;
  return false;
}

export default function PageDetail() {
  return (
    <DetailPageWrapper
      showUpdate={false}
      showDuplicate={false}
      showButtonAddNew={false}
      showDelete={false}
      showActivate={false}
      showDeactivate={false}
      toolbarFixRightContent={<div style={{ width: 50 }}></div>}
    >
      <Card className="card-content-wrapper">
        <Row>
          <Col xs={24} sm={8} md={8} lg={5} xl={4}>
            <DetailBuilder
              columns={[
                {
                  title: 'PROFILE PICTURE',
                  fields: [
                    {
                      fieldType: 'image',
                      valuePropName: 'imageUrl',
                      name: ['detail_data', 'profile_image'],
                      renderImageProps: {
                        width: 130,
                        height: 130,
                        fallbackType: 'user',
                        makeImageUrl(props) {
                          return makeUrlImageUser(props.imageUrl);
                        },
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
          <Col xs={24} sm={18} md={18} lg={19} xl={20}>
            <DetailBuilder
              columns={[
                {
                  title: 'INDIVIDUAL INFORMATION',
                  fields: [
                    {
                      gridColumn: grid,
                      label: 'Name',
                      name: ['detail_data'],
                      fieldType: 'text',
                      renderTextProps: {
                        defaultRender: () => '-',
                        renderValue(value) {
                          return `${value?.first_name} ${value?.last_name ?? ''}`;
                        },
                      },
                    },
                    {
                      gridColumn: grid,
                      label: 'Phone Number',
                      name: ['detail_data', 'phone'],
                      fieldType: 'text',
                      renderTextProps: {
                        defaultRender: () => '-',
                      },
                    },

                    {
                      gridColumn: grid,
                      label: 'Email',
                      name: ['detail_data', 'email'],
                      fieldType: 'text',
                      renderTextProps: {
                        defaultRender: () => '-',
                      },
                    },
                  ],
                },
                {
                  title: 'This user will be admin for:',
                  fields: [
                    {
                      gridColumn: grid,
                      label: 'Scope',
                      name: ['detail_data', 'scope'],
                      fieldType: 'text',
                      renderTextProps: {
                        defaultRender: () => '-',
                        renderValue: (value) => RegionScopeOptions.find((item) => item.value === value)?.label,
                      },
                    },
                    {
                      gridColumn: grid,
                      label: 'Region',
                      name: ['detail_data', 'scope_regions'],
                      customHidden: hiddenRegion,
                      fieldType: 'text',
                      renderTextProps: {
                        renderType: 'multi',
                        defaultRender: () => '-',
                        renderValue: (value) => value?.rc_dpw_name,
                      },
                    },
                    {
                      gridColumn: grid,
                      label: 'Local Church',
                      name: ['detail_data', 'scope_sub_regions'],
                      customHidden: hiddenRegion,
                      fieldType: 'text',
                      renderTextProps: {
                        renderType: 'multi',
                        defaultRender: () => '-',
                        renderValue: (value) => value?.church_name,
                      },
                    },
                    {
                      gridColumn: grid,
                      label: 'Satellite',
                      name: ['detail_data', 'scope_sub_regions_tpi'],
                      customHidden: hiddenRegion,
                      fieldType: 'text',
                      renderTextProps: {
                        renderType: 'multi',
                        defaultRender: () => '-',
                        renderValue: (value) => value?.church_name,
                      },
                    },
                  ],
                },
              ]}
            />
          </Col>
        </Row>
      </Card>
    </DetailPageWrapper>
  );
}
