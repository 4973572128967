import { FormInstance } from 'antd';
import React from 'react';
export interface DataTableContextEntity {
  form?: FormInstance;
  editingKey?: string;
  setEditingKey?: (key: string) => void;
  recordData: any[];
  setRecordData(data: any[]): void;
  selectedData: any[];
  setSelectedData(data: any[]): void;
  meta: any;
  setMeta(data: any): void;
  filter: any;
  setFilter(data: any): void;
  loading: boolean;
  setLoading(data: boolean): void;
  handleGetDataIndex(filter?: any, isLoadMore?: boolean): void;
  handleDelete(payload: any): void;
  handleActivate(payload: any): void;
  handleDeactivate(payload: any): void;
  handleUpdate(payload: any): void;
  handleDuplicate(payload: any): void;
  handleDetail?(payload: any): void;
  tableType?: 'inline-form' | 'modal-form' | 'normal';
  onConfirmSave?(payload?: any): void;
  formAction?: 'create' | 'update' | 'duplicate';
  setFormAction?: (key: 'create' | 'update' | 'duplicate') => void;
  onCancelEditing?(payload?: any): void;
  isEditing?(payload?: any): boolean;
  disableAddNew?: boolean;
  setDisableAddNew?(data: boolean): void;
}

interface DataTableProviderProps {
  initialValue: DataTableContextEntity;
  children: React.ReactNode | JSX.Element;
}
export const DataTableContext = React.createContext<DataTableContextEntity>(null);

export function DataTableProvider(props: DataTableProviderProps) {
  const { children, initialValue } = props;

  return (
    <React.Fragment>
      <DataTableContext.Provider value={initialValue}>{children}</DataTableContext.Provider>
    </React.Fragment>
  );
}
