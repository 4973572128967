import { authHelper, makeKeyGridLayout } from '@base-helpers';
import { uniq } from 'lodash';
import moment from 'moment';

export function parsePeriod(month = []) {
  const newData = uniq(
    month?.map((item) => {
      if (!item) return;
      return moment(item).format('MMM YY');
    }) ?? [],
  ).join(' - ');
  const arrNewData = newData.split(' - ');
  if (arrNewData.length > 1) return newData;
  return moment(month[0])?.format('MMMM YY');
}

export function getIndicatorLayoutFromLS(key) {
  const account = authHelper.getAccount();
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(makeKeyGridLayout({ account, prefix: 'indicator' }))) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

export function saveIndicatorLayoutToLS(key, value) {
  const account = authHelper.getAccount();
  // console.log({ account });
  if (global.localStorage) {
    global.localStorage.setItem(
      makeKeyGridLayout({ account, prefix: 'indicator' }),
      JSON.stringify({
        [key]: value,
      }),
    );
  }
}

export function resetIndicatorLayout() {
  const account = authHelper.getAccount();
  if (global.localStorage) {
    global.localStorage.removeItem(makeKeyGridLayout({ account, prefix: 'indicator' }));
  }
}
