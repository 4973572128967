import React from 'react';
import { RouteEntity } from '@base-entities';
import { useParams } from 'react-router-dom';
import { WebUrlData } from '@base-configs';

import PreviewFormBuilderComponent from '@pages/testing-pages/preview-form-generator';
import { MapFactory } from '@pages/map-pages/presentation/factories';
import { DashboardFactory } from '@pages/dashboard-pages/presentation/factories';

//Data
import { DataFeature } from '@pages/data-pages';
import { DataUserFactory } from '@pages/data-pages/user/presentation/factories';
import { HeadquarterFactory } from '@pages/data-pages/headquarter/presentation/factories';
import { RegionFactory } from '@pages/data-pages/region/presentation/factories';

//Activity
import { ActivityFeature } from '@pages/activity-pages';
import { ActivityFactory } from '@pages/activity-pages/activity/presentation/factories';
import { SummaryFactory } from '@pages/activity-pages/summary/presentation/factories';

//Crm
import { CrmFeature } from '@pages/crm-pages';
import { BoardFactory } from '@pages/crm-pages/board/presentation/factories';

//Configuration
import { ConfigurationFeature } from '@pages/configuration-pages';
import { CategoryConfigurationFactory } from '@pages/configuration-pages/category/presentation/factories';
import { GeneralConfigurationFactory } from '@pages/configuration-pages/general/presentation/factories';
import { WorkflowConfigurationFactory } from '@pages/configuration-pages/workflow/presentation/factories';
import { DashboardConfigurationFactory } from '@pages/configuration-pages/dashboard/presentation/factories';
import { EmailTemplateConfigurationFactory } from '@pages/configuration-pages/email-template/presentation/factories';
import { MetricIndicatorFactory } from '@pages/configuration-pages/metric-indicator/presentation/factories';

//Maintenance
import { MaintenanceFeature } from '@pages/maintenance-pages';

//Template
import { TemplateFeature } from '@pages/template-pages';
import { SimpleTemplateFactory } from '@pages/template-pages/simple-template/presentation/factories';
import { AdvanceTemplateFactory } from '@pages/template-pages/advance-template/presentation/factories';
import { LogFactory } from '@pages/maintenance-pages/log-data/presentation/factories';
import { DatabaseDeletionFactory } from '@pages/maintenance-pages/database-deletion/presentation/factories';

import NotificationFactory from '@pages/notification-pages/presentation/factories';
import { ReportFactory } from '@pages/report-pages/presentation/factories';

export const DefaultPage = (): JSX.Element => {
  const params = useParams();
  return <div>You are in default page {params.action}</div>;
};

export const DefaultPath = WebUrlData.map;
export const AuthRoutes: RouteEntity[] = [
  {
    label: 'Map',
    path: WebUrlData.map,
    element: MapFactory,
  },
  {
    label: 'Dashboard',
    path: WebUrlData.dashboard,
    element: DashboardFactory,
  },
  {
    label: 'Report',
    path: WebUrlData.report,
    element: ReportFactory,
  },

  //activity
  {
    label: 'Activity',
    path: WebUrlData.activity,
    element: ActivityFeature,
  },
  {
    label: 'Activity',
    path: WebUrlData.activity_activities,
    element: ActivityFactory,
  },

  {
    label: 'Summary',
    path: WebUrlData.activity_summary,
    element: SummaryFactory,
  },

  //crm
  {
    label: 'CRM',
    path: WebUrlData.crm,
    element: CrmFeature,
  },
  {
    label: 'Board',
    path: WebUrlData.crm_board,
    element: BoardFactory,
  },

  //Configuration
  {
    label: 'Data',
    path: WebUrlData.data,
    element: DataFeature,
  },

  {
    label: 'Headquarter',
    path: WebUrlData.data_headquarter,
    element: HeadquarterFactory,
  },

  {
    label: 'Region',
    path: WebUrlData.data_region,
    element: RegionFactory,
  },

  {
    label: 'User',
    path: WebUrlData.data_user,
    element: DataUserFactory,
  },

  //Configuration
  {
    label: 'Configuration',
    path: WebUrlData.configuration,
    element: ConfigurationFeature,
  },
  {
    label: 'Category',
    path: WebUrlData.configuration_category,
    element: CategoryConfigurationFactory,
  },
  {
    label: 'General Configuration',
    path: WebUrlData.configuration_general_configuration,
    element: GeneralConfigurationFactory,
  },
  {
    label: 'Workflow Configuration',
    path: WebUrlData.configuration_workflow,
    element: WorkflowConfigurationFactory,
  },

  {
    label: 'Dashboard Configuration',
    path: WebUrlData.configuration_dashboard,
    element: DashboardConfigurationFactory,
  },
  {
    label: 'Email Template',
    path: WebUrlData.configuration_email_template,
    element: EmailTemplateConfigurationFactory,
  },
  {
    label: 'Metric Indicator',
    path: WebUrlData.configuration_metric_indicator,
    element: MetricIndicatorFactory,
  },

  //maintenance
  {
    label: 'Maintenance',
    path: WebUrlData.maintenance,
    element: MaintenanceFeature,
  },
  {
    label: 'Log',
    path: WebUrlData.maintenance_log,
    element: LogFactory,
  },
  {
    label: 'Database Deletion',
    path: WebUrlData.maintenance_database_deletion,
    element: DatabaseDeletionFactory,
  },

  // Notification
  {
    label: 'Notification',
    path: WebUrlData.notification,
    element: NotificationFactory,
  },

  //template
  {
    label: 'Template',
    path: WebUrlData.template,
    element: TemplateFeature,
  },
  {
    label: 'Simple Template',
    path: WebUrlData.simple_template,
    element: SimpleTemplateFactory,
  },
  {
    label: 'Advance Template',
    path: WebUrlData.advance_template,
    element: AdvanceTemplateFactory,
  },
  {
    label: 'Preview Form Generator',
    path: WebUrlData.preview_form_generator,
    element: PreviewFormBuilderComponent,
  },
];
