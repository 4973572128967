import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Tooltip } from 'antd';
import { Check } from 'phosphor-react';
import { usePageProviderAction } from '@base-hooks';
import { notificationFailed, notificationSuccess } from '@base-helpers';

interface Props {
  item: any;
  showForm: boolean;
  updateAfterSave(payload: any): void;
}
export default function UpdateCard({ item, showForm, updateAfterSave }: Props): JSX.Element {
  const { dataSource } = usePageProviderAction();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showForm && item) form.setFieldsValue(item);
    else form.resetFields();
  }, [showForm, item]);

  async function onFinish(payload) {
    setLoading(true);
    await dataSource.handleUpdate(
      item.id,
      {
        name: payload.name,
        position: payload.position,
      },
      {
        onSuccess: ({ response }) => {
          console.log(response);
          setLoading(false);
          setVisible(false);
          notificationSuccess(['Successfully updated data.']);
          updateAfterSave(response);
        },
        onFailed: ({ message, statusCode }: any) => {
          console.log({ message, statusCode });
          setLoading(false);
          setVisible(false);
          notificationFailed(message);
        },
      },
    );
  }

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <Form name="update_card" form={form} onFinish={onFinish}>
      <Form.Item shouldUpdate noStyle name="position"></Form.Item>
      <Row className="Workflow__form">
        <Col span={20}>
          <Form.Item
            name="name"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Please input workflow title !' }]}
          >
            <Input placeholder="Add Name" className="Workflow__form-input" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Popconfirm
            placement="top"
            title="Update Title ?"
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
            okButtonProps={{ loading: loading }}
            onConfirm={() => form.submit()}
          >
            <Form.Item style={{ margin: 0 }}>
              <Tooltip placement="top" title="Save">
                <Button type="text" shape="circle" htmlType="submit" loading={loading} icon={<Check size={14} />} />
              </Tooltip>
            </Form.Item>
          </Popconfirm>
        </Col>
      </Row>
    </Form>
  );
}
