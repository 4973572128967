import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import { AdvanceTemplateTransformerRepository } from '../repositories';

export class AdvanceTemplateTransformer<E extends BaseEntity = BaseEntity>
  extends BaseTransformer<E>
  implements AdvanceTemplateTransformerRepository<E>
{
  transformerCustomData(payload: E): E {
    return payload;
  }
}
