import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space, List, Tag, Avatar, Tooltip } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import {
  Calendar,
  EnvelopeSimple,
  ListChecks,
  NotePencil,
  Phone,
  Student,
  Trash,
  UsersThree,
  WarningCircle,
} from 'phosphor-react';

import { dateFormatter } from '@base-helpers';

import FormTaskDrawer from '../form-task-drawer';
import { validateMessages } from '@base-components';

import './index.less';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { CategoryOptions, SendMethod } from '@pages/crm-pages/board/presentation/helpers/options';

interface Props {
  value?: any;
  onChange?(value: any): void;
  showAddTask?: boolean;
  showDeleteTask?: boolean;
  showEditTask?: boolean;
  scope?: string;
}
export function FormTask(props: Props): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const scope_ls = authUser?.scope;

  const { value, onChange, scope, showAddTask = true, showDeleteTask = true, showEditTask = true } = props;
  const [visible, setVisible] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (value) {
      const newValue = value.map((item) => {
        return {
          ...item,
          key: item.key ?? item.id ?? uuidV4(),
        };
      });

      setTaskList(newValue);
    }
  }, [value]);

  const onChangeValue = (payload) => {
    if (onChange) onChange(payload);
  };

  useEffect(() => {
    if (scope && value) {
      const newValue = value.map((item) => {
        return {
          ...item,
          scope,
        };
      });
      onChangeValue(newValue);
    }
  }, [scope]);

  const handleOnSave = (payload) => {
    const findKey = taskList.find((list) => list.key === payload.key);

    let newValue = [];

    if (findKey) {
      newValue = taskList.map((list) => {
        if (list.key !== payload.key) return list;

        return payload;
      });
    } else {
      newValue = [{ ...payload, key: uuidV4() }, ...taskList];
    }

    onChangeValue(newValue);
    onChildrenDrawerClose();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
    form.resetFields();
  };

  function onDeleteList(item) {
    const newData = taskList.filter((list) => list.key !== item.key);
    onChangeValue(newData);
  }

  function makeImage(url) {
    const previewUrl = `${process.env.REACT_APP_BASE_ASSET_URL}${url}`;
    return previewUrl;
  }

  const TaskCategoryOptions = [
    {
      icon: <EnvelopeSimple weight="bold" />,
      color: 'blue',
      label: 'Send Email',
      value: 'send_email',
    },
    {
      icon: <UsersThree weight="bold" />,
      color: 'blue',
      label: 'Meeting',
      value: 'meeting',
    },
    {
      icon: <Student weight="bold" />,
      color: 'blue',
      label: 'Training',
      value: 'training',
    },
    {
      icon: <Phone weight="bold" />,
      color: 'blue',
      label: 'Call',
      value: 'call',
    },
    {
      icon: <WarningCircle weight="bold" />,
      color: 'blue',
      label: 'Other',
      value: 'other',
    },
  ];

  const handleValuesChange = (item, values) => {
    const task_category = Object.keys(item).includes('task_category');
    if (task_category) {
      if (scope_ls === 'global') {
        form.setFieldsValue({
          ...values,
          venue_type: null,
        });
      } else {
        form.setFieldsValue({
          ...values,
          venue_type: null,
          send_method: item?.task_category === CategoryOptions.send_email ? SendMethod.manual : undefined,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Button size="small" type="text" onClick={showDrawer}>
        <ListChecks style={{ marginRight: '3px' }} /> Task
      </Button>
      <Drawer
        title="Detail List"
        width={600}
        closable={false}
        onClose={onClose}
        visible={visible}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                form.setFieldsValue({
                  scope,
                });
                showChildrenDrawer();
              }}
              hidden={!showAddTask}
            >
              Add Task
            </Button>
          </Space>
        }
      >
        <List
          itemLayout="vertical"
          dataSource={taskList}
          bordered
          renderItem={(item) => {
            if (taskList.length === 0) {
              return <h1>Task Not Found</h1>;
            }
            const CardCategory = TaskCategoryOptions.find((itemTask) => itemTask.value === item?.task_category);

            return (
              <List.Item className="custom-list">
                <div className="list-item">
                  <div className="list-item-body">
                    <div className="list-item-header">
                      <h3>{item?.task_id}</h3>
                      <p>{item?.summary}</p>
                    </div>
                    <div className="list-item-content">
                      <div className="list-item-content-date">
                        <Calendar size={14} />
                        <span>{dateFormatter(item?.date_plan).toLocal('DD-MM-YYYY')}</span>
                      </div>
                      <div className="list-item-content-tag">
                        <Tag color={CardCategory?.color}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px', marginTop: '3px' }}>{CardCategory.icon}</span>
                            <span>{CardCategory?.label}</span>
                          </div>
                        </Tag>
                      </div>
                      <div className="list-item-content-footer">
                        {item?.assignee?.map((item) => {
                          const username = item?.first_name.split('')?.splice(0, 2)?.join('')?.toUpperCase();
                          return item?.profile_image ? (
                            <Avatar key={item?.id} size="small" src={makeImage(item?.profile_image)} />
                          ) : (
                            <Avatar size="small">{username}</Avatar>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="list-item-action">
                    <Tooltip title="Update Task">
                      <Button
                        key="update"
                        hidden={!showEditTask}
                        type="text"
                        icon={<NotePencil size={14} />}
                        onClick={() => {
                          const newItem = {
                            ...(item ?? {}),
                            date_plan: item?.date_plan ? moment(item?.date_plan) : undefined,
                          };
                          form.setFieldsValue(newItem);
                          showChildrenDrawer();
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Task">
                      <Button
                        key="delete"
                        hidden={!showDeleteTask}
                        type="text"
                        icon={<Trash size={14} />}
                        onClick={() => onDeleteList(item)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
        <Drawer
          title="Add Task"
          width={520}
          closable={false}
          onClose={onChildrenDrawerClose}
          visible={childrenDrawer}
          footer={
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          }
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={handleOnSave}
            onValuesChange={handleValuesChange}
            validateMessages={validateMessages}
            initialValues={{
              scope: scope,
            }}
          >
            <Form.Item name="key" noStyle></Form.Item>
            <FormTaskDrawer />
          </Form>
        </Drawer>
      </Drawer>
    </React.Fragment>
  );
}
