import React from 'react';
import { Button, Tooltip } from 'antd';
import { Play } from 'phosphor-react';
import { useDataTableAction } from '@base-hooks';

export default function RunDeleteAction({ row, handleRunAction, isRunning }: any): JSX.Element {
  const { isEditing } = useDataTableAction();

  if (isEditing(row)) return <div></div>;
  return (
    <Tooltip title="Run Action" placement="bottom">
      <Button
        loading={isRunning}
        type="text"
        icon={<Play size={17} />}
        shape="circle"
        size="small"
        onClick={(evt) => {
          evt.stopPropagation();
          handleRunAction(row);
        }}
      />
    </Tooltip>
  );
}
