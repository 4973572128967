import React from 'react';
import { FormGenerator } from '@base-components';

export default function PreviewFormBuilder() {
  return (
    <div>
      <FormGenerator />
    </div>
  );
}
