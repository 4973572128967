import * as _ from 'lodash';
import { BaseMetaEntity } from '@base-entities';
import { atom, useRecoilState } from 'recoil';

interface CurrentMetaDataTableEntity {
  [key: string]: BaseMetaEntity;
}

const currentMetaDataTableRecoil = atom<CurrentMetaDataTableEntity>({
  key: 'currentMetaDataTableRecoil',
  default: {},
});

export function useMetaDataTable(key: string): any {
  const defaultData: BaseMetaEntity = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
    itemCount: 1,
  };

  const [metaTable, setMetaTable] = useRecoilState(currentMetaDataTableRecoil);
  const currentData = _.pick(metaTable, [key])[key] ?? defaultData;

  function setCurrentData(meta: BaseMetaEntity): void {
    setMetaTable({
      ...metaTable,
      [`${key}`]: meta,
    });
  }

  return [currentData, setCurrentData];
}
