import React from 'react';
import { IndexPageWrapper, InfinityDataTable, RenderText } from '@base-components';
import { dateFormatter } from '@base-helpers';
import { ApiUrlData } from '@base-configs';
import { Card } from 'antd';

export default function PageIndex() {
  const columns: any = [
    {
      title: 'Template Name',
      dataIndex: 'template_name',
      key: 'template_name',
      sorter: true,
      width: 250,
      fixed: true,
      render: (item) => {
        return <RenderText value={item} limitString={30} usePopover />;
      },
    },
    {
      title: 'Subject',
      dataIndex: 'email_subject',
      key: 'email_subject',
      sorter: true,
      width: 250,
      render: (item) => {
        return <RenderText value={item} limitString={30} usePopover />;
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      width: 200,
      render: (item) => dateFormatter(item).toLocal(),
    },
  ];

  return (
    <IndexPageWrapper
      showButtonBack={false}
      drawerFilterProp={{
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  name: 'status',
                  label: 'Status',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    placeholder: 'Select status',
                    options: [
                      { label: 'Active', value: 'Active' },
                      { label: 'Inactive', value: 'Inactive' },
                    ],
                  },
                },
                {
                  name: 'created_ids',
                  label: 'Created By',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    placeholder: 'select user',
                    dataSourceUrl: ApiUrlData.users,
                    customLabel: (value) => value?.first_name,
                  },
                },
                {
                  name: 'updated_ids',
                  label: 'Last Updated By',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    placeholder: 'select user',
                    dataSourceUrl: ApiUrlData.users,
                    customLabel: (value) => value?.first_name,
                  },
                },
                {
                  name: 'created_at',
                  label: 'Created At',
                  gridColumn: { span: 12 },
                  fieldType: 'dateRangePicker',
                },
                {
                  name: 'updated_at',
                  label: 'Last Updated At',
                  gridColumn: { span: 12 },
                  fieldType: 'dateRangePicker',
                },
              ],
            },
          ],
        },
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable columns={columns} />
      </Card>
    </IndexPageWrapper>
  );
}
