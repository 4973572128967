import React, { useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import { useParams } from 'react-router-dom';

export function LoginBlank() {
  const { account } = useParams();
  const [accountData, setAccountData] = useState(undefined);

  function makeRenderData(account): JSX.Element | React.ReactNode {
    if (!account) return 'No account data';
    else return `you login with email ${account.email}`;
  }
  useEffect(() => {
    if (account) {
      const accountObject = JSON.parse(atob(atob(account)));
      setAccountData(accountObject);
    }
  }, [account]);

  return <Result status="success" title={makeRenderData(accountData)} />;
}
