export const BaseStyleSelect = {
  base: (base) => ({
    ...base,
    // zIndex: 9999,
    minHeight: '205px',
  }),

  menu: (provided) => ({
    ...provided,
    // zIndex: 99,
    // paddingTop: '5px',
    marginTop: '5px',
    borderRadius: '2px !important',
  }),

  option: (provided) => ({
    ...provided,
    fontWeight: '400',
    fontSize: '12px',
    color: '#555',
    // zIndex: 999999,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#555',
    top: '55%',
    fontSize: '12px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0 2px',
    paddingLeft: '4px',
    color: '#555',
    fontSize: '12px',
    '&input': {
      background: 'magenta',
    },
  }),
  control: (provided, state) => {
    const style = {
      ...provided,
      width: '100%',
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: '#ccc',
      padding: '0',
      minHeight: '30px !important',
    };
    // if (state?.isMulti && !state?.isDisabled) Object.assign(style, { borderColor: 'purple' });
    return style;
  },
  placeholder: () => ({
    fontWeight: '400',
    fontSize: '11px',
    color: '#c4c4c4 !important',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: '400',
    fontSize: '12px',
    color: '#555',
    overflow: 'visible',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 6px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),

  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#f5f5f5',
      border: 'transparent',
      padding: '0px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '11px !important',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'rgba(0, 0, 0, 0.45)',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    color: 'rgba(0, 0, 0, 0.45)',
  }),
};
