import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { MapEntity } from '../../domain/entities';

import PageIndex from '../pages';

export function MapFactory(): JSX.Element {
  const InitialValue: InitialValuePageProviderEntity<MapEntity> = {
    moduleKey: 'map-page', // make sure this key unique each module
    webUrl: WebUrlData.map,
    apiUrl: ApiUrlData.map,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController PageIndex={PageIndex} />
    </PageProvider>
  );
}
