import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import { CategoryEntity } from '../entities';
import * as _ from 'lodash';

export class CategoryTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  protected category: CategoryEntity;
  constructor(category?: CategoryEntity) {
    super();
    this.category = category;
  }
  transformerGetData(payload: E): E {
    if (!payload) return payload;
    return payload;
  }

  transformerGetIndex(payload: E[]): E[] {
    if (!payload) return [];
    return payload;
  }

  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, {
      parent_id: this.category ? this.category.id : undefined,
    });
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    return this.transformerUpdate(payload);
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }
}
