export enum ScopeValue {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

export const ScopeOptions = [
  { label: 'Global', value: ScopeValue.global },
  { label: 'Region', value: ScopeValue.region },
  { label: 'Church', value: ScopeValue.sub_region },
];
