import React from 'react';
import { IndexPageWrapper, InfinityDataTable, RenderText } from '@base-components';
import { ApiUrlData } from '@base-configs';
import moment from 'moment';
import { Card, DatePicker, Form } from 'antd';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';
import { useFilterDataTable, usePageProviderData } from '@base-hooks';
import { ScopeValue } from '@pages/dashboard-pages/presentation/helpers';

enum Scope {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

const ScopeOptions = [
  { label: 'Global', value: Scope.global },
  { label: 'Region', value: Scope.region },
  { label: 'Church', value: Scope.sub_region },
];

export default function PageIndex(): JSX.Element {
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();

  const scope = authUser.scope;
  const sub_region = authUser?.data_sub_regions ?? [];
  const sub_region_tpi = authUser?.data_sub_regions_tpi ?? [];

  const region_ids = authUser?.data_regions ?? [];
  const sub_region_ids = [...sub_region, ...sub_region_tpi];

  const { moduleKey } = usePageProviderData();
  const [filter, setFilter] = useFilterDataTable(moduleKey);

  function makeDefaultScope(scope) {
    if (scope === ScopeValue.global) return scope;
    else if (scope === ScopeValue.region) {
      if (region_ids.length > 0) return scope;
      else if (sub_region_ids?.length > 0) return ScopeValue.sub_region;
    }
    return scope;
  }

  function filterScopeOptions(options, scope) {
    const newScope = makeDefaultScope(scope);
    if (newScope === ScopeValue.global) return options;
    else if (newScope === ScopeValue.region) return options.filter((item) => item.value !== ScopeValue.global);
    else if (newScope === ScopeValue.sub_region) return options.filter((item) => item.value === ScopeValue.sub_region);
  }

  const columns = [
    {
      title: 'Scope',
      dataIndex: 'scope_activity',
      key: 'scope_activity',
      sorter: true,
      width: 80,
      render(value) {
        return ScopeOptions.find((item) => value === item.value)?.label;
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      sorter: true,
      width: 150,
      render(value) {
        return (
          <RenderText
            renderType="multi"
            value={value}
            defaultRender={() => '-'}
            renderValue={(item) => item?.rc_dpw_name}
          />
        );
      },
    },
    {
      title: 'Local Church & Satellite',
      dataIndex: 'sub_region',
      key: 'sub_region',
      sorter: true,
      width: 180,
      render(value) {
        return (
          <RenderText
            renderType="multi"
            value={value}
            defaultRender={() => '-'}
            renderValue={(item) => item?.church_name}
          />
        );
      },
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      sorter: true,
      width: 100,
      render(_, row) {
        return (
          <RenderText
            value={row}
            renderValue={(date) => {
              const item = date?.month ?? date?.week;
              if (!item) return '';
              else if (row?.month) return moment(item).format('MMMM YYYY');
              return `${moment(item).format('MMMM YYYY')} (Week ${moment(item).week()})`;
            }}
          />
        );
      },
    },
    {
      title: 'Indicator Name',
      dataIndex: 'indicator_name',
      key: 'indicator_name',
      sorter: true,
      width: 150,
    },
    {
      title: 'Result',
      dataIndex: 'indicator_color',
      key: 'indicator_color',
      sorter: true,
      width: 100,
      render(item) {
        return (
          <div
            style={{
              height: '20px',
              width: '70px',
              background: item,
              // border: '1px solid #e0e0e0',
              borderRadius: '2px',
            }}
          />
        );
      },
    },
    {
      title: 'Indicator Label',
      dataIndex: 'indicator_label',
      key: 'indicator_label',
      sorter: true,
      width: 120,
    },
  ];

  return (
    <IndexPageWrapper
      showButtonAddNew={false}
      showButtonBack={false}
      drawerFilterProp={{
        formBuilderProps: {
          columns: [
            {
              fields: [
                {
                  name: 'scope',
                  label: 'Scope',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    placeholder: 'Select scope',
                    options: filterScopeOptions(ScopeOptions, scope),
                    customLabel: (value) => ScopeOptions.find((item) => value === item.value)?.label,
                  },
                },
                {
                  name: 'region_ids',
                  label: 'Region',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    isMulti: true,
                    placeholder: 'Select region',
                    dataSourceUrl: ApiUrlData.region,
                    customLabel: (value) => value?.rc_dpw_name,
                    customFilterRequest: () => ({
                      status: 'Active',
                    }),
                  },
                },
                {
                  name: 'sub_region_ids',
                  label: 'Local Church & Satellite',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    isMulti: true,
                    placeholder: 'Select Local Church',
                    dataSourceUrl: ApiUrlData.sub_region,
                    customLabel: (value) => value?.church_name,
                    customFilterRequest: () => ({
                      status: 'Active',
                    }),
                  },
                },

                {
                  name: 'period_type',
                  label: 'Period Type',
                  fieldType: 'select',
                  gridColumn: { span: 12 },
                  selectProps: {
                    options: [
                      { label: 'monthly', value: 'monthly' },
                      { label: 'weekly', value: 'weekly' },
                    ],
                  },
                },

                {
                  // name: 'period',
                  // label: 'Period',
                  // fieldType: 'dateRangePicker',
                  gridColumn: { span: 12 },
                  // dateRangePickerProps: {
                  //   picker: 'month',
                  // },

                  renderField(form) {
                    const periodType = form.getFieldValue('period_type');
                    return (
                      <Form.Item name="period" label="Period">
                        <DatePicker.RangePicker
                          disabled={!periodType}
                          picker={periodType === 'monthly' ? 'month' : 'week'}
                        />
                      </Form.Item>
                    );
                  },
                },
                {
                  name: 'indicator_name',
                  label: 'Indicator Name',
                  fieldType: 'selectPaginate',
                  gridColumn: { span: 12 },
                  selectPaginateProps: {
                    placeholder: 'Indicator name',
                    dataSourceUrl: ApiUrlData.metric_indicator,
                    customLabel: (value) => value?.name,
                  },
                },
                {
                  name: 'indicator_label',
                  label: 'Indicator Label',
                  fieldType: 'inputText',
                  gridColumn: { span: 12 },
                },
              ],
            },
          ],
        },
      }}
    >
      <Card className="card-content-wrapper">
        <InfinityDataTable
          useRowSelection={false}
          userPointerCursor={false}
          useDirectDetailOnRowClick={false}
          ignoreDefaultColumn={['action']}
          scroll={{ x: 'fit-content', y: 450 }}
          columns={columns}
        />
      </Card>
    </IndexPageWrapper>
  );
}
