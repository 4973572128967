import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';

export const boardColumns = [
  { id: uuidV4(), title: 'BACKLOG' },
  { id: uuidV4(), title: 'TODO' },
  { id: uuidV4(), title: 'FIXING' },
  { id: uuidV4(), title: 'IN PROGRESS' },
  { id: uuidV4(), title: 'COMMIT' },
  { id: uuidV4(), title: 'REVIEW' },
  { id: uuidV4(), title: 'PIPELINE' },
  { id: uuidV4(), title: 'USER TESTING' },
  { id: uuidV4(), title: 'DONE' },
];

export const sourceData = [
  {
    id: uuidV4(),
    date: moment().add(1, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        title: 'Card 1',
        code: 'WO01[2-220400001]',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[3].id,
        title: 'Card 2',
        code: 'WO02[2-220400002]',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(2, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO03[2-220400003]',

        title: 'Card 3',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO04[2-220400004]',

        title: 'Card 4',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(3, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[2].id,
        code: 'WO05[2-220400005]',

        title: 'Card 5',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[2].id,
        code: 'WO06[2-220400006]',

        title: 'Card 6',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(4, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[4].id,
        code: 'WO07[2-220400007]',

        title: 'Card 7',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO08[2-220400008]',
        title: 'Card 8',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(5, 'days').format('DD-MM-YYYY'),
    cards: [
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO09[2-220400009]',
        title: 'Card 9',
        description: 'Description 1',
      },
      {
        id: uuidV4(),
        board_column_id: boardColumns[0].id,
        code: 'WO10[2-220400010]',
        title: 'Card 10',
        description: 'Description 2',
      },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
  {
    id: uuidV4(),
    date: moment().add(6, 'days').format('DD-MM-YYYY'),
    cards: [
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 11', description: 'Description 1' },
      { id: uuidV4(), board_column_id: boardColumns[0].id, title: 'Card 12', description: 'Description 2' },
    ],
  },
];

export const sourceDataHardCode = [
  {
    id: uuidV4(),
    key: 1,
    scope: 'global',
    label: 'Global',
    cards: [],
  },
  {
    id: uuidV4(),
    key: 2,
    scope: 'region',
    label: 'Region',
    cards: [],
  },
  {
    id: uuidV4(),
    key: 3,
    scope: 'sub_region',
    label: 'Church',
    cards: [],
  },
];
