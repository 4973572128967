import React, { useEffect, useState } from 'react';
import { Button, PageHeader as Header, Menu, Dropdown, Modal, FormInstance, Tooltip, Tag, Input, Row, Col } from 'antd';
import './page-header.less';
import {
  useCollapsibleLayout,
  useCurrentRoute,
  useMediaQuery,
  usePageProviderAction,
  usePageProviderData,
  useShowSidebarLayout,
} from '@base-hooks';
import { capitalize } from 'lodash';

import { stringFormatter, historyRoute } from '@base-helpers';
import { Link } from 'react-router-dom';
import { EnumColorStatusData, EnumStatusData, RouteEntity } from '@base-entities';
import {
  Check,
  LockKeyOpen,
  CopySimple,
  Funnel,
  LockKey,
  Note,
  NotePencil,
  Plus,
  Printer,
  TrashSimple,
  UserGear,
  UserList,
} from 'phosphor-react';
import { ArrowLeftOutlined, ReloadOutlined, HistoryOutlined, PushpinOutlined, MoreOutlined } from '@ant-design/icons';
import { DrawerFilterProps, DrawerFilter } from './drawer-filter';

function makeTitleAction(action: string): string {
  if (!action) return '';
  else if (action === 'create' || action === 'duplicate') return 'new';
  return action;
}

export interface PageHeaderProps {
  onReload?: () => void;
  title?: string;

  showSearch?: boolean;
  showButtonAddNew?: boolean;
  showButtonBack?: boolean;
  showButtonReload?: boolean;
  showButtonHistory?: boolean;

  showButtonMoreAction?: boolean;
  showConfirmProcess?: boolean;
  showUpdate?: boolean;
  showDelete?: boolean;
  showDuplicate?: boolean;
  showPrint?: boolean;
  showLog?: boolean;
  showNote?: boolean;
  showApproval?: boolean;
  showActivate?: boolean;
  showDeactivate?: boolean;

  showBatchDelete?: boolean;
  showBatchActivate?: boolean;
  showBatchDeactivate?: boolean;
  showFilter?: boolean;

  showButtonSave?: boolean;

  titleConfirmProcess?: JSX.Element | React.ReactNode;
  titleConfirmDelete?: JSX.Element | React.ReactNode;
  titleConfirmSave?: JSX.Element | React.ReactNode;
  titleConfirmActivate?: JSX.Element | React.ReactNode;
  titleConfirmDeactivate?: JSX.Element | React.ReactNode;

  contentConfirmProcess?: JSX.Element | React.ReactNode;
  contentConfirmDelete?: JSX.Element | React.ReactNode;
  contentConfirmSave?: JSX.Element | React.ReactNode;
  contentConfirmActivate?: JSX.Element | React.ReactNode;
  contentConfirmDeactivate?: JSX.Element | React.ReactNode;

  onConfirmSave?: (payload?: any) => void;
  onConfirmProcess?: (payload?: any) => void;
  onConfirmDelete?: (payload?: any) => void;
  onConfirmActivate?: (payload?: any) => void;
  onConfirmDeactivate?: (payload?: any) => void;

  onBatchConfirmProcess?: (payload?: any) => void;
  onConfirmBatchDelete?: (payload?: any) => void;
  onConfirmBatchActivate?: (payload?: any) => void;
  onConfirmBatchDeactivate?: (payload?: any) => void;
  onSearch?: (payload?: any) => void;
  handleAddNew?: () => void;
  handleUpdate?: () => void;
  handleDuplicate?: () => void;

  form?: FormInstance;
  disableAddNew?: boolean;
  selectedRows?: any[];
  rowData?: any;

  showRightAction?: boolean;
  showTagStatus?: boolean;
  defaultValueSearch?: string;
  drawerFilterProp?: DrawerFilterProps;
  toolbarFixRightContent?: JSX.Element | React.ReactNode;
}

const defaultTitleConfirmProcess = 'Confirm Process Confirmation';
const defaultTitleConfirmDelete = 'Delete Confirmation';
const defaultTitleConfirmSave = 'Save Confirmation';
const defaultTitleConfirmActivate = 'Activate Confirmation';
const defaultTitleConfirmDeactivate = 'Deactivate Confirmation';

const defaultContentConfirmProcess = 'Are you sure you want to confirm this process?';
const defaultContentConfirmDelete = 'Are you sure you want to delete this?';
const defaultContentConfirmSave = 'Are you sure you want to save this?';
const defaultContentConfirmActivate = 'Are you sure you want to activate this?';
const defaultContentConfirmDeactivate = 'Are you sure you want to deactivate this?';

export function PageHeader(props: PageHeaderProps) {
  const isMobile = useMediaQuery({ media: 'mobile' });
  const shortWidth = isMobile ? 5 : 10;
  const longWidth = isMobile ? 5 : 30;

  const {
    onReload,
    title,
    showSearch = false,
    showButtonAddNew = false,
    showButtonBack = true,
    showButtonReload = true,
    showButtonHistory = true,

    showButtonMoreAction = true,
    showConfirmProcess = true,
    showUpdate = true,
    showDelete = true,
    showDuplicate = true,
    showPrint = true,
    showLog = true,
    showNote = true,
    showApproval = true,
    showActivate = true,
    showDeactivate = true,

    showButtonSave = true,

    showBatchActivate = false,
    showBatchDeactivate = false,
    showBatchDelete = false,
    showFilter = false,

    contentConfirmSave = defaultContentConfirmSave,
    contentConfirmDelete = defaultContentConfirmDelete,
    contentConfirmProcess = defaultContentConfirmProcess,
    contentConfirmActivate = defaultContentConfirmActivate,
    contentConfirmDeactivate = defaultContentConfirmDeactivate,

    titleConfirmSave = defaultTitleConfirmSave,
    titleConfirmDelete = defaultTitleConfirmDelete,
    titleConfirmProcess = defaultTitleConfirmProcess,
    titleConfirmActivate = defaultTitleConfirmActivate,
    titleConfirmDeactivate = defaultTitleConfirmDeactivate,

    onConfirmProcess,
    onConfirmDelete,
    onConfirmSave,
    onConfirmActivate,
    onConfirmDeactivate,

    onBatchConfirmProcess,
    onConfirmBatchDelete,
    onConfirmBatchActivate,
    onConfirmBatchDeactivate,
    onSearch,
    form,
    handleAddNew,
    handleUpdate,
    handleDuplicate,
    disableAddNew = false,
    selectedRows,
    rowData,
    showRightAction = false,
    showTagStatus = false,
    defaultValueSearch,
    drawerFilterProp,
    toolbarFixRightContent,
  } = props;

  const [showMoreAction, setShowMoreAction] = useState(showButtonMoreAction);

  useEffect(() => {
    if (
      !showConfirmProcess &&
      !showUpdate &&
      !showDelete &&
      !showDuplicate &&
      !showPrint &&
      !showLog &&
      !showNote &&
      !showApproval &&
      !showActivate &&
      !showDeactivate
    ) {
      setShowMoreAction(false);
    } else {
      setShowMoreAction(showButtonMoreAction);
    }
  }, [showConfirmProcess, showUpdate, showDelete, showDuplicate, showPrint, showLog, showNote, showApproval]);

  const [loadingReload, setLoadingReload] = useState(false);
  const menuRouteData = historyRoute.popHistoryRoute(5) ?? [];
  const [visibleDrawerFilter, setVisibleDrawerFilter] = useState(false);

  function toggleDrawerFilter() {
    setVisibleDrawerFilter(!visibleDrawerFilter);
  }

  const { id, action } = usePageProviderData();
  const { navigation } = usePageProviderAction();
  const [collapsed] = useCollapsibleLayout();
  const [showSidebar] = useShowSidebarLayout();

  const [currentRoute] = useCurrentRoute();

  const label = currentRoute?.label
    ? `${capitalize(makeTitleAction(action))} ${stringFormatter.capitalizeEachWord(currentRoute?.label)}`
    : 'Page Header';

  async function onReloadPage() {
    setLoadingReload(true);
    if (onReload) await onReload();
    setLoadingReload(false);
  }

  function makeMenuHistory(menuData: RouteEntity[]): JSX.Element {
    const actionMenu = ['create', 'duplicate', 'update', 'detail'];
    const newMenuItem = menuData; //TODO create optional filter for unique item

    return (
      <Menu style={{ width: '200px' }}>
        {newMenuItem.map((item, idxItem) => {
          const pathArr = item.path.split('/');
          const pathAction = pathArr[pathArr.length - 1];
          const hasAction = actionMenu.includes(pathAction);

          return (
            <Menu.Item key={idxItem}>
              <Link to={`${item.path}${item.search ? item.search : ''}`}>
                <span style={{ marginRight: '10px' }}>
                  <PushpinOutlined />
                </span>
                {capitalize(`${hasAction ? `${pathAction} ` : ''}${item.label}`)}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  async function onOkConfirmProcess(): Promise<void> {
    if (onConfirmProcess) await onConfirmProcess();
  }

  async function onOkConfirmDelete(): Promise<void> {
    if (onConfirmDelete) await onConfirmDelete();
  }

  async function onOkConfirmBatchDelete(): Promise<void> {
    if (onConfirmBatchDelete) await onConfirmBatchDelete(selectedRows);
  }

  async function onOkConfirmActivate(): Promise<void> {
    if (onConfirmActivate) await onConfirmActivate();
  }

  async function onOkConfirmBatchActivate(): Promise<void> {
    if (onConfirmBatchActivate) await onConfirmBatchActivate(selectedRows);
  }

  async function onOkConfirmDeactivate(): Promise<void> {
    if (onConfirmDeactivate) await onConfirmDeactivate();
  }

  async function onOkConfirmBatchDeactivate(): Promise<void> {
    if (onConfirmBatchDeactivate) await onConfirmBatchDeactivate(selectedRows);
  }

  async function onOkConfirmSave(): Promise<void> {
    if (onConfirmSave) await onConfirmSave();
  }

  function onClickUpdate() {
    if (handleUpdate) handleUpdate();
    else navigation.goToUpdate({ id });
  }

  function onClickDuplicate() {
    if (handleDuplicate) handleDuplicate();
    else navigation.goToDuplicate({ id });
  }

  function onClickConfirmProcess(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmProcess,
      content: contentConfirmProcess,
      onOk: onOkConfirmProcess,
    });
  }

  function onClickDelete(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmDelete,
      content: contentConfirmDelete,
      onOk: onOkConfirmDelete,
    });
  }

  function onClickBatchDelete(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmDelete,
      content: contentConfirmDelete,
      onOk: onOkConfirmBatchDelete,
    });
  }

  function onClickActivate(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmActivate,
      content: contentConfirmActivate,
      onOk: onOkConfirmActivate,
    });
  }

  function onClickBatchActivate(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmActivate,
      content: contentConfirmActivate,
      onOk: onOkConfirmBatchActivate,
    });
  }

  function onClickDeactivate(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmDeactivate,
      content: contentConfirmDeactivate,
      onOk: onOkConfirmDeactivate,
    });
  }

  function onClickBatchDeactivate(): void {
    Modal.confirm({
      mask: true,
      okText: 'Confirm',
      title: titleConfirmDeactivate,
      content: contentConfirmDeactivate,
      onOk: onOkConfirmBatchDeactivate,
    });
  }

  async function onClickSave(): Promise<void> {
    try {
      if (form) await form.validateFields();
      Modal.confirm({
        mask: true,
        okText: 'Confirm',
        title: titleConfirmSave,
        content: contentConfirmSave,
        onOk: onOkConfirmSave,
      });
    } catch (error) {}
  }

  function makeMenuMoreAction(): JSX.Element {
    return (
      <Menu style={{ width: '200px' }}>
        {showConfirmProcess && (
          <Menu.Item onClick={() => onClickConfirmProcess()}>
            <div>
              <Check style={{ marginRight: '10px' }} />
              Confirm
            </div>
          </Menu.Item>
        )}

        {(showUpdate || showDelete || showDuplicate) && showConfirmProcess && <Menu.Divider />}
        {showDelete && (
          <Menu.Item onClick={() => onClickDelete()}>
            <div>
              <TrashSimple style={{ marginRight: '10px' }} />
              Delete
            </div>
          </Menu.Item>
        )}
        {showUpdate && (
          <Menu.Item onClick={() => onClickUpdate()}>
            <div>
              <NotePencil style={{ marginRight: '10px' }} />
              Update
            </div>
          </Menu.Item>
        )}
        {showDuplicate && (
          <Menu.Item onClick={() => onClickDuplicate()}>
            <div>
              <CopySimple style={{ marginRight: '10px' }} />
              Duplicate
            </div>
          </Menu.Item>
        )}
        {(showActivate || showDeactivate) && <Menu.Divider />}
        {showActivate && (
          <Menu.Item onClick={() => onClickActivate()}>
            <div>
              <LockKeyOpen style={{ marginRight: '10px' }} />
              Activate
            </div>
          </Menu.Item>
        )}
        {showDeactivate && (
          <Menu.Item onClick={() => onClickDeactivate()}>
            <div>
              <LockKey style={{ marginRight: '10px' }} />
              Deactivate
            </div>
          </Menu.Item>
        )}
        {(showPrint || showLog || showNote || showApproval) && <Menu.Divider />}
        {showPrint && (
          <Menu.Item>
            <div>
              <Printer style={{ marginRight: '10px' }} />
              Print
            </div>
          </Menu.Item>
        )}
        {showLog && (
          <Menu.Item>
            <div>
              <UserList style={{ marginRight: '10px' }} />
              Logs
            </div>
          </Menu.Item>
        )}
        {showNote && (
          <Menu.Item>
            <div>
              <Note style={{ marginRight: '10px' }} />
              Notes
            </div>
          </Menu.Item>
        )}
        {showApproval && (
          <Menu.Item>
            <div>
              <UserGear style={{ marginRight: '10px' }} />
              Approvals
            </div>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function makeClassNameHeader(): string {
    if (!showSidebar) return 'no-sidebar';
    else if (collapsed) return 'collapsed-header';
    return 'not-collapsed-header';
  }

  function disableButtonBatch(): boolean {
    if (!selectedRows) return true;
    return selectedRows?.length == 0;
  }

  function showSeparatorRightAction() {
    if (showRightAction)
      return (
        showFilter ||
        showBatchDelete ||
        showBatchActivate ||
        showBatchDeactivate ||
        showButtonAddNew ||
        showButtonSave ||
        showDelete ||
        showActivate ||
        showDeactivate ||
        showUpdate ||
        showDuplicate
      );
    else return showButtonAddNew || showButtonSave || showFilter;
  }

  function onlyShowFilter() {
    return !showButtonAddNew && !showButtonSave && showFilter;
  }

  function onlyShowUpdate() {
    return (
      !showButtonAddNew &&
      !showButtonSave &&
      !showFilter &&
      !showButtonAddNew &&
      !showButtonSave &&
      !showDelete &&
      !showActivate &&
      !showDeactivate &&
      !showDuplicate &&
      showUpdate
    );
  }

  function RightAction() {
    if (!showRightAction) return <div></div>;
    return (
      <React.Fragment>
        {showUpdate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Update">
              <Button
                type="text"
                shape="circle"
                icon={
                  <NotePencil
                    size={16}
                    style={{
                      marginRight: onlyShowUpdate() ? '5px' : '0px',
                    }}
                  />
                }
                onClick={() => onClickUpdate()}
                style={{
                  marginLeft: onlyShowUpdate() ? longWidth : shortWidth,
                  marginRight: onlyShowUpdate() ? longWidth : shortWidth,
                  display: onlyShowUpdate() ? 'flex' : 'block',
                }}
              >
                {onlyShowUpdate() && !isMobile && <p>Update</p>}
              </Button>
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}

        {showDuplicate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Duplicate">
              <Button
                type="text"
                shape="circle"
                icon={<CopySimple size={16} />}
                onClick={() => onClickDuplicate()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}

        {showDelete && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Delete">
              <Button
                type="text"
                shape="circle"
                icon={<TrashSimple size={16} />}
                onClick={() => onClickDelete()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}

        {showActivate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Activate">
              <Button
                type="text"
                shape="circle"
                icon={<LockKeyOpen size={17} />}
                onClick={() => onClickActivate()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}

        {showDeactivate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Deactivate">
              <Button
                type="text"
                shape="circle"
                icon={<LockKey size={17} />}
                onClick={() => onClickDeactivate()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  function makeColorStatus(status) {
    if (status === EnumStatusData.active) return EnumColorStatusData.active;
    else if (status === EnumStatusData.inactive) return EnumColorStatusData.inactive;
    return '';
  }

  function onEnterSearch(e) {
    if (onSearch) onSearch(e);
  }

  function onSubmitFilter(payload) {
    if (drawerFilterProp?.onSubmitFilter) drawerFilterProp.onSubmitFilter(payload);
  }

  function DesktopToolbar() {
    return (
      <>
        {showTagStatus && rowData && <Tag color={makeColorStatus(rowData?.status)}>{rowData?.status}</Tag>}
        {showSeparatorRightAction() && <div className="separator-content"></div>}
        <RightAction />
        {showBatchDelete && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Delete">
              <Button
                type="text"
                shape="circle"
                icon={<TrashSimple size={16} />}
                disabled={disableButtonBatch()}
                onClick={() => onClickBatchDelete()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
        {showBatchActivate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Activate">
              <Button
                type="text"
                shape="circle"
                icon={<LockKeyOpen size={17} />}
                disabled={disableButtonBatch()}
                onClick={() => onClickBatchActivate()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
        {showBatchDeactivate && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Deactivate">
              <Button
                type="text"
                shape="circle"
                icon={<LockKey size={17} />}
                disabled={disableButtonBatch()}
                onClick={() => onClickBatchDeactivate()}
                style={{ marginLeft: shortWidth, marginRight: shortWidth }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
        {showFilter && (
          <React.Fragment>
            <Tooltip placement="bottom" title="Filters">
              <Button
                type="text"
                shape="circle"
                onClick={() => toggleDrawerFilter()}
                icon={
                  <Funnel
                    size={16}
                    style={{
                      marginRight: onlyShowFilter() ? '5px' : '0px',
                    }}
                  />
                }
                style={{
                  marginLeft: onlyShowFilter() ? longWidth : shortWidth,
                  marginRight: onlyShowFilter() ? longWidth : shortWidth,
                  display: onlyShowFilter() ? 'flex' : 'block',
                }}
              >
                {onlyShowFilter() && !isMobile && <div>Filters</div>}
              </Button>
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        )}
        {toolbarFixRightContent}
        {showButtonAddNew && (
          <React.Fragment>
            <Button
              type="text"
              disabled={disableAddNew}
              icon={<Plus style={{ marginRight: '7px' }} />}
              onClick={() => handleAddNew()}
              style={{ marginLeft: shortWidth, marginRight: longWidth }}
            >
              {!isMobile && 'Add New'}
            </Button>
          </React.Fragment>
        )}
        {showButtonSave && (
          <React.Fragment>
            <Button
              type="text"
              icon={<Check style={{ marginRight: '7px' }} />}
              onClick={() => onClickSave()}
              style={{ marginLeft: shortWidth, marginRight: longWidth }}
            >
              Save
            </Button>
          </React.Fragment>
        )}
      </>
    );
  }

  return (
    <div className={`custom-page-header ${makeClassNameHeader()}`}>
      <DrawerFilter
        className="drawer-filter-index"
        {...(drawerFilterProp ?? {})}
        visible={visibleDrawerFilter}
        onSubmitFilter={onSubmitFilter}
        onClose={() => toggleDrawerFilter()}
      />
      {!isMobile && (
        <div className="prefix-content">
          {showButtonBack && (
            <React.Fragment>
              <Tooltip placement="bottom" title="Back">
                <Button
                  type="text"
                  shape="circle"
                  onClick={() => navigation.goBack(currentRoute)}
                  icon={<ArrowLeftOutlined style={{ fontSize: '13px' }} />}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                />
              </Tooltip>
              <div className="separator-content"></div>
            </React.Fragment>
          )}

          {showButtonReload && (
            <React.Fragment>
              <Tooltip placement="bottom" title="Reload">
                <Button
                  type="text"
                  shape="circle"
                  loading={loadingReload}
                  onClick={() => onReloadPage()}
                  icon={<ReloadOutlined style={{ fontSize: '13px' }} />}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                />
              </Tooltip>
              <div className="separator-content"></div>
            </React.Fragment>
          )}

          {showButtonHistory && (
            <React.Fragment>
              <Dropdown overlay={makeMenuHistory(menuRouteData)} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                <Button
                  type="text"
                  shape="circle"
                  icon={<HistoryOutlined />}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                />
              </Dropdown>
              <div className="separator-content"></div>
            </React.Fragment>
          )}

          {showMoreAction && (
            <React.Fragment>
              <Dropdown overlay={makeMenuMoreAction()} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
                <Button
                  type="text"
                  shape="circle"
                  icon={<MoreOutlined rotate={90} />}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                />
              </Dropdown>
              <div className="separator-content"></div>
            </React.Fragment>
          )}
        </div>
      )}
      <Header title={title ?? label} style={{ width: '100%' }}>
        {showSearch && (
          <Row style={{ marginLeft: '20px' }}>
            <Col xl={12} lg={16} md={18} sm={20} xs={20}>
              <Input.Search
                allowClear
                placeholder="Search"
                onSearch={onEnterSearch}
                defaultValue={defaultValueSearch}
              />
            </Col>
          </Row>
        )}
      </Header>
      <div className="prefix-content">
        <DesktopToolbar />
      </div>
    </div>
  );
}
