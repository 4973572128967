import React from 'react';
import { dateFormatter } from '@base-helpers';
import { Button, Col, Row, Tooltip } from 'antd';
import { NotePencil, Trash } from 'phosphor-react';
import { RenderTag } from '@base-components';

type Props = {
  [key: string]: any;
};

export default function JournalContent({ data, handleDelete, handleUpdate }: Props): JSX.Element {
  const handleUpdateJournal = (item, id) => {
    handleUpdate(item, id);
  };

  return (
    <div>
      <div className="journal-content-title" key={data?.key}>
        <div className="creator-title">
          {dateFormatter(data?.created_at).toLocal()} | {data?.created_by?.first_name}
        </div>
        <div className="participant-title">
          <span>Participant</span> :{' '}
          <span style={{ display: 'inline-flex' }}>
            {data?.participant.length > 0
              ? data?.participant.map((item) => (
                  <RenderTag
                    style={{ display: 'flex', fontSize: 12, fontWeight: 400 }}
                    key={data.id}
                    value={`${item?.first_name} ${item?.last_name ?? ''}`}
                  />
                ))
              : '-'}
          </span>
        </div>
        <div className="description">{data?.description}</div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Tooltip placement="bottom" title="Edit">
          <Button type="text" shape="circle" icon={<NotePencil />} onClick={() => handleUpdateJournal(data, data.id)} />
        </Tooltip>
        <Tooltip placement="bottom" title="Delete">
          <Button type="text" shape="circle" icon={<Trash />} onClick={() => handleDelete(data.id)} />
        </Tooltip>
      </div>
    </div>
  );
}
