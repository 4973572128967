import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { MetricIndicatorEntity } from '../../domain/entities';
import { makeMetricIndicatorTransformer } from '../../domain/factories';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';

export function MetricIndicatorFactory(): JSX.Element {
  const transformer = makeMetricIndicatorTransformer<MetricIndicatorEntity>();
  const InitialValue: InitialValuePageProviderEntity<MetricIndicatorEntity> = {
    transformer,
    moduleKey: 'configuration-metric-indicator-pages', // make sure this key unique each module
    webUrl: WebUrlData.configuration_metric_indicator,
    apiUrl: ApiUrlData.metric_indicator,
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
