import React from 'react';
import { Button } from 'antd';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';

import { DetailPageWrapper } from '@base-components';
import { AdvanceTemplateEntity } from '../../domain/entities';
import { AdvanceTemplateDataSourceRepository } from '../../domain/repositories';

export default function PageDetail() {
  const [currentRoute] = useCurrentRoute();
  const { action } = usePageProviderData();
  const { navigation } = usePageProviderAction<
    AdvanceTemplateEntity,
    AdvanceTemplateDataSourceRepository<AdvanceTemplateEntity>
  >();

  return (
    <DetailPageWrapper>
      <div>Advance Template Page {`${action}`}:</div>
      <Button onClick={() => navigation.goBack(currentRoute)}>Back</Button>
      <Button onClick={() => navigation.goToIndex()}>Index</Button>
      <Button onClick={() => navigation.goToDetail({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Detail</Button>
      <Button onClick={() => navigation.goToCreate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Create</Button>
      <Button onClick={() => navigation.goToDuplicate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>
        Duplicate
      </Button>
      <Button onClick={() => navigation.goToUpdate({ id: 'b0d0978d-f6ff-4bd1-8719-bff5ed0e7823' })}>Update</Button>
    </DetailPageWrapper>
  );
}
