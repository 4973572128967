import { atom, useRecoilState } from 'recoil';

const menuOnNavbarRecoil = atom<boolean>({
  key: 'menuOnNavbarRecoil',
  default: true,
});

export function useShowMenuOnNavbar(): any {
  const [showMenuOnNavbar, setShowMenuOnNavbar] = useRecoilState(menuOnNavbarRecoil);
  return [showMenuOnNavbar, setShowMenuOnNavbar];
}
