import React, { useEffect, useState } from 'react';
import { useCurrentRoute, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { DetailPageWrapper, DetailBuilder } from '@base-components';
import { Card } from 'antd';

export default function PageIndex() {
  const [currentRoute] = useCurrentRoute();
  const { action } = usePageProviderData();
  const { navigation, dataSource, transformer } = usePageProviderAction();
  const [defaultData, setDefaultData] = useState<any>({});

  async function handleGetDataIndex() {
    await dataSource.handleGetIndex({
      onSuccess: ({ response }: any) => {
        const items = transformer.transformerGetIndex(response.items ?? []);
        const payload = items[0];
        setDefaultData(payload);
      },
      onFailed: () => {
        setDefaultData({});
      },
    });
  }

  useEffect(() => {
    handleGetDataIndex();
  }, []);

  function mapsUrl(latitude, longitude) {
    const lat = latitude ?? -6.9477683949803675;
    const lng = longitude ?? 107.60652816536386;
    return `https://maps.google.com/maps?q=${lat},${lng}&iwloc=&output=embed`;
  }

  function handleUpdate() {
    navigation.goToUpdate({ id: defaultData.id });
  }

  return (
    <DetailPageWrapper
      showButtonAddNew={false}
      showButtonBack={false}
      handleUpdate={handleUpdate}
      showDelete={false}
      showDuplicate={false}
      showActivate={false}
      showDeactivate={false}
      dataDummy={defaultData}
    >
      <Card className="card-content-wrapper">
        <DetailBuilder
          title="GENERAL INFORMATION"
          columns={[
            {
              fields: [
                {
                  label: 'Name',
                  name: ['detail_data', 'name'],
                  fieldType: 'text',
                },

                {
                  label: 'Address',
                  name: ['detail_data', 'address', 'address'],
                  fieldType: 'text',
                  gridColumn: { xs: 24, sm: 12, md: 10, lg: 8, xl: 6 },
                },
                {
                  label: 'Latitude',
                  name: ['detail_data', 'address', 'latitude'],
                  fieldType: 'text',
                },
                {
                  label: 'Longitude',
                  name: ['detail_data', 'address', 'longitude'],
                  fieldType: 'text',
                },
                {
                  label: 'Notes',
                  name: ['detail_data', 'notes'],
                  fieldType: 'text',
                  gridColumn: { xs: 24, sm: 12, md: 10, lg: 8, xl: 6 },
                },
              ],
            },
            {
              fields: [
                {
                  label: 'Maps',
                  name: ['detail_data', 'address'],
                  fieldType: 'text',
                  gridColumn: { span: 24 },
                  renderField(form) {
                    const lat = form.getFieldValue(['detail_data', 'address', 'latitude']);
                    const lng = form.getFieldValue(['detail_data', 'address', 'longitude']);
                    return (
                      <div>
                        <iframe
                          style={{ width: '100%', height: '57vh' }}
                          src={mapsUrl(lat, lng)}
                          frameBorder="0"
                          scrolling="no"
                        />
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </Card>
    </DetailPageWrapper>
  );
}
