import React, { useState } from 'react';
import { Drawer, Tag } from 'antd';

import { InfinityDataTable } from '@base-components';
import { dateFormatter, stringFormatter } from '@base-helpers';

import { BoardContextValueEntity, BoardProvider } from '../crm-kanban/context';
import { TaskCategoryOptions } from '../../helpers/options';

import BodyDrawer from '../crm-kanban//components/drawer-body';

import './style/index.less';
import { ScopeOptions } from '@pages/configuration-pages/metric-indicator/presentation/helpers';
import { makeCommonDataSource } from '@base-factories';

export default function TableView(props: BoardContextValueEntity): JSX.Element {
  const {
    keyBodyCard,
    keyTitleBadgeCard,
    keyTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    renderTitleCard,
    makeColorBadgeCard,
  } = props;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [dataOnDrawer, setDataOnDrawer] = useState(undefined);
  const [loadingGetDetail, setLoadingGetDetail] = useState(false);
  const dataSourceTask = makeCommonDataSource({ apiUrl: 'v1/crm/task' });

  async function onShowTable(table: any): Promise<void> {
    setShowDrawer(true);

    setDataOnDrawer(table);
    setLoadingGetDetail(true);

    await dataSourceTask.handleGetData(table.id, {
      onSuccess: ({ response }: any): any => {
        setDataOnDrawer(response);
        setLoadingGetDetail(false);
      },
      onFailed: ({ message }: any): any => {
        setDataOnDrawer(undefined);
        setLoadingGetDetail(false);
      },
    });
  }

  function onCloseShowTable(): void {
    setShowDrawer(false);
    setDataOnDrawer(undefined);
  }

  function onDeleteCard(card: any, parentCard: any): void {
    console.log({ card, parentCard });
  }

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'task_id',
      key: 'task_id',
      sorter: true,
      width: 100,
    },
    {
      title: 'Task Category',
      dataIndex: 'task_category',
      key: 'task_category',
      sorter: true,
      width: 150,
      render: (item) => TaskCategoryOptions.find((category) => category.value === item)?.label,
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      key: 'scope',
      sorter: true,
      width: 100,
      render: (item) => ScopeOptions.find((scope) => scope.value === item)?.label,
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      sorter: true,
      width: 100,
      render: (row) => row?.rc_dpw_name || '-',
    },
    {
      title: 'Church',
      dataIndex: 'sub_region',
      key: 'sub_region',
      sorter: true,
      width: 100,
      render: (row) => row?.church_name || '-',
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
      sorter: true,
      width: 150,
    },
    {
      title: 'Reporter',
      dataIndex: ['created_by', 'first_name'],
      key: 'created_by',
      sorter: true,
      width: 150,
    },
    {
      title: 'Plan Date',
      dataIndex: 'date_plan',
      key: 'date_plan',
      sorter: true,
      width: 150,
      render: (row) => (!row ? '=' : dateFormatter(row).toLocal()),
    },
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      key: 'completed_date',
      sorter: true,
      width: 150,
      render: (row) => (!row ? '-' : dateFormatter(row).toLocal()),
    },
    {
      title: 'Status',
      key: 'workflow.name',
      width: 100,
      sorter: true,
      dataIndex: ['workflow', 'name'],
      render(item) {
        if (!item) return <div></div>;
        return <Tag>{stringFormatter.capitalizeEachWord(item)}</Tag>;
      },
    },
  ];

  const contextValue: BoardContextValueEntity = {
    keyTitleCard,
    keyBodyCard,
    keyTitleBadgeCard,
    renderTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    makeColorBadgeCard,
    onDeleteCard,
    dataOnDrawer,
    onShowTable,
    onCloseShowTable,
    loadingGetDetail,
    setLoadingGetDetail,
  };

  function generateTitleDetail(): string {
    const scope = dataOnDrawer?.scope;
    if (scope === 'region') {
      return `${dataOnDrawer?.task_id} / ${dataOnDrawer?.region?.rc_dpw_name || '-'}`;
    }

    if (scope === 'sub_region') {
      return `${dataOnDrawer?.task_id} / ${dataOnDrawer?.sub_region?.church_name || '-'} `;
    }
    return dataOnDrawer?.task_id ?? '';
  }

  return (
    <>
      <BoardProvider value={contextValue}>
        <InfinityDataTable
          ignoreDefaultColumn={['status']}
          columns={columns}
          rowAction={{
            showDuplicate: () => false,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                onShowTable(record);
              },
            };
          }}
        />

        <Drawer
          title={<div>{generateTitleDetail()}</div>}
          width={500}
          onClose={onCloseShowTable}
          visible={showDrawer}
          className="kanban-drawer-detail"
        >
          <BodyDrawer />
        </Drawer>
      </BoardProvider>
    </>
  );
}
