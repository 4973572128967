import React, { useEffect, useState } from 'react';
import { Collapse, Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DragDropContext } from 'react-beautiful-dnd';
const { Panel } = Collapse;

import BodyDrawer from './components/drawer-body';
import ColumnBoard from './components/column-board';
import HeaderBoard from './components/header-board';
import HeaderDrawer from './components/drawer-header';
import SeparatorBody from './components/separator-body';

import { BoardEntity, DataSourceEntity } from './entities';
import { BoardContextValueEntity, BoardProvider, useBoardContextValue } from './context';
import './style.less';
import { useRecoilValue } from 'recoil';
import { loginAccountState } from '@base-states';

interface DragResultEntity {
  index: any;
  cardGroup: any;
  column: any;
}

interface Props extends BoardContextValueEntity {
  boardColumns?: BoardEntity[];
  dataSource?: DataSourceEntity[];
  keyHeaderLabel?: string;
  renderLabelGroup?: (item: any) => JSX.Element | React.ReactNode;
  onCardMove?: (source: DragResultEntity, destination: DragResultEntity, cardItem: any) => void;
  handleDeleteCard(payload: any): void;
  loading?: boolean;
}

export default function index(props: Props): JSX.Element {
  const {
    boardColumns = [],
    dataSource = [],
    keyHeaderLabel = 'code',
    renderLabelGroup,
    keyBodyCard,
    keyTitleBadgeCard,
    keyTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    renderTitleCard,
    makeColorBadgeCard,
    onCardMove,
    handleDeleteCard,
    loading,
  } = props;

  const { createActionForm, updateActionForm, showDrawerForm, setFieldsUpdate } = useBoardContextValue();
  const { authState } = useRecoilValue(loginAccountState);

  const [showDrawer, setShowDrawer] = useState(false);
  const [dataOnDrawer, setDataOnDrawer] = useState(undefined);
  const [dataParentOnDrawer, setDataParentOnDrawer] = useState(undefined);
  const [activeCollapse, setActiveCollapse] = useState([]);
  const [loadingGetDetail, setLoadingGetDetail] = useState(false);

  const authUser: any = authState.getAccount();

  const scopeGlobal = authUser?.scope === 'global';
  const scopeRegion = authUser?.scope === 'region' && authUser?.data_regions.length > 0;
  const scopeSubRegion =
    authUser?.scope === 'region' &&
    (authUser?.data_sub_regions.length > 0 || authUser?.data_sub_regions_tpi.length > 0);

  function dataSourceScope() {
    if (scopeGlobal) return dataSource;

    if (scopeRegion) {
      return dataSource.filter((data) => data.scope === 'region' || data.scope === 'sub_region');
    }

    if (scopeSubRegion) {
      return dataSource.filter((data) => data.scope === 'sub_region');
    }
  }

  const dataScope = dataSourceScope();

  function onChangeActiveCollapse(collapseKey) {
    setActiveCollapse(collapseKey);
  }
  useEffect(() => {
    if (dataSource && dataSource?.length > 0 && activeCollapse.length === 0) {
      const key = dataSource[0].key.toString();
      setActiveCollapse([key]);
    }
  }, [dataSource]);

  function HeaderPanel(row: any): React.ReactNode {
    if (renderLabelGroup) return renderLabelGroup(row);
    return row[keyHeaderLabel];
  }

  async function onDragEnd(result: any): Promise<void> {
    const destinationRaw: any = JSON.parse(atob(result.destination.droppableId));
    const sourceRaw: any = JSON.parse(atob(result.source.droppableId));
    const cardItem: any = JSON.parse(atob(result.draggableId));

    const destination: DragResultEntity = {
      index: result.destination.index,
      cardGroup: destinationRaw.groupItem,
      column: destinationRaw.itemBoardColumn,
    };

    const source: DragResultEntity = {
      index: result.source.index,
      cardGroup: sourceRaw.groupItem,
      column: sourceRaw.itemBoardColumn,
    };

    if (onCardMove) await onCardMove(source, destination, cardItem);
  }

  function onShowCard(card: any, parentCard: any): void {
    setShowDrawer(true);
    setDataOnDrawer(card);
    setDataParentOnDrawer(parentCard);
  }

  function setDataDrawer(data) {
    setDataOnDrawer(data);
  }

  function onShowDrawer() {
    setShowDrawer(true);
  }

  function onCloseDrawer() {
    setShowDrawer(false);
  }

  function onCloseShowCard(card: any, parentCard: any): void {
    setShowDrawer(false);
    setDataOnDrawer(undefined);
    setDataParentOnDrawer(undefined);
  }

  function onDeleteCard(card: any, parentCard: any): void {
    console.log({ card, parentCard });
  }

  function onUpdateCard(item, id) {
    updateActionForm();
    showDrawerForm();
    if (item.id === id) {
      setFieldsUpdate(item);
    }
  }

  const contextValue: BoardContextValueEntity = {
    keyTitleCard,
    keyBodyCard,
    keyTitleBadgeCard,
    renderTitleCard,
    renderBodyCard,
    renderTitleBadgeCard,
    makeColorBadgeCard,
    onShowCard,
    onCloseShowCard,
    onDeleteCard,
    dataOnDrawer,
    dataParentOnDrawer,
    handleDeleteCard,
    createActionForm,
    updateActionForm,
    onUpdateCard,
    onShowDrawer,
    onCloseDrawer,
    setDataDrawer,
    setLoadingGetDetail,
    loadingGetDetail,
  };

  return (
    <div className="crm-kanban">
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <BoardProvider value={contextValue}>
          <Row>
            <Col span={showDrawer ? 15 : 24}>
              <div className="crm-board">
                <HeaderBoard boardColumns={boardColumns} />
                <div className="card-area">
                  <Collapse bordered={false} activeKey={activeCollapse} onChange={onChangeActiveCollapse}>
                    <SeparatorBody boardColumns={boardColumns} />
                    {dataScope?.map((item, index) => {
                      return (
                        <Panel header={HeaderPanel(item)} key={item.key ?? index}>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <ColumnBoard groupItem={item} boardColumns={boardColumns} cardItem={item.cards} />
                          </DragDropContext>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </div>
              </div>
            </Col>
            {showDrawer && (
              <Col span={9}>
                <div className="crm-drawer-board">
                  <HeaderDrawer />
                  <BodyDrawer />
                </div>
              </Col>
            )}
          </Row>
        </BoardProvider>
      </Spin>
    </div>
  );
}
