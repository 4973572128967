enum MODULE_NAME {
  ACTIVITY = 'Activity',
  ACTIVITY_SUMMARY = 'Activity Summary',
  CONFIG_CATEGORY = 'Config Category',
  CONFIG_EMAIL = 'Config Email Template',
  CONFIG_METRIC_INDICATOR = 'Config Metric Indicator',
  DASHBOARD = 'Dashboard',
  DELETE_DATA = 'Maintenance Delete Data',
  GENERAL_CONFIG = 'General Config',
  HEADQUARTER = 'Data Headquarter',
  REGION = 'Data Region',
  SUB_REGION = 'Data Sub Region',
  TASK = 'Task',
  TASK_JURNAL = 'Task-Jurnal',
  USER = 'Data Users',
  WORKFLOW = 'Workflow',
}

enum Operation {
  active = 'active',
  create = 'create',
  delete = 'delete',
  inactive = 'inactive',
  update = 'update',
}

enum UserCategory {
  global = 'global',
  region = 'region',
  sub_region = 'sub_region',
}

export const ModuleOptions = [
  { label: 'Activity', value: MODULE_NAME.ACTIVITY },
  { label: 'Activity Summary', value: MODULE_NAME.ACTIVITY_SUMMARY },
  { label: 'Config Category', value: MODULE_NAME.CONFIG_CATEGORY },
  { label: 'Config Email Template', value: MODULE_NAME.CONFIG_EMAIL },
  { label: 'Config General Configuration', value: MODULE_NAME.GENERAL_CONFIG },
  { label: 'Config Metric Indicator', value: MODULE_NAME.CONFIG_METRIC_INDICATOR },
  { label: 'Config Workflow', value: MODULE_NAME.WORKFLOW },
  { label: 'Dashboard', value: MODULE_NAME.DASHBOARD },
  { label: 'Data Headquarter', value: MODULE_NAME.HEADQUARTER },
  { label: 'Data Region', value: MODULE_NAME.REGION },
  { label: 'Data Church', value: MODULE_NAME.SUB_REGION },
  { label: 'Data Users', value: MODULE_NAME.USER },
  { label: 'Maintenance Delete Data', value: MODULE_NAME.DELETE_DATA },
  { label: 'Task', value: MODULE_NAME.TASK },
  { label: 'Task Jurnal', value: MODULE_NAME.TASK_JURNAL },
];

export const OperationOptions = [
  { label: 'Active', value: Operation.active },
  { label: 'Create', value: Operation.create },
  { label: 'Delete', value: Operation.delete },
  { label: 'Inactive', value: Operation.inactive },
  { label: 'Update', value: Operation.update },
];

export const UserCategoryOptions = [
  { label: 'Global', value: UserCategory.global },
  { label: 'Region', value: UserCategory.region },
  { label: 'Church', value: UserCategory.sub_region },
];
