import { AntdIcon, PageHeader, SelectPaginate } from '@base-components';
import { ApiUrlData } from '@base-configs';
import { makeCommonDataSource } from '@base-factories';
import { useCollapsibleLayout, useFilterDataTable, usePageProviderAction, usePageProviderData } from '@base-hooks';
import { loginAccountState } from '@base-states';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Spin, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ScopeOptions, ScopeValue } from '../helpers';

import LayoutIndicator from '../components/layout-indicator';
import LayoutChart from '../components/layout-chart';
import moment from 'moment';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { notificationFailed, notificationSuccess } from '@base-helpers';
import { resetIndicatorLayout } from '../components/layout-indicator/helper';
import { resetChartLayout } from '../components/layout-chart/helper';
import { chartLayoutState, indicatorLayoutState } from '../state';
import { MdResetTv } from 'react-icons/md';
import { resetDraggableIndicatorLayout } from '../components/layout-indicator/react-draggable.helper';

export default function dashboard() {
  const { navigation } = usePageProviderAction();
  const { authState } = useRecoilValue(loginAccountState);
  const authUser: any = authState.getAccount();
  const [collapsed] = useCollapsibleLayout();
  const [showContent, setShowContent] = useState(true);

  const scope = authUser.scope;
  const sub_region = authUser?.data_sub_regions ?? [];
  const sub_region_tpi = authUser?.data_sub_regions_tpi ?? [];

  const region_ids = authUser?.data_regions ?? [];
  const sub_region_ids = [...sub_region, ...sub_region_tpi];

  const { moduleKey } = usePageProviderData();
  const [filterContent, setFilterContent] = useFilterDataTable(moduleKey);

  function makeDefaultScope(scope) {
    if (scope === ScopeValue.global) return scope;
    else if (scope === ScopeValue.region) {
      if (region_ids.length > 0) return scope;
      else if (sub_region_ids?.length > 0) return ScopeValue.sub_region;
    }
    return scope;
  }

  function filterScopeOptions(options, scope) {
    const newScope = makeDefaultScope(scope);
    if (newScope === ScopeValue.global) return options;
    else if (newScope === ScopeValue.region) return options.filter((item) => item.value !== ScopeValue.global);
    else if (newScope === ScopeValue.sub_region) return options.filter((item) => item.value === ScopeValue.sub_region);
  }

  function makeDefaultFilter(filter) {
    return {
      ...filter,
      date_from: filter?.date_from ?? moment(),
      date_to: filter?.date_to ?? moment(),
      period_type: filter?.period_type ?? 'month',
      scope: filter?.scope ?? makeDefaultScope(scope),
      region_ids: filter?.region_ids ?? region_ids,
      sub_region_ids: filter?.sub_region_ids ?? sub_region_ids,
      chart_period: filter?.chart_period ?? [moment(), moment()],
    };
  }

  const columnFilter: any = useMemo(() => {
    return [
      {
        fields: [
          {
            name: 'scope',
            label: 'Scope',
            fieldType: 'select',
            gridColumn: { span: 12 },
            selectProps: {
              options: filterScopeOptions(ScopeOptions, scope),
              customLabel(row) {
                return filterScopeOptions(ScopeOptions, scope).find((item) => item.value === row)?.label;
              },
            },
          },
          {
            gridColumn: { span: 12 },
            renderField(form) {
              const key = JSON.stringify([form.getFieldsValue() ?? {}]);
              const scope = form.getFieldValue('scope');
              const region = scope === 'region';
              const sub_region = scope === 'sub_region';
              if (region) {
                return (
                  <Form.Item
                    name="region_ids"
                    label="Region"
                    rules={[{ required: !!region, message: 'Filter region is required.' }]}
                  >
                    <SelectPaginate
                      key={key}
                      dataSourceUrl={ApiUrlData.region}
                      customLabel={(value) => {
                        return value?.rc_dpw_name;
                      }}
                      filterRequest={{
                        // region_ids: makeArrayIds({ data: region_ids }),
                        is_strict_region: true,
                        status: 'Active',
                      }}
                      transformOptions={(options) => {
                        const newScope = makeDefaultScope(authUser.scope);
                        if (newScope === 'global') return options;
                        const ids = region_ids?.map((item) => item.id) ?? [];
                        return options.filter((item) => ids.includes(item.id));
                      }}
                    />
                  </Form.Item>
                );
              }

              if (sub_region) {
                return (
                  <Form.Item
                    name="sub_region_ids"
                    label="Church"
                    rules={[{ required: !!sub_region, message: 'Filter sub region is required.' }]}
                  >
                    <SelectPaginate
                      key={key}
                      dataSourceUrl={ApiUrlData.sub_region}
                      customLabel={(value) => {
                        return value?.church_name;
                      }}
                      filterRequest={{
                        // region_ids: makeArrayIds({ data: region_ids }),
                        // sub_region_ids: makeArrayIds({ data: sub_region_ids }),
                        status: 'Active',
                      }}
                    />
                  </Form.Item>
                );
              }
            },
          },
          {
            name: 'period_type',
            label: 'Period Type',
            fieldType: 'radioGroup',
            gridColumn: { span: 12 },
            radioGroupProps: {
              options: [
                { label: 'Year', value: 'year' },
                { label: 'Month', value: 'month' },
                { label: 'Week', value: 'week' },
              ],
            },
          },
          {
            gridColumn: { span: 12 },
            renderField(form) {
              const type = form.getFieldValue('period_type');
              return (
                <Form.Item name="date_to" label="Indicator End Period">
                  <DatePicker picker={type} style={{ width: '100%' }} />
                </Form.Item>
              );
            },
          },
          {
            gridColumn: { span: 24 },
            renderField(form) {
              const type = form.getFieldValue('period_type');
              return (
                <Form.Item label="Chart Period" name={'chart_period'}>
                  <DatePicker.RangePicker picker={type} style={{ width: '100%' }} />
                </Form.Item>
              );
            },
          },
          {
            gridColumn: { span: 24 },
            renderField(form) {
              const type = form.getFieldValue('period_type');
              return (
                <Form.Item label="Year on Year Chart Period">
                  <Form.List name="yoy_chart_date_time">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Row gutter={[8, 8]} key={field.key}>
                            <Col span={22}>
                              <Form.Item {...field} name={[field.name, 'date']}>
                                <DatePicker.RangePicker picker={type} style={{ width: '100%' }} />
                              </Form.Item>
                            </Col>

                            {/* {fields.length > 1 && ( */}
                            <Col span={2}>
                              <Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              </Form.Item>
                            </Col>
                            {/* )} */}
                          </Row>
                        ))}

                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Date
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              );
            },
          },
        ],
      },
    ];
  }, []);

  const indicatorDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.metric_indicator });
  const [dataIndicator, setDataIndicator] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const chartDataSource = makeCommonDataSource({ apiUrl: ApiUrlData.dashboard });
  const [dataChart, setDataChart] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);

  function updateFilterOnData(data, filter) {
    return data.map((item) => {
      return {
        ...item,
        filter_dashboard: filter,
      };
    });
  }

  async function handleGetIndicator(filter) {
    setLoadingIndicator(true);
    await indicatorDataSource.handleGetIndex({
      params: { limit: 0, show_on_dashboard: true, status: 'Active', scopes: [filter?.scope] },
      onSuccess({ response }: any) {
        const items = response?.items ?? [];
        setLoadingIndicator(false);
        setDataIndicator(updateFilterOnData(items, filter));
      },
      onFailed(error) {
        setLoadingIndicator(false);
      },
    });
  }

  async function handleGetChart(filter) {
    setLoadingChart(true);
    await chartDataSource.handleGetIndex({
      params: { limit: 0, chartType: ['pie', 'line', 'area', 'bar'], scope: [filter?.scope] },
      onSuccess({ response }: any) {
        const items = response?.items ?? [];
        setLoadingChart(false);
        setDataChart(updateFilterOnData(items, filter));
      },
      onFailed(error) {
        setLoadingChart(false);
      },
    });
  }

  async function handleReloadData(filter) {
    handleGetChart(filter);
    handleGetIndicator(filter);
  }

  async function handleFilterData(filter) {
    if (filter.scope !== filterContent.scope) {
      setFilterContent(filter);
      handleGetChart(filter);
      handleGetIndicator(filter);
    } else {
      setFilterContent(filter);
      setDataChart((prev) => updateFilterOnData(prev ?? [], filter));
      setDataIndicator((prev) => updateFilterOnData(prev ?? [], filter));
    }
  }

  useEffect(() => {
    const newFilter = makeDefaultFilter(filterContent);
    handleGetChart(newFilter);
    handleGetIndicator(newFilter);
    window.scrollTo(0, 0);
  }, []);

  async function handleConfirmDelete(payload): Promise<void> {
    await chartDataSource.handleDelete(payload, {
      onSuccess: ({ response }: any) => {
        notificationSuccess([response]);
        setDataChart((rowData) => rowData.filter((item: any) => item.id !== payload.id));
      },
      onFailed: ({ message }: any) => {
        notificationFailed([message]);
      },
    });
  }

  async function handleDelete(payload): Promise<void> {
    Modal.confirm({
      title: 'Delete Confirmation',
      content: `Are you sure you want to delete ${payload?.configuration?.name ?? 'this data'}?`,
      onOk: () => handleConfirmDelete(payload),
    });
  }

  const [indicatorLayout, setIndicatorLayout] = useRecoilState(indicatorLayoutState);
  const [chartayout, setChartLayout] = useRecoilState(chartLayoutState);

  function onLayoutReset() {
    resetIndicatorLayout();
    resetChartLayout();
    resetDraggableIndicatorLayout();
    setIndicatorLayout(undefined);
    setChartLayout(undefined);
    resetLayout();
  }

  function handleResetLayout(): void {
    Modal.confirm({
      title: 'Reset Layout Confirmation',
      content: 'Are you sure you want to reset layout?',
      onOk: () => onLayoutReset(),
    });
  }

  const isNoContent = useMemo(() => {
    const newChart = dataChart ?? [];
    const newIndicator = dataIndicator ?? [];
    if (newChart.length === 0 && newIndicator.length === 0) return true;
    return false;
  }, [dataChart, dataIndicator]);

  function resetLayout() {
    setShowContent(false);
    setTimeout(() => {
      setShowContent(true);
    }, 0);
  }

  useEffect(() => {
    resetLayout();
  }, [collapsed]);

  return (
    <React.Fragment>
      <PageHeader
        title="Dashboard"
        showButtonHistory={false}
        showButtonBack={false}
        showButtonMoreAction={false}
        showButtonSave={false}
        showSearch={false}
        showFilter={true}
        showButtonAddNew={scope === ScopeValue.global}
        onReload={() => handleReloadData(makeDefaultFilter(filterContent))}
        handleAddNew={() => navigation.goToCreate()}
        drawerFilterProp={{
          onSubmitFilter(payload) {
            handleFilterData({ ...payload, ...(makeDefaultFilter(payload) ?? {}) });
          },
          defaultValueFilter: {
            ...filterContent,
            ...(makeDefaultFilter(filterContent) ?? {}),
          },
          formBuilderProps: {
            columns: columnFilter,
          },
        }}
        toolbarFixRightContent={
          <React.Fragment>
            <Tooltip placement="bottom" title="Reset Layout">
              <Button
                type="text"
                shape="circle"
                icon={<MdResetTv size={16} />}
                onClick={() => handleResetLayout()}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </Tooltip>
            <div className="separator-content"></div>
          </React.Fragment>
        }
      />

      {showContent && (
        <div style={{ padding: 24 }}>
          <Spin spinning={loadingChart || loadingIndicator}>
            <Card
              hidden={!isNoContent || loadingChart || loadingIndicator}
              className="card-content-wrapper"
              style={{ marginBottom: 20 }}
            >
              <div style={{ textAlign: 'center', paddingTop: 30, paddingBottom: 30 }}>
                {loadingChart || loadingIndicator ? 'Load content' : 'No chart content.'}
              </div>
            </Card>
            <LayoutIndicator data={dataIndicator} loading={loadingIndicator} />
            <div style={{ marginBottom: 15 }}></div>
            <LayoutChart
              showDelete={scope === ScopeValue.global}
              showUpdate={scope === ScopeValue.global}
              handleDelete={handleDelete}
              loading={loadingChart}
              data={dataChart}
            />
          </Spin>
        </div>
      )}
    </React.Fragment>
  );
}
