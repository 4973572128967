import React, { useEffect, useState } from 'react';
import { Palette } from 'phosphor-react';
import { Button, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import './style.less';

export interface ColorPickerProps {
  value?: string;
  onChange?: (color: string) => void;
}

export function ColorPicker(props: ColorPickerProps) {
  const { value, onChange } = props;
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState(value);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const presetColors = ['rgb(0,0,0)', 'rgb(0,0,255)', 'rgb(0,255,0)', 'rgb(255,0,0)', 'rgb(255,255,255)'];
  function onChangeComplete(color: any) {
    if (color) setColor(color.hex);
  }

  function onConfirm() {
    if (onChange) onChange(color);
    hide();
  }

  useEffect(() => {
    if (value && value !== color) setColor(value);
  }, [value]);

  return (
    <div className="manual-color-picker">
      <Popover
        content={
          <div>
            <SketchPicker presetColors={presetColors} color={color ?? '#fff'} onChangeComplete={onChangeComplete} />
            <div style={{ marginTop: '20px', display: 'flex' }}>
              <Button type="default" style={{ marginLeft: 'auto' }} onClick={() => hide()}>
                Cancel
              </Button>
              <Button type="primary" style={{ marginLeft: '5px' }} onClick={() => onConfirm()}>
                Save
              </Button>
            </div>
          </div>
        }
        // title="Color Picker"
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <div className="color-picker-wrapper">
          <div
            style={{
              height: '20px',
              width: '70px',
              background: value,
              // border: '1px solid #e0e0e0',
              borderRadius: '2px',
            }}
          />
          <Button shape="circle" size="small" type="text" icon={<Palette size={18} color="rgba(0, 0, 0, 0.25)" />} />
        </div>
      </Popover>
    </div>
  );
}
