import {
  BaseEntity,
  BaseDataSourceConstructorEntity,
  BaseManagerParamsEntity,
  BaseResponseIndexEntity,
  BaseResponseEntity,
} from '@base-entities';
import { ErrorRequest } from '@base-protocols';
import { BaseRemoteDataSource } from '@base-data-sources';
import { AdvanceTemplateDataSourceRepository } from '../../domain/repositories';

export class AdvanceTemplateDataSource<E extends BaseEntity = BaseEntity>
  extends BaseRemoteDataSource<E>
  implements AdvanceTemplateDataSourceRepository<E>
{
  constructor(params: BaseDataSourceConstructorEntity) {
    super(params);
  }

  async handleCustomRequest(manager: BaseManagerParamsEntity<BaseResponseIndexEntity<E>>): Promise<void> {
    const { params, onSuccess, onFailed } = manager;
    try {
      const response: any = await this.requestHttpClient.request({
        url: this.makeApiUrl(this.getIndexUrl),
        method: 'GET',
        params: this.makeParams(params),
      });
      const { message } = response as BaseResponseEntity<BaseResponseIndexEntity<E>>;
      onSuccess({ response: message });
    } catch (error) {
      const { message, statusCode } = this.handleError(error) as ErrorRequest;
      onFailed({ message, statusCode });
    }
  }
}
