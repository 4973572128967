import React from 'react';
import { ApiUrlData, WebUrlData } from '@base-configs';
import { InitialValuePageProviderEntity } from '@base-entities';
import { PageProvider } from '@base-providers';
import { PageController } from '@base-components';

import { DataUserEntity } from '../../domain/entities';

import PageIndex from '../pages';
import PageForm from '../pages/form';
import PageDetail from '../pages/detail';
import { makeUserTransformer } from '../../domain/entities/factories';

export function DataUserFactory() {
  const transformer = makeUserTransformer<DataUserEntity>();
  const InitialValue: InitialValuePageProviderEntity<DataUserEntity> = {
    transformer,
    moduleKey: 'data-user-page',
    webUrl: WebUrlData.data_user,
    apiUrl: ApiUrlData.users,
    // apiUrl: ApiUrlData.sales_basic_price,
    // baseApiUrl: 'http://api.testing.erp.eigen.co.id/api/',
  };

  return (
    <PageProvider initialValue={InitialValue}>
      <PageController
        PageIndex={PageIndex}
        PageDetail={PageDetail}
        PageCreate={PageForm}
        PageUpdate={PageForm}
        PageDuplicate={PageForm}
      />
    </PageProvider>
  );
}
