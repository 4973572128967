export const AuthWebUrl = {
  // Sample Path
  about: '/about',
  testing: '/testing',
  template: '/template',
  simple_template: '/template/simple-template',
  advance_template: '/template/advance-template',

  // Implement Path
  map: '/map',
  dashboard: '/dashboard',
  report: '/report',

  activity: '/activity',
  activity_activities: '/activity/activities',
  activity_summary: '/activity/summary',

  crm: '/crm',
  crm_board: '/crm/board',

  data: '/data',
  data_headquarter: '/data/headquarter',
  data_region: '/data/region',
  data_user: '/data/user',

  configuration: '/configuration',
  configuration_email_template: '/configuration/email-template',
  configuration_metric_indicator: '/configuration/metric-indicator',
  configuration_workflow: '/configuration/workflow',
  configuration_general_configuration: '/configuration/general-configuration',
  configuration_dashboard: '/configuration/dashboard',
  configuration_category: '/configuration/category',

  maintenance: '/maintenance',
  maintenance_log: '/maintenance/log',
  maintenance_database_deletion: '/maintenance/database-deletion',

  notification: '/notification',
};
