import { BaseEntity } from '@base-entities';
import { BaseTransformer } from '@base-transformers';
import * as _ from 'lodash';

export class UserTransformer<E extends BaseEntity = BaseEntity> extends BaseTransformer<E> {
  transformerUpdate(payload: E): E {
    if (!payload) return payload;
    const cleanVal: any = _.omit(payload, ['id', 'form_action']);
    Object.assign(cleanVal, {
      title_id: 1,
      profile_image: payload?.profile_image ?? undefined,
      phone: payload?.phone ?? undefined,
      church_name: [],

      //FIXME di sesuaikan dengan hasil inputan
      scope_regions: payload?.scope_regions ?? [],
      scope_sub_regions: payload?.scope_sub_regions ?? [],
      scope_sub_regions_tpi: payload?.scope_sub_regions_tpi ?? [],
    });
    // console.log(cleanVal);
    return cleanVal;
  }

  transformerCreate(payload: E): E {
    const cleanVal = this.transformerUpdate(payload);
    // Object.assign(cleanVal, {
    //   title_id: 1,
    // });
    return cleanVal;
  }

  transformerDuplicate(payload: E): E {
    return this.transformerCreate(payload);
  }
}
