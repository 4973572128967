export enum EnumStatusData {
  draft = 'Draft',
  active = 'Active',
  inactive = 'Inactive',
  posted = 'Posted',
  obsolete = 'Obsolete',
}

export enum EnumColorStatusData {
  // active = '#87d068',
  active = 'success',
  // inactive = '#cd201f',
  inactive = 'error',
}
export interface BaseEntity {
  id?: string;
  [key: string]: any;
}
