import React from 'react';
import { PageNotFound } from '@base-components';
import { usePageProviderData } from '@base-hooks';
import NotFoundPage from '@pages/result-pages/404';

interface PageControllerProps {
  PageIndex?: any;
  PageDetail?: any;
  PageCreate?: any;
  PageUpdate?: any;
  PageDuplicate?: any;
}

export function PageController(props: PageControllerProps) {
  const { isDetail, isCreate, isUpdate, isDuplicate, action } = usePageProviderData();
  const {
    PageIndex = PageNotFound,
    PageDetail = PageNotFound,
    PageCreate = PageNotFound,
    PageUpdate = PageNotFound,
    PageDuplicate = PageNotFound,
  } = props;

  if (!action) return <PageIndex />;
  else if (isDetail) return <PageDetail />;
  else if (isCreate) return <PageCreate />;
  else if (isUpdate) return <PageUpdate />;
  else if (isDuplicate) return <PageDuplicate />;
  else return <NotFoundPage />;
}
